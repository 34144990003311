<template>
 <div>

  <!-- {{ col_five }} -->
  <!-- obj_user {{obj_user}} <br/> <br/> -->
  <!-- pode:{{pode}}  <br/><br/> -->
  <!-- user_permissoes:{{user_permissoes}}  <br/><br/> -->
  <!-- {{vai_imprimir}} -->
  <!-- obj_dados_empresa: {{obj_dados_empresa}} <br/> -->
  <!-- obj_clicado: {{obj_clicado}} -->
  <!-- {{value_expansion}} -->
  <!-- {{selected}} -->
  <!-- {{ items_pacientes }} <br/><br/> -->
  <!-- {{ obj_dados_paciente }} -->
  <!-- {{whats_aviso}} -->
  <!-- {{unificar_dados}} -->
  <!-- {{ unificar_antigo }}
  {{ unificar_novo }} -->
 <!-- {{items_header}} -->
  <!-- {{ items_agendamentos }} -->
  <!-- {{ items_clicou }} -->
    <!-- {{ items_lista }} -->


  <span v-if="(user_permissoes.cadastrar)">
  <v-fab-transition>
             <v-tooltip 
               bottom
             >
               <template v-slot:activator="{ on, attrs }">
                 <v-btn
                   v-bind="attrs"
                   v-on="on"
 
                   class="mt-8"
                   @click="novo_cliente('')"
                   color="primary"
                   dark
                   small
                   absolute
                   top
                   right
                   fab
                 >
                 <!-- v-show="!hidden" -->
                   <v-icon>mdi-account-plus</v-icon>
                 </v-btn>
               </template>
               <span> Novo Cadastro </span>
             </v-tooltip>
         </v-fab-transition>
        </span>

  <template>
  <div class="text-center">
    <v-dialog
      v-model="dialog_img"
      width="500"
    >

      <v-card>
        <v-card-title class="text-h6 white lighten-2 justify-center">
          {{items_clicou.NOME}} 
        </v-card-title>

        <v-card-text>
          <v-img
            alt="user"
            :src="items_clicou.FOTO_URL"
            height="30%"
            v-if="(items_clicou.FOTO_URL)"
          >
         </v-img>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="dialog_img = false"
          >
            FECHAR
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>


<v-dialog
  v-model="dialog_editar"
  max-width="300px"
  scrollable
>
  <v-card
    class="mx-auto"
  >
    <v-list>

      <v-subheader>
        <h4>OPÇÕES</h4>
      </v-subheader>

      <v-divider
        class="mx-0"
      ></v-divider>

      <v-list-item
      link
      @click="editItem(items_clicou, false)"
      >
        <v-list-item-icon>
          <v-icon>mdi-pencil</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Alterar Cadastro</v-list-item-title>
      </v-list-item>


      <!-- <span v-if="(esta_empresa('artmed'))"> -->
      <span>
      <v-divider
        class="mx-4"
      ></v-divider>


      <v-list-item
      link
      @click="abrir_janela_modal(items_clicou,'novo'), dialog_editar=false"
      >
        <v-list-item-icon>
          <v-icon color="orange">mdi-credit-card-multiple</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Lançamentos</v-list-item-title>
      </v-list-item>
    </span>

      

      <v-divider
        class="mx-4"
      ></v-divider>

      <v-list-item
      link
      dense
      @click="adicionar_exames(items_clicou)"
      >
        <v-list-item-icon>
          <v-icon>mdi-microscope</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Adicionar Resultado de Exames</v-list-item-title>
      </v-list-item>
      

      <v-divider
        class="mx-4"
      ></v-divider>


          
      <v-list-group
          no-action
          
        >
          <template v-slot:activator>

              <v-icon color="red" class="mr-8">
                <!-- mdi-account-switch  -->
                mdi-account-convert 
                <!-- mdi-account-multiple-outline  -->
                </v-icon>

            <v-list-item-content>
              <v-list-item-title>Unificar Cadastros</v-list-item-title>
            </v-list-item-content>
          </template>
        

            <v-list-item
            link
            dense
            @click="inicia_unificar('antigo')"
            >
              <v-icon class="mr-2" color="orange">
                  <!-- mdi-account-switch  -->
                  mdi-account-circle
                  <!-- mdi-account-multiple-outline  -->
              </v-icon>
              <v-list-item-title class="orange--text">Este é o Antigo</v-list-item-title>
            </v-list-item>

            <v-list-item
            link
            dense
            @click="inicia_unificar('novo')"
            >
              <v-icon class="mr-2" color="blue">
                  <!-- mdi-account-switch  -->
                  mdi-account-circle
                  <!-- mdi-account-multiple-outline  -->
              </v-icon>
              <v-list-item-title class="blue--text">Este é o Novo</v-list-item-title>
            </v-list-item>

      </v-list-group>


      <v-divider
        class="mx-4"
      ></v-divider>


    </v-list>
  </v-card>
</v-dialog>



<v-dialog
          v-model="dialog_tabela"
          persistent
          max-width="990"
          scrollable
          :fullscreen="vai_imprimir"
      >

      <v-card
      class="" width="15cm" 
      :elevation="(vai_imprimir) ? 0 : 2"
      >
      <!-- height="80%" -->


      <span v-if="(vai_imprimir)">
        <v-card-title class="mb-1 text-h5 d-flex align-center justify-center" >{{obj_dados_empresa.NOME}}</v-card-title>
        <v-divider class="mx-7"></v-divider>
      </span>

      <v-card-title v-if="(vai_imprimir)" class="mb-1 text-h6 d-flex align-center justify-center">{{items_clicou.NOME}}</v-card-title>
      <v-card-title v-else class="mb-1 text-h6">{{items_clicou.NOME}}</v-card-title>

      <v-card-subtitle>

          <!-- <span class="no-print mr-1 blue--text" v-text="(item_edita.CODIGO>0) ? 'Alterar ' : 'Novo '"/>   -->

      <span v-if="(!vai_imprimir)">
          <span class="text-h5 no-print blue--text mr-2" >
             {{ ((item_edita.CODIGO>0) ? 'Alterar ': 'Novo ') }}  
          </span>

          <span class="text-h6 ml-n2">
              {{ (card_titulo !='Registros') ? card_titulo : '' }}  
          </span>

          <span class="no-print" v-if="(item_edita.CODIGO>0)">
            {{item_edita.CODIGO}}
          </span>
      </span>

      </v-card-subtitle>

      <!-- <v-card-title>
          <span class="text-h5" 
            v-text="(item_edita.CODIGO>0) ? 'Alterar '+ card_titulo +' '+item_edita.CODIGO : 'Novo '+ card_titulo "
          >
          Alterar
          </span>
      </v-card-title> -->



          <!-- passo 4 monto os campos editaveis -->
          <!-- :readonly = "readonly" -->
              <v-card-text class="pa-1">
                <v-form
                  ref="form"
                  v-model="valido"
                  >
                  <v-container>
                      <v-row>
                          <v-col
                              class=""
                              v-for="(item, i) in items_editaveis"
                              :key="i"
                              :cols="(isMobile) ? 12 : item.cols"
                              v-if="parseInt(item.cols)>0"   
                          >
                          <!-- :cols="item.cols" -->


                          <v-card class="grey lighten-5"  elevation="4"
                             v-if="item.tag == 'v-editor'"
                            >
                              <v-card-text>
                                  <editor-content
                                    :editor="editor"
                                    class="white lighten-5"
                                    :style="[{ minHeight: altura_meio + 'cm'}]"
                                    v-if="item.tag == 'v-editor'"
                                  />
                              </v-card-text>
                            </v-card>


                            <p v-if="item.tag == 'p'" :class="[item.class]"
                             v-html="item_edita[item.value]">
                            </p>

                            <span v-if="(item.tag == 'v-img')&&(item_edita[item.value]?.length >10)" style="display: block; text-align: center;">
                              <!-- caso esteja preenchido mostra..caso contrario ele mostra o botao para upload -->
                              <v-divider class="mx-10 my-4"/>
                              <img
                                v-if="(item.tag == 'v-img')&&(item_edita[item.value].length >10)"
                                alt="user"
                                :src="item_edita[item.value]"
                                :width="[item.width]"
                              >
                              </img>
                            </span>


                              <v-chip
                               v-if="item.tag == 'v-chip'" :color="item.color" @click="clicou_em(item.value)"
                              >
                                {{ item.text }}
                              </v-chip>


                              <v-text-field
                                  dense
                                  v-model="item_edita[item.value]"
                                  :label="item.rule == 'S' ? item.text+' *': item.text"
                                  :type="item.type"
                                  v-if="item.tag == 'v-text'"
                                  @keyup="meu_keyup($event, item.value)"
                                  :rules="item.rule == 'S' ? notnullRules: noRules"
                                  v-mask = "getVmask(item.vmask)"
                                  :class="[item.class]"
                              ></v-text-field>
                              <!-- :autofocus="i==1" -->


                              <v-combobox
                                  dense
                                  v-model="item_edita[item.value]"
                                  :label="item.text"
                                  v-if="item.tag == 'v-autocomplete'"
                                  :items="item.tag_items"
                                  :rules="item.rule == 'S' ? notnullRules: noRules"
                              ></v-combobox>
                              
                              <v-select
                                  :class="[item.class]"
                                  dense
                                  v-model="item_edita[item.value]"
                                  :label="item.text"
                                  v-if="item.tag == 'v-select'"
                                  :items="item.tag_items"
                                  :rules="item.rule == 'S' ? notnullRules: noRules"

                                  item-value='CODIGO'
                                  item-text='NOME'
                                  :clearable = "item.tag_clear == 'S'"
                                  :persistent-hint="item.cols>2"
                                  :hint="item.hint"
                              >
                              
                              <!-- <template slot="selection" v-slot-scope="item"> -->
                                
                              <!-- <template slot="selection" v-slot-scope="item"> -->
                                <template v-slot:label>
                                  <v-tooltip bottom v-if="item.hint">
                                      <template v-slot:activator="{ on, attrs }">
                                        <span
                                            v-bind="attrs"
                                            v-on="on"
                                        > 
                                          <span :class="item.class">
                                            {{ item.text }} 
                                            <!-- {{ item_edita[item.value] }} -->
                                          </span>
                                        </span>

                                      </template>
                                      <span> {{ item.hint }} </span>
                                    </v-tooltip>
                                </template>
    
                            </v-select>


                              <!-- CAMPO ANOTAÇÕES OU OBSERVAÇÃO -->
                              <v-textarea
                                v-model="item_edita[item.value]"
                                :label="item.text"
                                v-if="item.tag == 'v-textarea'"
                                :hint="item.hint"
                                :persistent-hint="item.cols>2"
                                dense
                                :rows="(vai_imprimir) ? '35' : item.rows"
                                :autofocus="i==2"
                                :rules="item.rule == 'S' ? notnullRules: noRules"
                                :outlined="(vai_imprimir) ? false : true"
                              >
                              </v-textarea>




                              <!-- INICIO DIV IMAGEM -->
                              <div class=""
                                v-if="item.tag == 'img'"
                              >
                              
                              <v-row dense class="grey lighten-5">
                                <v-col cols="8" class="">
                                  <span class="pb-2">Logo</span>
                                    <v-img
                                    v-if="urlLogo"
                                    max-height="90"
                                    max-width="170"
                                    :src="urlLogo"
                                    contain
                                  >
                                  </v-img>
                                </v-col>
                                
                                <v-col class="mt-5">
                                  <p class="primary--text"><label for="file_upload" style="cursor: pointer;">Alterar Logo</label></p>
                                  <!-- <p class="primary--text"><label for="file_upload" style="cursor: pointer;">Alterar Logo</label></p> -->
                                  <!-- <p>Remover</p> -->
                                  <v-btn text x-small color="red" for="file_upload"
                                    @click="item_edita[item.value] = '', urlLogo=''"
                                  >
                                    Remover
                                  </v-btn>
                                </v-col>


                                </v-row>
                                <!-- oculto -->
                                <v-row dense class="grey lighten-3">
                                  <v-col>
                                    <!-- necessario para fazer upload logo -->
                                    <v-file-input
                                            v-model="files_upload"
                                            accept="image/*"
                                            label="Logomarca"
                                            @change="upload_logo(files_upload,'DSi','logo',item.value)"
                                            style="display: none"
                                            id="file_upload"
                                          ></v-file-input>
                                    <!-- necessario para fazer upload logo -->
                                  </v-col>
                                </v-row>
                              </div>
                              <!-- FINAL DIV IMAGEM -->


                              
                              
                          </v-col>
                      </v-row>
                  </v-container>
                </v-form>
              </v-card-text>


              <v-divider class="mx-10" v-if="(!vai_imprimir)"></v-divider>

              <span v-if="(vai_imprimir)">
                 <span class="d-flex align-center justify-center black--text fs-10" > {{ LSendereco }} - {{obj_dados_empresa.FONE1}}</span>
              </span>

      <v-card-actions class="no-print">
          <v-btn
              x-small
              color="red"
              text
              @click="remover_tabela(tabela_selecionada, item_edita, card_titulo), dialog_tabela = false"
              v-if="pode_alterar(tabela_selecionada)"
          >
            Excluir
          </v-btn>

          <v-spacer></v-spacer>


          <v-btn
              color="blue"
              text
              @click="imprimir_pagina()"
              class="mr-5"
              small
          >
            Imprimir.
          </v-btn>

          <v-btn
              color="red"
              text
              @click="dialog_tabela = false"
          >
            Cancelar
          </v-btn>
          
          &nbsp

          <v-btn
              :disabled="!valido"
              color="primary"
              @click="salvar_tabela(tabela_selecionada, item_edita, 'dialog_tabela')"
              v-if="pode_alterar(tabela_selecionada)"
          >
            Salvar
          </v-btn>
      </v-card-actions>
      </v-card>
  </v-dialog>





<resultado_exameVue
:pro_ide = 'items_clicou.CODIGOCLIENTE'
:pro_nome = 'items_clicou.NOME'
@emit_nome="emit_acao"
v-if="dialog_re_exames"
>
</resultado_exameVue>




    <v-snackbar
      v-model="snackbar"
      :timeout="2000"
      top
      :color="snack_color"    
    >
      {{ snack_text }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="white"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Fechar
        </v-btn>
      </template>
    </v-snackbar>


        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="text-h5">Deseja realmente excluir este ítem?  </v-card-title>
            <p/>
            <v-card-subtitle> {{ editedItem.nome }} </v-card-subtitle>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="red" text @click="closeDelete">Cancelar</v-btn>
              <v-btn color="primary"  @click="deleteItemConfirm">Confirmar</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>


        <v-dialog
          v-model="dialog"
          persistent
          max-width="950px"
          scrollable 
        >        
          
          <v-card>
            <v-card-title>
              <span class="text-h5">{{ formTitle }}</span>
              <span class="text-overline"> &nbsp {{ editedItem.id }}</span>
              <!-- <v-subheader>Subheader</v-subheader> -->
            </v-card-title>

            <v-card-text>
              <v-form
              ref="form"
              :readonly = "readonly"
              >
              <v-container>
                <v-row>
                  <v-col
                    cols="9"
                    md="5"
                    
                  >
                    <v-text-field
                      v-model="editedItem.NOME"
                      label="Nome*"
                      autofocus
                      type="text"
                      @input="allUpper($event, 'editedItem','NOME')"
                      :rules="minimoRules"
                    ></v-text-field>
                  </v-col>

                  <v-col
                    cols="3"
                    md="1"
                  >                   
                   <v-select
                    v-model="editedItem.ATIVO"
                    label="Ativo"
                    :items="['S', 'N']"
                    :rules="notnullRules"
                    ></v-select>

                  </v-col>

                  <v-col
                    cols="6"
                    md="3"
                    
                  >
                  <v-select
                  v-model="items_selecionados.cod_convenio"
                  label="Convênio*"
                  :items="convenios"
                  :rules="notnullRules"
                  item-value='CODIGO'
                  item-text='NOME'
                  @change = 'muda_convenio($event)'
                  :return-object = 'true'
                  >
                  
                  
                    <template slot='selection' slot-scope='{ item }'>
                      {{ item.NOME }}
                    </template>
  
                    <template slot='item' slot-scope='{ item }'>
                      <h5>{{ item.NOME }}</h5> 
                           <!-- {{ item.CODIGO }}  -->
                    </template>

                  </v-select>

                  </v-col>


                 
                  <v-col
                    cols="6"
                    md="3"
                  >
                    <v-text-field
                      v-model="editedItem.NUMCARTEIRA"
                      label="Nº Carteira"
                      type="text"
                    ></v-text-field>
                  </v-col>

                  <v-col
                    cols="6"
                    md="3"
                  >
                    <v-text-field
                      v-model="editedItem.DATANASCIMENTO"
                      label="Nascimento*"
                      type="date"
                      
                    >
                    <!-- :rules="notnullRules" -->
                    <!-- @blur="oi" -->
                    </v-text-field>
                    <!-- <v-text-field
                      v-model="editedItem.DATANASCIMENTO"
                      label="Nascimento*"
                      type="date"
                      :rules="notnullRules"
                    >
                    </v-text-field> -->

                  </v-col>

              

                  <v-col
                    cols="6"
                    md="2"
                  >
                    <v-text-field
                      v-model="editedItem.RG"
                      label="RG"
                      type="text"
                    ></v-text-field>
                  </v-col>
                  
                  <v-col
                    cols="6"
                    md="2"
                  >
                    <v-text-field
                      v-model="editedItem.CPF"
                      label="CPF"
                      type="text"
                      v-mask = "'###.###.###-##'"
                    ></v-text-field>
                  </v-col>
                  
                  <v-col
                    cols="6"
                    md="2"
                    
                  >
                   <v-select
                    v-model="editedItem.SEXO"
                    label="Sexo"
                    :items="['FEMININO', 'MASCULINO']"
                    ></v-select>
                  </v-col>                  

                  <v-col
                    cols="6"
                    md="3"
                  >
                    <v-select
                      v-model="editedItem.ESTADOCIVIL"
                      label="Estado Civil"
                      :items="[
                          'MENOR',
                          'SOLTEIRO (A)',
                          'CASADO (A)',
                          'SEPARADO (A)',
                          'DIVORCIADO (A)',
                          'UNIAO ESTAVEL',
                          'VIUVO (A)'
                          ]"
                      type="text"
                    ></v-select>
                  </v-col>


                  <v-col
                    cols="6"
                    md="2"
                  >
                    <v-text-field
                      v-model="editedItem.CELULAR"
                      label="Celular Whatss *"
                      placeholder="(  )    -    "
                      :rules="notnullRules"
                      v-mask = "'(##) #####-####'"
                    ></v-text-field>
                  </v-col>

                  <v-col
                    cols="6"
                    md="2"
                  >
                    <v-text-field
                      v-model="editedItem.FONE1"
                      label="Celular 2"
                      placeholder="(  )    -    "
                      v-mask = "'(##) #####-####'"
                    ></v-text-field>
                  </v-col>

                  <v-col
                    cols="6"
                    md="2"
                  >
                    <v-text-field
                      v-model="editedItem.FONE2"
                      label="Fone"
                      placeholder="(  )    -    "
                      v-mask = "'(##) ####-####'"
                    ></v-text-field>
                  </v-col>



                  <v-col
                    cols="12"
                    md="6"
                  >
                    <v-text-field
                      v-model="editedItem.EMAIL"
                      label="Email"
                      type="email"
                    ></v-text-field>
                  </v-col>


                  <v-col
                    cols="6"
                    md="2"
                  >
                    <v-text-field
                      v-model="editedItem.CEP"
                      label="CEP"
                      type="text"
                      placeholder="00000-000"
                      v-mask = "'#####-###'"
                      @keyup="consulta_cep(editedItem.CEP)"
                      @click:append="consulta_cep(editedItem.CEP)"
                      append-icon="mdi-magnify"
                    ></v-text-field>
                  </v-col>
                  
                  
                  <v-col
                    cols="12"
                    md="4"
                  >
                    <v-text-field
                      v-model="editedItem.ENDERECOCOMPLETO"
                      @keydown="firstUpper($event, 'ENDERECOCOMPLETO')"
                      label="Endereço Nº"
                      type="text"
                    ></v-text-field>
                  </v-col>


                  <v-col
                    cols="5"
                    md="2"
                  >
                    <v-text-field
                      v-model="editedItem.BAIRRO"
                      @keydown="firstUpper($event, 'BAIRRO')"
                      label="Bairro"
                      type="text"
                    ></v-text-field>
                  </v-col>
                  
                  
                  <v-col
                    cols="5"
                    md="3"
                  >
                    <v-text-field
                      v-model="editedItem.CIDADE"
                      @keydown="firstUpper($event, 'CIDADE')"
                      label="Cidade"
                      type="text"
                    ></v-text-field>
                  </v-col>
                  
                  <v-col
                    cols="2"
                    md="1"
                  >
                    <v-text-field
                      v-model="editedItem.UF"
                      label="UF"
                      type="text"
                      @input="allUpper($event, 'editedItem','UF')"
                    ></v-text-field>
                  </v-col>
                  
                  <v-col
                    cols="12"
                    md="4"
                  >
                    <v-text-field
                      v-model="editedItem.PROFISSAO"
                      @keydown="firstUpper($event, 'PROFISSAO')"
                      label="Profissão"
                      type="text"
                    ></v-text-field>
                  </v-col>
                  
                  <v-col
                    cols="12"
                    md="4"
                  >
                    <v-text-field
                      v-model="editedItem.NOMEMAE"
                      @keydown="firstUpper($event, 'NOMEMAE')"
                      label="Nome da Mãe"
                      type="text"
                    ></v-text-field>
                  </v-col>
                  
                  <v-col
                    cols="12"
                    md="4"
                  >
                    <v-text-field
                      v-model="editedItem.NOMEPAI"
                      @keydown="firstUpper($event, 'NOMEPAI')"
                      label="Nome do Pai"
                      type="text"
                    ></v-text-field>
                  </v-col>
                  
                  <v-col
                    cols="12"
                    md="12"
                  >
                    <v-textarea
                      v-model="editedItem.OBS"
                      label="Anotação"
                      hint="Máximo de 200 caracteres"
                      @keydown="firstUpper($event, 'OBS')"
                      background-color="amber lighten-5"
                    >
                    <!-- background-color="amber lighten-4" -->
                    </v-textarea>
                  </v-col>
                  
                  <v-col
                    cols="12"
                    md="12"
                  >
                    <v-textarea
                      v-model="editedItem.AVISO"
                      label="Aviso"
                      hint="Máximo de 200 caracteres"
                      @keydown="firstUpper($event, 'AVISO')"
                      background-color="amber lighten-5"
                    >
                    <!-- background-color="amber lighten-4" -->
                    </v-textarea>
                  </v-col>


                </v-row>

                <v-row>
                  <v-col
                    cols="6"
                    md="6"
                  >
                    <v-text-field
                      v-model="editedItem.FOTO_URL"
                      label="URL da Foto"
                      type="text"
                      dense
                    >
                    </v-text-field>
                  </v-col>
                </v-row>

              </v-container>
              </v-form>
            </v-card-text>

            <small>&nbsp * Campos necessários</small>
            
            <v-card-actions>
              <v-btn
                x-small
                color="red"
                text
                @click="remover_cliente(editedItem.CODIGOCLIENTE)"
                v-if = "(readonly==false)&&(user_permissoes.cadastrar)"
              >
                Excluir
              </v-btn>

              <v-spacer></v-spacer>
              <v-btn
                color="red"
                text
                @click="close"
              >
                Cancelar
              </v-btn>
              
              <v-btn
                color="primary"
                @click="qual_cli_exec"
                v-if = "(readonly==false)&&(user_permissoes.cadastrar)"
              >
                Salvar
              </v-btn>
            </v-card-actions>


          <!-- <div
          class="blue"
          
          >
            <v-row>
              <v-col
              class=""
              >
              </v-col>
            </v-row>
          </div> -->

          </v-card>
        </v-dialog>
        


  <v-row>
    <v-col
    class=""
    cols="12 mt-1 py-0"
    >

    <v-card class="mx-1 my-1 overflow-y-auto" :height="retorna_altura(55)">
        <v-tabs
            v-model="tab"
            background-color="#FAFAFA"
            show-arrows    
            height="33"
            color=""
        >

            <v-tabs-slider></v-tabs-slider>

            <v-tab href="#pesquisar" @click="mostrar_tabela=true">
                <v-icon>
                mdi-account-search 
                </v-icon>
                   
                <div>
                  &nbsp Pesquisar
                </div>

            </v-tab>


            <v-tab href="#cadastro"
            v-if="items_clicou.CODIGO > 0"
            >
                    <v-icon>
                    mdi-account 
                    </v-icon>

                    <div>
                      &nbsp Cadastro
                    </div>
            </v-tab>
            
            
        </v-tabs>

        <v-tabs-items 
        v-model="tab"
        touchless
        >
        <!-- touchless = impede deslizar horizontal -->
        <v-tab-item :key="1" value="pesquisar" class="">
            <v-row>
                <v-col
                class=""
                cols="12"
                md="12"
                >
                  <v-data-table
                    v-model="selected"
                    item-key="CODIGO"
                    :show-select="false"
                    v-if="(mostrar_tabela)"
                    
                    :headers="headers_pacientes"
                    :items="items_pacientes"

                    fixed-header

                    dense

                    class="elevation-1"
                    
                    :disable-sort="false"
                    :mobile-breakpoint="55"
                    :height="(items_pacientes.length>0) ? retorna_altura(290) : ''"

                    :items-per-page="100"
                    :footer-props="footerProps"
                  
                  >
                  <!-- :height="(isMobile) ? '' : (items_pacientes.length>0) ? retorna_altura(230) : ''" -->
                  <!-- :footer-props="{
                    'items-per-page-options': [10, 20, 30, 50,100,200,300,900,-1]
                    }" -->


                    

                    <template v-slot:item.NOME="{ item }">
                      
                      <a
                        @click="abrir_cadastro(item)"
                      >
                      <!-- @click="editItem(item, true)" -->
                        {{ item.NOME }}
                      </a>
                    </template>

                    <template v-slot:item.ATIVO="{ item }">
                       <v-chip small dark :color="(item.ATIVO!='S') ? 'red' :'primary'"> {{ item.ATIVO }} </v-chip>
                    </template>

                    <template v-slot:item.IDADE="{ item }">
                      {{ calcula_idade(getFormatDate(item.DATANASCIMENTO),1) }}
                    </template>

                    <template v-slot:item.CODIGOCLIENTE="{ item, index }">
                      <span style=" text-align: right; font-size: 8px;" class="grey--text pa-0 mt-2 ml-0"> {{ index + 1 }}º &nbsp</span>

                      {{ item.CODIGOCLIENTE }}
                    </template>


                    <template v-slot:item.CELULAR="{ item }">

                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">

                              <v-icon             
                                  class="px-2 "
                                  :color="(item.X_ENVIOU_AVISO)? '': 'blue'"
                                  
                                  @click="pergunta_aviso(item)"
                                  v-bind="attrs"
                                  v-on="on"
                                  v-if="(whats_aviso) && (item.CELULAR)"
                              >
                              mdi-alpha-a-box 
                              </v-icon> 

                            </template>
                            <span> Enviar Aviso </span>
                          </v-tooltip>

                        <v-chip
                            color="green lighten-4"
                            small
                            v-if="item.CELULAR"
                            @click="send_whats(item.CELULAR, item.NOME, item.CODIGO_ALUNO,'')"
                          >
                          <v-icon
                             size= "14"
                             class="mr-1"
                          >
                              mdi-whatsapp
                          </v-icon>

                            <span>
                              {{ item.CELULAR }}
                            </span>

                          </v-chip>


                    </template>
                    
                    <template v-slot:item.FONE1="{ item }">

                      <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">

                              <v-icon             
                                  class="px-2 "
                                  :color="(item.X_ENVIOU_AVISO)? '': 'blue'"
                                  
                                  @click="pergunta_aviso(item)"
                                  v-bind="attrs"
                                  v-on="on"
                                  v-if="(whats_aviso) && (item.FONE1)"
                              >
                              mdi-alpha-a-box 
                              </v-icon> 

                            </template>
                            <span> Enviar Aviso </span>
                          </v-tooltip>

                        <v-chip
                            color="green lighten-4"
                            small
                            v-if="item.FONE1"
                            @click="send_whats(item.FONE1, item.NOME, item.CODIGO_ALUNO,'')"
                          >
                          <v-icon
                             size= "14"
                             class="mr-1"
                          >
                              mdi-whatsapp
                          </v-icon>

                            <span>
                              {{ item.FONE1 }}
                            </span>

                          </v-chip>




                    </template>

                    <!-- <template v-slot:item.CELULAR="{ item }"
                    >  
                        <v-icon             
                            class="mr-2"
                            color="green"
                            @click="send_whats(item.CELULAR, item.NOME)"
                            v-if = "item.CELULAR !== null && item.CELULAR !==''">
                        >
                            mdi-whatsapp
                        </v-icon>     
                        
                        <v-icon             
                            class="mr-2"
                            color="blue"
                            @click="call_phone(item.CELULAR)"

                            v-if = "item.CELULAR !== null && item.CELULAR !==''">
                        >
                            mdi-phone 
                        </v-icon>     
                      {{ item.CELULAR }}
                      
                    </template> -->



                      <template v-slot:item.DATANASCIMENTO="{ item }">
                          <span>
                          {{ getFormatDate(item.DATANASCIMENTO) }}
                          </span>
                      </template>


                    <template v-slot:item.actions="{ item }">
                      <v-icon
                        class="mr-2"
                        @click="editItem(item, false)"
                        color="primary"
                      >
                        mdi-pencil
                      </v-icon>
                    </template>



                    <template v-slot:top>
                      <!-- <v-toolbar
                        flat
                        class="red"
                      > -->
                      
                        <template>
                            <v-card
                              tile
                              elevation="3"
                              class=""
                            >
                          <v-container
                          class="mt-1"
                          >
                            <v-row>

                              <v-col class="" cols="12" md="6">

                                <v-row dense class=" pa-1">
                                  <v-col class="">
                                    <v-text-field
                                      v-model="nome_digitou"
                                      :label="`Nome, IDE, Celular, CPF, Data Nasc.: (${qt_items_pacientes})`"
                                      required
                                      @keypress.enter="localizar_paciente($event.target.value, false)"
                                      append-icon="mdi-magnify "
                                      @click:append="localizar_paciente(nome_digitou, false)"   
                                      clearable         
                                      @keyup.esc="nome_digitou=''"    
                                      hide-details  
                                    ></v-text-field>

                                    <div class="text-center">
                                    <v-progress-linear
                                      value="0"
                                      indeterminate
                                      color="blue"
                                      v-if="progress_user"
                                    />
                                  </div>
                                  </v-col>

                                  <v-col class="ml-2 pt-5" cols="2">
                                    <v-checkbox
                                      v-model="find_ativos"
                                      :label="(find_ativos) ? 'Ativos' : 'Todos'"
                                      dense
                                      class=""
                                      style="margin-left: 8px"   
                                      hide-details
                                  ></v-checkbox>
                                  </v-col>

                                  <v-col class="ml-2 pt-5" cols="2">
                                    <v-checkbox
                                      v-model="find_start"
                                      label="Iniciando"
                                      dense
                                      class=""
                                      style="margin-left: 8px"   
                                      hide-details
                                  ></v-checkbox>
                                  </v-col>

                                  
                                </v-row>

                                <v-row dense class="" v-if="0>1">

                                  <v-col class="pa-0 ml-n1" cols="3">
                                    <v-checkbox
                                      v-model="find_start"
                                      label="Iniciando"
                                      dense
                                      class=""
                                      style="margin-left: 8px"   
                                      hide-details
                                  ></v-checkbox>
                                  </v-col>
                                </v-row>

                              </v-col>



                              <v-spacer></v-spacer>
                              
                              <v-divider
                                class="mx-1"
                                inset
                                vertical
                              ></v-divider>
                

                            </v-row>
                          </v-container>
                          </v-card>

                      

                        </template>
                    </template>
                  
                  </v-data-table> 
                </v-col>
            </v-row>
        </v-tab-item>

        <v-tab-item :key="2" value="cadastro">
          <div>
            <!-- LINHA TOPO -->
            <v-row dense>
              <v-col class="">
                <v-card
         flat
         class="mt-1"
         elevation="4"
         v-if="items_clicou.CODIGO > 0"
         >
      

          <v-card-title class="black--text  py-0">

            <v-chip-group class="" column>

            <v-icon
              class="pb-1"
              color="primary"
              @click="dialog_editar = true"
              size="35"
              v-if="(user_permissoes.cadastrar)"
            >
              mdi-dots-vertical
            </v-icon>


            <v-avatar  class="pb-0 mr-2">
              <img
                alt="user"
                :src="items_clicou.FOTO_URL"
                v-if="(items_clicou.FOTO_URL.length>20)"
                @click="dialog_img=true"
                class="hand"
               >
               <v-icon x-large v-else class="mt-n1">
                mdi-account-circle
              </v-icon>
            </v-avatar>

    

            <v-chip
                color="primary lighten-1"
                class="text-h6"
                @click="editItem(items_clicou, false)" 
            >
            <!-- @click="dialog_editar = true" -->
              {{  items_clicou.NOME }}

              &nbsp
 
              <v-icon
                class=""
                color="primary lighten-4"
                size="18"
              >
                mdi-pencil
              </v-icon>
            </v-chip>

            <v-chip class="text-subtitle-2 blue--text" color="white">
              {{ items_clicou.CODIGOCLIENTE}}
            </v-chip>


            <v-chip
                color=""
                class="text-h6"
                v-if="items_clicou.CONVENIO_NOME"
            >
            {{  items_clicou.CONVENIO_NOME }}
            </v-chip>

            <v-chip
                color=""
                v-if="items_clicou.PROFISSAO"
            >
             {{ items_clicou.PROFISSAO }}
            </v-chip>

            <v-chip
                color=""
            >
            {{ calcula_idade(getFormatDate(items_clicou.DATANASCIMENTO),0) }}
            </v-chip>


            <v-chip
                color="green lighten-5"
                @click="send_whats(items_clicou.CELULAR, items_clicou.NOME,'')"
                v-if = "items_clicou.CELULAR !== null && items_clicou.CELULAR !==''"
            >
                <v-icon             
                    size="22"
                    class="mr-2"
                    color="green"
                >
                    mdi-whatsapp
                </v-icon> 
             {{items_clicou.CELULAR}} 
            </v-chip>

            <v-chip
                color="green lighten-5"
                @click="send_whats(items_clicou.FONE1, items_clicou.NOME,'')"
                v-if = "items_clicou.FONE1 !== null && items_clicou.FONE1 !==''"
            >
                <v-icon             
                    class="mr-2"
                    size="22"
                    color="green"
                    >
                >
                    mdi-whatsapp
                </v-icon> 
             {{items_clicou.FONE1}} 
            </v-chip>



            <v-chip
                color="" v-if="(items_clicou.CPF)"
            >
            CPF: {{  items_clicou.CPF }}
            </v-chip>

            <v-chip
                color="" v-if="(items_clicou.RG)"
            >
            RG: {{  items_clicou.RG }}
            </v-chip>


            <v-chip
                color="" v-if="(items_clicou.DATANASCIMENTO)"
            >
            Nasc.: {{  getFormatDate(items_clicou.DATANASCIMENTO) }}
            </v-chip>


            <v-chip
                color=""
                v-if="items_clicou.NUMCARTEIRA"
            >
            Nº Cart.: {{  items_clicou.NUMCARTEIRA }}
            </v-chip>

            <v-chip
                color="green lighten-5"
                @click="maps(items_clicou.ENDERECOCOMPLETO+' '+items_clicou.CIDADE+' '+items_clicou.UF)"
                v-if="items_clicou.ENDERECOCOMPLETO"
            >
                <v-icon             
                    class="mr-2"
                    size="22"
                    color="green"
                >
                    mdi-map-marker-radius 
                    <!-- mdi-google-maps  -->
                </v-icon> 
                {{items_clicou.ENDERECOCOMPLETO+', '+items_clicou.CIDADE}} 
            </v-chip>




          </v-chip-group>

          
          </v-card-title>
                </v-card>
              </v-col>
            </v-row>
            
            <!-- LINHA 2 -->
            <v-row dense>

              <!-- COLUNA CENTRAL -->
              <v-col class="pt-0" cols="">
                <v-card class="mt-2 overflow-y-auto mb-2" :height="retorna_altura(210)" v-if="items_clicou.CODIGO > 0">
        <v-tabs
            v-model="tab_2"
            background-color="#FAFAFA"
            show-arrows    
            height="33"
            color=""
        >

            <v-tabs-slider></v-tabs-slider>

            <v-tab 
            href="#agendamento"
            >
                <v-icon
                small
                >
                mdi-calendar-clock
                </v-icon>
                   
                <span>
                  &nbsp Agenda
                </span>
               
                <span
                  class="count_item"
                >
                {{ count_a }}
                </span>

            </v-tab>


            <v-tab 
              href="#historico"
              @click="monta_historico()"
              v-if="pode.ver_historico"
            >
                    <v-icon
                    small
                    >
                    mdi-text-box-multiple 
                    </v-icon>

                    <span>
                      &nbsp Histórico
                    </span>

                    <span
                      class="count_item"
                    >
                    {{ count_h }}
                    </span>

            </v-tab>

            <v-tab 
              href="#tabelas"
            >
                    <v-icon
                    small
                    >
                    mdi-format-list-bulleted
                    </v-icon>

                    <span>
                      &nbsp Atendimento
                    </span>

                    <span
                      class="count_item"
                    >
                    {{ count_tabelas }}
                    </span>

            </v-tab>
            
            
        </v-tabs>

    <v-tabs-items 
    v-model="tab_2"
    touchless
    >
        <v-tab-item :key="1" value="agendamento" class="">

            <v-row>

            <v-col
            class="mt-2"
            >

              <v-data-table
                dense
                :headers="headers_agendamentos"
                :items="items_agendamentos"
                class="elevation-1"
                :items-per-page="100"
                disable-sort
                :mobile-breakpoint="55"

                :footer-props="{
                'items-per-page-options': [10, 20, 30, 50,100,200,-1]
                }"
                v-if="this.items_agendamentos.length>0"
              >

              <template v-slot:item.HORA="{ item }">

                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-chip
                      :color="getColorSituacao(item.ATENDIDO, '')"
                      dark
                      small
                    >
                    <span
                      v-bind="attrs"
                      v-on="on"
                    >{{ item.HORA }}</span>

                    </v-chip>
                  </template>
                  <span>
                    {{ getSituacao(item.ATENDIDO, '') }}
                      <p>
                        {{ item.NOME_DOC }} 
                      </p>
                      </p>
                  </span>

                </v-tooltip>
              </template>


              <template v-slot:item.DATA="{ item }">
                  <span>
                  {{ getFormatDate(item.DATA) }}
                  </span>
              </template>

              </v-data-table> 



              

            </v-col>   

            </v-row>

        </v-tab-item>

        <v-tab-item :key="2" value="historico" class="grey lighten-2 ">


<v-card
class="overflow-y-auto  lighten-2 pb-2 "
>
<!-- max-height="550" -->
<v-card
class="mx-auto mt-2"
v-for="(value,  index ) in obj_historico2"
max-width="99%"
>
<!-- {{ value }} -->
<!-- {{ value['receitas'] }} -->
<v-card-text>
  <div style="display: inline" class="text-subtitle-2 primary--text">
    <v-icon small color="primary">
    <!-- mdi-text-box-multiple  -->
    mdi-calendar-month 
    </v-icon>
    {{ value['X_DATA']}}

    <h5 style="display: inline" class="black--text"> - {{ value['PROCEDIMENTO']}}</h5>
    <h5 style="display: inline" class="grey--text"> - {{ value.CODIGO}}</h5>

  </div>
  <br/>

    <!-- <v-chip
      active-class="deep-purple accent-4 white--text"        
    >{{ value['DOUTOR']}}</v-chip> -->

    <h4 style="display: inline" class="black--text "> &nbsp {{ value['DOUTOR']}}</h4>
    - {{ value['ESPECIALIDADE']}}         
<br/>  
<br/>
<v-divider class="mx-0"></v-divider>

    <v-textarea
    :key="autoGrowHack"
      auto-grow
      no-resize
      label=""
      v-model="value['DESCRICAO']"
      :disabled=false
      readonly         
    >
    </v-textarea>

</v-card-text>


<v-card-text
v-if="value['receitas'].length >0"
class=""

>
  <div class="text-subtitle-2 black--text primary--text">
    <v-icon small color="primary">
    mdi-pill 
    </v-icon>
    RECEITAS
  </div>

    <div
      v-for="(value,  index ) in value['receitas']"
    >

      <!-- <v-chip>
      {{ value['MEDICAMENTO'] }}
      </v-chip> -->
      
      <!-- <v-chip>
      {{ value['POSOLOGIA'] }}
      </v-chip> -->

      <p style="display: inline" class="black--text ">{{ index+1 }} - &nbsp </p>

      <h4 style="display: inline" class="black--text "> {{ value['MEDICAMENTO']}}</h4>


      <v-tooltip bottom v-if="(value['MANIPULADO'].length>5)">
        <template v-slot:activator="{ on, attrs }">
          <v-chip
              v-bind="attrs"
              v-on="on"
              class="ml-2 mt-1"
              small
          > 
          MANIPULADO
          </v-chip>

        </template>
        <span v-html="quebra_linha(value['MANIPULADO'])"> 
        </span>
      </v-tooltip>

      <p v-else style="display: inline" class="text-caption black--text "> &nbsp - &nbsp {{ value['POSOLOGIA']}}</p>

    </div>

</v-card-text>


<v-divider class="mx-8"></v-divider>

<v-card-text
v-if="value['exames'].length >0"
>
  <div class="text-subtitle-2 primary--text">
    <v-icon small color="primary">
    mdi-microscope
    </v-icon>
    EXAMES
  </div>

  <div
  v-for="(value,  index ) in value['exames']"
  >

<h4 style="display: inline" class="black--text "> - &nbsp {{ value['EXAME']}}</h4>
<p style="display: inline" class="text-caption black--text "> &nbsp - &nbsp {{ value['COD96']}}</p>
      <!-- <v-chip>
      {{ value['COD96'] }}
      </v-chip>

      &nbsp
      <v-chip>
        {{ value['EXAME'] }}
      </v-chip> -->
  </div>
</v-card-text>

</v-card>

</v-card>

        </v-tab-item>

        <v-tab-item :key="3" value="tabelas">
          <v-card
              elevation="4"
              class="mt-1 mb-1 pa-1 "
          >

            <v-container>
              <v-row class="" >
                  <v-col cols="12" md="2" class=" py-1">
                    <v-card
                        class="mx-auto orange "
                    >
                        <v-list         
                          dense
                          nav
                        >
                            <v-list-item-group
                            v-model="selectedItem"
                            color="primary"
                            active-class="blue--text"
                            >

                                <v-divider
                                    class="mx-0"
                                ></v-divider>

                                <!-- passo 2  mostro os items da lista -->
                                <v-list-item
                                    v-for="(item, i) in lista_tabelas"
                                    :key="i"
                                    @click="card_titulo=item.text, tabela(item.click,'')"
                                    class="pa-0 mr-1 "
                                >
                                    <v-list-item-icon class="pa-0 mr-2">
                                      <v-icon v-text="item.icon" :color="(item.color) ? item.color: ''"></v-icon>
                                    </v-list-item-icon>

                                    <v-list-item-content>
                                      <!-- <v-list-item-title v-text="item.text"> -->
                                      <v-list-item-title>
                                        {{ item.text }}

                                        <span v-if="item.click == 'PT'" class="count_item_2">                       {{ count_PT }} </span>
                                        <span v-else-if="item.click == 'PC'" class="count_item_2">                  {{ count_PC }} </span>
                                        <span v-else-if="item.click == 'WEB_REGISTROS'" class="count_item_2">       {{ count_Reg }} </span>
                                        <span v-else-if="item.click == 'ABRIR_LANCAMENTOS'" class="count_item_2">   {{ count_Lanc }} </span>
                                        <span v-else-if="item.click == 'LANCAMENTOS'" class="count_item_2">          {{ count_Lanc }} </span>

                                        <span v-else class="count_item_2"> {{ item.count }} </span>

                                      </v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>

                                <v-divider
                                    class="mx-4"
                                ></v-divider>

                            </v-list-item-group>
                        </v-list>
                    </v-card>    


                  </v-col> 


                  <v-col class="grey lighten-5 py-1" :cols="(isMobile) ? 12 :  (11-col_menu)">
                    <v-card class="mx-auto">
                    <!-- v-if="items_lista.length > 0" -->

                        <v-card-title
                        class="pa-1 pl-2 "
                        v-if="sub_codigo>0"
                        >
                          <!-- <h4 class="primary--text" @click="ultimo_click()" >{{ sub_descricao }} </h4> -->
                          <v-chip
                          color="primary"
                          @click="ultimo_click()"
                          >
                            {{ sub_descricao }}
                          </v-chip>
                          &nbsp
                          <h5 class="mb-2 grey--text">{{ sub_codigo }} </h5>

                          &nbsp

                          <v-chip
                          color="primary"
                          @click="ultimo_click()"
                          >
                            VOLTAR
                          </v-chip>

                        </v-card-title>

                        <v-card-title
                          v-if="card_titulo"
                          class="py-0"
                        >
                            <span class="text-h5"> {{ card_titulo }} </span>
                            <span class="red--text mb-2 ml-1"> {{ items_lista.length }} </span>


                            &nbsp 
                            <v-col
                            cols="5"
                            md="2"
                            >

                                <v-text-field
                                dense
                                v-model="search"
                                clearable
                                append-icon="mdi-magnify"
                                label="Procurar"
                                single-line                
                                hide-details
                                @keyup.esc="keyUpp('esc')"
                            
                                >
                                </v-text-field>

                            </v-col>

                            <v-spacer></v-spacer>

                            <v-btn
                                color="primary"
                                small
                                @click="nova_tabela('item_edita','dialog_tabela')"
                                v-if="0>1"
                            >
                            <!-- AQUI SOMENTE VISUALIZA.. NAO PODE ADICIONAR POR QUE NAO SELECIONOU O PROFISSIONAL -->
                            </v-btn>
                        
                        </v-card-title>

                        <v-card-subtitle>

                        </v-card-subtitle>

                        <v-card-text class="pa-1"
                            v-if="card_titulo"
                        >
                        <div>
                          <v-row class="">
                              <v-col :cols="col_five" class=""  >
                                  <!-- <v-card class="orange py-0"> -->
                                  <!-- passo 5 monto a lista -->

                                      <v-data-table
                                      v-if="(!vai_imprimir)"
                                      v-model="selected"
                                      single-select
                                      item-key="CODIGO"
                                      @click:row="seleciona_linha"

                                      :headers="items_header"
                                      :items="items_lista"
                                      class="elevation-1"
                                      :items-per-page="500"
                                      disable-sort
                                      :mobile-breakpoint="55"
                                      hide-default-footer
                                      :search="search"
                                      dense
                                      :height="(isMobile) ? '270' : retorna_altura((mostrar_rodape) ? 400 : 335)"
                                      >
                                      

                                          <template slot="no-data">
                                          <div></div>
                                          </template>


                                          <template v-slot:item.VALOR_UM="{ item }">
                                            <span class="bold"> R$ {{ item.VALOR_UM }} </span>
                                          </template>


                                          <template v-slot:item.NOME_TEXTO="{ item }">
                                              <!-- <v-chip
                                              color="primary"
                                              small
                                              class="mt-1"
                                              >
                                              
                                              {{ item.NOME_TEXTO }}
                                              </v-chip> -->
                                              <a class="bold"> {{ item.NOME_TEXTO }} </a>

                                              <span class="fs-10">{{ item.NOME_DOC }}</span>
                                              
                                          </template>


                                          <template v-slot:item.DATA="{ item }">
                                            <span> {{ getFormatDate(item.DATA,'br') }} </span>
                                            <!-- {{ item.DATA }} -->
                                          </template>
                                          

      
                                          <template v-slot:item.actions="{ item }">
                                              <v-icon
                                                  class="mr-2"
                                                  @click="editar_tabela(item, 'item_edita', 'dialog_tabela')"
                                                  color="blue"
                                                  :size = "size_mobile"
                                              >
                                              mdi-pencil
                                              </v-icon>
                                          </template>
      


                                          <!-- //passo 6 personalizo as acoes dos botoes na lista  INICIO-->
                                          <template v-slot:item.actions_clock="{ item }">
                                            <v-tooltip 
                                                  bottom
                                              >
                                                  <template v-slot:activator="{ on, attrs }">
                                                  <v-icon
                                                      class="mr-2"
                                                      :size = "size_mobile"
                                                      v-bind="attrs"
                                                      v-on="on"
                                                      color="blue"
                                                      @click="tabela('ANTIGO_HORARIO','SUB',item), card_titulo='Horários'"
                                                  > 
                                                  <!-- :color="item.CONTRATO ? 'blue': 'grey'" -->
                                                  mdi-clock-time-four-outline 
                                                  </v-icon>
      
                                                  </template>
                                                  <span> Horários</span>
                                              </v-tooltip>
                                          </template>
      

                                          
                                          <template v-slot:item.actions_proced="{ item }">
                                            <v-tooltip 
                                                  bottom
                                              >
                                                  <template v-slot:activator="{ on, attrs }">
                                                  <v-icon
                                                      class="mr-2"
                                                      :size = "size_mobile"
                                                      v-bind="attrs"
                                                      v-on="on"
                                                      color="blue"
                                                      @click="tabela('VALORES_AMB','SUB',item), card_titulo='Procedimentos'"
                                                  > 
                                                  mdi-format-list-checkbox 
                                                  </v-icon>
      
                                                  </template>
                                                  <span> Procedimentos</span>
                                              </v-tooltip>
                                          </template>


                                          <!-- //passo 6 personalizo as acoes dos botoes na lista  FIM -->
      
                                      </v-data-table>
                                  <!-- </v-card> -->
      
                              </v-col>

                              <v-col  :cols="(12-col_five)" class="" v-if="(col_five<9)">


                                <v-row class="" dense>
                                  <v-col class="">

                                      <v-row justify="center">
                                        <v-col cols="12">
                                          <v-card class="overflow-y-auto" :height=retorna_altura(335) elevation="8">
                                            <v-card-text>
                                              

                                              

                                              <!-- CAMPO ANOTAÇÕES OU OBSERVAÇÃO -->
                                              <v-textarea
                                                v-model="obj_clicado.TEXTO_REGISTRO"
                                                label="TEXTO"
                                                v-if="obj_clicado.TIPO == 'TEXTO'"
                                                :persistent-hint="false"
                                                dense
                                                
                                                readonly
                                                auto-grow
                                                rows="18"
                                                class="pt-2"
                                                solo
                                                
                                              >
                                              <!-- rows="12" -->

                                                <!-- <template v-slot:label>
                                                  <span class=""> {{(vai_imprimir) ? '' : item.text }} </span>
                                                </template>   -->
                                              </v-textarea>

                                              <p v-else v-html="obj_clicado.TEXTO_REGISTRO"></p>
                                              

                                              <v-divider class="mx-10 my-4"/>

                                              <span v-if="(obj_clicado.PATH_PDF)" style="display: block; text-align: center;">
                                                <img
                                                  alt="user"
                                                  :src="obj_clicado.PATH_PDF"
                                                  height="30%"  
                                                  v-if="(obj_clicado.PATH_PDF)"
                                                >
                                                </img>
                                              </span>
                                              
                                              
                                              <span v-if="(obj_clicado.PATH_ASSINATURA?.length >10)" style="display: block; text-align: center;">
                                                <img
                                                  alt="userr"
                                                  :src="obj_clicado.PATH_ASSINATURA"
                                                  width="50%"
                                                  v-if="(obj_clicado.PATH_ASSINATURA.length >10)"
                                                >
                                                </img>
                                              </span>


                                            </v-card-text>
                                          </v-card>
                                        </v-col>
                                      </v-row>

                                  </v-col>
                                </v-row>  
                              </v-col>
                          </v-row>
                          

                          <!-- row do rodape -->
                          <v-row dense v-if="mostrar_rodape" class="" ref="rodape_soma" id="rodape_soma">


                              <!-- mostro os cards de somatorias -->
                              <v-col 
                                class="py-0 ml-3"
                                cols="12"
                                md="11"
                              >

                              <v-container
                              class="px-0"
                              >
                              <v-row>
                                <v-col
                                  class="pa-0"
                                >
                                
                                <v-chip-group 
                                  active-class="deep-purple accent-4 white--text"
                                  column
                                  class="pl-0"
                                  >

                                  <v-chip 
                                    v-for="(item,  index ) in items_somatorias"
                                    dark label
                                    large
                                    :class="retorna_cor(item.CAMPO)"
                                    @click="search=item.CAMPO.toLowerCase()"
                                  >
                                  <!-- :class="[(item.CAMPO == 'TOTAL') ? 'blue' : 'grey']" -->
                                  
                                  <span class="mt-n3 fs-12">{{ item.CAMPO }} </span>
                                  <span class="mt-n6 ml-1  white--text text-size-4 count_item_2 fs-14"> {{ item.QT }} </span>

                                  <h5 class="mt-5 ml-n8" v-if="(item.VALOR!='NaN')">
                                    {{ getFormatCurrency(item.VALOR,'N') }}
                                  </h5>
                                  <!-- <div style="position: absolute; top: 90%; left: 50%; transform: translate(-50%, -50%);">Texto Sobreposto</div> -->
                                  
                                  </v-chip>



                                </v-chip-group>
                                </v-col>
                              </v-row>
                              </v-container>

                              </v-col>

                              </v-row>

                          
                        </div>
                        </v-card-text>
                    </v-card>
                  </v-col>     
              </v-row> 
            </v-container>  

          </v-card>
        </v-tab-item>
        
    </v-tabs-items>
                </v-card>
              </v-col>

              <!-- COLUNA ESQUERDA -->
              <v-col
                class="mr-1 px-0 px-1 py-1 "
                cols="12"
                md="2"
                v-if="tabela_selecionada!=='LANCAMENTOS'"
              >

            <v-card 
            flat
            elevation="5"
            class="mt-1 grey lighten-3"
            >
              <v-expansion-panels 
                :multiple="true"
                :focusable='false'
                v-model="value_expansion"
                >


                <!-- ANOTAÇÕES -->
                <v-expansion-panel>
                  <v-expansion-panel-header 
                  class="pa-2 px-4"
                  style="min-height: 28px"
                  
                  >
                    <div>
                      <v-row
                      >
                        <v-col
                          cols="12"
                          md="12"
                        >
                            <v-icon
                             size="20"
                             color=""
                            >
                            <!-- mdi-blur  -->
                            <!-- mdi-card-account-details-outline  -->
                            mdi-clipboard-text 
                            </v-icon> 
                            &nbsp
                            Anotações

                            <span
                              class="count_item_2"
                              v-if="items_clicou.OBS"
                            >
                            1
                            </span>


                        </v-col>
                      </v-row>
                    </div>
                  </v-expansion-panel-header>

                  <v-expansion-panel-content
                   class="py-0 pa-0"
                  >
                  <v-divider class="mb-2"></v-divider>

                  <v-row dense class="">
                    <v-col cols="12">
                      <v-textarea
                        dense
                        v-model="items_clicou.OBS"
                        @keydown="firstUpper($event, 'OBS')"
                        background-color="amber lighten-5"
                        readonly
                        hint="Para alterar, clique em alterar cadastro"
                      >
                      </v-textarea>
                    </v-col>
                    

                    <v-col cols="9" v-if="(0>1)">
                      <!-- empurra -->
                    </v-col>

                    <v-col class="ma-0 pa-0"
                     cols="2" 
                     v-if="(0>1)"
                    >
                    
                        <v-btn
                          color="primary"
                          class="white--text"
                          x-small
                          text
                          @click="editItem(items_clicou, false)"
                        >
                          alterar
                          <v-icon
                            right
                            x-small
                          >
                            mdi-pencil
                          </v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>

                  </v-expansion-panel-content>
                </v-expansion-panel>

                <!-- AVISOS -->
                <v-expansion-panel>
                  <v-expansion-panel-header 
                  class="pa-2 px-4"
                  style="min-height: 28px"
                  
                  >
                    <div>
                      <v-row
                      >
                        <v-col
                          cols="12"
                          md="12"
                        >
                            <v-icon
                             size="20"
                             color=""
                            >
                            <!-- mdi-blur  -->
                            <!-- mdi-card-account-details-outline  -->
                            mdi-clipboard-text 
                            </v-icon> 
                            &nbsp
                            Aviso

                            <span
                              class="count_item_2"
                              v-if="items_clicou.AVISO"
                            >
                            </span>


                        </v-col>
                      </v-row>
                    </div>
                  </v-expansion-panel-header>

                  <v-expansion-panel-content
                   class="py-0 pa-0"
                  >
                  <v-divider class="mb-2"></v-divider>

                  <v-row dense class="">
                    <v-col cols="12">
                      <v-textarea
                        dense
                        v-model="items_clicou.AVISO"
                        @keydown="firstUpper($event, 'AVISO')"
                        background-color="amber lighten-5"
                        readonly
                        hint="Para alterar, clique em alterar cadastro"
                      >
                      </v-textarea>
                    </v-col>
                    

                    <v-col cols="9" v-if="(0>1)">
                      <!-- empurra -->
                    </v-col>

                    <v-col class="ma-0 pa-0"
                     cols="2" 
                     v-if="(0>1)"
                    >
                    
                        <v-btn
                          color="primary"
                          class="white--text"
                          x-small
                          text
                          @click="editItem(items_clicou, false)"
                        >
                          alterar
                          <v-icon
                            right
                            x-small
                          >
                            mdi-pencil
                          </v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>

                  </v-expansion-panel-content>
                </v-expansion-panel>


                <!-- LANÇAMENTOS -->
                <v-expansion-panel v-if="0>1">
                  <v-expansion-panel-header 
                  class="pa-2 px-4"
                  style="min-height: 28px"
                  @click="minhas_tarefas(),expansion_selecionao='WHATSAPP'"
                  >

                    <div>
                      <v-row>
                        <v-col cols="12">
                            <v-icon size="26" class="pr-1" color="orange"> 
                              mdi-credit-card-multiple 
                            </v-icon> 
                            
                            <span class="fs-14">Lançamentos</span>
                              
                        </v-col>
                      </v-row>
                    </div>
                  </v-expansion-panel-header>

                  <v-expansion-panel-content>
                    <!-- class="py-0 ma-0" -->
                      <v-container>

                        <v-row>
                          <v-col class="px-0 py-0 red ">

                            <v-fab-transition>
                              <v-btn
                                color="primary"
                                dark
                                absolute
                                right
                                fab
                                x-small
                                @click="abre_janela_modal(true, true, 'WHATSAPP', 'WHATSAPP')"
                              >
                                <v-icon>mdi-menu</v-icon>
                              </v-btn>
                          </v-fab-transition>

                          </v-col>
                        </v-row>
                      </v-container>

                  </v-expansion-panel-content>
                </v-expansion-panel>

              </v-expansion-panels>
            </v-card>
  
              </v-col>

            </v-row>
          </div>

            </v-tab-item>

        </v-tabs-items>
    </v-card>   
    </v-col>
  </v-row>



    <!-- MOSTRA JANELA MODAL -->
    <div class="mt-n10">
      <v-row>
        <v-col>
        <LembreteVue 
          :aberto = 'janela_modal'
          pro_tabela = 'LANCAMENTOS'
          :pro_titulo = "'Lançamentos - '+obj_dados_paciente.NOME"
          :pro_dados_paciente = "obj_dados_paciente"
          :pro_dados_proceds = "null"
          :pro_obj_1 = "obj_janela_modal"
          :pro_obj_menus = null
          :show_menu = 'false'
          :pro_modo_edicao = 'false'
          :pro_pode_alterar = 'true'
          :pro_tag = pro_tag
          @muda_aberto_lembretes="muda_aberto_modal"
          @modal_salvar="modal_salvar"
        />
        </v-col>
      </v-row>
    </div>


  </div>
</template>





<script>
  

  import axios from "axios";
  import resultado_exameVue from './resultado_exame.vue';
  import DSibasico from '../assets/js/DSi_basico'//chamo funcao do arquivo
  import { removerCampos, somatoria_lista } from '../assets/js/DSi_basico'
  import DSisql from '../assets/js/DSi_sql'//chamo funcao do arquivo
  import DSirotinas from '../assets/js/DSi_rotinas'//chamo funcao do arquivo
  
  
  var url = sessionStorage['url']
  // var url = localStorage['url']



  export default {
  
    components:{
      resultado_exameVue,
      LembreteVue: () => import("./Lembretes.vue")
    },

    data: () => ({


      obj_user: {},
      obj_dados_empresa: null,
      LSendereco: '',
      pro_tag:'',

      mostrar_rodape: false,
      def_parcelas : [],
      lista_parcelas:[1,2,3,4,5,6,7,8,9,10,11,12,13,14,15],
      items_tabela_formas: ['DINHEIRO', 'CREDITO','DEBITO','PIX','BOLETO','CONVENIO','GRATIS','CHEQUE','CORTESIA','OUTROS','PERMUTA','DEPOSITO'],


      itemsPerPageOptions: [10, 20, 30, 50,100,200,300,900,-1],
      mostrar_tabela: true,

      janela_modal: false,
      modal_salvou: false,
      modal_qual:'',
      obj_janela_modal: {},
      item_principal:{},
      obj_dados_paciente: {},

    selected: [],


    find_start: false,
    find_ativos: true,
    qt_items_pacientes:0,

    whats_aviso:'',  

    unificar_dados:{} ,

    user_permissoes: {},

    dialog_img: false,

    // ------------ TABELAS INICIO ---------------
    obj_clicado : {},
    vai_imprimir: false,

    col_menu : 1,
    col_five : 12,
    selectedItem: -1,
    card_titulo:'',
    dialog_tabela: false,
    tabela_selecionada:'',
    search: '',

    count_PT:0,
    count_PC:0,
    count_Reg:0,
    count_Lanc:0,
    count_tabelas: 0,

    lista_tabelas:[],
    selected: [],
    items_lista:[],
    items_header: [],
    items_editaveis: [],
    items_count:[],
    item_edita: {},

    sub_codigo:0,
    sub_descricao:'',

    valido: false,
    
    size_mobile:22,

    w_data: '99px',
    w_fone: '125px',
    w_cpf: '120px',
    w_nome: '200px',
    // ------------ TABELAS FIM ---------------

       value_expansion: null, //0 ou null

       crud_table: 'clientes',//colocar o nome da tabela do BD
      //  sqlWhere: ' where id = 0',
       sqlWhere: '',

       abrir_auto: 'N',

       superA: false,

       pode:{
         ver_historico: false
       },

       progress_user: false,
       
       count_h: 0,
       count_a: 0,

       dialog_editar: false,
       dialog_re_exames: false,

       tab:'pesquisar',
       tab_2:'',

       isMobile: false, 
       DEBUG: false,
       autoGrowHack: false,

       desserts: [],
       editedIndex: -1,
       defaultItem: {},
       editedItem: {},
       readonly: true,

       nome_digitou:'',
       qual_acao:'',
       convenios:[],

      obj_historico2:[],

      historico: [],
      receitas: [],
      exames: [],

       items_pacientes:[],
       items_clicou:[],
       items_selecionados:{
              cod_convenio:'',
              nome_convenio:''
            },


       emailRules: [
        // v => !!v || 'necessário',
        v => /.+@.+\..+/.test(v) || 'E-mail inválido!',
       ],

       noRules:[v => true],
       
       notnullRules: [
        v => !!v || 'necessário'
       ],

       minimoRules: [
        v => !!v || 'necessário',
        v =>(v && v.length >= 5) || 'necessário'
       ],



       snackbar: false,
       snack_text: 'My timeout is set to 2000.',
       snack_color: "success",
    
      dialog: false,
      dialogDelete: false,
      headers_pacientes: [
        { text: 'IDE', value: 'CODIGOCLIENTE', width: '75px' },

        {
          text: 'Nome',
          align: 'start',
          //sortable: false,
          value: 'NOME',
          width: '300px'
        },

        { text: 'Celular', value: 'CELULAR',width:'210px'},
        { text: 'Fone', value: 'FONE1',width:'210px'},

        { text: 'Idade', value: 'IDADE',width: '90px' },
        { text: 'ATIVO', value: 'ATIVO',width: '90px' },

        // { text: '..', value: 'actions', sortable: false },
        { text: 'Nascimento', value: 'DATANASCIMENTO' },
        { text: 'Convenio', value: 'CONVENIO_NOME' },

        // { text: 'Fone', value: 'Fone' },
        { text: 'CPF', value: 'CPF',width:'130px' },
        { text: 'RG', value: 'RG' },
        { text: 'Cidade', value: 'CIDADE' },
        { text: 'OBS', value: 'OBS' },
      ],

      items_agendamentos:[],
      headers_agendamentos: [
        { text: 'Data', value: 'DATA', width:'110px' },
        { text: 'Hora', value: 'HORA' },
        // { text: 'At', value: 'ATENDIDO' },
        { text: 'Profissional', value: 'NOME_DOC',width:'300px'},
        { text: 'Convênio', value: 'CONVENIO'},
        { text: 'Procedimento', value: 'PROCEDIMENTO'},
        { text: 'Valor', value: 'VALOR'},
        { text: 'Forma', value: 'FORMA_PAGAMENTO'},
        { text: 'CS', value: 'CODIGOCONSULTA'},
      ],
      
    }),

    computed: {
      formTitle () {
        return this.editedIndex === -1 ? ('Novo Paciente') : ('Editar '+this.editedItem.CODIGOCLIENTE) //this.editedItem.id 
      },

      footerProps() {
        return {
          'items-per-page-options': this.itemsPerPageOptions,
        };
      },

    },

    watch: {


      'item_edita.TIPO'(val){
        this.campos_adicionais(this.item_edita)
      },
       //mudar valor do input pra uppercase maiuscula 
      //  'editedItem.nome'(a){
      //     if (a!==''){  
      //       a = a.toUpperCase();
      //       this.editedItem.nome = a;
      //       // alert(a);
      //     }          
      //  },

       //primeira letra maiuscula 
       'editedItem.rua_numero'(a){
          if (a!=='' && a.length==1){  
            a = a.toUpperCase();
            this.editedItem.rua_numero = a;
            // alert(a);
          }          
       },

      dialog (val) {
        val || this.close()
      },
      dialogDelete (val) {
        val || this.closeDelete()
      },
    },

    created () {
       this.onCreated() 
    },

    mounted () {
       this.onResize()
    },

    methods: { 

      retorna_cor(campo){

        if (campo == 'TOTAL'){
          return 'blue'
        }
        if (campo == 'EFETUADO'){
          return 'green'
        }
        if (campo == 'PENDENTE'){
          return 'red'
        }
        else{
          return 'grey'
        }

      },

      getFormatCurrency(v,string){
        if (v){
          return DSibasico.getFormatCurrency(v, string)
        }
        else{
          return v
        }
        
      },

      async replace_array_soma(){

        // aqui substituo os campos especificos do array somatoria

        let tabela = this.tabela_selecionada

        const jsonString = JSON.stringify(this.items_somatorias);

        if (tabela == 'LANCAMENTOS'){
          const novaString = jsonString.replace(/"CAMPO":\s*"N"/g, '"CAMPO": "PENDENTE"')
                                      .replace(/"CAMPO":\s*"S"/g, '"CAMPO": "EFETUADO"');

          const novoArray = JSON.parse(novaString);


          this.items_somatorias = await novoArray
        }
      },

      async onCreated(){

        await this.GetPermissoes()
        await this.showDrawer()
        await this.logado()
        await this.lista_convenios()
        await this.abre()
        await this.permissoes()
        await this.ler_sessions()
      },

      pode_alterar(tabela){
            
            if ( (tabela == 'WEB_REGISTROS') || (tabela == 'WEB_REGISTROS')  ){
              return  ( (this.obj_user.SUPERUSER=='S') || (this.obj_user.SUPERUSER=='S') )
            }

            else{
                return false
            }

            return false
            
        },


      imprimir_pagina(){
        this.vai_imprimir = true

        setTimeout(function(){
          window.print()
          this.vai_imprimir = false            
        }.bind(this), 111);
      },

      async campos_adicionais(item){

        // console.log('campos_adicionais');
        // return 'exit'

        if (this.tabela_selecionada=='WEB_REGISTROS'){
            
            removerCampos(this, 'items_editaveis', ['PATH_PDF','TEXTO_REGISTRO','get_impressao','NOME_TEXTO','PATH_ASSINATURA'])
            
            if (['IMAGEM','PDF'].includes(item.TIPO)){

                this.items_editaveis.push(
                  { text: 'Descrição', value: 'NOME_TEXTO', tag:'v-text', type:'text', cols:'7',rule:'S', upper:'S',class:'no-print' },
                  // { text: 'PATH_PDF', value: 'PATH_PDF', tag:'v-text', type:'text', cols:'12',rule:'N', if_campo:'ACEITOU', if_value:'S', upper:'N' },
                  { text: 'PATH_PDF', value: 'PATH_PDF', tag:'v-img', width:'70%', cols:'12',rule:'N', if_campo:'ACEITOU', if_value:'S', upper:'N' },
                )
            }
            
            else if (['TEXTO'].includes(item.TIPO)){

                this.items_editaveis.push(
                  { text: 'Descrição', value: 'NOME_TEXTO', tag:'v-text', type:'text', cols:'8',rule:'S', upper:'S',class:'no-print' },
                  { text: 'CONTEÚDO', value: 'TEXTO_REGISTRO',tag:'v-textarea', type:'text', cols:'12', rows:'19',rule:'S' }
                )
            }
            
            else if (item.TIPO){  
            // if (['TERMOS'].includes(item.TIPO)){

              if (this.item_edita.TEXTO_REGISTRO){
                this.items_editaveis.push(
                  { text: 'Descrição', value: 'NOME_TEXTO', tag:'v-text', type:'text', cols:'8',rule:'S', upper:'S',class:'no-print' },
                  { text: 'CONTEÚDO', value: 'TEXTO_REGISTRO',tag:'p', type:'text', class:'mx-15', cols:'12', rows:'19',rule:'N' },
                  // { text: 'CONTEÚDO', value: 'TEXTO_REGISTRO',tag:'v-editor', type:'text', cols:'12', rows:'19',rule:'N' },
                  // { text: 'PATH_ASSINATURA', value: 'PATH_ASSINATURA', tag:'v-text', type:'text', cols:'12',rule:'N', if_campo:'ACEITOU', if_value:'S', upper:'N' },
                  { text: 'PATH_ASSINATURA', value: 'PATH_ASSINATURA', tag:'v-img', width:'50%', cols:'12',rule:'N', if_campo:'ACEITOU', if_value:'S', upper:'N' },
                )
              }
              else{

                // this.items_editaveis.splice(this.items_editaveis.findIndex(x => x.value === "NOME_TEXTO") , 1);//removo a coluna senha

                this.items_editaveis.push(
                  { text: 'ABRIR IMPRESSÃO', value: 'get_impressao', tag:'v-chip', cols:'2', color:'primary'}
                )
              }
            }

            
        }
      },

      esta_empresa(nome){
        // verifico o nome da empresa e se mostro ou nao algumas funcoes..exemplo Artmed
        nome   = nome.toLowerCase()
        let titulo = document.title.toLowerCase()
        
        if (titulo.includes(nome)){
          return true
        }else{
          return false
        } 
      },

      modal_salvar(valor, tabela){
       this.modal_salvou = valor
       this.modal_qual   = tabela
      },

      muda_aberto_modal(valor){
        this.janela_modal = valor

        if (!valor){
          // alert('atualiza')
        }
      },

      async abrir_janela_modal(item, acao, pro_tag){

        console.log('abrir_janela_modal:', item);

        this.janela_modal   = true
        this.pro_tag        = pro_tag

        if (acao == 'editar'){
          this.obj_janela_modal  = item
        }
        else if (acao == 'novo'){
          this.obj_janela_modal  = null
        }

      },

      quebra_linha(entra){
        let r = ''
        if (entra){
          r = entra.replaceAll('\n', '<br/>')
        }

        return r
      },

      async formata_datas(entra,tipo){
        if (tipo == 1){

          if (entra?.includes('/')){
            var partes = entra.split('/');

            // Reorganizar as partes para o formato yyyy-mm-dd
            var fechaTransformada = partes[2] + '-' + partes[1] + '-' + partes[0];

            return fechaTransformada;
          }
          else{
            return entra
          }
        }
        else if (tipo == 2){
          //celular
          // alert(entra?.length)
          if (entra?.length == 9){
              // 999685172
              entra = entra.substring(0,5) +'-'+entra.substring(5,10)
              return entra
          }
          else if (entra?.length == 11){//com ddd
              // 44999685172
              entra = entra.substring(2,7) +'-'+entra.substring(7,11)//pego sem o ddd
              return entra
          }
          else if (entra?.length == 12){//sem digito 9
              // 554499685172
              entra =  '9'+entra.substring(4,8) +'-'+entra.substring(8,13)//pego sem o ddd
              return entra
          }
          else if (entra?.length == 13){//com ddi
              // 5544999685172
              entra = entra.substring(4,9) +'-'+entra.substring(9,13)//pego sem o ddd
              return entra
          }
          else{
            return entra
          }
        }

      },

      async ler_sessions(){
        this.whats_aviso = sessionStorage['whats_aviso']

        this.LSendereco = sessionStorage['LSendereco']

        this.obj_dados_empresa = JSON.parse(sessionStorage['obj_dados_empresa'])

        this.obj_user = await DSibasico.retorna_obj_sessions('obj_user')
      },

      pergunta_aviso(item){
        if (confirm("Enviar Aviso?")){

          this.$set(item, 'X_ENVIOU_AVISO', 'S' )

          this.send_whats(item.CELULAR, item.NOME, this.whats_aviso)
          
        }
      },

      inicia_unificar(tipo){


         this.dialog_editar = false

         if (tipo == 'antigo'){
          
           this.$set(this.unificar_dados, 'ANTIGO'      ,  this.items_clicou.CODIGOCLIENTE)
           this.$set(this.unificar_dados, 'ANTIGO_NOME' ,  this.items_clicou.NOME)

           alert('Agora entre no Cadastro no Novo')
           return false

         }

         else if (tipo == 'novo'){

            this.$set(this.unificar_dados, 'NOVO'      ,  this.items_clicou.CODIGOCLIENTE)
            this.$set(this.unificar_dados, 'NOVO_NOME' ,  this.items_clicou.NOME)

            if (this.unificar_dados.ANTIGO == this.unificar_dados.NOVO){
              alert('Não pode unificar para ele mesmo, selecione o novo cadastro!')
              return false
            }


            let ANTIGO = parseInt(this.unificar_dados.ANTIGO)
            let NOVO = parseInt(this.unificar_dados.NOVO)

            if ((!ANTIGO)||(!NOVO)) {
              alert('Selecione os Cadastros Antigo e Novo para unificar!') 
              return false
            }


            if (confirm(`Deseja Transferir os dados\n`+
                 `DE ${'  '} :  ${this.unificar_dados.ANTIGO} - ${this.unificar_dados.ANTIGO_NOME} \n`+
                 `PARA: ${this.unificar_dados.NOVO} - ${this.unificar_dados.NOVO_NOME}`
              )){

                this.crud_sql(`execute procedure UNIFICACADASTRO (${NOVO}, ${ANTIGO})`)
                
                this.abrir_cadastro(this.items_clicou)

                this.unificar_dados = {}//limpa objeto


            }

         }

      },

      retorna_altura(menos){
        // let r = '100vh'
        // let r = '720'
        // let r = (window.innerHeight - 80 - 60)
        let r = (window.innerHeight - menos)
        let height = window.innerHeight;

        return r
      },

      async GetPermissoes(){
        let r = await DSirotinas.GetPermissoes()
        this.user_permissoes = Object.assign({}, r) 
      },

      async showDrawer(){
        this.$emit('executar', true)//mostrar ou nao paineis
      },

      seleciona_linha(row) {
         this.selected= [{"CODIGO" : row.CODIGO}] //via v-modal no data-table

            //clonando objeto
        this.obj_clicado = Object.assign({}, row)
      },



      permite_items(){
        //passo 1 monto os items e suas tabelas e permissoes

        let s = []


        // s.push( { text: 'Convênios', icon: 'mdi-home-city ', click:'CONVENIO' } )

        this.lista_tabelas = [
        { text: 'Plano Tratam.', count: 0, icon: 'mdi-clipboard-list-outline ', click:'PT' },
        { text: 'Proc. Clínico', count: 0 , icon: 'mdi-format-list-checks', click:'PC' },
        { text: 'Registros', count: 0 , icon: 'mdi-text-box ', click:'WEB_REGISTROS', color:'success' },
        // { text: 'Lançamentos', count: 0 , icon: 'mdi-credit-card-multiple ', click:'LANCAMENTOS', color:'primary' },
        { text: 'Lançamentos', count: 0 , icon: 'mdi-credit-card-multiple ', click:'ABRIR_LANCAMENTOS', color:'primary' },

        ]

        this.return_count('PT', 'count_PT')
        this.return_count('PC', 'count_PC')
        this.return_count('WEB_REGISTROS', 'count_Reg')
        this.return_count('LANCAMENTOS', 'count_Lanc')

        // this.lista_tabelas = s
    },

          
    async return_count(tabela, onde){

      let sql = ''
      if (tabela == 'PT'){
        sql = `select  '${tabela}' as tipo, count(codigo) as count_codigo from ${tabela} where codigo_paciente = ${this.items_clicou.CODIGOCLIENTE}`
      }
      else if (tabela == 'PC'){
        sql = `select  '${tabela}' as tipo, count(codigo) as count_codigo from ${tabela} where codigo_paciente = ${this.items_clicou.CODIGOCLIENTE}`
      }
      else if (tabela == 'WEB_REGISTROS'){
        sql = `select  '${tabela}' as tipo, count(codigo) as count_codigo from ${tabela} where codigo_cliente = ${this.items_clicou.CODIGOCLIENTE}`
      }
      else if (tabela == 'LANCAMENTOS'){
        sql = `select  '${tabela}' as tipo, count(codigo) as count_codigo from ${tabela} where codigo_cliente = ${this.items_clicou.CODIGOCLIENTE}`
      }

      // console.log( tabela+' : '+sql);
      await this.crud_abrir_tabela(sql,'items_count')

      await definir(this, onde)

      async function definir(that,onde){
        that[onde] = await that.items_count[0].COUNT_CODIGO

        that.count_tabelas += parseInt(that[onde])
      }
    },

    async tabela(tabela, acao, item){
      //passo 3 executo a acao quando clicar nos items e monto os campos editaveis e os campos do data-table

        if (!item){
          sessionStorage['tabela_clicou']   = tabela
          sessionStorage['tabela_titulo']   = this.card_titulo
        }

        this.search = ''//limpa filtro

        this.tabela_selecionada = tabela
        this.mostrar_rodape     = false

      //   console.log('item:');
      //   console.log(item);

        //limpa array
        this.items_lista       = [] //recebe os dados do sql
        this.items_header      = [] //campos que tem que mostrar no header
        this.items_editaveis   = [] //campos editaveis mas nao tem que mostrar no header

        this.col_five = 12


        if (tabela=='ABRIR_LANCAMENTOS'){
           this.abrir_janela_modal(this.obj_dados_paciente,'novo','normal')
        }

        else if (tabela=='LANCAMENTOS'){


          this.col_menu = 1
          // this.col_five = 11

          //limpa campos sub
          this.sub_codigo    = 0
          this.sub_descricao = ''


          this.items_header = [

              { text: '..', value: 'actions', sortable: false, type:'hide', cols:'0',width:'5px'},
              { text: '..', value: 'menu', sortable: false, type:'hide', cols:'0',width:'5px', click:'agora'},
              { text: 'Codigo', value: 'CODIGO', type:'number', cols:'0' },
              { text: 'Seq', value: 'SEQUENCIA', tag:'v-text', type:'text', cols:'1'},
              // { text: 'EF', value: 'EFETUADO', tag:'v-text', type:'text', cols:'1'},

              { text: 'Vencimento', value: 'x_DATA', tag:'v-text', type:'date', cols:'2', width: this.w_data },

              { text: 'Pago', value: 'x_DATA_EFETUADO', tag:'v-text', type:'date', cols:'2', width: this.w_data,},
              
              
              // { text: 'DATA', value: 'DATA', tag:'v-text', type:'date', cols:'2', width: this.w_data },

              { text: 'Valor', value: 'VALOR_UM', tag:'v-text', type:'text', cols:'2',rule:'S', upper:'N', click:'verifica_vezes', width: '90px' },


              { text: 'X', text_2:'/', value: 'x_TIPO', tag:'v-checkbox', cols:'1',rule:'S', upper:'N', class_col:'',click:'verifica_vezes', style_tag: {'margin-left': '-4px'} },

              { text: 'Parcelas', value: 'x_VEZES',
                  type:'text',
                  tag:'v-select',
                  tag_items: this.lista_parcelas,
                  cols:'1',rule:'S' ,
                  click:'verifica_vezes',
                  class:'ml-n4'
              },

              

              // { text: 'Total', value: 'x_TOTAL', tag:'v-chip-value', color:'', cols:'1', click:'', class_col:'px-0', class:'ml-n3', hint:''},



              { text: 'Forma', value: 'FORMA_PAGAMENTO',
                  type:'text',
                  tag:'v-select',
                  tag_items: this.items_tabela_formas,
                  cols:'3',rule:'S' ,
                  click:'verifica_vezes'
              },

              // { text: 'CATEGORIA', value: 'CATEGORIA', tag:'v-text', type:'text', cols:'3',rule:'S', upper:'S', width: this.w_nome },
              // { text: 'OPERACAO', value: 'OPERACAO', tag:'v-text', type:'text', cols:'3',rule:'S', upper:'S', width: this.w_nome },
              // { text: 'PROFISSIONAL', value: 'PROFISSIONAL', tag:'v-text', type:'text', cols:'3',rule:'S', upper:'S', width: this.w_nome },
              
              { text: 'Categoria', value: 'CATEGORIA', tag:'v-text', type:'text', cols:'5',rule:'S', upper:'S', width: '330px', click:'verifica_vezes' },
              { text: 'Descrição', value: 'DESC_PROCEDIMENTO', tag:'v-text', type:'text', cols:'7',rule:'N', upper:'S', width: '330px', click:'verifica_vezes' },
              
              // { text: 'LANCOU', value: 'LANCOU', tag:'v-text', type:'text', cols:'7',rule:'S', upper:'S', width: '220px' },
              { text: 'Alterou', value: 'ALTEROU', tag:'v-text', type:'text', cols:'7',rule:'S', upper:'S', width: '220px' },
              
              

          ]

          // clono os items
          this.items_editaveis = this.items_header.map((x) => x); //clonando array copiar array

          // removo da edição
          // this.items_editaveis.splice(this.items_editaveis.findIndex(x => x.value === "PROFISSIONAL") , 1);//removo a coluna senha
          this.items_editaveis.splice(this.items_editaveis.findIndex(x => x.value === "x_DATA") , 1);//removo a coluna senha
          this.items_editaveis.splice(this.items_editaveis.findIndex(x => x.value === "x_DATA_EFETUADO") , 1);//removo a coluna senha
          this.items_editaveis.splice(this.items_editaveis.findIndex(x => x.value === "LANCOU") , 1);//removo a coluna senha
          // this.items_editaveis.splice(this.items_editaveis.findIndex(x => x.value === "ALTEROU") , 1);//removo a coluna senha
          this.items_editaveis.splice(this.items_editaveis.findIndex(x => x.value === "SEQUENCIA") , 1);//removo a coluna senha


          // ADICIONO SOMENTE NA EDIÇÃO
          this.items_editaveis.unshift(
              { text: 'Vencimento', value: 'DATA', tag:'v-text', type:'date', cols:'2',click:'verifica_vezes' },

              // { text: 'EFETUADO', text_2:'EFETUADO', value: 'x_EFETUADO', tag:'v-checkbox', cols:'1',rule:'N', upper:'N', class_col:'red', class_tag:'mt-9 ml-10',click:'verifica_vezes', if:'x_EFETUADO' },
              // { text: 'EFETUADO', text_2:'EFETUADO', value: 'x_EFETUADO', tag:'v-checkbox', cols:'1',rule:'N', upper:'N', class_col:'blue', class_tag:'ml-10',click:'verifica_vezes', else:'x_EFETUADO' },
              { text: 'EFETUADO', text_2:'PENDENTE', value: 'x_EFETUADO', tag:'v-checkbox', cols:'1',rule:'N', upper:'N', class_col:'', 
                class_if:'x_EFETUADO', class_tag: 'mt-9 ml-10', class_tag2: 'ml-10', click:'verifica_vezes'},

              { text: 'Efetuado', value: 'DATA_EFETUADO', tag:'v-text', type:'date', cols:'2', click:'verifica_vezes', if:'x_EFETUADO', class:'', class_tag:'ml-n10' },
          )



          this.items_header.splice(this.items_header.findIndex(x => x.value === "x_VEZES") , 1);//removo a coluna senha
          this.items_header.splice(this.items_header.findIndex(x => x.value === "x_TIPO") , 1);//removo a coluna senha

          let sql = `
                SELECT
                  CODIGO,
                  CODIGO_CLIENTE,
                  CODIGO_PROCED,
                  CODIGO_CONSULTA,
                  CODIGO_PROFISSIONAL,
                  CODIGO_CONVENIO

                  COD_USER_RECEBEU,
                  COD_USER_LANCOU,

                  TIPO,
                  EFETUADO,
                  VISTO,
                  DATA,
                  DATA_LANCAMENTO,
                  DATA_EFETUADO,
                  
                  R_RECEBEU,
                  ALTEROU,
                  LANCOU,
                  CATEGORIA,
                  PROFISSIONAL,
                  VALOR_UM,
                  VALOR_DOIS,
                  FORMA_PAGAMENTO,

                  OPERACAO,
                  DESCRICAO,
                  DESC_PROCEDIMENTO,

                  SEQUENCIA
                FROM
                  LANCAMENTOS
                WHERE  (LANCAMENTOS.CODIGO_CLIENTE = ${this.items_clicou.CODIGOCLIENTE}) order by DATA desc, CODIGO desc`


          // console.log( tabela+' : '+sql);
          await this.crud_abrir_tabela(sql,'items_lista')

          await somatoria_lista(this, 'items_lista', 'items_somatorias', 'VALOR_UM', 'EFETUADO', false)

          await this.replace_array_soma()

          this.mostrar_rodape = true 

        }

        else if (tabela=='WEB_REGISTROS'){

          this.col_menu = 1
          if (!this.isMobile){this.col_five =  5}//somente se nao for mobile

          this.items_header = [

              { text: '..', value: 'actions', sortable: false, type:'hide', cols:'0',width:'30px'},
              // { text: 'CODIGO', value: 'CODIGO', tag:'v-text', type:'number', cols:'0' },
              { text: 'DATA', value: 'DATA', tag:'v-text', type:'date', cols:'2', width: '80px',rule:'S' },
              { text: 'Tipo', value: 'TIPO', type:'text', tag:'v-select', tag_items:['TEXTO','TERMOS','OUTROS'],cols:'2',rule:'S', class : 'no-print'  },      
              // { text: 'Tipo', value: 'TIPO', type:'text', tag:'v-select', tag_items:['PDF','IMAGEM','TEXTO','TERMOS','OUTROS'],cols:'2',rule:'S', class : 'no-print'  },      
              { text: 'Descrição', value: 'NOME_TEXTO', tag:'v-text', type:'text', cols:'7',rule:'S', upper:'S' },
              // { text: 'Profissional', value: 'NOME_DOC', tag:'v-text', type:'text', cols:'0' },
              
          ]

          // clono os items
          this.items_editaveis = this.items_header.map((x) => x); //clonando array copiar array

          let sql = `SELECT * FROM ${tabela} where CODIGO_CLIENTE = ${this.items_clicou.CODIGOCLIENTE}  order by CODIGO desc`

          //  console.log('usuarios: '+sql);
          await this.crud_abrir_tabela(sql, 'items_lista')

          // await this.simula_click()

        }

        else if (tabela=='PT'){

          this.col_menu = 1

          //limpa campos sub
          this.sub_codigo    = 0
          this.sub_descricao = ''

          this.items_header = [

              { text: '..', value: 'actions', sortable: false, type:'hide', cols:'0',width:'5px'},
              // { text: 'CODIGO', value: 'CODIGO', type:'number', cols:'0' },
              { text: 'DATA', value: 'DATA', tag:'v-text', type:'date', cols:'2', width: this.w_data,rule:'S' },
              { text: 'DESCRIÇÃO', value: 'DESCRICAO',tag:'v-textarea', type:'text', cols:'12', rows:'19',rule:'S' },
              
          ]

          // clono os items
          this.items_editaveis = this.items_header.map((x) => x); //clonando array copiar array

          // removo os campos que nao quero visivel somente para edicao
          // this.items_header.splice(this.items_header.findIndex(x => x.value === "NOTAS") , 1);//removo a coluna senha

          let sql = `select * from ${tabela} where codigo_paciente = ${this.items_clicou.CODIGOCLIENTE} order by data`

          // console.log( tabela+' : '+sql);
          this.crud_abrir_tabela(sql,'items_lista')
        }
        else if (tabela=='PC'){

          this.col_menu = 1

          //limpa campos sub
          this.sub_codigo    = 0
          this.sub_descricao = ''

          this.items_header = [

              { text: '..', value: 'actions', sortable: false, type:'hide', cols:'0',width:'5px'},
              // { text: 'CODIGO', value: 'CODIGO', type:'number', cols:'0' },
              { text: 'DATA', value: 'DATA', tag:'v-text', type:'date', cols:'2', width: this.w_data,rule:'S' },
              { text: 'DESCRIÇÃO', value: 'PROCEDIMENTO',tag:'v-textarea', type:'text', cols:'12', rows:'3',rule:'S' },
              
          ]

          // clono os items
          this.items_editaveis = this.items_header.map((x) => x); //clonando array copiar array

          // removo os campos que nao quero visivel somente para edicao
          // this.items_header.splice(this.items_header.findIndex(x => x.value === "NOTAS") , 1);//removo a coluna senha

          let sql = `select * from ${tabela} where codigo_paciente = ${this.items_clicou.CODIGOCLIENTE} order by data`

          // console.log( tabela+' : '+sql);
          this.crud_abrir_tabela(sql,'items_lista')
        }

    },

    before_update(tabela){
        // passo 8 antes de salvar verifico alguns campos necessarios em determinada tabela


            // VERIFICA CAMPOS NUMERICOS QUE NAO PODEM IR VAZIOS

            let VERIFICAR = [
                // 'USUARIOS|CODIGODOUTOR',
            ]
            
            let CAMPOS = []
            for (let i = 0; i < VERIFICAR.length; i++) {
                
                CAMPOS = VERIFICAR[i].split("|");//divide
                if (tabela==CAMPOS[0]){//verifica se é a tabela selecionada
                    if (!this.item_edita[CAMPOS[1]]){//verifica se vazio
                        if (!this.item_edita[CAMPOS[0]]){this.$set(this.item_edita, CAMPOS[1], 0) }
                    }
                }
            }
        },

    define_defaults(tabela){

      // --------------------DEFINE DATA E HORA-----------------------
      let hoje = new Date().toLocaleDateString('pt-Br',{ datestyle: 'short', hour12: false, timeZone: 'America/Sao_Paulo' });  
      // console.log('hoje:'+ hoje);

      let today = hoje.slice(6, 10) +'-'+ hoje.slice(3, 5) +'-'+ hoje.slice(0, 2) ; //formato us yyyy-mm-dd
      // console.log('today:'+ today);

      let hora = new Date().toLocaleTimeString('pt-Br',{ hour12: false, timeZone: 'America/Sao_Paulo' });
      let hora_curta = hora.slice(0, 5)
      // console.log('hora = '+ hora);
      // console.log('hora = '+ hora_curta);
      // --------------------DEFINE DATA E HORA-----------------------


      // passo 7 defino valores default para alguns campos de determinada tabela
      if (tabela=='PT'){
        this.$set(this.item_edita, 'DATA', today)
        this.$set(this.item_edita, 'CODIGO_PACIENTE', this.items_clicou.CODIGOCLIENTE)
        this.$set(this.item_edita, 'CODIGO_MEDICO',   this.getCodigoDoc)
        this.$set(this.item_edita, 'CODIGO_DIAG',     0)
        this.$set(this.item_edita, 'TIPO',           '1')
      }
      else if (tabela=='PC'){
        this.$set(this.item_edita, 'DATA', today)
        this.$set(this.item_edita, 'CODIGO_PACIENTE', this.items_clicou.CODIGOCLIENTE)
        this.$set(this.item_edita, 'CODIGO_MEDICO',   this.getCodigoDoc)
        this.$set(this.item_edita, 'CODIGO_DIAG',     0)
        this.$set(this.item_edita, 'TIPO',           '1')
      }

    },


      async consulta_cep(cep){

        if (cep?.length>=9){

          let link = "https://viacep.com.br/ws/"+cep+"/json/"
          const res = await axios.get(link);
          // console.log(res.data);

          this.$set(this.editedItem, 'ENDERECOCOMPLETO', res.data.logradouro)
          this.$set(this.editedItem, 'BAIRRO', res.data.bairro)
          this.$set(this.editedItem, 'CIDADE', res.data.localidade)
          this.$set(this.editedItem, 'UF', res.data.uf)
        }
      } , 

      getVmask(qual){
            
          if (qual == 'cel'){
              return "(##) #####-####"
          }
          else if (qual == 'fone'){
              return "(##) ####-####"
          }
          else if (qual == 'cnpj'){
              return "##.###.###/####-##"
          }
          else if (qual == 'cpf'){
              return "###.###.###-##"
          }
          else if (qual == 'data'){
              return "##/##/####"
          }
          else{
              return ""
          }
      },

      oi(vem){
        console.log(vem);
        console.log(vem.target.value);
          // this.getKEYsql()
          // alert('oi')
      } , 

      getKEYsql(){
        let r = DSibasico.getKEYsql() 
        // alert(r)
        return r
      },

      calcula_idade(data,campos){
        let s =  DSibasico.calcula_idade(data, campos)
        return s.texto
      },

      async permissoes(){

         let is_doc           = this.read_sessionStorage('is_doc')          // usuario.CODIGODOUTOR > 0
         let set_doutor       = this.read_sessionStorage('set.doutor')      // usuario.DOUTOR
         let superUser        = this.user_permissoes.superUser              // usuario.SUPERUSER
         let ver_historico    = this.user_permissoes.ver_historico          // usuario.VEHISTORICO


         this.superA        = (this.read_sessionStorage('superA')=='S' ? true : false)

        
         //se for superusuario e se for doutor pode acessar historico
        //  if ((this.superA) || (is_doc=='S') ||(set_doutor == 'S') ){
         if ( (this.superA) || (is_doc=='S')  || (ver_historico) ){
              this.pode.ver_historico = true
         } 

      },

      emit_acao(value,v,v2){
        this.dialog_re_exames = false

        // alert(value+' v:'+v)
        if (value=='salvou'){
          //atualiza historico
          this.crud_historico(this.items_clicou.CODIGOCLIENTE)

          this.alerta(v,v2)// v= msg, v2=color
          // alerta("Preencha os campos!","red");

          // setTimeout(function(){
          //   this.monta_historico()
          // }.bind(this), 1500);//usar esse .bind(this) para chamar funcoes com o setTimeout   
        }
      },

     adicionar_exames(item){
       //estou chamando um componente filho aqui...
        // console.log('adicionar_exames:');
        // console.log(item);
        this.dialog_re_exames = true
        this.dialog_editar    = false
     },

     async abre(){
      //  console.log('abre:');
      //  console.log(this.nome_digitou);

       let acao    = this.read_sessionStorage('qual_acao')//pega acao vindo desse parametro
       let digitou = this.read_sessionStorage('nome_digitou')//pega acao vindo desse parametro
       if (acao == 'novo'){

           //limpa parametros
           this.set_sessionStorage('nome_digitou','')
           this.set_sessionStorage('qual_acao','')

           //clica no botao novo cadastro
           this.novo_cliente(digitou)
       }
       else{
          this.nome_digitou    = this.read_sessionStorage('nome_digitou') !== 'undefined'? ( this.read_sessionStorage('nome_digitou') ) : ('')  //Operador Condicional Ternário
          this.abrir_auto      = this.read_sessionStorage('nome_digitou_abrir')//abrir cadastro automaticamente
       }

       
      // alert(this.nome_digitou)
       if ((this.abrir_auto == 'S' ) && (this.nome_digitou?.length > 0)){ //coloquei o ?. para ele nao gerar erro = encadeamento opcional   = https://pt.stackoverflow.com/questions/486355/o-que-significa-uma-interroga%C3%A7%C3%A3o-no-acesso-%C3%A0s-propriedades-de-um-objeto
          
          // alert('entrou')
          this.localizar_paciente(this.nome_digitou, true)
       }
     },

     set_sessionStorage($name, $value){
      sessionStorage[$name] = $value;
     },

     read_sessionStorage($name,$to){
        if (sessionStorage[$name]) {
           return sessionStorage[$name]
          //  this[$to] =  sessionStorage[$name];
        } 
     },
 

      async logado(){

          let key_session  = this.read_sessionStorage('refresh_size') 
          let logado       =  this.read_sessionStorage('buffer')
          let key          =  this.return_key().toString() //passo pra string para comparar

          if (key_session == undefined){
              key_session = ''
          }
          key_session  =  key_session.toString()//passo pra string para comparar

          if ( (logado !=='S') || (key !== key_session)) {
            this.$router.push('/')//redireciona para a pagina login
          }

          //chamo o ler_session do app.vue para atualizar o nome do usuario
          this.$emit('executar2', 'ler_session')      
      },

      return_key(){

         //pego o mes e o dia multiplico e chego num valor.. sera analisado   
          let dia = new Date().toLocaleDateString('pt-Br',{ datestyle: 'short', hour12: false, timeZone: 'America/Sao_Paulo' }); 
          dia =  dia.slice(3, 5) + dia.slice(0, 2) ; //formato us yyyy-mm-dd
          let a = dia.substring(0,2)
          let b = dia.substring(4,2)
          let c = (a * 3 * b)
          return c
      },




    forceReRender() {
      this.autoGrowHack = !this.autoGrowHack;
    },

    async crud_historico (ide) {

        this.count_h = 0

        const res = await axios.post(url+'meuSQL.php', {
        qual : 'historico', 
        ide  : ide
        })
        var meuSQL =  res.data;
        // console.log(res.data);
        
        const res2 = await axios.post(url+'conexao.php', {
            acao:   5,
            meuSQL: meuSQL
        })
      
        if(this.DEBUG == true){
          console.log('crud_historico');
          console.log(res2.data);
        }   

        this.historico =  res2.data;
        this.count_h   = res2.data?.length//qt historico

          setTimeout(function(){
            this.monta_historico()
          }.bind(this), 0);//usar esse .bind(this) para chamar funcoes com o setTimeout 


        //percorrer o array de dados retornados
        // let esse = res2.data;
        // for (let i = 0; i < esse.length; i++) {
        //     console.log('esse: '+esse[i].CODIGO +' '+esse[i].DATA);
        // }
    },

     async crud_receitas (ide) {//historico de receitas do paciente
        const res = await axios.post(url+'meuSQL.php', {
        qual : 'hist_receitas', 
        ide  : ide
        })
        var meuSQL =  res.data;
        // console.log(res.data);
        
        const res2 = await axios.post(url+'conexao.php', {
            acao:   5,
            meuSQL: meuSQL
        })
      
        if(this.DEBUG == true){
          console.log('crud_receitas');
          console.log(res2.data);  
        }

        if (res2.data.length > 0 ){
          if (res2.data[0].CODIGO > 0) {
            this.receitas =  res2.data;
        }
    }
    },

    async crud_exames (ide) {//historico de exames do paciente
        const res = await axios.post(url+'meuSQL.php', {
        qual : 'hist_exames', 
        ide  : ide
        })
        var meuSQL =  res.data;
        // console.log(res.data);
        
        const res2 = await axios.post(url+'conexao.php', {
            acao:   5,
            meuSQL: meuSQL
        })
      
        if(this.DEBUG == true){
          console.log('crud_exames');
          console.log(res2.data);
        }  

        if (res2.data.length > 0 ){
          if (res2.data[0].CODIGO > 0) {
            this.exames =  res2.data;
          }
        }
    },



    monta_historico(){
      if (this.pode.ver_historico){
        
    
      // if (this.boo_monta_historico == true){
      //   exit
      // }
      // this.boo_monta_historico = true //executar somente 1 vez por atendimento

      this.obj_historico2 = [] //limpa array
  

      this.forceReRender()

      // console.log('monta_historico');

      let h = this.historico
      let r = this.receitas
      let e = this.exames
      let f_final = []


      //percorre o historico.. filtrando as receitas e exames
      for (let i = 0; i < h.length; i++) {
          // console.log('H: '+h[i].CODIGO +' '+h[i].DATA+' - ide:'+h[i].CODIGOCLIENTE+' - doc:'+h[i].CODIGOMEDICO+' - dd:'+h[i].DESCRICAO);
          // console.log('-------receitas\n');


          //filtra array receitas
          var r_filtrado = r.filter(function(obj) { return (obj.DATA == h[i].DATA) && (obj.CODIGOMEDICO == h[i].CODIGOMEDICO)&& (obj.CODIGOCLIENTE == h[i].CODIGOCLIENTE) });

          for (let i = 0; i < r_filtrado.length; i++) {
              // console.log('R: '+filtrado[i].CODIGO +' '+filtrado[i].DATA+' - ide:'+filtrado[i].CODIGOCLIENTE+' - doc:'+filtrado[i].CODIGOMEDICO+' - mm:'+filtrado[i].MEDICAMENTO);
          }


          // console.log('-------exames\n');
          //filtra array exames
          var e_filtrado = e.filter(function(obj) { return (obj.DATA == h[i].DATA) && (obj.CODIGOMEDICO == h[i].CODIGOMEDICO)&& (obj.CODIGOCLIENTE == h[i].CODIGOCLIENTE) });

          for (let i = 0; i < e_filtrado.length; i++) {
              // console.log('E: '+filtrado[i].CODIGO +' '+filtrado[i].DATA+' - ide:'+filtrado[i].CODIGOCLIENTE+' - doc:'+filtrado[i].CODIGOMEDICO+' - mm:'+filtrado[i].EXAME);
          }


          // criando o objeto array que sera usado no html
          let obj = h[i]
          let sfields = {}

          //descobrindo campo do objetos e preencho
          for (var property in obj){
            this.$set(sfields, property, obj[property]);//insiro no objeto os valores

            //trato alguns campos
            if (property='DATA'){
              let aux = obj[property]
              aux = aux.substring(8,10) + '/'+ aux.substring(5,7)+ '/'+ aux.substring(0,4);//formato a data dd/mm/yyyy // 2021-09-28
              
              this.$set(sfields, 'X_'+property, aux);//insiro no objeto os valores
            }
          }
          //insiro outros valroes
          this.$set(sfields, 'receitas', r_filtrado);//insiro no objeto os valores
          this.$set(sfields, 'exames', e_filtrado);//insiro no objeto os valores

          //insiro no objeto final as linhas
          f_final.push(sfields)
      }

      //defino o final no objeto que esta no html
      this.obj_historico2 = f_final
      // console.log('obj_historico2');
      // console.log(this.obj_historico2);

       }

    },


      getColorSituacao (v,sub) {

        if ((sub =='')||(sub == null)){
          if (v == 'G') return '#0080ff'
          if (v == 'N') return '#804040'
          if (v == 'F') return '#ff8080'
          if (v == 'L') return '#ff8080'
          if (v == 'T') return '#dede03'
          if (v == 'O') return '#800080'
          if (v == 'C') return 'black'
          else if (['D','B'].includes(v)) return 'red'
          else if (v == 'A') return 'green'
          else return 'grey'

        }
        else {
          return '#03e3e3'
        }
      },

      getSituacao (v,sub) {
        if ((sub =='')||(sub == null)){
          if (v == 'G') return 'Aguardando'
          if (v == 'N') return 'Remarcado'
          if (v == 'T') return 'Atendendo'
          if (v == 'F') return 'Faltou'
          if (v == 'B') return 'Bloqueado'
          if (v == 'D') return 'Desmarcou'
          if (v == 'M') return 'Agendado'
          if (v == 'O') return 'Confirmado'
          if (v == 'L') return 'Cancelado'
          if (v == 'C') return 'Cancelado'
          else if (v == 'A') return 'Atendido'
        }
        else{
          return 'Compartilhado com: '
        }
      },



      onResize () {
        this.isMobile = window.innerWidth < 600
        // this.isMobile = this.$vuetify.breakpoint.smAndDown

        // if(this.isMobile){
        //   this.switch_agendar = false
        // }
      },

      maps(item){
        let link = 'https://www.google.com/maps/search/'+item
        window.open(link, '_blank');      
      },


      abrir_cadastro(item){
        // console.log('abrir_cadastro:');
        // console.log(item);

        this.mostrar_tabela = false

        this.obj_dados_paciente = Object.assign({}, item)

        
        this.items_clicou = item
        this.tab = 'cadastro'
        this.tab_2 = 'agendamentos'

        this.boo_monta_historico = false

        // this.value_expansion = (item.OBS) ?  0 : null
        this.value_expansion = [0,1]

        this.lista_agendamentos(item.CODIGOCLIENTE)
        this.crud_historico(item.CODIGOCLIENTE)
        this.crud_receitas(item.CODIGOCLIENTE)
        this.crud_exames(item.CODIGOCLIENTE)

        // TABELA DE ATENDIMENTOS PT PC 
        this.permite_items()

        if (item.AVISO){
          if (item.AVISO.length>5){

            setTimeout(function(){
              alert(item.AVISO)
            }.bind(this), 333);//usar esse .bind(this) para chamar funcoes com o setTimeout   
          }
        }

      },

      remover_cliente(ide){
        console.log(ide);

        if (ide > 0){
          if (confirm("Deseja realmente excluir "+ide+' ?')){

            // let sql = `delete from clientes where codigocliente = `+ide
            let sql = `update clientes set ativo= 'X' where codigocliente = `+ide
            // console.log(sql);
            this.crud_sql(sql)

            // console.log(this.items_clicou);

            this.dialog = false

            this.editedIndex = this.items_pacientes.indexOf(this.items_clicou)
            this.items_pacientes.splice(this.editedIndex, 1)

          }
        }
      },

      novo_cliente(nome){

        this.mostrar_tabela = false


        //default  
        this.$set(this.editedItem, 'CODIGOCONVENIO', 0)
        this.$set(this.editedItem, 'CONVENIO_NOME', '')
        // this.$set(this.editedItem, 'DATANASCIMENTO', null)

        this.$set(this.editedItem, 'CODIGOCONSULTORIO', 0);//defino valor manualmente
        this.$set(this.editedItem, 'ATIVO', 'S');//defino valor manualmente
        // this.$set(this.editedItem, 'CODIGOCLIENTE', '(SELECT max(CLIENTES.CODIGOCLIENTE+1)FROM CLIENTES)');//defino valor manualmente
        this.$set(this.editedItem, 'CODIGOCLIENTE', '(SELECT COALESCE(MAX(CLIENTES.CODIGOCLIENTE) + 1, 1)FROM CLIENTES)');//defino valor manualmente


        if (nome){
          this.$set(this.editedItem, 'NOME', nome)
        }

        this.dialog = true
        this.readonly = false


      },

      getFormatDate(data){
        if (data !== undefined){
           let dia =   data.substring(8,10) + '/'+ data.substring(5,7)+ '/'+ data.substring(0,4);//formato a dd/mm/yyyy
           return dia 
        }else{
          return ''
        }  
      },

      addHashToLocation(params) {
        history.pushState(
        {},
        null,
        this.$route.path + '#' + encodeURIComponent(params)
        )
      }, 

      allUpper(event, obj_name, field){
          this[obj_name][field] = DSibasico.allUpper(event)
      },
     
     firstUpper(event, field){
      // console.log(event);
      let a = event.target.value;//captura o valor do event
            if (a!=='' && a.length==1){  
        a = a.toUpperCase();
        this.editedItem[field] = a;
      } 
     },

     send_whats(cel,nome, msg_enviar){

       nome = nome.toLowerCase().trim();  //passo tudo pra minuscula

        cel = cel.replace(/[^\d]+/g,'');
        cel = cel.substring(0,2) + cel.substring(3,20);//deixo de fora o 1º 9

        nome = nome.split(" ");//separo as palavras
        nome = nome[0];//pego o primeiro nome        
        nome = nome.charAt(0).toUpperCase() + nome.slice(1);//passo 1 letra maiuscula
        // alert(nome);

        let msg = ''
        if (msg_enviar){
           msg = msg_enviar
        }
        else{
           msg = "&text=Olá%20" + nome 
        }

        if (msg !==''){
          if (this.isMobile){
            window.open("https://api.whatsapp.com/send?phone=55"+ cel +'&text='+ msg);
          }else{
            window.open("https://web.whatsapp.com/send?phone=55"+ cel +'&text='+ msg);
          }
        }

     },

     call_phone(cel){
         window.open("tel:"+ cel);
     },


     keyUpp(evt) {
       
        //limpa o input search quando pressiona Esc
        if (evt == 'esc') {            
            // alert('clicou em Esc');
            this.search = '';
        }
      },

      alerta(text,color){
        // alert(text);
         this.snackbar = true;
         this.snack_text= text;

         if (color){
            this.snack_color = color;
         }
         else{
            if ( (text.includes('ERROR:')) || (text.includes('error'))){  
              this.snack_color = "red";
            }else{
              this.snack_color = "success";
            }
         }
      },


      editItem (item, readonly) {

        this.items_clicou = item

        this.editedIndex = this.items_pacientes.indexOf(item)
        this.editedItem = Object.assign({}, item)

        this.items_selecionados.cod_convenio      = item.CODIGOCONVENIO
        this.items_selecionados.nome_convenio      = item.CONVENIO_NOME


        this.dialog = true
        this.readonly = readonly
        this.dialog_editar = false
      },

      deleteItem (item) {
        this.editedIndex = this.desserts.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialogDelete = true
      },

      deleteItemConfirm () {
        // alert(this.editedItem.id)
        this.desserts.splice(this.editedIndex, 1)
        this.closeDelete()

        this.crud_delete(this.editedItem.id)
      },

      close () {        
        
        this.dialog = false
        this.mostrar_tabela = true

        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },


      save () {
        // alert('save');
      if (this.$refs.form.validate()){

        if (this.editedIndex > -1) {
          Object.assign(this.desserts[this.editedIndex], this.editedItem)
          // alert('Editando = '+ this.editedIndex+' '+this.editedItem.id)

          this.crud_update(this.editedItem.id);//passo o id do registro na tabela
        } else {
          this.desserts.push(this.editedItem);
          //alert('Novo = '+ this.editedIndex);

          this.crud_create();
        }

        this.close()
      }
      else{
        alerta("Preencha os campos!","red");
      }

        
      },

       async crud_create(){

          //alert(this.editedItem.crm);
          //alert(url);
         const data = {
                acao: 2,
                table:             this.crud_table,
                fields:            this.editedItem,
         }  

        const res = await axios.post(url+'conexao.php', data);

        console.log(res.data);
        this.alerta(res.data, "success");
      },



      async crud_update(id){
                 
          // alert('crud update id:'+id);

          if (id != null && id !== undefined) {

            // console.log(this.editedItem);
              const res = await axios.post(url+'conexao.php', {
                acao: 3,
                id:                id,
                table:             this.crud_table,
                fields:            this.editedItem,
              })

              // console.log(res.data);
              this.alerta(res.data, "success");
          }else{
             this.alerta("id inválido","red");
          }
      },


      async crud_delete(id){
                 
          //  alert('crud update id:'+id);
          if (id != null && id !== undefined) {

              const res = await axios.post(url+'conexao.php', {
                acao: 4,
                id:     id,
                table:  this.crud_table
              })

              // console.log(res.data);
              this.alerta(res.data, "success"); 
      
            
          }else{
            this.alerta("id inválido","red");
          }
      },


    async localizar_paciente(event, pelo_ide){

        event = event.replaceAll("'",'');

        this.progress_user = true

        if (event == undefined){
          event = ''
        }

          //salvo na session
          this.set_sessionStorage('nome_digitou',this.nome_digitou)

        if (event == undefined){
          this.nome_digitou = ''
        }

        if (event == ''){
          event = '....'
        }


        let arrayNomes = []

        if (isNaN(event)) {
          // alert('texto')
          arrayNomes = event.trim().split(' ');//retiro espaços vazios e separo a string
          var nome = event
          var ide  = null

          nome = nome.toLocaleLowerCase()
        }else{
          // alert('eh numero')
          var nome = null
          var ide = event

          if (!pelo_ide){//se nao for pra pegar exatamento pelo ide, entao pega pelo que achar
             nome = event
          }
        }


        let sql_nome = ''
        if (this.find_start){//caso procure pela letra inicial
          sql_nome =  `OR (LOWER(CLIENTES.NOME) ${(this.find_start)? 'STARTING':'CONTAINING'}   '${nome}'))`
        }
        else{//busca padrao 

            for(let i = 0; i< arrayNomes.length; i++){
              if (i>0){
                sql_nome += ` AND `
              }
              sql_nome += `(CLIENTES.NOME CONTAINING '${arrayNomes[i]}')`     
            }

            if (sql_nome){
              sql_nome = `((${sql_nome})OR`
            }
            else{
              sql_nome = `(`
            }
        }

        // OBS: (ATIVO = X) SAO PACIENTES EXCLUIDOS

        let ddata = await this.formata_datas(nome,1)
        let ccelular = await this.formata_datas(nome,2)

        let ativos = ''
        if (this.find_ativos){
          //  ativos = `<> 'X'`
          //  ativos = ` (CLIENTES.ATIVO <> 'X') AND `
           ativos = ` (CLIENTES.ATIVO = 'S') AND `
        }
        else{
          // ativos = `<> ''`//todos
          ativos = ``
        }

        let where = `
        WHERE
        ${ativos}
        ((CLIENTES.CODIGOCLIENTE = `+ ide +`)OR
        ${sql_nome}
        (CLIENTES.CELULAR CONTAINING '${ccelular}')OR
        (CLIENTES.DATANASCIMENTO CONTAINING '`+ ddata +`')OR
        (CLIENTES.CPF CONTAINING '${nome}')))
        `

        var meuSQL = 
          `
          SELECT
            CLIENTES.CODIGO,
            CLIENTES.CODIGOCLIENTE,
            CLIENTES.CODIGOCONVENIO,
            CLIENTES.CODIGOCONSULTORIO,
            CLIENTES.ATIVO,
            CLIENTES.NOME,
            CLIENTES.RG,
            CLIENTES.CPF,
            CLIENTES.ENDERECOCOMPLETO,
            CLIENTES.CEP,
            CLIENTES.CIDADE,
            CLIENTES.BAIRRO,
            CLIENTES.UF,
            CLIENTES.FONE1,
            CLIENTES.FONE2,
            CLIENTES.CELULAR,
            CLIENTES.EMAIL,
            CLIENTES.PROFISSAO,
            CLIENTES.ESTADOCIVIL,
            CLIENTES.SEXO,
            CLIENTES.NOMEMAE,
            CLIENTES.NOMEPAI,
            CLIENTES.NUMCARTEIRA,
            CLIENTES.CONVENIO_NOME,
            CLIENTES.DATANASCIMENTO,
            CLIENTES.IDADE,
            CLIENTES.OBS,
            CLIENTES.AVISO,
            CLIENTES.FOTO_URL
          FROM
            CLIENTES
            ${where}
          ORDER BY
            CLIENTES.NOME
          `

          // console.log(meuSQL);

        
          const res2 = await axios.post(url+'conexao.php', {
            acao:   5,
            meuSQL: meuSQL
          })

          // console.log(res2.data);

        this.items_pacientes = await res2.data;

        this.qt_items_pacientes = this.items_pacientes.length

        if(this.items_pacientes.length == 0){
          this.alerta('Nada encontrado!','orange')
        }
        else{

           if ( (this.abrir_auto == 'S') && ((ide >0)) ){ //somente ser for pesquisado pelo IDE = direto
              this.abrir_cadastro(this.items_pacientes[0])
           }
        }

        this.progress_user = false

        //voltando o comando abrir para NAO
        this.set_sessionStorage('nome_digitou_abrir', 'N')
        this.abrir_auto = 'N'

        // console.log('items_pacientes:');
        // console.log(this.items_pacientes);

        if (this.DEBUG){
          this.items_clicou =  this.items_pacientes[0]// isso somente para teste
        }
        
    },



    async lista_convenios(){

        var meuSQL = 
        `
        SELECT
        CONVENIO.NOME,
        CONVENIO.CODIGO
        FROM
        CONVENIO
        WHERE
        CONVENIO.ATIVO = 'S'
        ORDER BY
        CONVENIO.NOME
        `        

        // console.log(meuSQL);
        
          const res2 = await axios.post(url+'conexao.php', {
            acao:   5,
            meuSQL: meuSQL
          })

        this.convenios = res2.data;  
        // console.log('convenios:');
        // console.log(this.convenios);

    },


    async lista_agendamentos(ide){

        var meuSQL = 
        `
        SELECT
          AGENDA.DATA,
          AGENDA.HORA,
          AGENDA.ATENDIDO,
          AGENDA.PROCEDIMENTO,
          AGENDA.VALOR,
          AGENDA.FORMA_PAGAMENTO,
          AGENDA.CONVENIO,
          AGENDA.CODIGOCONSULTA,
          DOUTORES.NOME AS NOME_DOC
        FROM
        AGENDA
        LEFT OUTER JOIN DOUTORES ON (AGENDA.CODIGOMEDICO=DOUTORES.CODIGO)
        WHERE
          (AGENDA.CODIGOCLIENTE = `+ ide +`)
        ORDER BY
          AGENDA.DATA DESC,
          AGENDA.HORA DESC
        `        

        // console.log(meuSQL);
        
          const res = await axios.post(url+'conexao.php', {
            acao:   5,
            meuSQL: meuSQL
          })

        this.items_agendamentos = res.data;  
        this.count_a = res.data?.length//qt historico
        // console.log('items_agendamentos:');
        // console.log(this.items_agendamentos);

    },

    qual_cli_exec(){


        this.mostrar_tabela = true

        if (this.editedItem.CODIGO >0 ){
           this.cliente_update()
        }else{
           this.cliente_create()
        }
    },

    async cliente_create(){

      if (this.$refs.form.validate()){


          let KEY_SQL = this.getKEYsql()
          // alert(KEY_SQL)
          this.$set(this.editedItem, 'KEY_SQL', KEY_SQL)

          console.log('this.editedItem:');
          console.log(this.editedItem);

         const data = {
                acao: 2,
                table:             'clientes',
                fields:            this.editedItem,
         }  

        const res = await axios.post(url+'conexao.php', data);


        console.log(res.data);
        let salvo = false

        if (res.data.includes('Error')){
          salvo = false
          this.alerta(res.data, "red");
        }else{
          salvo = true
          this.alerta('Adicionado com sucesso', "success");
        }


        this.dialog = false

        //atualiza lista
        // this.items_pacientes.push(this.editedItem);

        this.nome_digitou = this.editedItem.NOME 
        await this.localizar_paciente(this.nome_digitou, false)

        if (salvo == true){

            let up_agenda =  sessionStorage['up_agenda']
            sessionStorage['up_agenda'] = '' //limpando
            if (up_agenda == 'undefined'){ up_agenda = ''}

            if (up_agenda){
                
                let sql = `
                  update agenda set codigocliente =
                  (select CODIGOCLIENTE from clientes where KEY_SQL = '${KEY_SQL}')
                  where
                  agenda.codigoconsulta = ${up_agenda}
                `

                console.log(sql);


                await this.crud_sql(sql)

                // VOLTO PRA AGENDA
               await this.$router.push('/agenda')//redireciono para a pagina do router
            }
        }



        function faz(){
          // console.log('aquiiii');funcao dentro de uma funcao
          alert('oi')
        }


        // this.editedItem = {}//limpa array

      }
      else{
        this.alerta("Preencha os campos!","red");
      }
    },

    async cliente_update(){

      if (this.$refs.form.validate()){

          // console.log('this.editedItem:');
          // console.log(this.editedItem);

         let id = this.editedItem.CODIGO
         if (id<1){
           this.alerta('Código inválido!','red')
         }
         else{

           // verifica campos que se vazio gera erro..entao remove do objeto
            if (!this.editedItem.DATANASCIMENTO){
              // console.log('vazio');
              delete this.editedItem.DATANASCIMENTO;
            }
            

            const data = {
                    acao: 3,
                    id:          this.editedItem.CODIGO ,
                    id_field:    'CODIGO', 
                    table:       'CLIENTES',
                    fields:       this.editedItem,
            }  

            const res = await axios.post(url+'conexao.php', data);

            console.log(res.data);
            this.alerta(res.data, "success");

            // this.abre_agenda()
            this.dialog = false

            //atualiza o obj
            Object.assign(this.items_pacientes[this.editedIndex], this.editedItem)

            // this.editedItem = {}//limpa array
         }

      }
      else{
        this.alerta("Preencha os campos!","red");
      }
    },

    muda_convenio(item){

      this.$set(this.editedItem, 'CODIGOCONVENIO', item.CODIGO)
      this.$set(this.editedItem, 'CONVENIO_NOME', item.NOME)

    },

    // async crud_sql (sql) {

    //     const res = await axios.post(url+'conexao.php', {
    //         acao:   6,
    //         meuSQL: sql
    //     })
      
    //     console.log(res.data);
    //     this.alerta(res.data, "success");//so retorna ok ou error
    // },




           //---------------importando funcoes sql ----------------------- INICIO
          //EXECUTAR UM SQL TIPO UPDATE
          async crud_sql (sql) {
              await DSisql.crud_sql(this, sql)
          },
  
          async tabela_create(tabela, obj_edita){
              await DSisql.tabela_create(this, tabela, obj_edita)
          },
  
          async tabela_update(tabela, obj_edita){
              await DSisql.tabela_update(this, tabela, obj_edita)
          },
  
          //ABRE OS DADOS DA TABELA
          async crud_abrir_tabela(sql, items_name){
              await DSisql.crud_abrir_tabela(this, sql, items_name)
          }, 
  
          //FICA NO BOTAO SALVAR.. VERIFICA SE UPDATE OU CREATE
          async salvar_tabela(tabela, obj_edita, dialog_name){

              await this.before_update(this.tabela_selecionada)//trato alguns campos necessarios

              await DSisql.salvar_tabela(this,tabela, obj_edita, dialog_name)
          },
  
          async remover_tabela(tabela, obj_edita, titulo){
              await DSisql.remover_tabela(this, tabela, obj_edita, titulo)
          },
  
          editar_tabela(item, obj_edita_name, dialog_name) {
              this.seleciona_linha(item)
              DSisql.editar_tabela(this, item, obj_edita_name, dialog_name)
          },
  
          nova_tabela(obj_edita_name,dialog_name){

             DSisql.nova_tabela(this, obj_edita_name,dialog_name)

             //define alguns valores default
             this.define_defaults(this.tabela_selecionada)
          },



    }

  }
</script>

<style scoped>

  .container{
    max-width: 100%;
  }

  .v-tab{
  font-size: 9pt; /* usando o sass-variables */
 }

.count_item {
  position: relative !important;
  top: -5px;
  font-size: 80%;
  vertical-align: super;
  color: red;
}
.count_item_2 {
  position: relative !important;
  top: -0px;
  font-size: 80%;
  vertical-align: super;
  color: red;
}

::v-deep td.text-start{ /* padding das colunas data-table  */
  padding-left: 5px !important;
  padding-right: 2px !important;
  /* padding: 0px 2px !important; */
  /* background-color: red; */
}

::v-deep th.text-start{ /* padding dos titulos das colunas data-table */
  padding: 0px 4px !important;
  /* background-color: red; */
}

.v-data-table >>> tr>td{
  font-size:12px !important;
  height: 25px !important;
}

@media print {
    .no-print {
      display: none !important;
      /* margin: 5cm !important; */
    }

    .v-app,
    .v-footer,
    .v-navigation-drawer,
    .v-card-actions,
    .v-toolbar {
      display: none !important;
    }

 }

</style>
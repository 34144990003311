<template>
 <div class="">


  <!-- {{sou_acencon}} -->
  <!-- desserts items principal:{{desserts}} <br/> -->
  <!-- {{dialog_protocolo}} -->
  <!-- {{vai_imprimir}} -->
  <!-- user_permissoes:{{user_permissoes}} <br/><br/> -->
  <!-- convenios:{{convenios}} <br/> -->
  <!-- procedimentos {{procedimentos}} <br/><br/> -->
  <!-- items_valores_procedimentos {{items_valores_procedimentos}} <br/><br/> -->
  <!-- {{items_somatorias}} -->
  <!-- obj_dados_empresa: {{obj_dados_empresa}} <br/> -->
  <!-- quando clico na linha do data-table:{{ item_clicou }} <br/> -->
  <!-- array_whats: {{array_whats}} <br/><br/> -->
  <!-- whats_multiplos: {{whats_multiplos}} <br/><br/> -->
  <!-- config_whats:{{config_whats}} <br/><br/> -->


  <!-- SALVAR_WEBHOOK = zapTIPO:{{zapzapTIPO}}<br/> -->
   <!-- zapzapSITUACAO:{{zapzapSITUACAO}}<br/> -->
   <!-- zapzap:{{zapzap}}<br/> -->
   <!-- zap_status:{{zap_status}} <br/> -->

  <!-- {{mostrar_tabela}} -->
  <!-- {{lista_lancamentos}} -->

  <!-- user_def: {{user_def}} <br/><br/> -->
  <!-- obj_user:{{obj_user}}<br/><br/> -->
   
  <!-- {{doc_codigo}} define obj_doc_selecionado -->
  
  <!-- item_principal:{{item_principal}}<br/><br/> -->
  <!-- items_protocolo:{{items_protocolo}}<br/><br/> -->
  <!-- {{items_niver}} -->
  <!-- {{items_selecionados}} -->
  <!-- item_copiar_agendamento:{{ item_copiar_agendamento }}<br/> <br/> -->

  <!-- items_selecionados:{{items_selecionados}} <br/> -->
    <!-- {{item_clicou_menu_definir}} -->
  <!-- {{janela_modal_tabela}} -->
   <!-- value_expansion:{{value_expansion}} <br/><br/> -->
  <!-- expansion_selecionao:{{expansion_selecionao}} <br/><br/> -->
<!-- {{horarios_cancelados_lista}} -->
  <!-- {{dias_bloqueados}} -->
<!-- {{Maviso}} -->
  <!-- {{procedimentos}} -->
  <!-- editedItem: {{editedItem}} <br/> -->
  <!-- copia_editedItem: {{copia_editedItem}} <br/> -->
 <!-- {{editedItem.CODIGOMEDICO}} -->


 <!-- {{horario_doc_array}} -->
 <!-- {{hora_agendar}} -->
 <!-- {{superA}} -->

 <!-- {{adiantar_hora}} -->
 <!-- {{grafico1}} -->
 <!-- {{grafico2}} -->
 <!-- {{lista_estatiticas}} -->
 <!-- {{sousuper}} -->

 
 <!-- {{boot_edit}} -->

  <!-- {{doc_codigo.CODIGO}} -->
  <!--dados profissional: {{doc_codigo}} -->
<!-- {{profissionais}} -->
<!-- {{mensagens_whats}} -->

 <!-- {{item_principal}} -->

<!-- {{ ja_adicionado }} -->

<!-- {{ hora_agendar_selecionada }} -->
<!-- {{ horario_do_dia }} -->
<!-- {{ selected }} -->

 <!-- {{ editedItem }} -->
<!-- {{ pre_cadastro }} -->
<!-- {{ editedItem.CODIGOCLIENTE }} -->

  <v-overlay :value="overlay">
    <v-progress-circular
      indeterminate
      size="64"
    ></v-progress-circular>
  </v-overlay>


  <!-- <div>
    <canvas ref="canvas" width="400" height="200"></canvas>
    <button @click="saveSignature">Salvar Assinatura</button>
    <div v-if="signatureDataURL">
      <img :src="signatureDataURL" alt="Assinatura">
    </div>
  </div> -->



  <!-- <v-container>
    <v-row justify="center">
      <v-col cols="12" sm="6">
        <v-card>
          <v-card-text>
            <v-row justify="center">
              <canvas ref="canvas" width="400" height="200" style="border: 1px solid #000;"></canvas>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-btn @click="clearSignature">Limpar</v-btn>
            <v-btn @click="saveSignature">Salvar</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container> -->



  <template>
  <div class="text-center custom-dialog ">
    <!-- :is="vai_imprimir ? 'v-dialog' : 'v-dialog'" -->
    <v-dialog
      v-model="dialog_protocolo"
      width="660"
      :fullscreen="(vai_imprimir)"
      :class="[(vai_imprimir)?'mx-15' : '']"
    >
      
      
      <v-card elevation="0">
        
          <v-card>
            <v-list-item three-line>
              <v-list-item-content>
                
                <v-list-item-title class="text-h5 mb-1 text-center">
                  {{obj_dados_empresa.NOME}}
                </v-list-item-title>

                <div class="text-overline text-center">
                  Comprovante de Agendamento 
                </div>

                <!-- <v-list-item-subtitle>Greyhound divisely hello coldly fonwderfully</v-list-item-subtitle> -->
              </v-list-item-content>
            </v-list-item>
          </v-card>
        
        

        <v-divider class="mx-5 mb-2"></v-divider>

        <v-card>
            <v-list-item >
              <v-list-item-content>

                <div class="text-overline text-center">

                  <v-list-item-title class="text-h6 mb-4 text-center">
                    {{  items_protocolo.NOME  }}
                  </v-list-item-title>

                </div>

                <v-row class="">
                  <v-col cols="auto" class="">
                    <h4 class="font-weight-regular mb-1" v-if="(!sou_acencon)"> PROFISSIONAL: </h4>
                    <h4 class="font-weight-regular mb-1" v-if="(sou_acencon)"> PROCESSO: </h4>
                    <h4 class="font-weight-regular mb-1"> SERVIÇO: </h4>
                    <h4 class="font-weight-regular mb-1"> DATA:  </h4>
                    <h4 class="font-weight-regular mb-1"> HORA:  </h4>
                    <h4 class="font-weight-regular mb-1"> LOCAL:  </h4>
                    <h4 class="font-weight-regular mb-1"> ENDEREÇO:  </h4>
                  </v-col>
                  
                  <v-col cols="auto" class="">
                    <h4 class="mb-1" v-if="(!sou_acencon)"> {{  items_protocolo.PROFISSIONAL  }} </h4>
                    <h4 class="mb-1" v-if="(sou_acencon)"> {{  items_protocolo.PROCESSO  }} </h4>
                    <h4 class="mb-1"> {{  items_protocolo.PROCEDIMENTO  }} </h4>
                    <h4 class="mb-1"> {{  items_protocolo.DATA  }} </h4>
                    <h4 class="mb-1"> {{  items_protocolo.HORA  }} </h4>
                    <h4 class="mb-1"> {{ (sou_acencon) ? 'ACENCON' : obj_dados_empresa.NOME   }} </h4>
                    <h4 class="mb-1"> {{  obj_dados_empresa.ENDERECO }} - {{  obj_dados_empresa.CIDADE }} </h4>
                  </v-col>
                </v-row>

              </v-list-item-content>
            </v-list-item>
          </v-card>

          <v-divider class="mx-5 mb-2"></v-divider>


          <v-card>
            <v-list-item three-line>
              <v-list-item-content>
                
                <v-list-item-title class="text-h6 mb-1">
                  OBSERVAÇÕES:
                </v-list-item-title>

                <!-- <v-card-text v-html='quebra_linha(mensagens_whats.MSG_OBS)'> </v-card-text> -->
                <v-card-text v-html='items_protocolo.OBS'> </v-card-text>

              </v-list-item-content>
            </v-list-item>
          </v-card>

        <v-card-actions class="" v-if="(!vai_imprimir)">
          <v-spacer></v-spacer>
          
          <v-btn
            color="primary"
            class="mr-2"
            @click="imprimir()"
          >
            IMPRIMIR
          </v-btn>

          <v-btn
            color="red"
            text
            @click="dialog_protocolo = false"
          >
            FECHAR
          </v-btn>
          
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>


  <v-dialog
        v-model="dialog_pendentes"
        max-width="500px"
        persistent
      >
        <v-card>
          <v-card-title class="orange">
            {{array_ids_whats.length}} Mensagens de WhatsApp não enviadas!
          </v-card-title>
          <v-card-text>
            <v-select
              :items="array_ids_whats"
              label="Número"
              item-value="CODIGO"
              item-text="CELULAR"
              return-object
              hide-details="true"
            >
          
               <!-- <template v-slot:slot-scope="{ item , index}">  -->
               <!-- <template v-slot:item.NOME="{ item , index}">  -->
               <template slot='item' slot-scope="{ item }">
                 <div class="caption">
                 CODIGO ({{ item.CODIGO }} ) às  {{ item.HORA }} &nbsp | &nbsp {{ item.CELULAR }}
                 </div>                      
              </template>
          
          </v-select>

          <!-- <div v-if="selectedFarmacia" class="mt-2 fs-11">
            {{ selectedFarmacia.WHATS_MSG }}
          </div> -->



          <!-- <v-divider class="px-5 my-10"></v-divider> -->

          <br/>
          <h2 class="mb-3">Possíveis Motivos:</h2>
          <p> 1- Verifique se o Módulo WhatsApp esta conectado</p>
          <p> 2- Verifique se o ícone do WhatsApp se esta verde ou vermelho</p>
          <p> 3- Caso esteja conectado, informe a DSi sobre o mesmo</p>


          </v-card-text>
          <v-card-actions>

            <v-spacer></v-spacer>

            <v-checkbox
              v-model="checkbox_avisar_pendentes"
              label="Não avisar mais"
              dense
              class="mr-9 mb-2"
              hide-details="true" 
              style="margin-left: 8px !important"  
          ></v-checkbox>

            <v-btn
              color="error"
              text
              @click="fechar_dialog_pendentes"
            >
              Fechar
            </v-btn>
            

          </v-card-actions>
        </v-card>
    </v-dialog>

    <!-- MOSTRA JANELA MODAL -->
    <div class="mt-n5">
      <v-row>
        <v-col>
        <LembreteVue 
          :aberto = 'janela_modal'
          pro_tabela = 'LANCAMENTOS'
          :pro_titulo = "item_principal.NOME"
          :pro_dados_paciente = "item_principal"
          :pro_dados_proceds = "procedimentos"
          :pro_obj_1 = "obj_janela_modal"
          :pro_obj_menus = null
          :show_menu = 'false'
          :pro_modo_edicao = 'false'
          :pro_pode_alterar = 'true'
          :pro_tag = pro_tag
          @muda_aberto_lembretes="muda_aberto_modal"
          @modal_salvar="modal_salvar"
        />
        </v-col>
      </v-row>
    </div>


  <div class="text-center">
    <v-dialog v-model="dialog_qrcode" width="300" persistent>

      <v-card>
        <v-card-title class="text-h6 grey lighten-5 justify-center">
           Concluído
        </v-card-title>

        <v-card-text>
          <v-img
            alt="user"
            :src="img_qrcode_zap"
            height="30%"
          >
         </v-img>


         <iframe :src="url_externo" frameborder="0" height="70px" width="100%" ></iframe>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            x-large
            @click="dialog_qrcode = false, status_zap()"
          >
            FECHAR
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>



  <template>
  <div class="text-center">
    <v-dialog
      v-model="dialog_img"
      width="500"
    >

      <v-card>
        <v-card-title class="text-h6 grey lighten-3 justify-center">
          <!-- {{item_clicou.NOME}}  -->
          {{dialog_img_titulo}} 
        </v-card-title>

        <v-card-text>          
          <v-img
            alt="user"
            :src="dialog_img_url"
            height="30%"
            v-if="(dialog_img_url)"
          >
         </v-img>
        </v-card-text>

        <v-card-actions>
          <v-btn
            color="primary"
            text
            x-large
            @click="saiba_mais_click(dialog_img_mais)"
            v-if="dialog_img_mais"
          >
            saiba mais
          </v-btn>

          <v-spacer></v-spacer>


          <v-btn
            color="primary"
            text
            x-large
            @click="dialog_img = false"
          >
            FECHAR
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>


  <!-- DIALOG TESTAR WHATS -->
  <div class="text-center">
    <v-dialog
      v-model="dialog_whats_ativar"
      width="650"
      scrollable
      persistent
    >

      <v-card 
         max-height="550" class="overflow-y-auto scroll"  id="id_sala" 
      >
        <v-card-title class="text-h5 grey lighten-2">
          Envio Automático de WhatsApp
        </v-card-title>

        <v-card-text class="mt-4">
          <div v-if="(zap_testar!='Quero saber mais')">
          <p class="orange--text">Chega de perder tempo enviando mensagens individualmente para seus pacientes!</p>

          <p>
           Sabemos o quão valioso é o seu tempo, e é por isso que temos a solução perfeita para você.
          </p>        
          <p>Nosso novo sistema de <b> mensagens automáticas</b>!</p>
          <!-- <p>nosso novo sistema de mensagens em massa!</p> -->
          <!-- <p> Com nosso módulo WhatsApp, você pode enviar mensagens personalizadas para todos os seus pacientes de forma rápida, fácil e eficiente</p> -->
        </div>

          <v-form
            ref="form"
            v-model="valido"
            >


            <div>
              <v-row align="center">
                <v-col
                  class=""
                  cols="12"
                >
                  <v-select v-model="zap_testar" v-if="(zap_testar!='Quero saber mais')"
                    dense
                    :items="['Quero saber mais', 'Quero continuar enviando mensagens manualmente']"
                    label="O que deseja fazer?"
                    :rules="notnullRules"
                    filled
                  ></v-select>
                  <!-- Quero Testar GRÁTIS por 7 dias -->
                  <!-- Quero saber mais -->
                </v-col>
              </v-row>
           </div>
          </v-form>

        </v-card-text>


          <v-card-text :class="['px-0 py-1 flex-grow-1 overflow-y-auto']" id="id_sala2" v-if="(zap_testar=='Quero saber mais')">

          <!-- {{items_mensagens}} -->

          <v-container fluid style="padding: 0;" id="id_sala2">
              <v-row no-gutters :class="cor_fundo" >

                  <v-col  v-if="0>1" cols="1" :class="cor_fundo+ ' pb-5 px-1 pr-5 d-flex align-end justify-center'">
                    
                    <v-progress-linear
                        color="blue"
                        indeterminate
                        rounded
                        height="6"
                      >
                    </v-progress-linear>
                  </v-col>

                  <v-col cols="12" style="position: relative;" :class="cor_fundo + ' pb-2 px-2'" >
                  <!-- <v-col :cols="(digitando)?'11':'11 offset-1'" style="position: relative;" :class="cor_fundo + ' pb-2 px-2 pl-0'" > -->
                      <div 
                      :class="'chat-container2 ' + cor_fundo"  ref="chatContainer"  
                      @scroll="usou_scroll($event)"
                      >

                          <div class=" message pt-2"  v-for="(message,index) in items_mensagens" v-bind:key="index" :class="{own: message.DE != 'DSI'}">


                              <div :class="[ (message.DE=='DSI') ? '' :  'black--text']" >
                                  <v-chip  v-if="(!message.TAG)"
                                      :color="(message.COR) ? message.COR : (message.RESPOSTA)? 'primary' : 'white'" 
                                      @click="responde_bot('DSI', 'user', (message.RESPOSTA)? message.RESPOSTA  : 'Legal, vamos prepar', 900, '','')">                                  
                                     <span :class="[(message.RESPOSTA) ? 'font-weight-bold' : '']">
                                      {{message.MENSAGEM}}
                                     </span> 
                                  </v-chip>

                                  <v-col cols="5" v-if="(message.TAG)" class="px-0">
                                  <v-text-field 
                                    dense
                                    v-model="boot_edit[message.TAG]"
                                    :label="message.MENSAGEM"
                                    required
                                    outlined
                                    autofocus
                                    hide-details=""
                                    @keypress.enter="bot_edit_send(message.TAG)"
                                    append-icon="mdi-send"
                                    @click:append="bot_edit_send(message.TAG)"   
                                    clearable               
                                  ></v-text-field>
                                </v-col>

                                  
                                  <!-- <div :class="[((message.JALEU == 'N')&&(message.DE != eu_sou) ? 'grey lighten-2' : 'white'), 'content']">
                                    <div>
                                      {{message.MENSAGEM}}
                                        </div>
                                  </div> -->
                              </div>
                          </div>


                          <v-chip>
                          <v-progress-linear v-if="(digitando)"
                              color="blue"
                              indeterminate
                              rounded
                              height="6"
                              class="px-4"
                            >
                          </v-progress-linear>
                        </v-chip>

                          <!-- dou br para ficar com o scroll certinho kkkkk -->
                          <br/><br/> 
                      </div>
                  </v-col>

              </v-row>
          </v-container>

          

          </v-card-text>


        

        <!-- <v-divider></v-divider> -->

        <v-card-actions class="my-4">
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            small
            text
            @click="dialog_whats_ativar = false, enviar_zap_testar('refresh')"
          >
            fechar
          </v-btn>

          <v-btn
            color="primary"
            small
            @click="dialog_whats_ativar = false, enviar_zap_testar(zap_testar)"
            :disabled="!valido"
            v-if="(zap_testar!='Quero saber mais')"
          >
            Enviar
          </v-btn>

          <v-btn
            v-if="0>1"
            color="success"
            small
            @click="dialog_whats_ativar = false, enviar_zap_testar(zap_testar)"
            :disabled="!valido"
          >
            Quero Testar grátis
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>

 <!-- DIALOG RELATORIOS -->
 <v-dialog
      v-model="dialog_relatorios"
      max-width="999px"
      scrollable
    >
  <v-card
    class="mx-auto"
  >
    <v-card-title class=" grey lighten-5">
      <h5>Relatório de {{ qual_relatorio }} </h5>

      <v-spacer></v-spacer>
      
      <v-icon
        color="red"
        @click="dialog_relatorios=!dialog_relatorios"
      >
        mdi-close
      </v-icon>

    </v-card-title>

    <v-card-text>
      <v-container class="mx-0 px-0 mt-2">
        <v-row>
          
          <v-col
            cols="12"
            md="2"
            class="ml-2"
          >      
            <v-text-field
              dense
              v-model="rel_data_de"
              label="De"
              hide-details
              type="date"
              
            ></v-text-field>
          </v-col> 
          
          <v-col
            cols="12"
            md="2"
            class="ml-2"
          >      
            <v-text-field
              dense
              v-model="rel_data_ate"
              label="Até"
              hide-details
              type="date"
              
            ></v-text-field>
          </v-col> 


          <v-col class="pb-0" v-if="(qual_relatorio=='Lançamentos')"
              cols="6"
              md="2"
              lg="2"
            >   
                <v-select v-model="tipo_selecionado" label="Tipo"
                  :items="['PREVISAO','RECEBIDO','ATRASADO']"
                  dense
                  
                >
                <!-- @change="open_contas(di,df)" -->
                </v-select>
            </v-col>
          
          <v-col
            cols="12"
            md="3"
            class="ml-2"
          >      
          <!-- {{ rel_cod_conv }} -->
          <v-autocomplete
              dense
              v-model="rel_cod_conv"
              label="Convênio"
              :items="convenios"
              item-value='CODIGO'
              item-text='NOME'
              no-data-text="Hein ?"
              clearable
 
          >

            <template v-slot:label>
              <span v-if="!rel_cod_conv" class="blue--text">
                Todos os Convênios
              </span>
            </template>  

          </v-autocomplete>
          </v-col> 


          <v-col v-if="(qual_relatorio!='Lançamentos')"
            cols="12"
            md="3"
            class="ml-2"
          >
            <v-text-field
             v-model="rel_proced"
             dense
             label="Procedimento"
             clearable
             @input="rel_proced=rel_proced.toUpperCase()"
            >
            </v-text-field>

          </v-col>
          
          <v-spacer></v-spacer>
          
          <v-col
            cols="12"
            md="1"
            class="mt-0 mr-4"
          >   

          <!-- offset-md="2" -->
            <v-btn
             color="primary"
             small
             fab
             @click="rel_atendimentos(qual_relatorio, doc_codigo.CODIGO, rel_cod_conv, rel_data_de, rel_data_ate, rel_proced),dialog_relatorios=true"
            >
          <v-icon>
            mdi-printer 
          </v-icon>
            </v-btn>
          </v-col> 


        </v-row>


        <v-row class="pt-1 mt-n4 grey lighten-4" dense v-if="(qual_relatorio!='Lançamentos')">
          <!-- auto offset-md-7 -->
          <v-col
              cols="2"
              class="pa-0 "
              >
              <!-- md="auto offset-md-8" -->
                  <v-checkbox
                      v-model="checkbox_concluidos"
                      label="Concluidos"
                      dense
                      class=""
                      style="margin-left: 8px"   
                  ></v-checkbox>
                  
              </v-col>

              <v-col
              cols="2"
              class="pa-0"
              >
              <!-- md="auto offset-md-8" -->
                  <v-checkbox
                      v-model="checkbox_anotacao"
                      :label="(sou_acencon) ? 'Processo' : 'Anotação'"
                      dense
                      class=""
                      style="margin-left: 8px"   
                  ></v-checkbox>
                  
              </v-col>

              <v-col
              cols="2"
              class="pa-0"
              >
              <!-- md="auto offset-md-8" -->
                  <v-checkbox
                      v-model="checkbox_celular"
                      label="Celular"
                      dense
                      class=""
                      style="margin-left: 8px"   
                  ></v-checkbox>
                  
              </v-col>

        </v-row>


      </v-container>
    </v-card-text>
  </v-card>
</v-dialog>

 <!-- DIALOG OPÇÕES -->
 <v-dialog
      v-model="dialog_opcoes"
      max-width="300px"
      scrollable
    >
  <v-card
    class="mx-auto"
  >
    <v-list>

      <v-subheader class="grey lighten-5">
        <h4>Opções</h4>
      </v-subheader>

      <v-divider
        class="mx-0"
      ></v-divider>

      <v-list-item
      link
      v-if="(user_permissoes.superUser)"
      @click="dialog_agenda_log=true, dialog_opcoes=false,  qual_agenda_log='trans', width_log=500, trans_to=''"
      >
        <v-list-item-icon>
          <v-icon> mdi-calendar-clock </v-icon>
        </v-list-item-icon>
        <v-list-item-title>Transferir Agenda</v-list-item-title>
      </v-list-item>

      <v-divider
        class="mx-0"
      ></v-divider>

      <v-list-item
      link
      v-if="(user_permissoes.superUser)"
      @click="dialog_opcoes=false, alerta(`<div style='text-align: left;'> Clique em: <br/>  <i class='mdi mdi-cogs'></i> Configurações <br/> <i class='mdi mdi-calendar-clock'></i> Bloquear Agenda </div>`,'black', 5000)"
      >
        <v-list-item-icon>
          <v-icon color="red"> mdi-calendar-clock </v-icon>
        </v-list-item-icon>
        <v-list-item-title>Bloquear Agenda</v-list-item-title>
      </v-list-item>

    </v-list>
  </v-card>
</v-dialog>





    <v-dialog
      v-model="dialog_editar"
      max-width="300px"
      scrollable
    >
  <v-card
    class="mx-auto"
  >
    <v-list dense>

      <v-subheader class="grey lighten-5">
        

        <v-icon
        small
        :color="getColorSituacao(this.item_principal.ATENDIDO)"
        >mdi-checkbox-blank-circle
        </v-icon>
        &nbsp

        <!-- <h3> {{ this.item_principal.NOME_AGENDA }} </h3> -->

        {{ this.item_principal.NOME_AGENDA }}

      </v-subheader>

      <v-divider
        class="mx-0"
      ></v-divider>

      <v-list-item
      link
      @click="agendar_editar(item_principal,false),dialog_editar=false "
      >
        <v-list-item-icon>
          <v-icon color="blue">mdi-pencil</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Alterar</v-list-item-title>
      </v-list-item>


      <v-divider
        class="mx-4"
      ></v-divider>

<!-- :value="true" -->
        <v-list-group
          v-model="menu_aberto_definir"
          no-action
          sub-group
        >
        <!-- :value="menu_aberto_definir" -->

          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title>Definir</v-list-item-title>
            </v-list-item-content>
          </template>


              <v-list-item
              v-for="(item, i) in definir_A"
              :key = "i"
              link
              dense
              @click="(item.sigla=='F')||(item.sigla=='C') ? questiona_motivo(item) :  definir(item_principal,item, null)"
              >
                <v-list-item-title> {{item.text}} </v-list-item-title>

                <v-list-item-icon>
                  <v-icon
                  :color="getColorSituacao(item.sigla)"
                  >mdi-checkbox-blank-circle</v-icon>
                </v-list-item-icon>

              </v-list-item>
              
        </v-list-group>


        <v-divider
        class="mx-0"
      ></v-divider>

      <v-list-group
          no-action
          @click.stop="" 
          v-model="menu_aberto_remarcar"
      >

          <template v-slot:activator>

            <v-list-item-icon>
              <v-icon color="pink lighten-0"> mdi-clock-edit </v-icon>
            </v-list-item-icon>

            <v-list-item-content>
                <v-list-item-title> Remarcar </v-list-item-title>
            </v-list-item-content>
          </template>


          <v-list-item link dense @click="copiar_agendamento(item_principal, true), dialog_editar=false">
           <v-list-item-icon>
              <v-icon color="black" small> mdi-checkbox-blank-circle </v-icon>
            </v-list-item-icon>

            <v-list-item-content>
                <v-list-item-title> Cancelar e Remarcar </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item link dense @click="copiar_agendamento(item_principal, false), dialog_editar=false">
           <v-list-item-icon>
              <v-icon color="pink" small> mdi-checkbox-blank-circle </v-icon>
            </v-list-item-icon>

            <v-list-item-content>
                <v-list-item-title> Somente Remarcar</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
      </v-list-group>
      

      <v-divider class="mx-0"></v-divider>

      <v-list-group
          no-action
          @click.stop="" 
          v-if="(!menu_aberto_definir)"
          
      >
      <!-- v-model="menu_aberto_remarcar" -->

          <template v-slot:activator>

            <v-list-item-icon>
              <v-icon color="blue"> mdi-credit-card-multiple </v-icon>
            </v-list-item-icon>

            <v-list-item-content>
                <v-list-item-title> Lançamentos </v-list-item-title>
            </v-list-item-content>
          </template>


            <v-list-item
              link
              @click="abrir_janela_modal(item_principal,'novo','normal'), dialog_editar=false"
              :disabled="(item_principal.CODIGOCLIENTE<1)"
              v-if="2>1"
              >
              <!--  v-if="(esta_empresa('artmed')) || (0>1)">-->

              <v-list-item-icon>
                <v-icon color="red"> mdi-credit-card-search   </v-icon>
              </v-list-item-icon>
              <v-list-item-title>Visualizar A</v-list-item-title>
            </v-list-item>


            <v-list-item
              link
              @click="abrir_janela_modal(item_principal,'novo','artmed'), dialog_editar=false"
              :disabled="(item_principal.CODIGOCLIENTE<1)"
              >
              <!--  v-if="(esta_empresa('artmed')) || (0>1)">-->

              <v-list-item-icon>
                <v-icon color="orange"> mdi-credit-card-search   </v-icon>
              </v-list-item-icon>
              <v-list-item-title>Visualizar</v-list-item-title>
            </v-list-item>

            <v-divider class="mx-5 ml-15"></v-divider>

            <v-list-item
            link
            @click="gerar_gerar(), dialog_editar=false"
            :disabled="(item_principal.EFETUADO=='S')||(item_principal.CODIGOCLIENTE<1)"
            >
              <v-list-item-icon>
                <v-icon color="green"
                :disabled="(item_principal.EFETUADO=='S')"
                > mdi-credit-card-plus  </v-icon>
              </v-list-item-icon>
              <v-list-item-title> Gerar Lançamento </v-list-item-title>
            </v-list-item>
          

      </v-list-group>



      <v-divider
        class="mx-0"
      ></v-divider>


      <v-list-group
          :value="menu_aberto"
          no-action
          @click.stop="" 
          v-if="(!menu_aberto_definir)"
      >

          <template v-slot:activator>

            <v-list-item-icon>
              <v-icon color=""> mdi-printer </v-icon>
            </v-list-item-icon>

            <v-list-item-content>
                <v-list-item-title> Recibo </v-list-item-title>
            </v-list-item-content>
          </template>


          <v-list-item
            link
            dense
            @click="imprimir_recibo(item_principal, 'CNPJ'), dialog_editar=false"
          >
           <v-list-item-icon>
              <v-icon color="blue" small> mdi-checkbox-blank-circle </v-icon>
            </v-list-item-icon>

            <v-list-item-content>
                <v-list-item-title> CNPJ </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            link
            dense
            @click="imprimir_recibo(item_principal, 'CPF'), dialog_editar=false"
          >
           <v-list-item-icon>
              <v-icon color="green" small> mdi-checkbox-blank-circle </v-icon>
            </v-list-item-icon>

            <v-list-item-content>
                <v-list-item-title> CPF (do profissional) </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          
          <v-list-item
            link
            dense
            @click="preparar_comprovante('menu'),   dialog_editar=false"
          >
           <v-list-item-icon>
              <v-icon color="grey" small> mdi-checkbox-blank-circle </v-icon>
            </v-list-item-icon>

            <v-list-item-content>
                <v-list-item-title> De Agendamento </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
      </v-list-group>

      <v-divider class="mx-0"></v-divider>




      <v-list-group
          :value="menu_aberto"
          no-action
          @click.stop="" 
          v-if="(!menu_aberto_definir)"
      >

          <template v-slot:activator>

            <v-list-item-icon>
              <v-icon color=""> mdi-dots-horizontal </v-icon>
            </v-list-item-icon>

            <v-list-item-content>
                <v-list-item-title> Outros </v-list-item-title>
            </v-list-item-content>
          </template>


          <v-list-item
          link
          @click="dialog_agenda_log=true ,dialog_editar=false, qual_agenda_log='log', width_log=450 "
          v-if="(!menu_aberto_definir)"
          >
            <v-list-item-icon>
              <v-icon>mdi-file-find-outline  </v-icon>
            </v-list-item-icon>
            <v-list-item-title>Log</v-list-item-title>
          </v-list-item>

          <v-divider class="mx-5 ml-15"></v-divider>

          <v-list-item
          link
          @click="mostrar_impressao(),dialog_editar=false "
          v-if="(!menu_aberto_definir) && (item_clicou.CODIGOCLIENTE>0)"
          >
            <v-list-item-icon>
              <v-icon> mdi-text-box  </v-icon>
            </v-list-item-icon>
            <v-list-item-title>Termos</v-list-item-title>
          </v-list-item>

      </v-list-group>

      <v-divider class="mx-0"></v-divider>




    </v-list>
  </v-card>
    </v-dialog>



    <v-dialog
      v-model="dialog_paciente"
      persistent
      max-width="900px"
      scrollable
    >

      <v-card max-height="700px">
        <v-card-title>
          <span class="text-h5">Procurar Cadastro</span>
        </v-card-title>
        <v-card-text class="pa-1">
          <v-container>
            <v-row>

              <v-col cols="3" class="mt-3" v-if="(item_copiar_agendamento)">
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">

                      <v-chip
                        class="ma-2"
                        color="pink"
                        text-color="white"
                        @click="colar_agendamento()"
                        
                        v-bind="attrs"
                        v-on="on"
                      >
                          <span v-if="(editedItem.HORA!='vini')">
                            {{ getFormatTime(editedItem.HORA) }}
                          </span> &nbsp
                          {{ primeiro_nome(item_copiar_agendamento.NOME_AGENDA) }} 

                        <v-icon right>
                          mdi-clock-edit
                        </v-icon>
                      </v-chip>
                      
                    </template>
                    <span> Remarcar aqui </span>
                  </v-tooltip>
              </v-col>

              <v-col cols="9">
                <v-text-field
                  v-model="nome_digitou"
                  label="Nome, IDE, Celular, CPF, Data Nasc...."
                  required
                  autofocus
                  @keypress.enter="localizar_paciente($event.target.value)"
                  append-icon="mdi-magnify "
                  @click:append="localizar_paciente(nome_digitou)"   
                  @keyup.esc="nome_digitou=''"
                  clearable               
                ></v-text-field>
                <!-- dialog_paciente = false, -->
              </v-col>
            </v-row>

          <v-row>
            <v-col>


  <div class="text-center">
     <v-progress-linear
      value="0"
      indeterminate
      color="blue"
      v-if="progress_user"
     />
    <!-- <v-progress-circular
      :size="50"
      color="primary"
      indeterminate
    ></v-progress-circular> -->
  </div>

              <v-card v-if="items_pacientes.length > 0" class="">
              
                <v-data-table
                  :headers="headers_pacientes"
                  :items="items_pacientes"
                  class="elevation-1"
                  :items-per-page="-1"
                  disable-sort
                  :mobile-breakpoint="55"
                  dense
                  hide-default-footer
                  fixed-header
                  height="400"
                >

                  <template v-slot:item.NOME="{ item }">
                     <!-- <h4 @click="selecionar_paciente(item)">
                      {{ item.NOME }}
                    </h4> -->

                     <v-chip color="primary" small @click="selecionar_paciente(item)" class="hand">
                      <!-- {{ copy_string(item.NOME, 0 ,30)}} -->
                      {{ item.NOME}} 
                     </v-chip>

                  </template>

                  <template v-slot:item.CODIGOCLIENTE="{ item }">
                     <v-chip color="" small>
                      {{ item.CODIGOCLIENTE}} 
                     </v-chip>

                  </template>

                  <template v-slot:item.DATANASCIMENTO="{ item }">
                      <v-chip color="" small class="bold">
                      {{item.x_DATANASCIMENTO}}
                     </v-chip>
                  </template> 
                  
                  <template v-slot:item.CONVENIO="{ item }">
                      <!-- <v-chip color="" small dark>
                      {{item.CONVENIO}}
                     </v-chip> -->
                     <h4> {{item.CONVENIO}} </h4>
                  </template> 
                  
                  <template v-slot:item.CPF="{ item }">
                      <v-chip color="" small >
                      {{item.CPF}}
                     </v-chip>
                  </template> 

                </v-data-table>
              </v-card>
            </v-col>
          </v-row>

          </v-container>
        </v-card-text>
        <v-card-actions>


        <v-row dense>
          <v-col v-if="(user_permissoes.cadastrar)">
            <v-btn
            color="primary"
            text
            @click="abrir_cadastro(false, nome_digitou,'novo')"
             >
              Cadastrar
              <v-icon> mdi-account </v-icon>
            </v-btn>
          </v-col>
          
          <v-col>
            <v-btn
            color="orange"
            text
            @click="cadastro_provisorio(nome_digitou)"
          >
            Pré-cadastro

              <v-icon
              >
                mdi-account
              </v-icon>
          </v-btn>
          </v-col>

          <v-col>
            <v-btn
            color="red"
            text
            @click="bloqueio(nome_digitou)"
          >
            Bloquear

              <v-icon
              >
                mdi-clock-remove 
              </v-icon>
          </v-btn>
          </v-col>
          
          <v-col
           cols="auto"
          >
            <v-btn
            color="error"
            
            @click="dialog_paciente = false"
           >
            Cancelar
            </v-btn>
          </v-col>
        </v-row>
        

          <!-- <v-spacer></v-spacer> -->


        </v-card-actions>
      </v-card>
    </v-dialog>
  



  <div class="text-center">
    <v-dialog
      v-model="dialog_agenda_log"
      :width="width_log"
    >

    <!-- MOSTRA QUAL OPCAO -->
    <!-- {{ qual_agenda_log }} -->


      <!-- TRANSFERIR AGENDA -->
      <v-card
       v-if="qual_agenda_log=='trans'"
      >

        <!-- <v-card-subtitle>
          
        </v-card-subtitle> -->

        <v-card-title class="grey lighten-2 pb-0 pt-2">
          <h3 class="subtitle-1">
            Transferir Agenda de
          </h3>
        </v-card-title>

        <v-card-title class="grey lighten-2 pt-0 pb-2">
          <h4> {{ this.doc_codigo.NOME }} </h4>
        </v-card-title>


        <v-card-text
         class="pt-2"
        >

        <v-row>
          <v-col
          cols="3"
          class=""
          >      

          <h5 class="caption">Do dia </h5>
          <h3 class="black--text"> {{ getFormatDate(in_today) }} </h3>
          </v-col> 
          
          <v-col
          cols="4"
          class="ml-2"
          >      
            <v-text-field
              v-model="trans_to"
              label="Para o dia"
              hide-details
              type="date"
              
            ></v-text-field>

          </v-col> 
          
          <!-- <v-spacer></v-spacer> -->
          
          <v-col
          cols="3 mt-4"
          class="ml-10"
          >      
            <v-btn
             small
             color="primary"
             :disabled="(trans_to=='')"
             @click="transferir_agenda(doc_codigo.CODIGO,in_today, trans_to)"
            >
              Transferir
            </v-btn>
          </v-col> 


        </v-row>


        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="dialog_agenda_log = false"
          >
            Fechar
          </v-btn>
        </v-card-actions>
      </v-card>


      <!-- LOG -->
      <v-card
       v-if="qual_agenda_log=='log'"
      >
        <v-card-title class="subtitle-1 grey lighten-2">
          {{ this.item_principal.NOME_AGENDA }}
        </v-card-title>

        <v-card-text v-html='item_principal.LOG_RECEPCAO'>
        <!-- <v-card-text> -->
          <!-- {{ this.item_principal.LOG_RECEPCAO }} -->
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="dialog_agenda_log = false"
          >
            Fechar
          </v-btn>
        </v-card-actions>
      </v-card>


    </v-dialog>
  </div>


  <v-dialog
    v-model="dialog_agendar"
    persistent
    max-width="1024px"
    scrollable 
  >        
    
    <v-card>
      <v-card-title>
        <span class="text-h5">{{ formTitle }}</span>
        <span class="text-overline"> &nbsp {{ editedItem.CODIGO }}</span>
        
        <!-- <v-subheader>Subheader</v-subheader> -->
      </v-card-title>

      <v-card-text>
        <v-form
        ref="form"
        v-model="valido_agendar"
        >
<!-- :readonly = "readonly" -->

        <v-container>
          <v-row>
            
            <v-col
              cols="12"
              :md="(editedItem.CODIGOCLIENTE==0) ? 11 : 5"
            >
            
              <v-text-field
                v-model="editedItem.NOME"
                :label="(editedItem.CODIGOCLIENTE==0) ?  'Bloqueio ou Evento*' : 'Nome*'"
                :rules="minimoRules"
                autofocus
                @input="allUpper($event, 'editedItem','NOME')"
                @keypress.enter="dialog_paciente=true"
                @keypress.esc="dialog_agendar=false"
                :readonly="(editedItem.CODIGOCLIENTE>0)"
                :append-icon="(editedItem.CODIGOCLIENTE==-5) ? 'mdi-account-plus' : 'mdi-account'"
                @click:append="(editedItem.CODIGOCLIENTE==-5) ? abrir_cadastro(false, editedItem.NOME,'novo',editedItem.CODIGOCONSULTA) : dialog_paciente = true"
                :color="(editedItem.CODIGOCLIENTE==-5) ? 'orange' : (editedItem.CODIGOCLIENTE == 0)? 'red' : 'primary'"
              >
              <!-- append-icon="mdi-account" -->
              <!-- :color="(editedItem.CODIGOCLIENTE==-5) ? 'orange' : 'primary'" -->
              <!-- :color="(pre_cadastro) ? 'orange' : 'primary'" -->
              <!-- :label="(!bloqueando) ? 'Nome*' :  'Bloqueio ou Evento*'" -->
              </v-text-field>

              <v-btn
              small
              dense
              color="orange"
              @click="salvar_pre_cadastro(editedItem.NOME)"
              v-if="0>1"
              >
              Salvar Pré-cadastro

              </v-btn>

            </v-col>



            <v-col
              cols="12"
              md="4"
            >

            <v-select
            v-model="editedItem.CODIGOMEDICO"
            label="Profissional"
            :items="profissionais"
            :rules="notnullRules"
            item-value='CODIGO'
            item-text='NOME'
            return-object
            readonly
            >
            
              <template slot='selection' slot-scope='{ item }'>
                {{ item.NOME }}
              </template>

              <template slot='item' slot-scope='{ item }'>
                <!-- <v-chip>
                {{ item.NOME }}   
                </v-chip> -->
                <h5>{{ item.NOME }}</h5>
              
              &nbsp | &nbsp

                <div 
                  class="caption"
                >
                  {{ item.CATEGORIA }}
                </div>                      

              </template>

            </v-select>


            </v-col>

            <v-col
              cols="12"
              md="3"
              v-if="(editedItem.CODIGOCLIENTE!=0)"
            >
            <!-- v-model="editedItem.codigoconvenio" -->
            <v-select
            v-model="items_selecionados.convenio"
            label="Convênio*"
            :items="convenios"
            :rules="notnullRules"
            item-value='CODIGO'
            item-text='NOME'
            @change = 'muda_convenio($event)'
            :return-object = 'true'
            >
            
              <template slot='selection' slot-scope='{ item }'>
                {{ item.NOME }}
              </template>

              <template slot='item' slot-scope='{ item }'>
                <h5>{{ item.NOME }}</h5> 
                      <!-- {{ item.CODIGO }}  -->
              </template>

            </v-select>
            </v-col>

        

            
            <v-col
              cols="7"
              md="2"
              v-if="( (!sou_acencon) || (editedItem.CODIGO>0) )"
            >
              <v-text-field
                v-model="editedItem.DATA"
                label="Data*"
                :rules="notnullRules"
                type="date"
              ></v-text-field>
            </v-col>



            <v-col
              cols="4"
              md="2"
              
            >
            <!-- v-if="( (!esta_empresa('acencon')) || (editedItem.CODIGO>0) )" -->
              <v-text-field
                v-model="editedItem.HORA"
                label="Hora*"
                :rules="minimoRules"
                type="time"
              ></v-text-field>
            </v-col>




            <v-col
              cols="3"
              md="1"
              v-if="( (!sou_acencon) || (editedItem.CODIGO>0) )"
            >
            <v-text-field
                v-model="editedItem.DURACAO"
                label="Duração"
                hint="Minutos"
                persistent-hint
                @input="change_duracao"
              >
              <!-- :items="[editedItem.DURACAO,'20','30']"  -->
                <!-- <template v-slot:append>        
                  <v-icon color="blue" class=""> mdi-dots-vertical  </v-icon> 
                </template> -->

                <template v-slot:label> 

                  <div class="text-center">
                    <v-menu offset-y>
                      <template v-slot:activator="{ on, attrs }">

                        <a
                          v-bind="attrs"
                          v-on="on"
                        >
                          Duração
                        </a>
                      </template>
                      <v-list>
                        <v-list-item
                          v-for="(item, index) in items_duracao"
                          :key="index"
                          @click="(editedItem.DURACAO=item.duracao), change_duracao()"
                        >
                          <v-list-item-title>{{ item.title }}</v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </div>
                </template>
            </v-text-field>
            </v-col>

          

            <v-col cols="4" md="2" v-if="(editedItem.CODIGOCLIENTE==0)">
              <v-text-field
                v-model="editedItem.HORA_ATE"
                label="Até as"
                type="time"
                @input="change_hora_ate"
              ></v-text-field>
            </v-col>

          

            <v-col
              cols="12"
              md="5"   
              v-if="(editedItem.CODIGOCLIENTE!=0)"                 
            >

            <!-- {{ items_selecionados.cod_proced }} -->
            <v-autocomplete
            v-model="items_selecionados.cod_proced"
            label="Procedimento*"
            :items="procedimentos"
            :rules="notnullRules"
            item-value='CODIGO'
            item-text='CATEGORIA'
            clearable
            @change = 'proced_escolhido($event)'
            return-object
            >                  
              <template slot='selection' slot-scope='{ item }'>
                {{ item.CATEGORIA }}
              </template>

              <template slot='item' slot-scope='{ item }'>
                <h5>{{ item.CATEGORIA }}</h5>  
                    <!-- {{ item.CODIGO }}      -->
              </template>

            </v-autocomplete>
            </v-col>

          <!-- </v-row> -->
         

            <v-col
              cols="6"
              md="2"
              v-if="(editedItem.CODIGOCLIENTE!=0)"
            >
              <v-text-field
              v-model="editedItem.VALOR"
              label="Valor"
              type=""
              prefix="R$"
              @keyup="editedItem.VALOR = moeda($event)"
              ></v-text-field>
              <!-- tem que definir a propriedade editedItem.VALOR no DATA  -->
            </v-col>



            <v-col
              cols="6"
              md="3"
              v-if="(editedItem.CODIGOCLIENTE!=0)"
            >
              <v-select
              v-model="editedItem.FORMA_PAGAMENTO"
              label="Forma"
              :items="['DINHEIRO', 'CREDITO','DEBITO','PIX','BOLETO','CONVENIO','GRATIS','CHEQUE','CORTESIA','OUTROS','PERMUTA','DEPOSITO']"
              ></v-select>
            </v-col>

            <v-col
              cols="6"
              md="2"
              class="white lighten-4"
              v-if="(editedItem.CODIGO>0)"
            >

              <v-select
              v-model="editedItem.EFETUADO"
              label="Lançamento Gerado"
              :items="['S','N']"
              :disabled="(item_principal.EFETUADO=='N')"
              :append-icon=" (editedItem.EFETUADO=='S') ?  'mdi-credit-card-check' : 'mdi-card-remove'"
              ></v-select>

              <v-btn
                color="primary"
                @click="gerar_gerar()"
                v-if="0>1"
              >
              gerar
                  <v-icon class="mb-1">
                    mdi-currency-usd  
                  </v-icon>
              </v-btn>

              <v-switch
                v-model="editedItem.EFETUADO"
                label=""
                dense
                true-value="S"
                false-value="N"
                v-if="0>1"
              >
              </v-switch>
            </v-col>


            
            <v-col
              cols="12"
              md="2"
              v-if="(editedItem.CODIGOCLIENTE==-5)"
            >
              <v-text-field
                v-model="editedItem.FONE"
                label="Celular"
                placeholder="(  )    -    "
                v-mask = "'(##) #####-####'"
              ></v-text-field>
            </v-col>


            <v-col cols="5" v-if="(editedItem.CODIGOCLIENTE>0)">
              <v-select
                v-model="editedItem.SUB_DOC"
                
                label="Compartilhar Agenda com.."
                :items="profissionais_sub"
                item-value='CODIGO'
                item-text='NOME'
                :return-object="false"
                @change="changeSUB_DOC"
              >

                  <!-- <template slot='selection' slot-scope='{ item }'>
                      <span class="fs-12">
                         {{ (item.NOME)? item.NOME : item }}??
                      </span>
                  </template> -->

                  <template v-slot:label>
                    <span class="red--text">Compartilhar com..</span>
                  </template>
              </v-select>
            </v-col>


            <v-col
              cols="12"
              :md="(editedItem.CODIGOCLIENTE==-5)? '5' : '10'"
            >
              <v-text-field
                v-model="editedItem.OBS"
                :label="(sou_acencon) ? 'Processo' : 'Anotação'"
                type="text"
                @keydown="firstUpper($event, 'OBS')"
              ></v-text-field>
            </v-col>


            <v-col
              cols="6"
              md="2"
              v-if="sou_acencon"
            >
              <v-select
              v-model="uf_rg_agendar"
              label="UF DO RG*"
              :items = "items_uf"
              :rules="notnullRules"
              ></v-select>
            </v-col>


          </v-row>
        </v-container>



        </v-form>
      </v-card-text>

      <small>&nbsp * Campos necessários</small>
      
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="red"
          text
          @click="dialog_agendar=false"
        >
          Cancelar
        </v-btn>
        
        <v-btn
          :disabled="!valido_agendar"
          color="primary"
          @click="qual_agendar(), item_copiar_agendamento=null"
          v-if = "0==0"
        >
          Salvar;
        </v-btn>
      </v-card-actions>

    </v-card>
  </v-dialog>



<section class="grid-1" v-if="(!dialog_protocolo)">

  <!-- div principal onde mostra os componentes -->
  <div class="item-2 " v-if="(!vai_imprimir)" >
    <!-- <div class="item-5 orange">5</div> -->
    <div class="item-6" >

        <v-date-picker
        v-model="in_today"
        width="270"
        :full-width = "$vuetify.breakpoint.smAndDown"
        elevation="4"
        locale="pt-br"
        :first-day-of-week="0"
        :landscape = false
        class="ml-2 mb-4"
        v-if="isMobile!==true"
        >
        </v-date-picker>


        <v-row
        class="px-3"
        :style= "[isMobile ? {'height':'45px', 'margin-top': '0px'} : {'height':'30px'}]"
        >

            <v-col cols="2"  class="py-1"
            v-if="isMobile!==true"
            >
                <a
                @click="in_today = today"
                >
                Hoje
                </a>
            </v-col>

            <v-col cols="2"  class="py-1"
              v-if="(isMobile!==true)&&(user_permissoes.agendar)"
            >
            <v-icon
              color="primary"
              size="24"
              @click="dialog_opcoes=true"
              v-if="doc_codigo.CODIGO>0"
            >
              mdi-menu
              <!-- mdi-dots-horizontal -->
            </v-icon>
            </v-col>

              <v-col cols="6" md="6"
              style="margin-top: -15px"
              class=" py-0"
              >

                <v-switch
                  dense
                  v-model="switch_agendar"
                  label="Mais.."
                  v-if="isMobile"
                >

                  <template v-slot:label>
                    <span class="blue--text"
                    >
                      Opções
                    </span>
                    
                    <span
                      class="count_item"
                    >
                    6
                    <!-- v-if="abriu_agenda == true"
                    {{ hora_agendar.length }} -->
                    </span>
                  </template>  

                </v-switch>
              </v-col>
        </v-row>  

        <v-row>
          <v-col
          class=""
          cols="12"
          v-if="TESTE == true"
          >
            <v-card
            >

              <v-data-table
                dense
                single-select
                :show-select = false
                item-key="CODIGOCONSULTA"
                :headers="headers_horarios"
                :items="horario_do_dia"
                class="elevation-1"
                :items-per-page="50"
              >
              </v-data-table>


            </v-card>
          </v-col>

        </v-row>


        <v-row v-if="(switch_agendar == true) ">
          <!-- && (this.doc_codigo.CODIGO > 0) -->

          <!-- PAINEL ESQUERDA -->
          <v-col
            class="ml-1 px-0 pl-2 py-0"
            cols="12"
            md="11"
          >
            <v-card 
            flat
            elevation="5"
            class="mt-1 grey lighten-3 mb-4"
            >
              <v-expansion-panels 
                :multiple="false"
                :focusable='false'
                v-model="value_expansion"
                @change="change_expansion"
                >


                <!-- HORARIOS -->
                <v-expansion-panel v-if="(user_permissoes.agendar)&&(this.doc_codigo.CODIGO > 0)">
                  <v-expansion-panel-header 
                  class="pa-2 px-4"
                  style="min-height: 28px"
                  @click="expansion_selecionao='HORARIOS'"
                  
                  >
                    <div>
                      <v-row>
                        <v-col
                          cols="12"
                          md="12"
                        >
                            <v-icon
                             size="22"
                            >
                              mdi-clock-time-four-outline 
                            </v-icon> 
                            &nbsp
                            Horários

                              <span class="count_item"
                                v-if="abriu_agenda == true"
                              >
                              {{ hora_agendar.length }}
                              </span>
                        </v-col>
                      </v-row>
                    </div>
                  </v-expansion-panel-header>

                  <v-expansion-panel-content
                  class=""
                  >
                  <v-divider></v-divider>
                      <v-container
                        class="px-0"
                      >
                        <v-row>
                          <v-col
                            class="pa-0 pt-2"
                          >
                          
                          
                          
                          <v-chip-group 
                            active-class="deep-purple accent-4 white--text"
                            column
                            v-if="abriu_agenda == true"
                            class="pl-0"
                            
                            >

                            <v-chip
                            small
                            color="primary"
                            @click="agendar('vini')"
                            class="pa-1"
                            v-if="((sou_acencon)&&(user_permissoes.superUser)) || (!sou_acencon)"
                            >
                            encaixar
                            </v-chip>

              <!-- chip hora -->
                            <v-chip
                            v-for="(value,  index ) in hora_agendar"
                            small
                            @click="agendar(value)"
                            >
                            <!-- @click="menu_horario" -->
                            <!-- @click="agendar(value)" -->
                            <!-- {{ value }} -->
                            {{ getFormatTime(value) }}

                            </v-chip>
                          </v-chip-group>

                          <v-chip v-if="(aviso_horarios)" color="orange" small>
                            {{aviso_horarios}}
                          </v-chip>


                          </v-col>
                        </v-row>
                      </v-container>

                  </v-expansion-panel-content>
                </v-expansion-panel>


                <!-- HORARIOS CANCELADOS -->
                <v-expansion-panel v-if="(user_permissoes.agendar)&&(this.doc_codigo.CODIGO > 0)">
                  <v-expansion-panel-header 
                  class="pa-2 px-4"
                  style="min-height: 28px"
                  @click="expansion_selecionao='HORARIOS_CANCELADOS'"
                  >

                    <div>
                      <v-row>
                        <v-col cols="12">
                            <v-icon size="22" class="pr-1" color="red"> 
                              mdi-clock-time-four-outline 
                            </v-icon> 
                            Cancelados

                            <span class="count_item"
                                v-if="abriu_agenda == true"
                              >
                              {{ horarios_cancelados_lista.length }}
                              </span>

                        </v-col>
                      </v-row>
                    </div>
                  </v-expansion-panel-header>

                  <v-expansion-panel-content>
                    <!-- class="py-0 ma-0" -->

                      <v-container>
                        <v-row>
                          <v-col class="px-0 py-0 red ">

                          <v-list
                            dense
                            style="max-height: 222px"
                            class="overflow-y-auto px-0 py-0"
                            >
                              <v-list-item-group active-class="border">

                                <v-list-item
                                  v-for="(item, i) in horarios_cancelados_lista"
                                  :key="i"
                                  class="px-0 py-0 grey lighten-5"
                                  style="min-height: 20px !important;"
                                  
                                >

                                  <v-list-item-content
                                  class="py-0"
                                  >
                                      <div style="white-space: nowrap;overflow: hidden;text-overflow: ellipsis;">
                                        <v-row>

                                          <v-col cols="4" class="">

                                            <v-tooltip bottom>
                                                <template v-slot:activator="{ on, attrs }">
                                                  <v-chip
                                                      v-bind="attrs"
                                                      v-on="on"
                                                      x-small
                                                  > 

                                                  <span class="ml-n1 red--text bold hand">
                                                    {{ item.HORA }}
                                                  </span>
                                                  
                                                  </v-chip>
                                                </template>
                                                  <span v-html='quebra_linha(item.LOG_RECEPCAO)'>  </span>
                                                  <div class="body-2 mt-2">Motivo: {{item.REGISTRO}} </div>
                                                  <div class="body-2 mt-2">Contato: {{item.FONE}} </div>

                                              </v-tooltip>
                                              
                                          </v-col>

                                          <v-col class="px-0">

                                            <v-icon             
                                              class=""
                                              color="blue"
                                              @click="abrir_cadastro(false, item.CODIGOCLIENTE,'')"
                                              :size="(isMobile) ? si_mobile: 18"
                                          >
                                              mdi-account
                                          </v-icon> 

                                            <span>
                                              <h5 style="display: inline; font-size: 10px;" class="ml-1"> {{ item.NOME }} </h5>  
                                            </span>



                                          </v-col>
                                        </v-row>
                                      </div>

                                      <v-divider class="mx-0"/>
                                  </v-list-item-content>
                                </v-list-item>




                              </v-list-item-group>
                            </v-list>

                          </v-col>
                        </v-row>
                      </v-container>

                  </v-expansion-panel-content>
                </v-expansion-panel>




                <!-- ESTATISTICAS -->
                <v-expansion-panel v-if="(user_permissoes.ag_relatorio)&&(this.doc_codigo.CODIGO > 0)">
                  <v-expansion-panel-header 
                  class="pa-2 px-4"
                  style="min-height: 28px"
                  @click="expansion_selecionao='ESTATISTICAS'"
                  >

                    <div>
                      <!-- {{qual_estatisticas}} -->
                      <v-row
                      >
                        <v-col
                          cols="12"
                          md="12"
                        >
                            <v-icon
                             size="22"
                            >
                            mdi-chart-line-variant 
                            </v-icon> 
                            &nbsp
                            Estatísticas

                            <span class="count_item">
                              {{ count_estatiticas }}
                            </span>
                            
                        </v-col>
                      </v-row>
                    </div>
                  </v-expansion-panel-header>

                  <v-expansion-panel-content
                  class="py-0 ma-0"
                  >
                  <!-- <v-divider></v-divider> -->

                  <v-row
                    dense
                    class="mt-1 px-0"
                    >
                      <v-col
                      class="pl-0 pb-0 "
                      cols="6"
                      >
                        <v-select
                          dense
                          v-model="qual_estatisticas"
                          :items="dropdown_edit"
                          :label="`${r_estatisticas}`"
                          @change="abrir_estatisticas(doc_codigo.CODIGO, in_today, in_today)"
                        ></v-select>
                      </v-col>
                      
                      <v-col
                      class="pr-0 pb-0 "
                      cols="6"
                      >
                        <v-select
                          dense
                          v-model="geral_atendimentos"
                          :items="['Todos','Atendidos']"
                          label="TIPO"
                          @change="abrir_estatisticas(doc_codigo.CODIGO, in_today, in_today)"
                        ></v-select>
                      </v-col>
                  </v-row>


                  <!-- <v-divider></v-divider> -->
                  <!-- {{lista_estatiticas}} -->
                      <v-container
                        class="px-0"
                      >
                        <v-row>
                          <v-col
                            class="pa-0 py-0 pl-2"
                          >

                          

                          <v-list
                            dense
                            style="max-height: 222px"
                            class="overflow-y-auto px-0 py-0"
                            id="id_sala"
                            >
                              <v-list-item-group
                                active-class="border"
                              >


                                <v-list-item
                                  v-for="(item, i) in lista_estatiticas"
                                  :key="i"
                                  class="px-0 py-0 grey lighten-5"
                                  style="min-height: 20px !important;"
                                  
                                >

                                  <v-list-item-content
                                  class="py-0"
                                  >
                                      <div style="white-space: nowrap;overflow: hidden;text-overflow: ellipsis;">
                                        <!-- <p style="display: inline" class=" text-caption black--text">  {{ item.QT }} </p> -->
    
                                        <v-row
                                        :class="item.COR"
                                        >
                                          <v-col
                                          :cols="col_r"
                                          >

                                            <span v-if="item.PROCEDIMENTO == 'CONFIRMADOS VIA WHATS'">
                                              <h5 v-if="item.QT>0" 
                                              style="display: inline; font-size: 16px;" class="white--text ml-1"> {{ item.QT }} </h5>
                                            </span>
                                            <span v-else>
                                              <h5 style="display: inline; font-size: 16px;" class="blue--text ml-1"> {{ item.QT }} </h5>
                                            </span>

                                          </v-col>
                                          
                                          <v-col
                                          cols=""
                                          >
                                          
                                            <span v-if="item.PROCEDIMENTO == 'CONFIRMADOS VIA WHATS'">

                                              <v-icon
                                                v-if="item.QT>0"
                                                class="ml-1"
                                                size="18"
                                                color="white"
                                              >
                                              <!-- #800080 -->
                                              mdi-account-check 
                                              </v-icon>

                                              <h5  v-if="item.QT>0"
                                              style="display: inline; font-size: 12px;" class="ml-1 white--text"> {{ item.PROCEDIMENTO }} </h5>
                                            </span>

                                          <span v-else>
                                            <h5 style="display: inline; font-size: 12px;" class="ml-1"> {{ item.PROCEDIMENTO }} </h5>
                                          </span>


                                          </v-col>
                                        </v-row>

                                      </div>

                                      <v-divider
                                          class="mx-0"
                                      />
                                  </v-list-item-content>
                                </v-list-item>





                              </v-list-item-group>
                            </v-list>

                          </v-col>
                        </v-row>
                      </v-container>

                  </v-expansion-panel-content>
                </v-expansion-panel>


                <!-- LANCAMENTOS -->
                <v-expansion-panel v-if="(user_permissoes.agendar)&&(this.doc_codigo.CODIGO > 0) && (obj_user.SUPERUSER=='S')">
                  <v-expansion-panel-header 
                  class="pa-2 px-4"
                  style="min-height: 28px"
                  @click="expansion_selecionao='LANCAMENTOS'"
                  >
                  <!-- , abrir_lancamentos(doc_codigo.CODIGO, in_today, in_today) -->

                    <div>
                      <!-- {{qual_estatisticas}} -->
                      <v-row
                      >
                        <v-col
                          cols="12"
                          md="12"
                        >
                            <v-icon
                             size="22"
                             color="primary"
                            >
                            mdi-credit-card-multiple
                            </v-icon> 
                            &nbsp
                            Lançamentos

                            <span class="count_item">
                              {{  (lista_lancamentos.length>0) ? lista_lancamentos.length : '!'  }}
                            </span>
                            
                        </v-col>
                      </v-row>
                    </div>
                  </v-expansion-panel-header>

                  <v-expansion-panel-content class="py-0 ma-0">
                  
                  <v-row
                    dense
                    class="mt-1 px-0"
                    >
                      <v-col
                      class="px-0 pb-0"
                      cols="6"
                      >
                        <v-select
                          dense
                          v-model="qual_estatisticas"
                          :items="dropdown_edit2"
                          :label="`${r_estatisticas}`"
                          @change="abrir_lancamentos(doc_codigo.CODIGO, in_today, in_today)"
                        ></v-select>
                      </v-col>
                  </v-row>

                      <v-container
                        class="px-0"
                      >
                        <v-row class="ml-n4 pt-1">
                          <v-col class="pa-0 py-0">

                          <v-list
                            dense
                            style="max-height: 222px"
                            class="overflow-y-auto px-0 py-0"
                            >
                              <v-list-item-group active-class="border">


                                <v-list-item
                                  v-for="(item, i) in lista_lancamentos"
                                  :key="i"
                                  class="px-0 py-0 grey lighten-5"
                                  style="min-height: 20px !important;"
                                  
                                >

                                  <v-list-item-content class="py-0 pl-1">
                                      <div style="white-space: nowrap;overflow: hidden;text-overflow: ellipsis;">
                                        <!-- <p style="display: inline" class=" text-caption black--text">  {{ item.QT }} </p> -->
    
                                        <v-row>
                                          <v-col cols="3">

                                            <span>
                                              <v-tooltip bottom>
                                                <template v-slot:activator="{ on, attrs }">
                                                  <h5 class="green--text fs-11" style="display: inline"
                                                  v-bind="attrs" v-on="on"
                                                  > {{ item.VALOR_UM }} </h5>
                                                </template>
                                                <span> Lançou: {{ item.LANCOU }} </span>
                                                <div> Codigo: {{ item.CODIGO }} </div>
                                              </v-tooltip>
                                            </span>

                                          </v-col>
                                          
                                          <v-col cols="6" class="px-1" >
                                            <h5 class="fs-10" style="display: inline"> {{ item.FORMA_PAGAMENTO }} </h5>

                                            <v-tooltip bottom>
                                                <template v-slot:activator="{ on, attrs }">
                                                  <h5 class="fs-10 pl-2 blue--text" style="display: inline"
                                                  v-bind="attrs" v-on="on"
                                                  >
                                                     {{ item.DESCRICAO }} 
                                                  </h5>
                                                </template>
                                                <span>  {{ item.DESC_PROCEDIMENTO }} </span>
                                              </v-tooltip>

                                          </v-col>
                                          
                                        </v-row>

                                      </div>

                                      <v-divider
                                          class="mx-0"
                                      />
                                  </v-list-item-content>
                                </v-list-item>



                                <!-- SOMATORIA -->
                                <v-list-item
                                  v-for="(item, i) in items_somatorias"
                                  :key="i+99"
                                  class="px-0 py-0 grey lighten-5"
                                  style="min-height: 20px !important;"
                                  
                                >

                                  <v-list-item-content class="py-0 pl-1">
                                      <div style="white-space: nowrap;overflow: hidden;text-overflow: ellipsis;">
                                        <!-- <p style="display: inline" class=" text-caption black--text">  {{ item.QT }} </p> -->
    
                                        <v-row>
                                          <v-col cols="3">
                                            <span>
                                              <h5 class="green--text fs-12" style="display: inline"> 
                                                {{ item.CAMPO }} 
                                              </h5>
                                            </span>

                                          </v-col>
                                          
                                          <v-col cols="6" class="px-1" >
                                              <h5 class="fs-12 blue--text" style="display: inline">
                                                {{ getFormatCurrency(item.VALOR,'N') }}
                                              </h5>
                                          </v-col>
                                          
                                        </v-row>

                                      </div>

                                      <v-divider
                                          class="mx-0"
                                      />
                                  </v-list-item-content>
                                </v-list-item>

                              </v-list-item-group>
                            </v-list>

                          </v-col>
                        </v-row>
                      </v-container>

                  </v-expansion-panel-content>
                </v-expansion-panel>


                <!-- ESTATISTICAS TODOS WHATS-->
                <v-expansion-panel v-if="(user_permissoes.agendar)&&(this.doc_codigo.CODIGO == 0)">
                  <v-expansion-panel-header 
                  class="pa-2 px-4"
                  style="min-height: 28px"
                  @click="expansion_selecionao='ESTATISTICAS_WHATS'"
                  
                  >

                    <div>
                      <!-- {{qual_estatisticas}} -->
                      <v-row
                      >
                        <v-col
                          cols="12"
                          md="12"
                        >
                            <v-icon
                             size="22"
                            >
                            mdi-chart-line-variant 
                            </v-icon> 
                            &nbsp
                            Estatísticas Whats

                            <span class="count_item">
                              {{ count_estatiticas }}
                            </span>
                            
                        </v-col>
                      </v-row>
                    </div>
                  </v-expansion-panel-header>

                  <v-expansion-panel-content
                  class="py-0 ma-0"
                  >
                  <!-- <v-divider></v-divider> -->

                  <v-row
                    dense
                    class="mt-1 px-0"
                    >
                      <v-col
                      class="px-0 pb-0"
                      cols="6"
                      >
                        <v-select
                          dense
                          v-model="qual_estatisticas"
                          :items="dropdown_edit"
                          :label="`${r_estatisticas}`"
                          @change="abrir_estatisticas(doc_codigo.CODIGO, in_today, in_today)"
                        ></v-select>
                      </v-col>
                  </v-row>


                  <!-- <v-divider></v-divider> -->
                  <!-- {{lista_estatiticas}} -->
                      <v-container
                        class="px-0"
                      >
                        <v-row>
                          <v-col
                            class="pa-0 py-0 pl-2"
                          >

                          

                          <v-list
                            dense
                            style="max-height: 222px"
                            class="overflow-y-auto px-0 py-0"
                            id="id_sala"
                            >
                              <v-list-item-group
                                active-class="border"
                              >


                                <v-list-item
                                  v-for="(item, i) in lista_estatiticas"
                                  :key="i"
                                  class="px-0 py-0 grey lighten-5"
                                  style="min-height: 20px !important;"
                                  
                                >

                                  <v-list-item-content
                                  class="py-0"
                                  >
                                      <div style="white-space: nowrap;overflow: hidden;text-overflow: ellipsis;">
                                        <!-- <p style="display: inline" class=" text-caption black--text">  {{ item.QT }} </p> -->
    
                                        <v-row
                                        :class="item.COR"
                                        >
                                          <v-col
                                          :cols="col_r + parseInt(2)"
                                          >

                                            <span v-if="item.PROCEDIMENTO == 'CONFIRMADOS'">
                                              <h5 v-if="item.QT>0" 
                                              style="display: inline; font-size: 16px;" class="purple--text ml-1"> {{ item.QT }} </h5>
                                            </span>
                                            <span v-else>
                                              <h5 style="display: inline; font-size: 16px;" class="blue--text ml-1"> {{ item.QT }} </h5>
                                            </span>

                                            <!-- <h5 style="display: inline; font-size: 12px;" class="ml-1"> {{ item.PORC }} </h5>   -->

                                          </v-col>
                                          
                                          <v-col
                                          cols=""
                                          >
                                          
                                            <span v-if="item.PROCEDIMENTO == 'CONFIRMADOS'">

                                              <v-icon
                                                v-if="item.QT>0"
                                                class="ml-1"
                                                size="18"
                                                color="purple"
                                              >
                                              mdi-account-check 
                                              </v-icon>

                                              <h5  v-if="item.QT>0"
                                              style="display: inline; font-size: 12px;" class="ml-1"> {{ item.PROCEDIMENTO }} </h5>
                                            </span>

                                          <span v-else>
                                            <v-icon class="ml-1" size="18" color="blue">
                                              mdi-whatsapp
                                            </v-icon>
                                            <h5 style="display: inline; font-size: 12px;" class="ml-1"> {{ item.PROCEDIMENTO }} </h5>
                                          </span>

                                          <span>
                                            <h5 style="display: inline; font-size: 12px;" class="ml-1"> {{ item.PORC }} </h5>                                            
                                          </span>


                                          </v-col>
                                        </v-row>
                                      </div>

                                      <v-divider
                                          class="mx-0"
                                      />




                                  </v-list-item-content>
                                </v-list-item>





                              </v-list-item-group>
                            </v-list>

                          </v-col>
                        </v-row>


                        <v-row v-if="0>1">
                          <v-col class="px-0">
                            <v-card
                              class="mx-auto text-center"
                              color="grey lighten-4"
                              dark
                              max-width="600"
                            >
                                
                                <v-sheet color="white">
                                  <h6 class="grey">Gráfico do Mês</h6>
                                  

                                  <v-sparkline
                                    :value="grafico1"
                                    class=""
                                    color="blue"
                                    line-width="3"
                                    stroke-linecap="round"
                                    smooth
                                    height="70"
                                    
                                  >
                                    <template v-slot:label="item">
                                      {{ item.value }}
                                    </template>
                                  </v-sparkline>

                                  <v-sparkline
                                    :value="grafico2"
                                    class="stackSpark py-5"
                                    color="purple"
                                    line-width="3"
                                    stroke-linecap="round"
                                    smooth
                                    height="70"
                                  >
                                    <template v-slot:label="item">
                                        {{ item.value }}
                                    </template>
                                </v-sparkline>        
                                </v-sheet>

                            </v-card>
                          </v-col>
                        </v-row>
                      </v-container>

                  </v-expansion-panel-content>
                </v-expansion-panel>


                <!-- RELATORIOS -->
                <v-expansion-panel v-if="(user_permissoes.ag_relatorio)&&(this.doc_codigo.CODIGO > 0)">
                  <v-expansion-panel-header 
                  class="pa-2 px-4"
                  style="min-height: 28px"
                  @click="expansion_selecionao='RELATORIOS'"
                  
                  >
                    <div>
                      <v-row
                      >
                        <v-col
                          cols="12"
                          md="12"
                        >
                            <v-icon
                             size="20"
                            >
                            mdi-text-box-multiple-outline  
                            </v-icon> 
                            &nbsp
                            Relatórios
                            <span class="count_item fs-12">{{ items_relatorio.length }}</span>

                        </v-col>
                      </v-row>
                    </div>
                  </v-expansion-panel-header>

                  <v-expansion-panel-content
                  class="py-0 ma-0"
                  >

                  <v-row
                    dense
                    class="mt-1 px-0"
                    >
                      <v-col
                      class="px-0 pb-0"
                      cols="9"
                      >
                        <v-select
                          dense
                          v-model="qual_relatorio"
                          :items="items_relatorio"
                          label="Tipo"
                          @change="abrir_estatisticas(doc_codigo.CODIGO, in_today, in_today)"
                        ></v-select>
                      </v-col>

                      <v-col
                      class="px-2 pb-0 ml-4"
                      cols="2"
                      >
                        <v-btn
                          color="primary"
                          x-small
                          fab
                          @click="dialog_relatorios=true,rel_data_de=in_today,rel_data_ate=in_today"
                          >
                           <v-icon>
                            mdi-printer 
                           </v-icon>
                        </v-btn>
                      </v-col>
                  </v-row>

                  <!-- <v-divider class="mb-2"></v-divider> -->
                  <!-- <v-btn
                    color="primary"
                    x-small
                    @click="dialog_relatorios=true,rel_data_de=in_today,rel_data_ate=in_today"
                    >
                    Atendimentos
                   </v-btn> -->

                  </v-expansion-panel-content>
                </v-expansion-panel>

                <!-- LISTA DE ESPERA -->
                <v-expansion-panel v-if="(!sou_acencon) && (doc_codigo.CODIGO > 0)">
                  <v-expansion-panel-header 
                  class="pa-2 px-4"
                  style="min-height: 28px"
                  @click="abrir_espera(doc_codigo.CODIGO),expansion_selecionao='ESPERA'"
                  >

                    <div>
                      <v-row>
                        <v-col cols="12">
                            <v-icon size="26" class="pr-1" color="red"> 
                              mdi-account-clock
                            </v-icon> 
                            
                            <span class="fs-14">Lista de Espera 
                              <span v-if="items_espera.length>0" class="count_item fs-12">{{ items_espera.length }}</span>

                            </span>

                              
                        </v-col>
                      </v-row>
                    </div>
                  </v-expansion-panel-header>

                  <v-expansion-panel-content class="py-0 mt-0 ">
                    <!-- class="py-0 ma-0" -->

                      <!-- <v-container> -->

                        <v-row class="mt-0 px-0 ">

                          <v-col class="pt-0 px-0 " cols="9">
                            <v-text-field v-model="search_espera"
                              dense
                              clearable
                              append-icon="mdi-magnify"
                              label="Procurar"
                              single-line                
                              hide-details
                              @keyup.esc="filtrar_search(search_espera='', 'items_espera', 'NOME')"
                              @keypress.enter="filtrar_search(search_espera, 'items_espera', 'NOME')"
                              @click:clear="filtrar_search(search_espera='', 'items_espera', 'NOME')"
                            >
                            </v-text-field>
                          </v-col>

                          <v-col
                            class="pl-0 py-0 ml-4"
                            cols="2"
                            >
                              <v-btn
                                color="primary"
                                x-small
                                fab
                                @click="abre_janela_modal(true, true, 'ESPERA', 'Lista de Espera')"
                                >
                                <v-icon>
                                  mdi-plus
                                </v-icon>
                              </v-btn>
                            </v-col>

                        </v-row>

                        <v-row class="ml-n7 pt-1">
                          <v-col class="px-0 py-0">

                          <v-list
                            dense
                            style="max-height: 222px"
                            class="overflow-y-auto px-0 py-0"
                            >
                              <v-list-item-group active-class="border">

                                <v-list-item
                                  v-for="(item, i) in items_espera"
                                  :key="i"
                                  class="px-0 py-0 grey lighten-5"
                                  style="min-height: 20px !important;"
                                  
                                >

                                  <v-list-item-content class="py-0">
                                      <div style="white-space: nowrap;overflow: hidden;text-overflow: ellipsis;">
                                        <v-row>


                                          <v-col cols="10" class="">
                                          


                                            <span class="pl-0">
                                              <v-tooltip bottom>
                                                <template v-slot:activator="{ on, attrs }">
                                                  <v-icon 
                                                    class="pl-2"
                                                    color="red"
                                                    :size="(isMobile) ? si_mobile: 18"
                                                    @click="clicou_em('remover_espera', item)"

                                                    v-bind="attrs"
                                                    v-on="on"
                                                  >
                                                    mdi-close
                                                  </v-icon>

                                                </template>
                                                <span> Remover </span>
                                              </v-tooltip>
                                            </span>
                                   



                                            

                                            <v-tooltip bottom>
                                              <template v-slot:activator="{ on, attrs }">
                                                <v-icon 
                                                  class="pl-1"
                                                  color="green"
                                                  :size="(isMobile) ? si_mobile: 18"
                                                  @click="go_enviar_whats_manual(item.TELEFONE, `Olá ${item.NOME}`, isMobile)"
                                                  :disabled="(!item.TELEFONE)"

                                                  v-bind="attrs"
                                                  v-on="on"
                                                >
                                                  mdi-whatsapp
                                                </v-icon>

                                              </template>
                                              <span> Enviar Mensagem para {{ item.TELEFONE}} </span>
                                            </v-tooltip>


                                            <span v-if="0>1">
                                              <v-tooltip bottom>
                                                <template v-slot:activator="{ on, attrs }">
                                                  <v-icon 
                                                    class="pl-2"
                                                    color="primary"
                                                    :size="(isMobile) ? si_mobile: 18"
                                                    @click="abrir_cadastro(false, item.CODIGO_CLIENTE,'')"

                                                    v-bind="attrs"
                                                    v-on="on"
                                                  >
                                                    mdi-account
                                                  </v-icon>

                                                </template>
                                                <span> Abrir Cadastro </span>
                                              </v-tooltip>
                                            </span>

                                            <span style=" text-align: right; font-size: 8px;" class="grey--text "> {{ i + 1 }}º &nbsp </span>

                                            <span>
                                              <h5 style="display: inline; font-size: 10px;" class="ml-0 pl-0"
                                              @click="alerta( item.NOME +'<br>'+ item.CONVENIO, 'orange', 2000)"> 
                                              {{ item.NOME }} - {{ item.CONVENIO }}
                                              </h5>
                                            </span>





                                            <!-- <v-tooltip bottom>
                                              <template v-slot:activator="{ on, attrs }">
                                                <span style="font-size: 11px;" class=""
                                                  v-bind="attrs"
                                                  v-on="on"
                                                > 

                                                  {{ item.TELEFONE }} 
                                                </span>
                                              </template>
                                              <span> </span>
                                            </v-tooltip> -->


                                          </v-col>
                                        </v-row>
                                      </div>

                                      <v-divider class="mx-0"/>
                                  </v-list-item-content>
                                </v-list-item>




                              </v-list-item-group>
                            </v-list>

                          </v-col>
                        </v-row>
                      <!-- </v-container> -->

                  </v-expansion-panel-content>
                </v-expansion-panel>

                <!-- ANIVERSARIO -->
                <v-expansion-panel v-if="(!sou_acencon)">
                  <v-expansion-panel-header 
                  class="pa-2 px-4"
                  style="min-height: 28px"
                  @click="abrir_niver(),expansion_selecionao='NIVER'"
                  >

                    <div>
                      <v-row>
                        <v-col cols="12">
                            <v-icon size="26" class="pr-1" color="primary"> 
                              mdi-cake-variant-outline 
                            </v-icon> 
                            
                            <span class="fs-14">Aniversários 
                              <span v-if="items_niver.length>0" class="count_item fs-12">{{ items_niver.length }}</span>
                              <span v-else class="count_item fs-12"> hoje </span>

                            </span>

                              
                        </v-col>
                      </v-row>
                    </div>
                  </v-expansion-panel-header>

                  <v-expansion-panel-content class="py-0 ">
                    <!-- class="py-0 ma-0" -->

                      <v-container>



                        <v-row class="mt-1 px-0 ml-n6">
                          <v-col class="pa-0  mt-n4 mb-1" cols="12">
                            <v-text-field v-model="search_niver"
                              dense
                              clearable
                              append-icon="mdi-magnify"
                              label="Procurar"
                              single-line                
                              hide-details
                              @keyup.esc="filtrar_search(search_niver='', 'items_niver', 'NOME')"
                              @keypress.enter="filtrar_search(search_niver, 'items_niver', 'NOME')"
                              @click:clear="filtrar_search(search_niver='', 'items_niver', 'NOME')"
                            >
                            </v-text-field>
                          </v-col>
                        </v-row>

                        <v-row class="ml-n7 pt-1">
                          <v-col class="px-0 py-0">

                          <v-list
                            dense
                            style="max-height: 222px"
                            class="overflow-y-auto px-0 py-0"
                            >
                              <v-list-item-group active-class="border">

                                <v-list-item
                                  v-for="(item, i) in items_niver"
                                  :key="i"
                                  class="px-0 py-0 grey lighten-5"
                                  style="min-height: 20px !important;"
                                  
                                >

                                  <v-list-item-content class="py-0">
                                      <div style="white-space: nowrap;overflow: hidden;text-overflow: ellipsis;">
                                        <v-row>

                                          <v-col cols="10" class="">
                                          
                                            <v-tooltip bottom>
                                              <template v-slot:activator="{ on, attrs }">
                                                <span style="font-size: 11px;" class="primary--text bold pl-1"
                                                  v-bind="attrs"
                                                  v-on="on"
                                                > 

                                                  {{ item.IDADE }} anos
                                                </span>
                                              </template>
                                              <span> {{ item.x_DATANASCIMENTO }} </span>
                                            </v-tooltip>


                                            

                                            <v-tooltip bottom>
                                              <template v-slot:activator="{ on, attrs }">
                                                <v-icon 
                                                  class="pl-2"
                                                  color="green"
                                                  :size="(isMobile) ? si_mobile: 18"
                                                  @click="before_enviar_whats(item, 'NIVER')"
                                                  :disabled="(!item.CELULAR)"

                                                  v-bind="attrs"
                                                  v-on="on"
                                                >
                                                  mdi-whatsapp
                                                </v-icon>

                                              </template>
                                              <span> Enviar Parabéns </span>
                                            </v-tooltip>


                                            <v-tooltip bottom>
                                              <template v-slot:activator="{ on, attrs }">
                                                <v-icon 
                                                  class="pl-2"
                                                  color="primary"
                                                  :size="(isMobile) ? si_mobile: 18"
                                                  @click="abrir_cadastro(false, item.CODIGOCLIENTE,'')"

                                                  v-bind="attrs"
                                                  v-on="on"
                                                >
                                                  mdi-account
                                                </v-icon>

                                              </template>
                                              <span> Abrir Cadastro </span>
                                            </v-tooltip>

                                            <span style=" text-align: right; font-size: 8px;" class="grey--text "> {{ i + 1 }}º &nbsp </span>

                                            <span>
                                              <h5 style="display: inline; font-size: 10px;" class="ml-0 pl-0"> 
                                              {{ item.NOME }}
                                              </h5>
                                            </span>


                                          </v-col>
                                        </v-row>
                                      </div>

                                      <v-divider class="mx-0"/>
                                  </v-list-item-content>
                                </v-list-item>




                              </v-list-item-group>
                            </v-list>

                          </v-col>
                        </v-row>
                      </v-container>

                  </v-expansion-panel-content>
                </v-expansion-panel>

              </v-expansion-panels>
            </v-card>
  
          </v-col>

          <!-- PROPAGANDA MODULOS VERSAO DESKTOP -->
          <v-col cols="11" class=" pl-4" v-if="(!isMobile) && 2>1">

            <v-img
              alt="user"
              src="https://dsi.app.br/logos/propaganda/agenda_online.png"
              class="hand"
              @click="propaganda_alerta('agendamento_online')"
              v-if="0>1"
            >
          </v-img>


             <!-- <a> <v-img src="/images/zap.png" @click="propaganda_zap()"></v-img>  </a> -->

             <!-- <div v-if="(mostrar_propaganda('online'))">
               <a> <v-img link src="/i/images/agenda.png" @click="abre_img('Agendamento Online', '/i/images/agenda.png','online' )"></v-img>   </a>
             </div> -->

             <!-- <a> <v-img link src="/i/images/whats.png" @click="abre_img('Não perca mais tempo!', '/i/images/whats.png','zap' )"></v-img>   </a> -->

             <!-- zapzap propaganda  && (!value_expansion) --> 
             <iframe v-if="(!zapzap)"  width="170" height="100" src="https://www.youtube.com/embed/e428dP7k-xE?si=YFvcpGbOcFicgD8H" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
             <!-- <iframe width="270" height="200" src="https://www.youtube.com/embed/e428dP7k-xE?si=yvg_pefDMFliqwqK&amp;controls=0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe> -->

             
          </v-col>
        </v-row>


    </div>

    <!-- painel central -->
    <v-row
      class=""
    >
      <v-col
       :class="[isMobile ? '' : 'pl-0', 'pr-4']" 
       cols="12"
       style="margin-right: -200px;"
      >
      <!-- :style= "[isMobile ? '': {'margin-right':'90px'}]" -->
      

      <div class="pa-0">

      <!-- painel top -->
      <v-card
      tile
      elevation="3"
      class=""
      :height="(isMobile) ? '': 60"
      >
      <v-container
      class="mt-0"
      >
      <v-row>



      <v-col
        class=""
        cols="12"
        md="4"
        lg="4"
      > 

        <!-- {{ doc_codigo }} -->
        <v-select v-model="doc_codigo" :label="(profissionais.length==1) ? `Profissional: (${(desserts.length)} agendamentos)` :  `${(profissionais.length-1)} Profissionais: (${(desserts.length)} agendamentos)`"
            :items="profissionais"
            :rules="notnullRules"
            item-value='CODIGO'
            item-text='NOME'
            return-object
            dense
            v-if="simples"
          >
          <!-- :menu-props="{ maxHeight: '800' }" -->
          <template slot='selection' slot-scope='{ item }'>
              {{ item.NOME }}
            </template>

            <template slot='item' slot-scope='{ item }'>
              <!-- <v-chip>
              {{ item.NOME }}   
              </v-chip> -->
              <h5>{{ item.NOME }}</h5>
            
            &nbsp | &nbsp

              <div 
                class="caption"
              >
                {{ item.CATEGORIA }}
              </div>     


              <div 
                class="caption" v-if="(!isMobile)"
              >
                &nbsp | &nbsp
                {{ item.CRM }}
              </div>                      


              <div 
                class="caption"
              >
              </div>                      

            </template>
        </v-select>
          
        <v-combobox v-model="doc_codigo" :label="(profissionais.length==1) ? `Profissional: (${(desserts.length)} agendamentos)` :  `${(profissionais.length-1)} Profissionais: (${(desserts.length)} agendamentos)`"
            v-else
            :items="profissionais"
            :rules="notnullRules"
            item-value='CODIGO'
            item-text='NOME'
            return-object
            dense
            clearable
          >
          <template slot='selection' slot-scope='{ item }'>
              {{ item.NOME }}
            </template>

            <template slot='item' slot-scope='{ item }'>
              <!-- <v-chip>
              {{ item.NOME }}   
              </v-chip> -->
              <h5>{{ item.NOME }}</h5>
            
            &nbsp | &nbsp

              <div 
                class="caption"
              >
                {{ item.CATEGORIA }}
              </div>     


              <div 
                class="caption" v-if="(!isMobile)"
              >
                &nbsp | &nbsp
                {{ item.CRM }}
              </div>    


              <div 
                class="caption"
              >
              </div>                      

            </template>
        </v-combobox>

      </v-col>



      <v-col v-if="isMobile==true"
        cols="6"
        md="2"
        lg="2"
      >
              
        <v-text-field v-model="in_today"
          label="Data"
          hide-details
          type="date"
          dense
        ></v-text-field>

      </v-col> 

      <v-divider
      class="mx-3"
      inset
      vertical
      ></v-divider>


      <v-col
        cols="5"
        md="2"
        lg="2"
      >
        <v-text-field v-model="search"
          dense
          clearable
          append-icon="mdi-magnify"
          label="Procurar"
          single-line                
          hide-details
          @keyup.esc="keyUpp('esc')"
        >
        </v-text-field>
      </v-col>

      <v-col
        cols="12"
        md="auto"
        lg="auto"
        class=""
      >
        <v-row dense>


              <!-- ZAPZAP ATIVO NO CLIENTE -->
            <v-col cols="auto" class="" v-if="(((zapzap)  &&((zapzapSITUACAO=='ATIVO')||(zapzapSITUACAO=='TESTE')) && (zap_status)))">

            <!-- <v-col cols="auto" v-if="mostrar_zap"> -->
            <!-- <v-col cols="auto" v-if="((config_whats.WHATS_SITUACAO!='INATIVO') && (config_whats.ATE))"> -->
              <span v-if="mostrar_zap_tipo()">
                <v-menu
                  bottom
                  right
                >
                  <template v-slot:activator="{ on : menu }">
                    <!-- enviar whats automaticamente -->
                    
                    <v-badge
                      :content="cout_zap_enviar"
                      :value="cout_zap_enviar"
                      color="red"
                      overlap
                      :class="[(isMobile) ? 'ml-5 mt-1': 'ml-0']"
                    >
                    <!-- :dot="(isMobile)" -->

                        <v-tooltip bottom>
                            <template v-slot:activator="{ on : tooltip, attrs }">
                              <v-icon 
                              
                              :color="(config_whats.SITUACAO =='INATIVO') ? 'grey' :  'green'" 
                              :size=" (zap_status) ? '33' : (isMobile) ? 30: 33"
                              v-bind="attrs"
                              v-on="{...menu, ...tooltip}"
                            >
                            
                            
                              mdi-whatsapp
                            </v-icon>  
                            </template>
                          <span> Enviar mensagens automáticamente. </span>
                        </v-tooltip>
                    </v-badge>
                  </template>

                  <v-card
                      class="mx-auto"
                    >
                      <v-list dense>

                          <v-divider class="mx-0"></v-divider>

                          <v-subheader class="grey lighten-3">
                            
                            <v-list-item-icon class="mx-0 ml-0" >
                              <v-icon  size="22" class="mt-n2"> {{(config_whats.WHATS_PLATAFORMA=='MEU_PROPRIO_NUMERO')? 'mdi-home' : 'mdi-account-tie-woman'}} </v-icon>
                            </v-list-item-icon>

                            <h4 class="ml-1" @click.stop=""> Envio Automático - {{ config_whats.SITUACAO }}</h4>
                          </v-subheader>



                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-list-item-title>
                                  <v-subheader 
                                    class="white lighten-4"
                                    v-if="whats_obs !== undefined"
                                    v-bind="attrs" v-on="on"
                                  >
                                      <v-checkbox
                                          v-model="checkbox_obs"
                                          label="Anexar Obs. Geral (1)"
                                          dense
                                          style="margin-left: 0px; margin-bottom: -5px"
                                          @click.stop=""       
                                      ></v-checkbox>
                                  </v-subheader>
                                </v-list-item-title> 

                              </template>
                            <span v-html='quebra_linha(whats_obs) '>  </span>
                          </v-tooltip>
                          
                          
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-list-item-title>
                                  <v-subheader 
                                    class="white lighten-4"
                                    v-if="whats_obs_2 !== undefined"
                                    v-bind="attrs" v-on="on"
                                  >
                                      <v-checkbox
                                          v-model="checkbox_obs_2"
                                          label="Anexar Obs. Geral (2)"
                                          dense
                                          style="margin-left: 0px; margin-bottom: -5px"
                                          @click.stop=""       
                                      ></v-checkbox>
                                  </v-subheader>
                                </v-list-item-title> 

                              </template>
                            <span v-html='quebra_linha(whats_obs_2) '>  </span>
                          </v-tooltip>







                          <v-list-item-title>
                            <v-subheader 
                              class="white lighten-4"
                              v-if="whats_obs !== undefined"
                            >
                              <v-checkbox
                                  v-model="enviar_parar"
                                  label="Enviar PARAR"
                                  dense
                                  style="margin-left: 0px; margin-bottom: -5px"
                                  @click.stop="" 
                              ></v-checkbox>
                            </v-subheader>
                          </v-list-item-title>


                          <v-divider class="mx-0" />

                          <v-list-item-title>
                            <v-subheader 
                              class="grey lighten-4 mb-2 mt-1"
                            >
                              <v-card class="ma-n4 px-6 grey lighten-5" max-width="210">
                              <v-row>
                                <v-col cols="12" class="my-5 mt-12">

                                  <v-select v-model="adiantar_hora"
                                      dense
                                      :items="['Agendado','-10 minutos', '-15 minutos','-20 minutos','-30 minutos']"
                                      label="Horário:"
                                      @click.stop=""
                                    ></v-select>
                                </v-col>
                              </v-row>
                            </v-card>

                            </v-subheader>
                          </v-list-item-title>



                          <v-divider class="mx-0"></v-divider>

                          <v-list-item
                              v-for="item in menu_whats"
                              :key="item.title"
                              link
                              @click="clicou_menu_whats(item.click)"
                              v-if="item.type=='menu'"
                              >

                                <!-- <v-list-item-icon>
                                  <v-icon :color="item.icon_color"> {{ item.icon }}</v-icon>
                                </v-list-item-icon> -->

                                <v-icon x-small class="pr-2" :color="item.icon_color"> {{ item.icon }}</v-icon>

                                <v-list-item-content>
                                    <v-list-item-title v-text="item.title"></v-list-item-title>
                                </v-list-item-content>

                          </v-list-item>



                          <v-list-group
                              v-for="item in menu_whats"
                              :key="item.title"
                              v-model="item.active"
                              
                              no-action
                              @click.stop="" 
                              v-if="(item.type=='group')"
                          >

                              <template v-slot:activator>

                                <!-- <v-list-item-icon>
                                  <v-icon :color="item.icon_color" small> {{ item.icon }}</v-icon>
                                </v-list-item-icon> -->

                                <v-icon x-small class="pr-2" :color="item.icon_color"> {{ item.icon }}</v-icon>

                                <v-list-item-content>
                                    <v-list-item-title v-text="item.title"></v-list-item-title>
                                </v-list-item-content>
                              </template>


                              <v-list-item
                              v-for="sub in item.items"
                              :key="sub.title"
                              link
                              @click="clicou_menu_whats(sub.click)"
                              class="ml-n4"
                              >



                                <v-icon x-small class="pr-2" :color="sub.icon_color"> {{ sub.icon }}</v-icon>

                                <v-list-item-content>
                                    <v-list-item-title v-text="sub.title"></v-list-item-title>
                                </v-list-item-content>

                                

                              </v-list-item>

                          </v-list-group>



                      </v-list>
                    </v-card>

                </v-menu>
              </span>
              <span v-else>
                <v-icon size="33" :color="(zap_status ? 'green':'grey')">mdi-whatsapp</v-icon>
              </span>

            </v-col>

            <!-- AQUI ELE BUSCA O QRCODE PARA CONECTAR... -->
            <v-col cols="auto" class="" v-else-if="(zapzap) &&((zapzapSITUACAO=='ATIVO')||(zapzapSITUACAO=='TESTE')) && (!zap_status)">
              <span v-if="mostrar_zap_tipo()">
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon 
                      v-bind="attrs"
                      v-on="on"
                      :size="(zap_status ? '':'49')"
                      :color="(zap_status ? 'white':'red')"
                      
                      @click="status_zap('qrcode')"
                      >mdi-whatsapp</v-icon>
                      

                  </template>
                  <span> {{(zap_message) }} </span>
                </v-tooltip>
              </span>

              <span v-else>
                <v-icon size="33" :color="(zap_status ? 'green':'grey')">mdi-whatsapp</v-icon>
              </span>
            </v-col>

            <v-col cols="auto" v-else class="">
              <span>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon 
                      color="orange"
                      v-bind="attrs"
                      v-on="on"
                      size="33"
                      @click="propaganda_zap()"
                    
                      >mdi-whatsapp</v-icon>
                      

                  </template>
                  <span> Enviar WhatsApp automáticamente </span>
                </v-tooltip>
              </span>
            </v-col>

            <v-col cols="auto">

              <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                    <v-icon
                    color="primary"
                    @click="abrir_cadastro(false, search,'')"
                    size="33"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <!-- mdi-pencil -->
                    <!-- mdi-account-check  -->
                    mdi-account
                  </v-icon>
              </template>
              <span> Procurar Pacientes</span>
              </v-tooltip>


              <v-btn
                color="primary"
                @click="abre_agenda()"
                x-small
                class="ml-3"
              >
                Atualizar
              </v-btn>


              <span class="ml-2">
              <v-menu
                bottom
                left
                min-width="180"
              >
                <template v-slot:activator="{ on, attrs }">
        
                  
                    <v-icon
                      large
                      color="primary"
                      class="mr-4"
                      v-bind="attrs"
                      v-on="on"
                    >
                      mdi-menu
                    </v-icon>
                    <span  class="count_item fs-14 bold  ml-n5 mr-1"> 1 </span>
                  
        
        
                </template>
        
                <v-list dense>
        
        
                  <v-subheader class="grey lighten-3">
                    <h4 class="ml-1"> Opções: </h4>
                  </v-subheader>
        
                  <v-divider class="mx-0"></v-divider>
        
        
                  <v-list-item
                    v-for="(item, i) in menu_header"
                    :key="i"
                    @click="clicou_menu(item.click)"
                  >
                    <v-list-item-icon>
                      <v-icon :color=item.icon_color> {{ item.icon }} </v-icon>
                    </v-list-item-icon>
        
                    <v-list-item-title>
                      {{ item.title }}
                      <span  class="count_item fs-14 bold" v-if="(item.new=='S')"> 1 </span>
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </span>


              <v-btn
                color="success"
                @click="trata_retorno_webhook()"
                x-small
                class="ml-3"
                v-if="(zapzap)&&(superA)"
              >
                VERIFICAR
              </v-btn>


            </v-col>



            <!-- <v-col cols="auto" class="" v-if="mostrar_zap_testar"> -->
              <v-col cols="auto" class="" v-if="0>1">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                <v-btn
                  :color="(config_whats.SITUACAO =='INATIVO') ? 'grey' :  'blue'" 
                  v-bind="attrs"
                  v-on="on"
                  v-if="(!config_whats.ATE)"
                  class="ml-3"
                  small
                  @click="dialog_whats_ativar=true"
                  >
                    <v-icon
                      left
                      dark
                    >
                      mdi-whatsapp
                    </v-icon>

                    Teste Grátis

                </v-btn>
              </template>
                <p> Envio automático de WhatsApp </p>
                <div> Teste Grátis por 7 dias</div>
              </v-tooltip>

            </v-col>
          

            <!-- ZAPZAP PROMOVER CHAMAR PARA TESTAR-->
            <v-col cols="auto" class="" v-if="((zapzapSITUACAO=='PROMOVER'))">
                  
                  <v-badge
                    :content="cout_zap_enviar"
                    :value="cout_zap_enviar"
                    color="red"
                    overlap
                    :class="[(isMobile) ? 'ml-5 mt-1': 'ml-0']"
                  >
                  <!-- :dot="(isMobile)" -->

                      <v-tooltip bottom>
                          <template v-slot:activator="{ on : tooltip, attrs }">
                            <v-icon 
                            
                            color='primary' 
                            :size=" (zap_status) ? '33' : (isMobile) ? 30: 33"
                            v-bind="attrs"
                            v-on="{...tooltip}"
                            @click="dialog_whats_ativar=true"
                          >
                          
                          
                            mdi-whatsapp
                          </v-icon>  
                          </template>
                        <span> Enviar mensagens automáticamente </span>
                      </v-tooltip>
                  </v-badge>

            </v-col>
        
        </v-row>
      </v-col>


      <v-col class="ma-0 pa-0" md="1" v-if="0>1">
        <v-switch
          dense
          class="pa-0 ma-0 mt-4 "
          v-model="agenda_colorida"
          label="Colorido"
        ></v-switch>
      </v-col>



      <v-col class=" ml-5 ma-0 pa-0" md="1" v-if="(superA)">
        <v-switch
          dense
          class="pa-0 ma-0 mt-4 "
          v-model="stop_agenda"
          label="Stop"
        ></v-switch>
      </v-col>


      <div
      v-if="TESTE==true"
      >
      DSI
      <v-chip-group>

      <v-chip>
        doc_codigo.CODIGO:
        {{doc_codigo.CODIGO}}
      </v-chip>

      <v-chip>
        doc_codigo.NOME:
        {{doc_codigo.NOME}}
      </v-chip>

      <v-chip>
        doc_codigo.CRM:
        {{doc_codigo.CRM}}
      </v-chip>


      <v-chip>
        get.CodigoConsulta:
        {{get.CodigoConsulta}}
      </v-chip>


      <v-chip>
        selected:
        {{selected[0]}}
      </v-chip>



      </v-chip-group>
      </div>

      <v-spacer></v-spacer>

      <v-divider
      class="mx-1"
      inset
      vertical
      ></v-divider>



      </v-row>
      </v-container>
      </v-card>

      <!-- painel data-table lista -->
      <v-card class="mt-1">
    

        <!-- data-table principal -->
        <!-- crud_agenda = abre a lista desserts  -->
      <v-data-table
        v-if="(mostrar_tabela)"
        v-model="selected"

        :headers="headers"
        :items="desserts"
        :search="search"

        single-select
        item-key="CODIGOCONSULTA"
        @contextmenu:row="rightClick"
        @click:row="seleciona_linha"


        dense
        class="elevation-1  lighten-5"
      
        hide-default-footer

        :mobile-breakpoint="55"
        :items-per-page="-1"
        :disable-sort="$vuetify.breakpoint.smAndDown"
        :item-class= "(agenda_colorida) ? rowClass : ''" 
        :height="(isMobile) ?  retorna_altura(220) : (desserts.length>0) ? retorna_altura() : ''"
      >

      
      <template v-slot:top>
        <v-snackbar
        v-model="snackbar"
        :timeout="snack_timeout"
        top
        :color="snack_color"    
        >

        <center v-html="snack_text"></center>

          <template v-slot:X="{ attrs }">
            <v-btn
              color="white"
              text
              v-bind="attrs"
              @click="snackbar = false"
            >
              Fecharx
            </v-btn>
          </template>
        </v-snackbar>
      </template>


      <template v-slot:item.EFETUADO="{ item }">
            <v-icon size="20" color="green" 
             v-if="item.EFETUADO=='S'"
            >
              <!-- mdi-currency-usd -->
              <!-- mdi-check-bold  -->
              <!-- mdi-credit-card-plus  -->
              mdi-credit-card-check 
            </v-icon>
      </template>

      <template v-slot:item.IDADE="{ item }">
        <span>
        {{ calcula_idade(getFormatDate(item.DATANASCIMENTO), 1) }} 
        </span>
      </template>


      <template v-slot:item.HORA="{ item, index }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            
            
            <v-chip-group class="ml-1">
              <v-chip
                :color="getColorSituacao(item.ATENDIDO, item.SUB_DOC_NOME)"
                dark
                small
                active-class="white--text"
              >
              <!-- @click="abre_menu(item)" -->
              <span
                v-bind="attrs"
                v-on="on"
              >
                {{ item.HORA }}
                <!-- {{ (getFormatTime(item.HORA)) }} -->
              </span>

                        <v-icon
                          v-if="(item.OK_VIA_WHATS == 'S')"
                          class="ml-1"
                          size="15"
                        >
                        mdi-account-check 
                        </v-icon>

                        <v-icon
                          v-if="(item.AVISOU_WHATS)&&(item.OK_VIA_WHATS != 'S')"
                          class="ml-1"
                          :color="(item.AVISOU_WHATS=='T')? 'light-blue lighten-4' : (item.AVISOU_WHATS=='A')? 'yellow' : (item.AVISOU_WHATS=='X')? 'black' : (item.AVISOU_WHATS=='!')? 'light-blue lighten-4' : ''"
                          :size="(item.AVISOU_WHATS=='!') ? '8' :'12'"
                        >
                          mdi-whatsapp
                        </v-icon>


                        <!-- AVISOU_WHATS:
                        S:AVISADO MANUAL
                        T:AUTOMATICO
                        X:CLICOU EM PARAR OU ERRO
                        !:JA ENVIOU PELO AUTO PARA NAO DUPLICAR
                        A:ENVIOU AVISO -->
                
              </v-chip>
              
              <v-spacer></v-spacer>
              <span style=" text-align: right; font-size: 10px;" class="grey--text pa-0 mt-2 ml-0"> {{ index + 1 }}º &nbsp </span>

            </v-chip-group> 
          </template>
          <span>
            
            {{ getSituacao(item.ATENDIDO, item.SUB_DOC_NOME) }}
            <!-- <br/> -->

            <span
              v-if="(item.OK_VIA_WHATS == 'S') && ((item.ATENDIDO == 'O') ||(item.ATENDIDO == 'H'))"
            >
                atravez do link
            </span>

              <p 
              v-if = "item.SUB_DOC_NOME !== null"
              >
              {{ item.PROFISSIONAL }} 
              <p>
                {{ item.SUB_DOC_NOME }} 
              </p>
              </p>


              <!-- chegada -->
              <div class="body-2" v-if="(item.HORA)">  {{ item.HORA }} 
                <span class="body-2" v-if="(item.DURACAO > 0)">
                  às  {{ item.HORA_ATE }} 
                  {{ retorna_minutos(item.DURACAO) }}
                </span>
                <!-- (marcado) -->
              
              </div>

              <div class="body-2" v-if="(item.HORA_CHEGADA)">  {{ item.HORA_CHEGADA }} (chegou) </div>
              

              <!-- ANOTAÇÃO -->
              <p v-if = "item.OBS !== ''">
                * {{ item.OBS }} 
              </p>

              <!-- MOTIVO FALTA -->
              <p v-if = "item.REGISTRO !== ''">
              Motivo:
              {{ item.REGISTRO }} 
              </p>
          </span>

          <span v-if="item.OK_VIA_WHATS == 'S'"> Confirmado pelo link do WhatsApp </span> 
          <span v-else-if="(item.AVISOU_WHATS)"> {{(item.AVISOU_WHATS=='T')? 'Whats Enviado Automáticamente' : (item.AVISOU_WHATS=='S')? 'Whats Enviado Manualmente' : (item.AVISOU_WHATS=='X')? 'Ops: '+ item.WHATS_RETORNO : ''}} </span>

        </v-tooltip>
      </template>



      <template v-slot:item.PROCEDIMENTO="{ item }">
        <span
         :class="getColorTexto(item.PROCEDIMENTO)"
        >
          {{ item.PROCEDIMENTO }}
        </span>


          <span v-if="(item.WHATS_OBS_PROCED)">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  color="orange"
                  dark
                  v-bind="attrs"
                  v-on="on"
                  small
                  class="mt-n2"
                  @click="informacao('WHATS_OBS_PROCED')"
                >
                mdi-information-box 
                </v-icon>
              </template>
              <span v-html='quebra_linha(item.WHATS_OBS_PROCED) '> 
              </span>

            </v-tooltip>
          </span>

      </template>

      <template v-slot:item.VALOR="{ item }">
        <span>
        <!-- v-if="item.CODIGOCLIENTE > 0"> -->
          {{ item.VALOR }}
        </span>
      </template>

      <template v-slot:item.FORMA_PAGAMENTO="{ item }">
        <span
         :class="getColorTexto(item.FORMA_PAGAMENTO)"
         v-if="item.CODIGOCLIENTE > 0"
        >
          {{ item.FORMA_PAGAMENTO }}
        </span>
      </template>
      

      <template v-slot:item.CONVENIO="{ item }">
        <span
         :class="getColorTexto(item.CONVENIO)"
        >
          {{ item.CONVENIO }}
        </span>
      </template>
      
      
      <template v-slot:item.xxx="{ item }">
        <span>
          {{ item.HORA }}
        </span>

        </br>

        <span>
        {{ item.NOME }}
        </span>

      <span
      >
      {{ item.CELULAR }}
      </span>
      </template>


      <template v-slot:item.x="{ item }">
        <v-icon @click="abre_menu(item)">
          <!-- mdi-pencil -->
          mdi-dots-vertical
        </v-icon>
      </template>


      <template v-slot:item.atender="{ item }">
        <v-icon
          class="mr-2"
          color="blue"
          @click="abre_conduta(item)"
          size="30"
          
          v-if="((user_def.podeAbrir == 'S') || (user_def.is_doc == 'S') || (obj_user.ABRE_ATENDIMENTO == 'S')) &&
                (item.ATENDIDO !== 'F') && (item.ATENDIDO !== 'D')&& (item.ATENDIDO !== 'B') &&
                (item.CODIGOCLIENTE > 0) && (doc_codigo.CODIGO>0)"
        >
        mdi-gesture-tap 
        </v-icon>
      </template>


      <template v-slot:item.ATENDIDO="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <span
                v-bind="attrs"
                v-on="on"
              >{{ item.ATENDIDO }}</span>
            </template>
            <span> Alterou:  {{ item.ATENDIDO }}</span>
          </v-tooltip>
      </template>

      <template v-slot:item.CELULAR="{ item }">            
          <span v-if="(item.CODIGOCLIENTE==-5)">
            {{ item.FONE }}
          </span>
          <span v-else>
            {{ item.CELULAR }}
          </span>
      </template>


      <template v-slot:item.NOME="{ item , index}">  
        <v-icon
          @click="abre_menu(item)"
          color="blue"
          :size="(isMobile) ? si_mobile: 18"
          v-if="(user_permissoes.agendar) && (doc_codigo.CODIGO>0)"
        >
          <!-- mdi-pencil -->
          <!-- mdi-dots-vertical -->
          mdi-menu
        </v-icon>

        &nbsp
        &nbsp

        <v-menu offset-x>
        <template v-slot:activator="{ on, attrs }">

          <v-icon      
              @click="seleciona_linha(item)"
              :size="(isMobile) ? si_mobile: 18"
              class="mr-2"
              color="green"
              v-bind="attrs"
              v-on="on"
              :disabled = "(!item.CELULAR) && (!item.FONE)"
              v-if = "(item.CODIGOCLIENTE!=0)"
          >
              mdi-whatsapp
          </v-icon> 

        </template>

        <v-list>  
          <v-list-item-title>
            <v-subheader 
              class="grey lighten-4"
              v-if="whats_obs !== undefined"
            >
              <v-checkbox
                  v-model="checkbox_obs"
                  label="Anexar Obs. Geral"
                  dense
                  style="margin-left: 0px; margin-bottom: -5px"
                  @click.stop="" 
              ></v-checkbox>
            </v-subheader>
          </v-list-item-title>
          
          <v-divider class="mx-4" />

          <v-list-item-title>
            <v-subheader 
              class="grey lighten-4"
              v-if="whats_obs !== undefined"
            >
              <v-card class="ma-n4 px-4 grey lighten-5" max-width="210">
              <v-row>
                <v-col cols="12" class="my-5 mt-12">

                  <v-select v-model="adiantar_hora"
                      dense
                      :items="['Agendado','-10 minutos', '-15 minutos','-20 minutos','-30 minutos']"
                      label="Horário"
                      @click.stop="" 
                    ></v-select>
                </v-col>
              </v-row>
            </v-card>

            </v-subheader>
          </v-list-item-title>

          <v-divider class="mx-4" />
          
          
          <v-list-item 
          @click="send_whats('confirmacao',  (item.CELULAR)? item.CELULAR : item.FONE,   item.NOME, item,'SS', true), enviou_whats(item)"
          link
          >

              <span
              class="blue--text"
              >             
              Confirmação:
              </span>
                
                &nbsp
                [
                <v-icon
                color="success"
                >
                  mdi-check-bold  
                </v-icon>
                
                <v-icon
                color="red"
                >
                  mdi-close-thick   
                </v-icon>
                ]
          </v-list-item>
                
          <v-divider class="mx-4" />

          <v-list-item 
          @click="send_whats('confirmacao', (item.CELULAR)? item.CELULAR : item.FONE , item.NOME, item,'SN', true), enviou_whats(item)"
          link
          >

              <span
              class="blue--text"
              >             
              Confirmação:
              </span>
                
                &nbsp
                [
                <v-icon
                color="success"
                >
                  mdi-check-bold  
                </v-icon>
                ]
          </v-list-item>

          <v-divider class="mx-4" />
          <v-list-item 
          @click="send_whats('confirmacao', (item.CELULAR)? item.CELULAR : item.FONE , item.NOME, item,'NN', true), enviou_whats(item)"
          link
          >

              <span
              class="blue--text"
              >             
              Confirmação
              </span>
                
          </v-list-item>
                
          <v-divider class="" />
          <v-list-item 
          @click="send_whats('confirmado', (item.CELULAR)? item.CELULAR : item.FONE , item.NOME, item,'NN', true)"
          link
          >

              <span
              class="blue--text"
              >             
              Confirmado 😉
              </span>
                
          </v-list-item>
                
          <v-divider class="" />

          <v-list-item 
          link
          @click="send_whats('aviso',(item.CELULAR)? item.CELULAR : item.FONE , item.NOME, item,null, true)"
          >

              <span
              class="blue--text"
              >             
              Aviso
              </span>

          </v-list-item>

          <v-divider class=""/>

          <v-list-item
          link
          @click="send_whats('ola', (item.CELULAR)? item.CELULAR : item.FONE , item.NOME, item,null, true)"
          >

              <span
              class="blue--text"
              >             
              Olá
              </span>

          </v-list-item>
            

        </v-list>
      </v-menu>

        &nbsp

        <v-icon             
            class="mr-2"
            color="blue"
            @click="abrir_cadastro(false, item.CODIGOCLIENTE,'')"
            :size="(isMobile) ? si_mobile: 18"
            v-if = "item.NOME !== null&& item.NOME !==''"
        >
            mdi-account
        </v-icon> 

        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">

            <v-icon             
                class="mr-2"
                color="blue"
                :size="(isMobile) ? si_mobile: 18"
                v-if = "(!item.ENVIOU_AVISO) && (item.CODIGOCLIENTE>0) && (Maviso)"
                @click="pergunta_aviso(item)"
                v-bind="attrs"
                v-on="on"
            >
            mdi-alpha-a-box 
            </v-icon> 

          </template>
          <span> Enviar Aviso </span>
        </v-tooltip>


        <v-avatar  class="pb-0 ma-0 mr-1" size="22" v-if="(item.FOTO_URL.length>20)">
          <v-img
            alt="..."
            contain
            :src="item.FOTO_URL"
            v-if="(item.FOTO_URL.length>20)"
            @click="abre_img(item.NOME, item.FOTO_URL, '')"
            class="hand">
          </v-img>
            
            <!-- @click="dialog_img=true"  -->

            <v-icon size="22" v-else class="pa-0 ma-0">
              mdi-account-circle
            </v-icon>
        </v-avatar>

        <v-tooltip  bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon             
              v-bind="attrs"
              v-on="on"

              class="mr-2"
              color="orange"
              @click="abrir_cadastro(false, item.NOME_AGENDA,'novo',item.CODIGOCONSULTA)"
              :size="(isMobile) ? si_mobile: 18"
              v-if = "item.CODIGOCLIENTE == '-5' "
            >
            mdi-account-plus 
        </v-icon> 

          </template>
          <span> Cadastrar Paciente </span>
        </v-tooltip>


        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">


            <!-- <span class="black--text" v-if="item.NUM_CANCELAMENTOS>0">
              <span>
                {{ item.NUM_CANCELAMENTOS }}C
              </span>
            </span>

            <span class="red--text" v-if="item.NUM_FALTAS>0">
              <span>
                {{ item.NUM_FALTAS }}F
              </span>
            </span> -->

            <!-- icone que tem aviso -->

            <span v-if="(sou_acencon)">
              <v-chip small class="mx-2" color="" v-if="(item.OBS)"
              >
              <!-- :href="detran_links(item.OBS)" -->
               <!-- target="_blank" -->
                {{item.OBS}}
              </v-chip>
            </span>

            <div class="ml-0 " v-else>
                <h4 class="ml-0 py-1 orange--text" v-if="item.OBS">
                  
                  <v-icon
                  v-if="item.OBS !== ''"
                  v-bind="attrs"
                  v-on="on"
                  class="ml-1"
                  size="14"
                  color="orange"
                >
                  mdi-comment-alert 
                  <!-- mdi-alert-box -->
                </v-icon>
                  
                  ({{ item.OBS }})
                </h4>
            </div>
            <!-- <span class="ml-8 py-1" v-if="item.OBS"> *{{ item.OBS }}</span> -->
          


            <span class="red--text fs-10 bold count_item2" v-if="item.CODIGOCLIENTE>0">
              <span v-if="(item.NUM_CONSULTAS == '1')">
                  {{item.NUM_CONSULTAS}}ª vez
              </span>
              <span v-else>
                {{ item.NUM_CONSULTAS }}ª
              </span>
            </span>

            
            <span class="ml-1" v-if="(item.DATANASCIMENTO)">
              ({{ calcula_idade(getFormatDate(item.DATANASCIMENTO), 1) }})
            </span>
            
            <span
              v-bind="attrs"
              v-on="on"
              v-if = "item.NOME !== null&& item.NOME !==''"
            >

            <!-- <span style="font-size: 10px"> {{ index + 1 }}ª &nbsp </span> -->

            {{ item.NOME }}  
            

            </span>   

            <span
              v-bind="attrs"
              v-on="on"
              v-else = "item.NOME_AGENDA !== null&& item.NOME_AGENDA !==''"
            >
            {{ item.NOME_AGENDA }}      
            </span>
            


            
          </template>
          <div class="body-2"> IDE: {{ item.CODIGOCLIENTE }}</div>
          <h4>  {{ item.CELULAR }}</h4>
          
          <p
            v-if="item.OBS !== ''"
          > * {{ item.OBS }}
          </p>        
          
          <span v-if="(item.CODIGOCLIENTE>0)">
            <span class="mt-2 fs-12 quebra"> {{item.NUM_CONSULTAS}} Agendamento´s</span>
            <span class="fs-12 quebra" > {{item.NUM_FALTAS}} Falta´s</span>
            <span class="fs-12 quebra"> {{item.NUM_CANCELAMENTOS}} Cancelamento´s</span>
          </span>
        </v-tooltip>

      </template>
    
      </v-data-table> 



      <!-- PROPAGANDA MODULOS VERSAO CELULAR -->
      <center>
      <v-col cols="11" class="" v-if="(isMobile)">

          <!-- zapzap propaganda -->
          <iframe v-if="(!zapzap)" width="350" height="200" src="https://www.youtube.com/embed/e428dP7k-xE?si=YFvcpGbOcFicgD8H" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
      </v-col>
    </center>


      </v-card>

      </div>

      </v-col>
      
    </v-row>



  </div>
</section>


    <!-- MOSTRA JANELA MODAL -->
    <div>
      <v-row>
        <v-col>
        <Modal_Editar 
          :aberto            = 'janela_modal_abre'
          :pro_tabela        = 'janela_modal_tabela'
          :pro_titulo        = 'janela_modal_titulo'
          :pro_obj_menus     = null
          :show_menu         = 'false'
          :pro_modo_edicao   = 'janela_modo_edicao'
          @muda_aberto_lembretes="janela_modal_change"
        />
        </v-col>
      </v-row>
    </div>

  </div>
</template>





<script>
  
  import DSirotinas from '../assets/js/DSi_rotinas'//chamo funcao do arquivo
  import DSibasico from '../assets/js/DSi_basico'//chamo funcao do arquivo
  import { somatoria_lista, get_data_hora, somarCampo } from '../assets/js/DSi_basico'
  import DSi_funcoes from '../assets/js/DSi_funcoes'
  import { verificar_enviados, enviar_whats_manual } from '../assets/js/DSi_whats'
  // import SignaturePad from 'signature_pad';

  import axios from "axios";
  var url = sessionStorage['url']
  var URL_API = sessionStorage['url']



  export default {
  

    components:{
      LembreteVue:   () => import("./Lembretes.vue"),
      Modal_Editar:  () => import("./Editar.vue")
     },
  
    data: () => ({

      header_estatisticas : [],
      items_estatisticas  : [],

      menu_header: [],
      pro_tag:'',

      tipo_selecionado:'PREVISAO',

      items_uf: [
          "PR", "SP", "SC", "AC", "AL", "AP", "AM", "BA", "CE", "DF", 
          "ES", "GO", "MA", "MT", "MS", "MG", "PA", 
          "PB", "PR", "PE", "PI", "RJ", "RN", "RS", 
          "RO", "RR", "SC", "SP", "SE", "TO"
        ],

        w_data: '99px',
        w_fone: '125px',
        w_cpf: '120px',
        w_nome: '200px',

      uf_rg_agendar:'',

      sou_acencon: false,
      vai_imprimir: false,

      dialog_protocolo: false,
      items_protocolo:{},
      

      cor_zap:'orange',
      items_valores_procedimentos : [],
      items_somatorias : [],

      signatureDataURL: null,

      mostrar_tabela: true,

      checkbox_avisar_pendentes: false,
      dialog_pendentes: false,
      array_ids_whats:[],

      obj_user: {},
      obj_dados_empresa: {},
      items_espera :[],
      items_niver :[],
      items_niver_clone :[],
      items_espera_clone :[],
      search_niver :'',
      search_espera :'',

      janela_modal_abre: false,
      janela_modal_tabela: '',
      janela_modal_titulo: '',
      janela_modo_edicao : false,
      


      Maviso: false,
      aviso_horarios:'',

      expansion_selecionao:'',

      horarios_cancelados_lista : [],

      janela_modal: false,
      modal_salvou: false,
      modal_qual:'',
      obj_janela_modal: {},

       user_permissoes: {},

       superA: false,

       stop_agenda: false,

       adiantar_hora: 'Agendado',

       url_externo:'',
       dialog_qrcode: false,
       img_qrcode_zap: '',
       zap_status:'',
       zap_message:'',

      // grafico1: [],
      // grafico2: [],
      // grafico1: [ 0, 4, 9, 7, 0, 0, 0, 0, 0, 1, 0, 12, 6, 0, 5, 9, 8, 8, 10, 10, 7, 4, 8, 3, 8, 3, 0, 9, 4, 1 ],
      // grafico2: [ 0, 3, 9, 7, 0, 0, 0, 0, 0, 1, 0, 12, 6, 0, 5, 9, 8, 8, 10, 10, 7, 4, 8, 3, 8, 3, 0, 9, 4, 1 ],

       dialog_img_mais:'',
       dialog_img_titulo:'',
       dialog_img_url:'',
       dialog_img: false,
       item_clicou: {},
       item_copiar_agendamento: null,
       item_clicou_menu_definir: {},

       zapzapTIPO: '',
       zapzapSITUACAO: '',
       zapzap: false,
       checkbox_celular: false,
       checkbox_concluidos: true,
       checkbox_anotacao: false,

       items_mensagens:[],
       resposta_mensagens:[],
       cor_fundo: 'grey lighten-2',


       digitando: false,

       menu_whats: [],
       config_whats:{},

       array_whats:[],

       whats_multiplos: false,

       mensagens_whats:{},
       cout_zap_enviar:0,
       dialog_whats_ativar: false,
       zap_testar: '',
       mostrar_zap_testar: false,
       mostrar_zap: false,

       boot_edit:{},

       agenda_colorida: true,
       overlay: false,

       ja_adicionado:'',

       rel_cod_conv:0,
       rel_data_de: '',
       rel_data_ate: '',
       rel_proced: '',

       geral_atendimentos:'Todos',
       qual_estatisticas: 'Dia',
       qual_relatorio: 'Atendimentos',
       r_estatisticas:'',
       col_r:1,

       dropdown_edit: [
        { text: 'Dia' },
        { text: 'Mês' },
        { text: 'Ano' },
      ],

       dropdown_edit2: [
        { text: 'Dia' },
        { text: 'Mês' },
      ],

       items_relatorio: [
        { text: 'Atendimentos' },
        { text: 'Faltas' },
        { text: 'Lançamentos' },
      ],

       lista_lancamentos: [],

       lista_estatiticas: [],
       count_estatiticas: 0,
        
       isMobile: false, 
       verchat: true,
       simples: true,

       value_expansion: 0, //0 ou null
       
       //privilegios de usuarios
       user_def:{},

       crud_table: 'agenda',//colocar o nome da tabela do BD
       doc_codigo: {},

      valido:false,
      valido_agendar:false,

      pre_cadastro: false,
      bloqueando : false,

      whats_confirmacao:'',
      whats_obs:'',
      whats_obs_2:'',
      whats_aviso:'',

      checkbox_obs:true,
      checkbox_obs_2:true,
      enviar_parar:false,
      
      progress_user: false,

      dialog_relatorios: false, 
      dialog_opcoes: false, 
      dialog_agendar: false, 
      dialog_paciente: false,
      dialog_editar: false,
      
      menu_aberto: false,
      menu_aberto_definir: false,
      menu_aberto_remarcar: false,

      dialog_agenda_log: false,
      qual_agenda_log: '',
      width_log: 450,

      item_principal:{},
      definir_A:[
        {text:'Confirmado', sigla:'O'},
        {text:'Retorno',    sigla:'N'},
        {text:'Remarcado',  sigla:'Q'},
        {text:'Antecipar',  sigla:'P'},
        {text:'Aguardando', sigla:'G'},
        {text:'Atendendo',  sigla:'T'},
        {text:'Atendido',   sigla:'A'},
        {text:'Faltou',     sigla:'F'},
        {text:'Agendado',   sigla:'M'},
        {text:'Cancelado',  sigla:'C'},
        ],

      timerAgenda:'',
      pode_timer: true,
      pode_timer_horario: true,
      pode_timer_h1: true,
      hora_agendar:'',
      hora_agendar_selecionada:'',
      switch_agendar: true,
      abriu_agenda: false,
      ja_horario_doc : false,

       profissionais:[],
       profissionais_sub:[],
       procedimentos:[],
       convenios:[],
       desserts: [],
       desserts_filtrado: [],
       items_selecionados:{
         convenio:'',
         cod_proced:'',
         cod_paciente:'',
         nome_paciente:''
       },

       nome_digitou:'',
       campos_agenda:'',

       selected:[],
       get:[{//get das sessions
         CodigoConsulta :0,
       }],

       editedIndex: -1,
       defaultItem: {},
       
       editedItem: {
        VALOR:0
       },

       copia_editedItem : {},

       readonly: true,
       TESTE: false,

      editaCampos:{
        ATENDIDO: 'T'
      },

       hoje :'',
       hora :'',
       data_hora :'',
       today :'',
       in_today: '',
       trans_to: '',
       di: '', 
       df: '',


       emailRules: [
        // v => !!v || 'necessário',
        v => /.+@.+\..+/.test(v) || 'E-mail inválido!',
       ],
       
       notnullRules: [
        v => !!v || 'necessário'
       ],

       minimoRules: [
        v => !!v || 'necessário',
        v =>(v && v.length >= 5) || 'necessário'
       ],



       snackbar: false,
       snack_text: 'My timeout is set to 2000.',
       snack_color: "success",
       snack_timeout: 2000,
    

      si_mobile: 22,
      search: '',
      dialog: false,
      dialogDelete: false,
      headers: [],

      items_duracao: [
        { title: '30 minutos' ,   duracao: 30},
        { title: '1 hora' ,       duracao: 60},
        { title: '1 hora e meia', duracao: 90 },
        { title: '2 horas' ,      duracao: 120},
        { title: '3 horas' ,      duracao: 180},
        { title: '4 horas' ,      duracao: 240},
        { title: '5 horas' ,      duracao: 300},
        { title: 'dia todo' ,     duracao: 720},
      ],

      header_mobile:[
        { text: 'Hora', value: 'HORA' },
        {
          text: 'Nome',
          align: 'start',
          //sortable: false,
          value: 'NOME',
        },
        { text: '', value: 'xxx' }
      ],

      dias_bloqueados: [],
      horario_doc_array: [],
      horario_do_dia: [],
      headers_horarios: [
        { text: 'codigo', value: 'CODIGO' },
        { text: 'hi', value: 'HI' },
        { text: 'hf', value: 'HF' },
        { text: 'int', value: 'INTERVALO' }
      ],

      items_pacientes:[],
      headers_pacientes: [
        { text: 'Nome', value: 'NOME', width: '200px' },
        { text: 'Nasc.', value: 'DATANASCIMENTO' },
        { text: 'CPF', value: 'CPF' },
        { text: 'Convênio', value: 'CONVENIO', width: '90px' },
        { text: 'Celular', value: 'CELULAR' },
        { text: 'IDE', value: 'CODIGOCLIENTE' },
      ],
      
    }),

    computed: {
      formTitle () {
        return this.editedIndex === -1 ? ('Novo Agendamento') : ('Editar') //this.editedItem.id 
      },

      filteredList() {
        return this.listItems.filter(item => {
          return item.toLowerCase().includes(this.searchQuery.toLowerCase());
        });
      },
    },

    watch: {


      dialog_relatorios(a){
        // this.rel_data_de  = get_data_hora('mes_inicio')
        // this.rel_data_ate = get_data_hora('mes_fim')
        // this.rel_data_ate = this.in_today
      },

      // expansion_selecionao(a){
      //   alert('here:' + a)
      //   if (a == 'LANCAMENTOS'){
      //     this.abrir_lancamentos(this.doc_codigo.CODIGO, this.in_today, this.in_today)
      //   }
      // },

      dialog_paciente(a){
        this.uf_rg_agendar = ''
      },

      uf_rg_agendar(a){
         if ((a!=='') && (a!=='PR')){
            alert('Quando RG de outro estado a foto deverá ser feita somente no DETRAN')
         }
      },

      enviar_parar(a){
        if (a){
          alert('Enviando esta opção o Pacinte poderá escolher PARAR de receber essas mensagens de confirmação por um prazo de 6 meses')
        }
      },

      menu_aberto_remarcar(a){
        if (a){
          this.menu_aberto_definir = !a
        }
      },

      menu_aberto_definir(a){
        if (a){
          this.menu_aberto_remarcar = !a
        }
      },

      

      zap_testar(a){
          // this.analisa_resposta(a)
          if (a.includes('Quero saber mais')){
             this.responde_bot('user', 'DSI',a, false)
          }
      },

      agenda_colorida(val){
        if (val){
          localStorage['colorido'] = 'S'
        }else{
          localStorage['colorido'] = 'N'
        }
        
      },

      doc_codigo(val, val2){

        if ((val.CODIGO == 0)||(val2.CODIGO == 0)){
            this.monta_headers()
        }
        

        sessionStorage['obj_doc_selecionado']  =  JSON.stringify(this.doc_codigo)//salva para ser utilizado no print.vue

        // console.log('doc_codigo - val:', val);

        if (val.CODIGO >=0 ){
          this.set_sessionStorage('getCodigoDoc',         val.CODIGO)
          this.set_sessionStorage('getNomeDoc',           val.NOME)
          this.set_sessionStorage('getCRMdoc',            val.CRM)

          if (val.CODIGO >0 ){
              this.set_sessionStorage('getCRMuf',             val.UF)
              this.set_sessionStorage('getConselho',          val.CNES_PF)
              // this.set_sessionStorage('getPATH_LOGO',         val.PATH_LOGO)
              this.set_sessionStorage('getPATH_LOGO_PRO',     val.PATH_LOGO)
              this.set_sessionStorage('getCPFdoc'         ,   val.CPF)

              if(val.ATESTADO_TITULO.length >5){//se tiver algo no cadastro do profissinal
                this.set_sessionStorage('getATESTADO_TITULO',   val.ATESTADO_TITULO)
              }
              else{//senao pega o basico que seria os dados do profissional
                 let ss = val.NOME +'\n'+
                          val.CNES_PF+'-'+val.UF + ': '+val.CRM + '\n'+
                          val.CATEGORIA

                 this.set_sessionStorage('getATESTADO_TITULO',   ss)
              }

              if (val.CIDADE_UF.length>0){ //se salvar endereço e cidade no cadastro do profissional
                    sessionStorage['LSCidade2']    = val.CIDADE_UF
                    sessionStorage['LSendereco2']  = val.ENDERECO+ '  '+val.CIDADE_UF 
                    sessionStorage['LSfones2']     = val.FONE2

              }else{
                    sessionStorage['LSCidade2']    = sessionStorage['LSCidade']
                    sessionStorage['LSendereco2']  = sessionStorage['LSendereco']
                    sessionStorage['LSfones2']     = sessionStorage['LSfones']
              }
          }

          
        
          this.carregar_preferencias(val.CODIGO)
          this.abre_agenda()
          this.abrir_espera(val.CODIGO)
        }
      },

      in_today(val){
        this.di = val;
        this.df = val;        
        // this.crud_read();
        this.set_sessionStorage('dia',this.in_today)

        // console.log('in_today');
        if (this.ja_horario_doc == false){//EXECUTO ISSO AQUI QUANDO FA O F5...ele pega as preferencias do doc, horarios e procedimentos
          this.carregar_preferencias(this.doc_codigo.CODIGO)
        }
        this.abre_agenda()
        // console.log('in_today wath');
        // this.alerta(val,"grey");
      },

      dialog (val) {
        val || this.close()
      },
      dialogDelete (val) {
        val || this.closeDelete()
      },
    },

    created () {
      this.onCreated()
    },

    mounted () {
       
      // this.signaturePad = new SignaturePad(this.$refs.canvas, {
      //    minWidth: 0.5, // Defina a largura mínima do traço para 2 pixels (ou qualquer valor desejado)
      //    penColor: 'blue'
      // });

      const dialog = document.getElementById('dialogProtocolo');
  if (dialog) {
    dialog.style.alignItems = 'flex-start';
    dialog.style.marginTop = '16px';
  }

       this.onResize()
      //  this.verificar_enviadosHERE('MED_PRINT',5) //vou deixar na janela de print mesmo
    },


    methods: {


      async clicou_em(acao, item){

          let sql = ``

          if (acao == 'remover_espera'){
            if (confirm(`Remover ${item.NOME} da Lista de Espera?`)){
               sql = `delete from espera where codigo = ${item.CODIGO}`
               await this.crud_sql(sql)
               await this.abrir_espera(this.doc_codigo.CODIGO)

            }
          }
      },

      async go_enviar_whats_manual(numero, texto, mobile){
         enviar_whats_manual(numero, texto, mobile)
      },

      async tem_duplicidade(data, hora, cod_doc){

        // data = '2024-09-18'
        // hora = '10:10:00'
        // cod_doc = '72'


        let sql = `SELECT COUNT(*)
                    FROM AGENDA
                   WHERE DATA = '${data}' AND HORA = '${hora}' AND AGENDA.CODIGOMEDICO = ${cod_doc} AND ATENDIDO = 'M'`

        let retorno = null

        // console.log('sql:', sql);
        
        
        if (retorno = await this.crud_abrir_tabela(sql, null)){
          //  console.log('retorno:', retorno[0]);

            // alert(retorno[0].COUNT)
            return (retorno[0].COUNT>0)
           
        }

        
      },

      propaganda_alerta(tipo){
        if (tipo == 'agendamento_online'){
          alert('Sua agenda online 24 horas, 7 dias por semana. Teste agora mesmo!')

          window.open('https://dsi.app.br/teste/i/#/agendamento', '_blank');

        }
      },

      monta_menu(){

        this.menu_header.push( { title: 'Imprimir', icon: 'mdi-printer ', icon_color: "blue", click: 'imprimir_lista' } )

        // if (this.obj_dados_empresa.AGENDAR_ONLINE=='S'){
          this.menu_header.push( { title: 'Agendamento Online', icon: 'mdi-calendar-account', icon_color: "blue", click: 'agenda_online' } )
        // }
      },

      clicou_menu(acao){

        if (acao == 'imprimir_lista'){
           this.imprimir_conteudoOLD(this.headers, this.desserts, `Agenda de ${this.getFormatDate(this.in_today)} \n ${this.doc_codigo.NOME}`, true, true )
        }
        
        else if (acao == 'agenda_online'){
          let route = this.$router.resolve({path: '/agendamento'});
          window.open(route.href, '_blank');
        }
      },

      mostrar_zap_tipo(){
        // Agenda, Virtual secretaria
        let rr =  (['A','V'].includes(this.zapzapTIPO))
        console.log('rr:', rr);
        return rr
        
      },

      preparar_comprovante(origem){
        
        this.items_protocolo = {}//liimpa

        let obs 

        if (this.mensagens_whats.MSG_OBS){
            obs = this.quebra_linha(this.mensagens_whats.MSG_OBS)
        }

        if (origem == 'menu'){
        
          this.$set(this.items_protocolo, 'NOME'          , this.item_principal.NOME_AGENDA)
          this.$set(this.items_protocolo, 'PROFISSIONAL'  , this.item_principal.PROFISSIONAL)
          this.$set(this.items_protocolo, 'PROCEDIMENTO'  , this.item_principal.PROCEDIMENTO)
          this.$set(this.items_protocolo, 'DATA'          , this.item_principal.x_DATA)
          this.$set(this.items_protocolo, 'HORA'          , this.item_principal.HORA)
          this.$set(this.items_protocolo, 'PROCESSO'      , this.item_principal.OBS)
          this.$set(this.items_protocolo, 'OBS'           , obs)
        }
        else if (origem == 'agendar'){

          let x_DATA = this.copia_editedItem.DATA
          x_DATA =   x_DATA.substring(8,10) + '/'+ x_DATA.substring(5,7)+ '/'+ x_DATA.substring(0,4);//formato a dd/mm/yyyy

          this.$set(this.items_protocolo, 'NOME'          , this.copia_editedItem.NOME)
          this.$set(this.items_protocolo, 'PROFISSIONAL'  , this.copia_editedItem.NOME_DOC)
          this.$set(this.items_protocolo, 'PROCEDIMENTO'  , this.copia_editedItem.PROCEDIMENTO)
          this.$set(this.items_protocolo, 'DATA'          , x_DATA)
          this.$set(this.items_protocolo, 'HORA'          , this.copia_editedItem.HORA)
          this.$set(this.items_protocolo, 'OBS'           , obs)


          setTimeout(function(){
            this.imprimir()
          }.bind(this), 222);

          setTimeout(function(){
            this.dialog_protocolo = false//oculta dialog
          }.bind(this), 999);
        }

        this.dialog_protocolo = true
      

      },

      imprimir(){
        
        this.vai_imprimir = true

        setTimeout(function(){
          window.print()
          this.vai_imprimir = false  
          
          // this.dialog_protocolo = false
        }.bind(this), 111);
      },

      detran_links(PROCESSO){

        // console.log('detra_links:');
        // console.log(item); 
                
        let  link = 'https://www.habilitacao.detran.pr.gov.br/detran-habilitacao/consultaProcesso.do?action=carregarPesquisaProcessoPorNumProcesso&numProcesso='+PROCESSO

        //  window.open(link,'_blank'); //windows.open deu erro..usei href mesmo
        return link
      },

      propaganda_zap(){
        window.open('https://www.youtube.com/watch?v=e428dP7k-xE', '_blank');
      },

      async filtra_valores(medico, procedimento, convenio){

         if (!procedimento){
          return 0
         }

         let array_valores = [];
         if (this.items_valores_procedimentos && this.items_valores_procedimentos.length > 0) {
            array_valores = this.items_valores_procedimentos.map((x) => x);
         }
         else{
          return 0
         }
         //  console.log('array_valoresA:', array_valores); 
        
        
          if (!Array.isArray(array_valores) || array_valores.length === 0) {
            throw new Error("O array de dados não está preenchido.");
            return 0
          }


          let array_valores_filtrado =  array_valores.filter(item =>
            item.CODIGO_MEDICO   === medico &&
            item.CODIGO_PRO      === procedimento &&
            item.CODIGO_CONVENIO === convenio
          );

          // console.log('array_valoresB:', array_valores_filtrado); 

          
          // caso ache retorna valor
          if (array_valores_filtrado.length > 0) {
              this.alerta(`R$ ${array_valores_filtrado[0].DOUBLE_UM} para o Convênio ${this.editedItem.CONVENIO}`,"pink")
              return array_valores_filtrado[0].DOUBLE_UM
          }
          else{
            // caso nao ache..procurar por todos sendo o convenio 0

            let array_valores_filtrado =  array_valores.filter(item =>
              item.CODIGO_MEDICO   === medico &&
              item.CODIGO_PRO      === procedimento &&
              item.CODIGO_CONVENIO === '0'
            );

            // console.log('array_valoresC:', array_valores_filtrado); 

            if (array_valores_filtrado.length > 0) {
              this.alerta(`R$ ${array_valores_filtrado[0].DOUBLE_UM} para Todos outros Convênios`,"pink")
              return array_valores_filtrado[0].DOUBLE_UM  
            }
            else{
              this.alerta(`Valor não definido`,"pink")
              return 0
            }
          }

          


          
          
          
      },
      
       retorna_cor(campo){

          if (campo == 'TOTAL'){
            return 'blue'
          }
          if (campo == 'EFETUADO'){
            return 'green'
          }
          if (campo == 'PENDENTE'){
            return 'red'
          }
          else{
            return 'grey'
          }

      },

      getFormatCurrency(v,string){
        if (v){
          return DSibasico.getFormatCurrency(v, string)
        }
        else{
          return v
        }
        
      },

      mostrar_impressao(){

        sessionStorage['printConteudo'] = ''
        sessionStorage['printRodape']   = ''
        sessionStorage['printTipo']     = 'Termos'
        sessionStorage['PrintPode']     = JSON.stringify(false)//somente para as meninas assinarem o termo..nao pode alterar

        // dados necessarios na tela
        // sessionStorage.setItem('obj_dados_paciente', JSON.stringify(row));//ja estou pegando quando clica no data-table
        sessionStorage['obj_dados_profissional'] = JSON.stringify(this.doc_codigo)
        
 
        setTimeout(function(){
          sessionStorage['PrintPode']     = JSON.stringify(true)//volta ao normal depois de 5 segundos
        }.bind(this), 5000);

        let route = this.$router.resolve({path: '/print'});
        window.open(route.href, '_blank');
      },

      clearSignature() {
        this.signaturePad.clear();
      },

      saveSignature() {
        // Verifique se há uma assinatura para salvar
        if (this.signaturePad.isEmpty()) {
          alert('Por favor, assine primeiro!');
          return;
        }
        
        // Obtenha a representação base64 da assinatura
        this.signatureDataURL = this.signaturePad.toDataURL();

        console.log( this.signaturePad.toDataURL());

        // Aqui você pode enviar this.signatureDataURL para o servidor ou fazer o que precisar com ela
        // Por exemplo, você pode criar um elemento de imagem e definir o src para this.signatureDataURL
        // Ou então, você pode enviar a representação base64 para o servidor para salvar em um banco de dados ou em um arquivo
      },


      fechar_dialog_pendentes(){

         if (this.checkbox_avisar_pendentes){
            localStorage['enviou_MED_PRINT'] = JSON.stringify(false)
         }

         this.dialog_pendentes=false
      },


      async verificar_enviadosHERE(tipo, minutos){

        try {
          let gg =  JSON.parse(localStorage['enviou_MED_PRINT'])
          if (gg){
            

              setTimeout(async function(){

                if (!this.zapzap){//se nao tiver o modulo ele para
                  return false
                }
                
                let rr 
                if(rr =  await verificar_enviados(tipo, minutos)){
                  this.array_ids_whats = rr

                  // alert(rr.length+' mensagens não foram enviadas!')

                  this.dialog_pendentes = true
                }

              }.bind(this), 5000);
          

          }
        }
        catch (e) { alert(e) }


     },

      async filtra_whats_miltiplos(){

        let doc = this.doc_codigo.CODIGO
        // alert(doc)

        // this.config_whats =  JSON.parse(sessionStorage['config_whats'])//estou pegando esses dados la no onCreate
        // console.log('config_whats:', this.config_whats);
        try {
           this.array_whats =  JSON.parse(sessionStorage['array_whats'])//estou pegando esses dados la no onCreate
        }
        catch (err) {
          console.log('NAO TEM ARRAY_WHATS');
          return false
        }
        
        // alert(this.array_whats.length)
        if(parseInt(this.array_whats.length) > 1){
           this.whats_multiplos = true
        }
        else{
          // alert(this.array_whats[0].ID_USER)
          if (this.array_whats[0].ID_USER > 0) {
            this.whats_multiplos = true
          }
          else{
            this.whats_multiplos = false
            // pega a configuracao pra todos
            this.config_whats =  this.array_whats[0]
            sessionStorage['config_whats']  =  JSON.stringify(this.array_whats[0])
          }

        }


        if (this.whats_multiplos){
          let h = this.array_whats.filter( x => x.ID_USER === doc );
          console.log('h:', h);

          if (h.length>0){
            this.config_whats = h[0]
            sessionStorage['config_whats']  =  JSON.stringify(h[0])
            return true
          }
          else{
            this.config_whats = {}
            sessionStorage['config_whats']  = ''
            return false
          }
        }
        else{
          return true
        }
      },

      async gerar_gerar(){

        let quem_alterou = await DSibasico.returna_quem_alterou()
        let item         = Object.assign({}, this.item_principal) 
        let valor        = item.VALOR
        valor            = DSibasico.strtofloat(valor)
        
        
        if (item.CODIGOCLIENTE<1){
          alert('É necessário Cadastrar este paciente antes de gerar o Lançamento!')
          return false
        }



        let v = parseFloat(item.VALOR)
        if (!v){
          alert('Defina um Valor Válido!')
          return false
        }


        let sql = `insert into LANCAMENTOS 
        (
          CODIGO_CLIENTE,
          CODIGO_PROCED,
          CODIGO_CONSULTA,
          CODIGO_PROFISSIONAL,
          CODIGO_CONVENIO,

          COD_USER_RECEBEU,
          COD_USER_LANCOU,

          TIPO,
          EFETUADO,
          VISTO,
          DATA,
          DATA_LANCAMENTO,
          DATA_EFETUADO,

          R_RECEBEU,
          ALTEROU,
          LANCOU,
          CATEGORIA,
          PROFISSIONAL,
          VALOR_UM,
          FORMA_PAGAMENTO,

          OPERACAO,
          DESCRICAO,
          DESC_PROCEDIMENTO,
          SEQUENCIA

          
        ) 
         values
        (
          ${item.CODIGOCLIENTE},
          ${item.CODIGOPROCEDIMENTO},
          ${item.CODIGOCONSULTA},
          ${item.CODIGOMEDICO},
          ${item.CODIGOCONVENIO},

          ${this.obj_user.CODIGO},
          ${this.obj_user.CODIGO},

          'E',
          'S',
          'N',
          '${item.DATA}',
          '${this.today}',
          '${item.DATA}',

          '${quem_alterou}',
          '${quem_alterou}',
          '${quem_alterou}',
          '${item.PROCEDIMENTO}',
          '${item.PROFISSIONAL}',
          '${valor}',
          '${item.FORMA_PAGAMENTO}',

          'AGENDA',
          '${item.NOME}',
          '${item.PROCEDIMENTO}',
          '1'
        )`

        // console.log(sql);
        if (await this.crud_sql(sql)){

          sql = `update agenda set EFETUADO = 'S' where CODIGO = ${item.CODIGO}`
          if (await this.crud_sql(sql)){
          
            // atualizo
            let index = this.desserts.findIndex( x => x.CODIGO === item.CODIGO );
            this.$set(item, 'EFETUADO', 'S')
            Object.assign(this.desserts[index], item)


            this.$set(this.item_principal, 'EFETUADO', 'S')
            
          }
        }




      },

      filtrar_search(valor, item_name, campo){ 

        // const resultado = this.items_niver.filter(item => {
        // return item.toLowerCase().includes(nome.toLowerCase());
        // })

        // this.items_niver = this.items_niver_clone.map((x) => x); //clonando array copiar array          
        this[item_name] = this[item_name+'_clone'].map((x) => x); //clonando array copiar array          

        if (valor){
          // const resultado = this.items_niver.filter(elemento => elemento.NOME.toLowerCase().includes(valor.toLowerCase()));
          const resultado = this[item_name].filter(elemento => elemento[campo].toLowerCase().includes(valor.toLowerCase()));
          this[item_name] = resultado.map((x) => x); //clonando array copiar array          
        }
        else{
          // this.abrir_niver()//abre geral
        }
      },

      async before_enviar_whats(item, tipo){
        
        // console.log('item:', item);

        let empresa = sessionStorage['LSEmpresa']
        let quebra = '%0A'
        let quebra2 = '%0A%0A'
        let msg  
 
        if (tipo == 'NIVER'){

           let nome = this.primeiro_nome(item.NOME)
           msg = `
             Olá *${nome}*, gostaríamos de lhe desejar um 🎉 *Feliz Aniversário* 🎊 e que você aproveite muito bem este dia tão especial! 🥳`+ 
             `${quebra+quebra}São os votos do *${empresa}*`
             
 
           msg = `🎉 *Feliz Aniversário, ${nome}* 🎊`+ quebra2+
 
                 `Gostaríamos de lhe desejar um ano repleto de saúde, alegrias e conquistas.`+quebra2+
 
                 `*Aproveite muito bem o seu dia!*`+quebra2+
 
                 `Grande Abraço, `+quebra+ 
                 `*${empresa}*`
 
                 
             this.enviar_whats_manual(item, msg)
 
            //  await registra_log('WHATSAPP', `ENVIOU WHATS NIVER para ${item.NOME}`, item.CODIGO_ALUNO)
 
            //  await this.abrir_logs(this.in_today)//atualiza historico
        }
      },
 
      enviar_whats_manual(item, msg){
 
        DSibasico.send_whats(item.CELULAR, item.NOME, item.CODIGO_ALUNO, msg)
     },

      async abrir_espera(doc){

        let sql = 
        `
        SELECT * FROM ESPERA WHERE ESPERA.CODIGO_MEDICO = ${doc}
        `
        // console.log('sql:', sql);
        if (await this.crud_abrir_tabela(sql, 'items_espera')){
          this.items_espera_clone = this.items_espera.map((x) => x); //clonando array copiar array 
        }

      },

      async abrir_niver(){

        
        // alert('this.value_expansion:'+ this.value_expansion);

        // if (this.value_expansion ==''){ 
        //   alert('opsss')
        //     return false//so executa se estiver aberto
        // }


        let sql = 
        `
        SELECT DISTINCT
            CLIENTES.NOME,
            ' hoje' AS TIPO,
            EXTRACT(MONTH FROM CLIENTES.DATANASCIMENTO) AS MES,
            EXTRACT(DAY FROM CLIENTES.DATANASCIMENTO) AS DIA,
            CLIENTES.DATANASCIMENTO,
            CLIENTES.CODIGOCLIENTE,
            CLIENTES.CELULAR,
            DATEDIFF(year, CLIENTES.DATANASCIMENTO, CURRENT_DATE) AS IDADE
        FROM
            CLIENTES
        WHERE
            (CLIENTES.ATIVO = 'S')AND
            (CLIENTES.CELULAR is not null)AND
            EXTRACT(DAY FROM CLIENTES.DATANASCIMENTO) = EXTRACT(DAY FROM CURRENT_DATE) AND
            EXTRACT(MONTH FROM CLIENTES.DATANASCIMENTO) = EXTRACT(MONTH FROM CURRENT_DATE)
        ORDER BY
            NOME
        `
        // console.log('estatisticas_formas:', sql);
        if (await this.crud_abrir_tabela(sql, 'items_niver')){
          this.items_niver_clone = this.items_niver.map((x) => x); //clonando array copiar array 
        }

      },


      copy_string(entra, a,b){
        if (entra){
          return entra = entra.substring(a,b)
        }
        else{
          return entra
        }
      },

      async filtra_agora(item, hora){

        if(hora.length == 5){
          hora = hora+':00'
        }

        const filtrado =  item.filter(x => x.HORA == hora);
        // console.log('filtra_agora', filtrado);
      
        if (filtrado.length>0){
          this.selected = []
          this.selected.push( { CODIGOCONSULTA : filtrado[0].CODIGOCONSULTA}  )
        }
      },

      primeiro_nome(nome){

        if (!nome){ return nome}

        nome = nome.split(" ");//separo as palavras
        nome = nome[0];//pego o primeiro nome   

        return nome
      },

      copiar_agendamento(item, bloquear){

        this.item_copiar_agendamento = Object.assign({}, item) 

        let esse = { "sigla" : "C", "text" : "Cancelado"}

        if (bloquear){
          this.questiona_motivo(esse) 
        }

        this.alerta("Selecione o novo horário e clique em Remarcar","pink")

      },

      colar_agendamento(){
        
        let copia = this.item_copiar_agendamento

        if (!copia){
          return false
        }
        
        // console.log('copia:', copia);

        this.dialog_paciente  = false
        this.pre_cadastro     = false
        this.bloqueando       = false

        this.items_selecionados.cod_paciente         = copia.CODIGOCLIENTE
        this.items_selecionados.nome_paciente        = copia.NOME
        this.items_selecionados.convenio             = copia.CODIGOCONVENIO
        this.items_selecionados.cod_proced           = copia.CODIGOPROCEDIMENTO

        this.$set(this.editedItem, 'CODIGOCLIENTE',   copia.CODIGOCLIENTE);//defino valor manualmente
        this.$set(this.editedItem, 'NOME',            copia.NOME);//defino valor manualmente

        this.$set(this.editedItem, 'CODIGOCONVENIO',  copia.CODIGOCONVENIO)
        this.$set(this.editedItem, 'CONVENIO',        copia.CONVENIO)
        
        this.$set(this.editedItem, 'CODIGOPROCEDIMENTO',  copia.CODIGOPROCEDIMENTO)
        this.$set(this.editedItem, 'PROCEDIMENTO',        copia.PROCEDIMENTO)




        if (this.editedItem.HORA == 'vini'){
          this.dialog_agendar = true //abre dialog para digitar a hora
          this.alerta("Digite o Horário","pink")

        }
        else{
          this.agendar_create()
        }

        this.item_copiar_agendamento = null

      },

      questiona_motivo(item){
        
        // console.log('questiona_motivo:', item);
        this.dialog_editar = false

        //salvo para ser usado depois de pegar o motivo da falta ou cancelamento
        this.item_clicou_menu_definir = Object.assign({}, item) 

        let acao = item.sigla
        let msg = ''
        
        if (acao == 'F'){
          msg = 'Definir Faltou '
        }
        else if (acao == 'C'){
          msg = 'Cancelar Agendamento'
        }

        // ABRE JANELA PARA PEGAR O MOTIVO DA FALTA OU CANCELAMENTO
        this.abre_janela_modal(true, true, 'CANCELAR_AGENDA', msg)
      },

      abre_janela_modal(status, edicao, tabela, titulo){
        
        this.janela_modal_tabela   = tabela
        this.janela_modal_titulo   = titulo
        this.janela_modo_edicao    = edicao
        this.janela_modal_abre     = status

      },

      janela_modal_change(valor){

        this.janela_modal_abre = valor

        let tabela = this.janela_modal_tabela
        if (!valor){

          if (tabela == 'ESPERA' ){
              if (this.doc_codigo.CODIGO>0){
                this.abrir_espera(this.doc_codigo.CODIGO)
              }
          }

          else if (tabela == 'CANCELAR_AGENDA' ){
             if (sessionStorage['obj_janela_editar'] ){
                // alert('deu ok')

                let retorno = JSON.parse(sessionStorage['obj_janela_editar'])
                // console.log('retorno:', retorno);
                this.definir(this.item_principal, this.item_clicou_menu_definir, retorno.MOTIVO )
             }
             else{
              // alert(false)
             }
          }
        }
      },

      muda_aberto_modal(valor){
        this.janela_modal = valor

        if (!valor){
          // alert('atualiza')
        }
      },

      async formata_datas(entra,tipo){
        
        if (tipo == 1){

          // data formato firebird
          if (entra?.includes('/')){
            var partes = entra.split('/');

            // Reorganizar as partes para o formato yyyy-mm-dd
            var fechaTransformada = partes[2] + '-' + partes[1] + '-' + partes[0];

            return fechaTransformada;
          }
          else{
            return entra
          }
        }
        else if (tipo == 2){
          //celular
          // alert(entra?.length)
          if (entra?.length == 9){
              // 999685172
              entra = entra.substring(0,5) +'-'+entra.substring(5,10)
              return entra
          }
          else if (entra?.length == 11){//com ddd
              // 44999685172
              entra = entra.substring(2,7) +'-'+entra.substring(7,11)//pego sem o ddd
              return entra
          }
          else if (entra?.length == 12){//sem digito 9
              // 554499685172
              entra =  '9'+entra.substring(4,8) +'-'+entra.substring(8,13)//pego sem o ddd
              return entra
          }
          else if (entra?.length == 13){//com ddi
              // 5544999685172
              entra = entra.substring(4,9) +'-'+entra.substring(9,13)//pego sem o ddd
              return entra
          }
          else{
            return entra
          }
        }
        
      },

      quebra_linha(msg){

        if (msg){
          msg = msg.replaceAll('\n', '<br/>')
        }

        return msg
      },

      async horarios_cancelados_open(doc, dia){
        
        let sql = `
          SELECT
            AGENDA.DATA,
            AGENDA.HORA,
            AGENDA.LOG_RECEPCAO,
            AGENDA.REGISTRO,
            LEFT(AGENDA.NOME, 20) AS NOME,
            AGENDA.CODIGOCLIENTE,
            AGENDA.FONE
          FROM
          AGENDA
          WHERE
            (AGENDA.CODIGOMEDICO = ${doc}) AND
            (AGENDA.DATA BETWEEN '${dia}' AND '${dia}') AND
            (AGENDA.TIPO = 'A') AND
          ( (AGENDA.ATENDIDO = 'C')OR
            (AGENDA.ATENDIDO = 'D') )
          ORDER BY
            DATA,
            HORA

        `
        // console.log(sql);
        await this.crud_abrir_tabela(sql,'horarios_cancelados_lista')

      },

      informacao(tipo){
        if (tipo == 'WHATS_OBS_PROCED'){
          alert('Para Alterar esta Nota, entre na lista de procedimento deste profissional, alterar o campo "Nota via WhatsApp"')
        }
      },

      mostrar_propaganda(tipo){

         let titulo = document.title.toLowerCase()

         if (tipo == 'online'){

            // if( (titulo.includes('odontorriso')) ){
            if( (titulo.includes('amicis')) || (titulo.includes('odontorriso')) ){
              return false
            }
            else{
              return true
            } 

         }
      },

      abre_img(titulo, url_img, mais){
        this.dialog_img        = true
        this.dialog_img_titulo = titulo
        this.dialog_img_url    = url_img
        this.dialog_img_mais   = mais
      },

      saiba_mais_click(acao){

         let cel = '4491769097'
         let msg = ''
         if (acao == 'online'){
            msg = `Olá DSi, gostaria de mais informações sobre o *Módulo Agendamento Online*`
         }
         else if (acao == 'zap'){
            msg = `Olá DSi, gostaria de mais informações sobre o *Módulo WhatsApp*`
         }


         if (!msg){
            return false
         }

         if (this.isMobile){
            window.open("https://api.whatsapp.com/send?phone=55"+ cel +'&text='+ msg);
          }else{
            window.open("https://web.whatsapp.com/send?phone=55"+ cel +'&text='+ msg);
          }
      },


      pergunta_aviso(item){
        if (confirm("Enviar Aviso?")){
          this.send_whats('aviso',(item.CELULAR)? item.CELULAR : item.FONE , item.NOME, item,null, true)
        }
      },

      esta_empresa(nome){
        // verifico o nome da empresa e se mostro ou nao algumas funcoes..exemplo Artmed
        nome   = nome.toLowerCase()
        let titulo = document.title.toLowerCase()
        
        if (titulo.includes(nome)){
          return true
        }else{
          return false
        } 
      },

      modo_dev(){
        if (sessionStorage['url'] == '/api/'){
            // url =  `http://localhost/vue_cfc/public/`
            // console.log('dev true');
            return true
          }
          else{
            // url =  `https://dsi.app.br/f/c/`
            // console.log('dev false');

            return false
        }
      },

      async comAtraso(){

         setTimeout(function(){            
          this.status_zap()
        }.bind(this),  (!sessionStorage['config_whats']) ? 6000 : 600); //deixar em 6000 pq ele esta sendo lido la em app.vue..somente a 1vez
      },

      change_expansion(index){

        // if (index >= 0){
          if ( (this.expansion_selecionao == 'ESTATISTICAS') || (this.expansion_selecionao == 'ESTATISTICAS_WHATS')){
            this.abrir_estatisticas(this.doc_codigo.CODIGO, this.in_today, this.in_today)//abre estatisticas
          }
        // }



        let acao = this.expansion_selecionao
        
        if (index){ //se aberto
            if (acao == 'LANCAMENTOS'){
              this.abrir_lancamentos(this.doc_codigo.CODIGO, this.in_today, this.in_today)
            }
        }

      },

      change_duracao(){
        let mais = DSibasico.incMinutes(this.editedItem.HORA, this.editedItem.DURACAO )
        this.$set(this.editedItem, 'HORA_ATE', mais);//defino valor manualmente
      },

      change_hora_ate(){
        let duracao = DSibasico.difMinutes(this.editedItem.HORA, this.editedItem.HORA_ATE)
        this.$set(this.editedItem, 'DURACAO', duracao)              
      },

      muda_aberto_modal(valor){
        this.janela_modal = valor
      },


      modal_salvar(valor, tabela){
       this.modal_salvou = valor
       this.modal_qual   = tabela
      },


      async abrir_janela_modal(item, acao, pro_tag){


        // console.log('abrir_janela_modal:', item);

        this.janela_modal   = true
        this.pro_tag        = pro_tag

        if (acao == 'editar'){
          this.obj_janela_modal  = item
        }
        else if (acao == 'novo'){
          this.obj_janela_modal  = null
        }

      },

      changeSUB_DOC(codigo){

        console.log('changeSUB_DOC:', codigo);

        //acha determinado valor no array de objeto com base no campo codigo
        let acha =  this.profissionais_sub.filter(x => x.CODIGO === codigo); //clonando array copiar array
        acha = acha[0]
        // console.log('acha:', acha.NOME);

        if (codigo==0){
          this.$set(this.editedItem, 'SUB_DOC_NOME', '')              
        }else{
          this.$set(this.editedItem, 'SUB_DOC_NOME', acha.NOME)        
        }

      },

      async onCreated(){


        await this.logado()
        this.showDrawer()
        this.update_values();
        // this.set_defaults();
        
        await this.ler_sessions()
        await this.crud_empresa()

        this.set_defaults();
        
        await this.GetPermissoes()

        await this.abre_profissionais();
        await this.lista_convenios()
        await this.lista_valores_procedimentos()
        await this.monta_headers()

        await this.comAtraso()

        this.monta_menu()


      },

      async imprimir_conteudoOLD(headers, items, titulo, ocultar_header, auto_print){

        // DSibasico.setPrintStyle('portrait')

        let componente = ''

        if (items.constructor === Array){
          componente = 'v-data-table'
          headers = JSON.stringify(headers)
          items = JSON.stringify(items)
        }
        else{
          componente = 'v-textarea'
        }


        sessionStorage['rel.qual']         = 'AUTO'
        sessionStorage['rel.componente']   = componente
        sessionStorage['rel.headers']      = headers
        sessionStorage['rel.items']        = items
        sessionStorage['rel.titulo']       = titulo

        sessionStorage['rel.auto_print']      = (auto_print) ? 'S' : 'N'
            sessionStorage['rel_ocultar_header']  = (ocultar_header) ? 'S' : 'N'

        //  this.$router.push('/relatorios')//redireciono para a pagina do router
        let route = this.$router.resolve({path: '/relatorios'});
        window.open(route.href, '_blank');
      },


      async status_zap(acao){


        let ide = sessionStorage['LSid']
        let token = parseInt(ide * 777888999)

        // busca as configuracoes do zapi do session...veio de App.vue
        // alert(sessionStorage['config_whats'])

        // if (!sessionStorage['config_whats']){
        //   return false
        // }


  
        if(!await this.filtra_whats_miltiplos() ){
          this.zapzap = false
          return false
        }


        

        // ZAPZAP tirar isso depios de pronto       
        this.zapzap =   ((this.config_whats) && (this.config_whats.ATIVO != 'CANCELADO'))
        this.zapzapSITUACAO =   this.config_whats.SITUACAO
        this.zapzapTIPO     =   this.config_whats.SALVAR_WEBHOOK
        if (this.zapzap){

          // verifica se é Agenda ou Secretaria Virtual, caso contrario nao mostra o icone de enviar whats
          // this.mostrar_zap_tipo()

          // this.zapzap = (sessionStorage['superA']=='S') || (sessionStorage['lg:USUARIO'] == 'LAIS')
        }


        sessionStorage['zapzap_mostrar']    =  this.zapzap
        sessionStorage['zapzap_conectado']  =  false

        // return 'exit'
        // console.log('saldo_asaas:', this.config_emp);
        let url = ''

        
        if ((this.config_whats.SITUACAO != 'CANCELADO') && (this.config_whats)){


          let id_user = this.config_whats.ID_USER
          if (id_user>0){
            token = token +'i'+id_user
            console.log('id_user:', id_user);
            console.log('novo token:', token);
          }
          
          if (this.config_whats.API_LINK){
          

              url  = this.config_whats.API_LINK
              url = url?.replaceAll('send-messages','status') //removo caracter especial
              url = url?.replaceAll('@token', token) //removo caracter especial
              console.log(url);
              
              
              // const header =   { headers: {'Content-Type': 'application/json'}}//TEM QUE USAR O json_decode NO PHP que vai receber

              let retorno = ''
              try {

                  const data = {
                    url:  url,
                  }  

                  // console.log(data);
                  // const header =   { headers: {'Content-Type': 'multipart/form-data'}}
                  const header =   {}

                  const res = await axios.post(URL_API+'getQRCode.php', data, header);

                  retorno = JSON.parse(res.data);
                  // console.log(retorno);

                // res = await axios.get(url, header) //ORIGINAL ZAPI
              } catch (err) {
                  // Handle error
                  // console.log('status_zap: ', err);
                  // this.zap_message = err.message
                  // o programa pode nao estar rodando la no servidor
                  this.zap_message = 'Atualizando Servidor, tente novamente mais tarde'
                  return err.message
              }
            

              this.zap_status     = retorno.connected
              sessionStorage['zapzap_conectado']  =  retorno.connected
          
              if (retorno.error){
                this.zap_message = retorno.error
              }

          }

          if (acao == 'qrcode'){

              // somente super A pode ler codigo.. caso de secretaria virtual..depois tem que configurar isso
              // if (!this.superA){
              if (!this.user_permissoes.superUser){
                // alert('Somente o Administrador poderá conectar o WhatsApp')
                // return false
              }

              url  = this.config_whats.API_LINK
              url = url?.replaceAll('/send-messages','') //removo caracter especial
              url = url?.replaceAll('@token', token) //removo caracter especial

              window.open(url, '_blank');


              // defino um timer para atualizar se conectou ou nao o zap
              // setTimeout(function(){
              //   this.status_zap()
              // }.bind(this), 60000); //1minuo
              
              this.dialog_qrcode = true

              return false
              
              this.url_externo = url //vai abrir no iframe
              this.dialog_qrcode = true

              // window.open(url, '_blank'); //melhor abrir no ifrmae
            

              // aqui somente se fosse z-api
              // const header =   { headers: {'Content-Type': 'application/json'}}//TEM QUE USAR O json_decode NO PHP que vai receber
              // const res = await axios.get(url, header);//tem que ter 3 parametros
              // console.log(res.data);

              // if (res.data.value){
              //   this.img_qrcode_zap = res.data.value
              //   this.dialog_qrcode = true
              // }

            
          }
        }

      },

      async grafico_whats(di, df){


        return 'exit'

        let mes = di.substring(5, 7)
        let ano = di.substring(0, 4)
        let diasMes = 0
        diasMes =  new Date(ano, mes, 0).getDate();//pega o numero de dias do mes

        di = ano+'-'+mes+'-01'
        df = ano+'-'+mes+'-'+diasMes


        let sql = 

        `SELECT * FROM (

                              
            SELECT
            
            AGENDA.DATA,


            COUNT(CASE
            WHEN (AGENDA.AVISOU_WHATS IS NOT NULL)AND
            (AGENDA.OK_VIA_WHATS = 'S') THEN
            AGENDA.CODIGO
            END)
            AS QT,
            'CONFIRMADOS' AS PROCEDIMENTO

            FROM
            AGENDA
            WHERE (AGENDA.DATA BETWEEN '${di}' AND '${df}')
            GROUP BY
            AGENDA.DATA

            UNION



            SELECT
            AGENDA.DATA,

            COUNT(CASE
            WHEN (AGENDA.AVISOU_WHATS IS NOT NULL)AND
            (AGENDA.AVISOU_WHATS NOT IN ('X','!')) THEN
            AGENDA.CODIGO
            END)
            AS QT,
            'ENVIADOS' AS PROCEDIMENTO

            FROM
            AGENDA
            WHERE (AGENDA.DATA BETWEEN '${di}' AND '${df}')
            GROUP BY
            AGENDA.DATA
            )

            ORDER BY
            DATA`

          console.log(sql);
          let g1 = []
          let g2 = []
          if (await this.crud_abrir_tabela(sql,'grafico1')){

            for(let i = 0; i< this.grafico1.length; i++){
              if (this.grafico1[i].PROCEDIMENTO.includes('ENVIADOS')){
                g1.push(parseInt(this.grafico1[i].QT))
              }
              else if (this.grafico1[i].PROCEDIMENTO.includes('CONFIRMADOS')){
                g2.push(parseInt(this.grafico1[i].QT))
              }

              // g1.push(parseInt(this.grafico1[i].QT))

            }
            this.grafico1 = g1
            this.grafico2 = g2
            console.log(g1);
            console.log(g2);
          }    



      },

      async monta_headers(){

          let s = []

          s.push(
            { text: 'Hora', value: 'HORA',width:'5px' },
            // { text: '..', value: 'x',width:'10px'  },
            { text: 'Nome', value: 'NOME',width:'450px'},
            { text: 'Atender', value: 'atender', sortable: false },
            // { text: 'Celular', value: 'CELULAR', width:'110px' },
            { text: 'Convênio', value: 'CONVENIO', width:'140px' },
            { text: 'Procedimento', value: 'PROCEDIMENTO', width:'190px' },
            { text: 'Valor', value: 'VALOR' },
            { text: 'Forma', value: 'FORMA_PAGAMENTO' },
            { text: 'EF.', value: 'EFETUADO', visibility: 'true' },
            { text: 'Chegou', value: 'HORA_CHEGADA', visibility: 'true' },
            { text: 'Contato', value: 'CELULAR', visibility: 'true', width: '110px' },
            

            // { text: 'Avaliação', value: 'ENVIOU_AVISO' },
            // { text: 'SUB_DOC', value: 'SUB_DOC' },
            // { text: 'Anotação', value: 'OBS' },
            // { text: 'CS', value: 'CODIGOCONSULTA' },
            // { text: 'avisou whats', value: 'AVISOU_WHATS' },
            // { text: 'CODIGO', value: 'CODIGO' },
            // { text: 'CODIGO', value: 'ATENDIDO' },
            // { text: 'Doc', value: 'CODIGOMEDICO' },
            // { text: 'IDE', value: 'CODIGOCLIENTE' },
            // { text: 'HORA_CHEGADA', value: 'HORA_CHEGADA' },
            // { text: 'DURACAO', value: 'DURACAO' },
            // { text: 'HORA_ATE', value: 'HORA_ATE' },
          )


          if (this.doc_codigo.CODIGO == 0){
            s.push(
              { text: 'Profissional', value: 'PROFISSIONAL', width:'150px' },
              // { text: 'WHATS_ID', value: 'WHATS_ID', width:'60px' },
            )
          }


          if (this.superA){
            s.push(
              { text: 'AVISOU_WHATS', value: 'AVISOU_WHATS', width:'60px' },
              { text: 'WHATS_ID', value: 'WHATS_ID', width:'60px' },
              { text: 'Sit', value: 'ATENDIDO' },
              { text: 'OK_VIA_WHATS', value: 'OK_VIA_WHATS' },
            )
          }

          this.headers = s
      },

      meu_url(){
          
          let url = ''

          if (sessionStorage['url'] == '/api/'){
            url =  `http://localhost/vue_cfc/public/`
          }
          else{
            url =  `https://dsi.app.br/f/c/`
          }

          return url
      },

      async envia_simples_whats(celular, msg){


          if ((!celular)||(!msg)){
            return 'exit-celular ou msg não definidos'
          }

          let url = this.meu_url()+`clientes/envia_whats.php`


          // pega dados do zap
          let config_whats = JSON.parse(sessionStorage['config_whats'])//busco objeto no sessionStorage
          // console.log('config_whats:', config_whats);
          let ide = sessionStorage['LSid']
          let token = parseInt(ide * 777888999)

          let id_user = this.config_whats.ID_USER
          if (id_user>0){
            token = token +'i'+id_user
            console.log('id_user2:', id_user);
            console.log('novo token2:', token);
          }

          let api_url   = ''
          api_url       = config_whats.API_LINK
          api_url       = api_url?.replaceAll('@token', token) //removo caracter especial


          const data = {
                            
                acao            : 'ENVIA_SIMPLES',
                api_url         : api_url,
                tipo            : 'ENVIA_SIMPLES',
                id_cliente      : sessionStorage['LSid'], 
                empresa         : sessionStorage['LSEmpresa'], 
                id_dsi          : 0, 
                usuario         : sessionStorage['lg:USUARIO'], 
                celular         : celular, 
                msg             : msg
          } 

          // console.log(data);
          const header = { headers: {'Content-Type': 'application/x-www-form-urlencoded'}}//so funciona com isso daqui
          const res    = await axios.post(url, data, header);//envia 
          let retorno  = (res.data);

          // console.log('envia_simples_whats:', retorno);
          // let retorno_obj = JSON.parse(retorno);

      },

      async crud_abrir_tabela_45(data){

        let url = this.meu_url()+`clientes/canal.php`
        

        const header = { headers: {'Content-Type': 'multipart/form-data'}}//so funciona com isso daqui
        const res    = await axios.post(url, data, header);
        let retorno  = (res.data);
        // console.log('trata_retornoA:', retorno);

        //pego o erro que nao conseugi tirar dessa bosta.. to dando um stringreplace nele kkkkkkk dai mudo o retonro para um objejto
        let erro = `<b>Warning</b>:  Missing boundary in multipart/form-data POST data in <b>Unknown</b> on line <b>0</b><br />`
        retorno = retorno.replaceAll(erro,'')
        retorno = retorno.replaceAll(`<br />`,'')

        let retorno_obj = JSON.parse(retorno);
        console.log('trata_retornoB:', retorno_obj);

        return retorno_obj
      },

      async crud_sql_45(sql){

        // console.log('crud_sql_45:', sql);

        let url = ''
        if (sessionStorage['url'] == '/api/'){
          url = `http://localhost/f/c/clientes/canal.php`
        }
        else{
          url = `https://dsi.app.br/f/c/clientes/canal.php`
        }

        const data = {
                    acao   : '6',
                    meuSQL : sql,
                  } 

        const header = { headers: {'Content-Type': 'multipart/form-data'}}//so funciona com isso daqui
        const res    = await axios.post(url, data, header);
        let retorno  = (res.data);

        //pego o erro que nao conseugi tirar dessa bosta.. to dando um stringreplace nele kkkkkkk dai mudo o retonro para um objejto
        let erro = `<b>Warning</b>:  Missing boundary in multipart/form-data POST data in <b>Unknown</b> on line <b>0</b><br />`
        retorno = retorno.replaceAll(erro,'')
        retorno = retorno.replaceAll(`<br />`,'')
      
        // console.log(retorno);
        return retorno
      },

      // zapzap trata retorno do webhook do whats
      async trata_retorno_webhook(){


        var expirar  = new Date();
        expirar.setMinutes(expirar.getMinutes() + 1);
        // expirar.setSeconds(expirar.getSeconds() + 5);
        sessionStorage['zazap_time']  =  expirar 


        // busco no dsi o webhook
        // busco com ID<>0 para pegar somente a resposta da ultima msg enviada..caso ja tenha respondido ai o ID = 0 
        let sql = `SELECT * FROM tabela_webhooks WHERE (VISTO = 'N') AND (ID <> '0') AND (CODIGO_CLIENTE = ${sessionStorage['emp_ide']})`
       console.log('sql:', sql);
        const data = {
                    acao   : '5',
                    meuSQL : sql,
                  }  

        let verRespostasOK = ['1','digite 1', 'confirmar','ok', 'pode confirmar','pode confirma','pode confirmar sim', 'sim pode confirmar','confirmado','confirmo','confirmei','confirma', 'vou', 'sim', 'vou sim', 'sim bom dia','confirma pra mim']
        let verRespostasCANCEL = ['9']//deixei so o 9 pq tem clientes que nao querem enviar cancelar na msg
        // let verRespostasCANCEL = ['9','digite 9','cancelar', 'nao', 'não','pode cancelar','quero cancelar', 'nao vou', 'não vou']
        let retorno_obj  = {}
        if ( retorno_obj = await this.crud_abrir_tabela_45(data)){


          // caso tenha algo...
          if (retorno_obj.length>0){

            for(let i = 0; i< retorno_obj.length; i++){
              
                let linha    = retorno_obj[i]
                let conteudo    = retorno_obj[i]
                // let conteudo = JSON.parse(linha.CONTEUDO)

                  if (linha.TIPO == "DeliveryCallback"){//estatus das mensagens enviadas

                    if (conteudo.error){//deu erro ao enviar msg salvo aqui o erro
                      // console.log(conteudo);
                      sql = `update agenda set agenda.AVISOU_WHATS = 'X', WHATS_RETORNO = '${conteudo.error} - ${conteudo.phone}' where  WHATS_ID ='${conteudo.messageId}'`
                      await this.crud_sql(sql)
                    }
                    else{
                      // console.log('sem erro..entao msg foi enviada');
                    }
                    await this.crud_sql_45(`update tabela_webhooks set VISTO = 'S' WHERE (VISTO = 'N')AND (CODIGO = ${linha.CODIGO})`)// VISTO LA NO DSI
                  }

                  // else if (linha.TIPO == "ReceivedCallback"){//respostas das mensagens enviadas
                  else if (linha.TIPO == "CONFIRMAR_AGENDA"){//respostas das mensagens enviadas
                      // console.log(conteudo);
                      if (conteudo.RESPOSTA){

                        let resposta  =  conteudo.RESPOSTA.toLowerCase()
                        resposta      = resposta.trim()
                        let atendido  = ''
                        let sql2      = ''
                        let responder = ''

                        // verifica das respostas
                        if (resposta == 'parar'){
                          //  console.log('conteudo:', conteudo);
                           sql = `update agenda set agenda.AVISOU_WHATS = 'X', WHATS_RETORNO = 'Digitou PARAR de receber avisos' where  WHATS_ID ='${conteudo.ID}'`
                           await this.crud_sql(sql)
                        }
                        else if (verRespostasOK.indexOf(resposta) !== -1){//se conter resposta ok

                           atendido = 'O'
                         
                           let respota_confirmado = ``
                           if (this.obj_dados_empresa.IDE_PROGRAMA == '150'){//cliniman
                              
responder = `*Seu agendamento está confirmado!* ✅
Para garantirmos sua melhor experiência conosco, pedimos por gentileza que se atente a algumas recomendações: 
       ❗️Chegue no horário marcado.
       ❗️Será permitido a presença de apenas *um* acompanhante.
       ⁠❗️Não se esqueça de trazer seus documentos pessoais. 

Será uma alegria receber você! 
*Equipe Cliniman* %F0%9F%8C%B7 \n\n`

                           }
                           else{
                             responder = "Seu *agendamento* esta *Confirmado*!\nEstaremos aguardando por você. @;u \n\n*(Mensagem automática)*"
                           }
                           
                        }
                        else if (verRespostasCANCEL.indexOf(resposta) !== -1){//se conter resposta not
                           atendido = 'C' //F ou C
                           sql2     = `,HORAF = 'now', REGISTRO = 'Cancelado via WhatsApp' `
                           responder = "Olá, informamos que seu agendamento foi *Cancelado*.\nCaso deseje *remarcar*, por favor, entre em contato conosco pelo WhatsApp ou telefone.\n\n*(Mensagem automática)*";
                        }


                        if (atendido){

                            sql = `update agenda set agenda.OK_VIA_WHATS = 'S', ATENDIDO = '${atendido}' ${sql2}  where  (WHATS_ID ='${conteudo.ID}')` 
                            // and (OK_VIA_WHATS <>'S')`
                            // console.log(sql);
                            await this.crud_sql(sql)

                            // da pra atualizar a foto do cliente vinda do whats
                            if (conteudo.URL_FOTO){
                              sql = `update clientes set FOTO_URL = '${conteudo.URL_FOTO}'  
                                    where clientes.codigocliente = (select agenda.codigocliente from agenda where agenda.WHATS_ID = '${conteudo.ID}')`
                              
                              console.log('sql:', sql);       
                              await this.crud_sql(sql)
                            }


                            // salvo la no dsi que ja respondeu === AGORA FAÇO ISSO DIRETO PELO APP.JS DO WHATS..ELE JA DA O UPDATE LA MESMO
                            await this.crud_sql_45(`UPDATE tabela_api_whats set RESPONDEU = 'S' WHERE (WHATS_ID_DSI = '${conteudo.ID}')`)


                            // MANDA RESPOSTA PARA O ZAP DO PACIENTE
                            await this.envia_simples_whats(conteudo.CELULAR, responder)
                        }

                        
                      }

                      await this.crud_sql_45(`update tabela_webhooks set VISTO = 'S' WHERE (VISTO = 'N')AND (CODIGO = ${linha.CODIGO})`)// VISTO LA NO DSI

                  }

            }
          }

          // this.abre_agenda()
        }

      },

      
      bot_edit_send(resposta){

        let sql = ''

        if (!this.boot_edit[resposta]){
          alert('Digite as informações no campo!')
          return 'exit'
        }

        if (resposta == 'whats_teste'){
          // alert('here: ', this.boot_edit)
          this.responde_bot('DSI', 'user', `salvando ${this.boot_edit[resposta]} como Whats de TESTE` , 900, '','')
          this.responde_bot('DSI', 'user', `agora digite o Whats que você utiliza para enviar mensagens para os pacientes` , 900, '','')
          this.responde_bot('DSI', 'user', `vamos chamá-lo de Whats do Atendente` , 900, '','')

          sql = `update CONFIG_WHATS set CELULAR_TESTE ='${this.boot_edit[resposta]}'`
          this.crud_sql(sql); 

        }
        else if (resposta == 'whats_atendente'){
          // alert('here: ', this.boot_edit)
          this.responde_bot('DSI', 'user', `salvando ${this.boot_edit[resposta]} como Whats do Atendente` , 900, '','')
          this.responde_bot('DSI', 'user', `tudo certo, você já pode enviar mensagens para TESTE` , 900, '','')
          this.responde_bot('DSI', 'user', `qualquer dúvida entre em contato com nosso suporte DSi 😉` , 900, '','')

          sql = `update CONFIG_WHATS set CELULAR_RESPONDER ='${this.boot_edit[resposta]}', WHATS_SITUACAO = 'TESTE', TG = 'S', ATE = '${this.today}'`
          this.crud_sql(sql); 
          
        }

        // this.boot_edit = ''//limpa o campo

      },

      carrega_respostas(){
        
        this.resposta_mensagens= [
          
          { A: 'Legal, vamos lá\n', B: 'Quero saber mais',  },
        ]

      },

      responde_bot(de, para, msg, timer, resposta, cor, tag){

        let obj = {}


        if (!msg){
          return 'exit'
        }

        this.$set(obj, 'DATA'    , this.today)
        this.$set(obj, 'HORA'    , this.hora)
        this.$set(obj, 'DE'      ,de)
        this.$set(obj, 'PARA'    , para )
        this.$set(obj, 'MENSAGEM', msg)
        this.$set(obj, 'RESPOSTA', resposta)
        this.$set(obj, 'COR'     , cor)
        this.$set(obj, 'TAG'     , tag)


        if (timer){

          
          this.digitando = true

          setTimeout(function(){

            this.items_mensagens.push(obj)

            // alert(msg)
            this.digitando = false
            this.scroll_to('id_sala2', 999999)
          }.bind(this), timer);          
        }
        else{
          this.items_mensagens.push(obj)
          this.scroll_to('id_sala2', 999999)
        }

        

        
      },


      scroll_to(id, posicao){
       //tem que definir um id para o componente 
       let container = document.getElementById([id])
       if (container !== null){

        if (posicao == -1){
          posicao = container.scrollHeight //pega o tamanho do scroll para ir ate o final
        }
        
        container.scrollTop = posicao  // not working
        // container.scrollTop = container.scrollHeight

       }
     },

      enviar_zap_testar(resposta){
        
        if (resposta.includes('manualmente')){
            
            let sql = `update CONFIG_WHATS set WHATS_SITUACAO = 'INATIVO'`
            this.crud_sql(sql); 
            
            setTimeout(function(){
                location.reload();//F5 na pagina para puxar atualizacao
            }.bind(this), 222);

        }
        else if (resposta.includes('refresh')){

            setTimeout(function(){
                location.reload();//F5 na pagina para puxar atualizacao
            }.bind(this), 222);
        }
      },

      ver_zap_testar(){
        this. mostrar_zap_testar =   ((this.config_whats.SITUACAO!='INATIVO'))
      },

      ver_zap(){
        this. mostrar_zap =  ((this.config_whats.SITUACAO!='INATIVO'))
      },

      filtra_zap_enviar(items){
        let pode = items.filter(x => (!x.AVISOU_WHATS) && (x.ATENDIDO == 'M') && ((x.CELULAR)||(x.FONE))); //clonando array copiar array
        // console.log('pode', pode);
        this.cout_zap_enviar = pode?.length
      },


      // zapzap
      clicou_menu_whats(acao){
        // alert(acao)
        if (acao == 'confirmacao_ss'){
          this.disparar_whats('CONFIRMAR_AGENDA', this.desserts, 'SS')
        }
        else if (acao == 'confirmacao_sn'){
          this.disparar_whats('CONFIRMAR_AGENDA', this.desserts, 'SN')
        }
        else if (acao == 'confirmacao_nn'){
          this.disparar_whats('CONFIRMAR_AGENDA', this.desserts, 'NN')
        }
        else if (acao == 'somente_aviso'){
          
          let aviso = this.whats_aviso

          if (!aviso){
             alert('Mensagem de Aviso não foi definida!\nEntre em Tabelas, Mensagens do Whats para configurar o Aviso via WhatsApp')
             return false
          }

          if (confirm(`Deseja enviar a seguinte mensagem para toda esta lista?\n${aviso}`)){
             this.disparar_whats('SOMENTE_AVISO', this.desserts, false)
          }
        }
        else if (acao == 'saiba_mais'){
          this.dialog_whats_ativar = true
        }
      },

      monta_menu_whats(){

        if (this.config_whats.SITUACAO =='INATIVO'){

           this.menu_whats.push(           
            {type:'menu', title: 'Saiba mais',        icon:'mdi-help',            icon_color: 'blue',    click:'saiba_mais' },
           )
        }
        else{


            if (this.config_whats.ATE < this.today){
              //  alert(this.config_whats.ATE)
              // alert('Seu período de Teste Gratis de envio de Whats terminou')
            } 
            else{

            }         
            this.menu_whats.push(           

              // {type:'menu', title: 'EFETUADO',        icon:'mdi-text-box',            icon_color: '',    click:'contrato' },

              {
                  type:'group',
                  icon:'mdi-circle',
                  icon_color: '#800080',
                  title: 'Confirmação',
                  click: '', 
                  items: [
                      {type:'', title: 'Com Link ✅ ❌',        icon:'mdi-circle', icon_color: '',  click:'confirmacao_ss' },//#800080 mdi-clock-outline
                      {type:'', title: 'Com Link ✅',            icon:'mdi-circle', icon_color: '',  click:'confirmacao_sn' },
                      {type:'', title: 'Sem Link',               icon:'mdi-circle', icon_color: '',  click:'confirmacao_nn' },
                      {type:'', title: 'Aviso',                  icon:'mdi-circle', icon_color: 'yellow',  click:'somente_aviso' },

                  ],
              },
              
            )
        }
      },

      // zapzap principal...envio de zap envio de whats 
      async disparar_whats(tipo, items, tag){

        // console.log('disparar_whats:', items);

        let url = this.meu_url()+`clientes/envia_whats.php`
      

        // pega dados do zap
        let config_whats = JSON.parse(sessionStorage['config_whats'])//busco objeto no sessionStorage
        // console.log('config_whats:', config_whats);
        let ide = sessionStorage['LSid']
        let token = parseInt(ide * 777888999)

        let id_user = this.config_whats.ID_USER
        if (id_user>0){
          token = token +'i'+id_user
          console.log('id_user3:', id_user);
          console.log('novo token3:', token);
        }

        let api_url   = ''
        api_url       = config_whats.API_LINK
        api_url       = api_url?.replaceAll('@token', token) //removo caracter especial


        
        this.overlay = true

        let nao_repete = ''
        let count_ok   = 0

        // percorre a lista
        for(let i = 0; i< items.length; i++){

          let item           = items[i]
          let celular        = (item.CELULAR)? item.CELULAR : item.FONE
          let coringa        = '#'+item.CODIGOCLIENTE+'#'+item.CODIGOMEDICO+'#\n'
          let link_responder = ''

          
          celular =  await DSibasico.somenteNumeros(celular)

          if (celular){ 
             if ((config_whats.SITUACAO == 'TESTE') && (config_whats.CELULAR_TESTE)){
                 celular = config_whats.CELULAR_TESTE
             }
          }

          if ((config_whats.USAR_LINK_ATENDENDE == 'S') && (config_whats.CELULAR_RESPONDER)){
              let celll = config_whats.CELULAR_RESPONDER
              celll = celll.replace(/\D/gim, '');//somente numeros
              link_responder = `\n\n* *Caso necessite falar com um Atendende, Clique no link abaixo:* \n 👉🏼 https://wa.me/55${celll}/?text=${item.CODIGOCLIENTE}%0AOlá`;
          }

          if (item.CODIGOCLIENTE <0){//PRE-CADASTRO
             coringa = item.HORA 
          }

          // return 'exit' //para testar

          // alert(url)
          if (tipo == 'CONFIRMAR_AGENDA'){

              // alert(sessionStorage['url'] +' MUDAR ISSO DAQUI')
              if  ( ((this.modo_dev()) &&  (celular) && (item.AVISOU_WHATS !='?') && (!(nao_repete).includes(coringa))) ||
                  ( (!this.modo_dev()) && ((celular) && (!item.AVISOU_WHATS) && (['M'].includes(item.ATENDIDO)) && (!(nao_repete).includes(coringa)) ) )){
              // if ((celular) && (item.AVISOU_WHATS !='?') && (!(nao_repete).includes(coringa))){
              // if ((celular) && (!item.AVISOU_WHATS) && (['M'].includes(item.ATENDIDO)) && (!(nao_repete).includes(coringa)) ){
                
              
                nao_repete += coringa

                // console.log('OK - ', item.NOME_AGENDA);

                let pega_msg =  this.prepara_whats('confirmacao',  celular,   item.NOME_AGENDA, item, tag) //pega a mensagem
                // console.log(r);
                pega_msg += link_responder


                  let ID_DSI = await DSibasico.gerar_token(ide)
                  
                  const data = {
                            
                            acao            : tipo,
                            api_url         : api_url,
                            tipo            : tipo,
                            id_cliente      : sessionStorage['LSid'], 
                            empresa         : sessionStorage['LSEmpresa'], 
                            id_dsi          : ID_DSI, 
                            usuario         : sessionStorage['lg:USUARIO'], 
                            celular         : celular, 
                            msg             : pega_msg 
                            // optionList      : optionList, 
                  }  

                  // console.log(data);
                  const header = { headers: {'Content-Type': 'application/x-www-form-urlencoded'}}//so funciona com isso daqui
                  const res    = await axios.post(url, data, header);//envia 


                  let retorno  = (res.data);

                  // let retorno_obj = JSON.parse(retorno); 
                  let retorno_obj = retorno; 

                  

                  if (retorno_obj.id){

                      
                      let parou   = retorno_obj.parou //verifico se ele parou de receber, busco da tabela tabela_nao_whats no cfc_45
                      // console.log('parou:', parou);
                      let addLog  = 'Avisado pelo Whats Automático'
                      let sql_log = ''
                      if (item.LOG_RECEPCAO.includes(addLog) == false){
                        // alert('nao tem entao salva log')

                          sql_log = `,LOG_RECEPCAO = '`+ item.LOG_RECEPCAO+'\r\n'+ this.data_hora +'_w_'+ this.user_def.user_name +': '+ addLog +`'`
                      }

                      let sql = `UPDATE 
                                  agenda set 
                                WHATS_ID       = '${retorno_obj.id}', 
                                AVISOU_WHATS   = '${(parou) ? 'X' : 'T'}',
                                WHATS_RETORNO  = '${parou}'
                                ${sql_log}
                                where codigo = ${item.CODIGO}`

                      // console.log('sql:', sql);
                      await this.crud_sql(sql); 

                      count_ok++
                      this.alerta(count_ok+ ` enviados` , "success");//so retorna ok ou error
                  }
                  
              }
              else if((nao_repete).includes(coringa)){
                  // quando se repete ele ja salva para nao mandar novamente 
                  // console.log('JA TEM '+ item.CODIGO );
                  let sql = `UPDATE 
                              agenda set 
                            AVISOU_WHATS   = '!'
                            where codigo = ${item.CODIGO}`

                  await this.crud_sql(sql); 
              }
          }

          else if (tipo == 'SOMENTE_AVISO'){
            
            
              nao_repete += coringa
              
              let pega_msg =  this.prepara_whats('aviso',  celular,   item.NOME_AGENDA, item, tag) //pega a mensagem
              // console.log('pega_msg:',pega_msg);
              

              let ID_DSI = await DSibasico.gerar_token(ide)
                    
              const data = {
                        
                        acao            : 'ENVIA_SIMPLES',
                        api_url         : api_url,
                        tipo            : 'ENVIA_SIMPLES',
                        id_cliente      : sessionStorage['LSid'], 
                        empresa         : sessionStorage['LSEmpresa'], 
                        id_dsi          : ID_DSI, 
                        usuario         : sessionStorage['lg:USUARIO'], 
                        celular         : celular, 
                        msg             : pega_msg 
                        // optionList      : optionList, 
              }  

              //  console.log(data);
              const header = { headers: {'Content-Type': 'application/x-www-form-urlencoded'}}//so funciona com isso daqui
              const res    = await axios.post(url, data, header);//envia 


              let retorno  = (res.data);
              // console.log('retorno');
              

              // let retorno_obj = JSON.parse(retorno); 
              let retorno_obj = retorno; 


              if (retorno_obj.id){

                      
                // console.log('parou:', parou);
                let addLog  = 'Enviado Aviso pelo Whats'
                let sql_log = ''
                if (item.LOG_RECEPCAO.includes(addLog) == false){
                  // alert('nao tem entao salva log')

                    sql_log = `,LOG_RECEPCAO = '`+ item.LOG_RECEPCAO+'\r\n'+ this.data_hora +'_w_'+ this.user_def.user_name +': '+ addLog +`'`
                }

                let sql = `UPDATE 
                            agenda set 
                          WHATS_ID       = '${retorno_obj.id}', 
                          AVISOU_WHATS   = 'A',
                          WHATS_RETORNO  = ''
                          ${sql_log}
                          where codigo = ${item.CODIGO}`

                // console.log('sql:', sql);
                await this.crud_sql(sql); 

                count_ok++
                this.alerta(count_ok+ ` enviados` , "success");//so retorna ok ou error
              }


          }

        }

        

        await this.abre_agenda()

        this.overlay = false

        setTimeout(function(){
          // this.alerta(`Legal, você levou ${(count_ok * 2)} segundos para confirmar esta agenda 😉` , "primary", 5000);//so retorna ok ou error
          this.alerta(`${(count_ok)} mensagens enviadas em ${(count_ok)} segundos 😉` , "primary lighten-1", 5000);//so retorna ok ou error
        }.bind(this), 999);
        

        
        

      },

      async imprimir_recibo(item, tipo){
        // console.log(item);
        
        //insiro alguns campos necessarios no objeto

        this.$set(item, 'EMPRESA_TIPO'   , tipo)//CPF ou CNPJ

        this.$set(item, 'EMPRESA'        , sessionStorage['LSEmpresa'])
        this.$set(item, 'EMPRESA_CNPJ'   , sessionStorage['LScnpj'])
        this.$set(item, 'EMPRESA_FONES'  , sessionStorage['LSfones'])
        this.$set(item, 'EMPRESA_END'    , sessionStorage['LSendereco'])
        this.$set(item, 'EMPRESA_EMAIL'  , sessionStorage['emp_email'])
        this.$set(item, 'EMPRESA_CIDADE' , sessionStorage['LSCidade'])
        this.$set(item, 'CRM_DOC'        , this.doc_codigo.CRM)
        this.$set(item, 'CNES_PF'        , this.doc_codigo.CNES_PF)

        if (tipo == 'CPF'){//pega o cpf do profissional
          this.$set(item, 'EMPRESA_CNPJ'   , sessionStorage['getCPFdoc'])
        }

        if (this.esta_empresa('acencon')){
            this.$set(item, 'PROCESSO' , item.OBS)
            // this.$set(item, 'NOME_AGENDA'        , item.NOME_AGENDA + ' PROCESSO: '+ item.OBS)
        }

        sessionStorage['recibo:dados']  =  JSON.stringify(item)

        let v = parseFloat(item.VALOR)
        if (v){
          let route = this.$router.resolve({path: '/recibo'});
          window.open(route.href, '_blank');          
        }
        else{
          alert('Defina um Valor Válido!')
        }

      },

      negrito(entra){

         return '*'+ entra +'*'
      },

      replace_parametros(entra, item, hora){


        // console.log(item);
        let empresa = sessionStorage['LSEmpresa']

        let msg = entra
        let quebra = '\n'
        // let quebra = '%0A'

        let nome = item.NOME
         
        nome = nome.toLowerCase().trim();  //passo tudo pra minuscula
        nome = nome.split(" ");//separo as palavras
        nome = nome[0];//pego o primeiro nome        
        nome = nome.charAt(0).toUpperCase() + nome.slice(1);//passo 1 letra maiuscula


        msg = msg.replaceAll('@ide',                this.negrito(item.CODIGOCLIENTE))
        msg = msg.replaceAll('@paciente',           this.negrito(nome))
        msg = msg.replaceAll('@profissional',       this.negrito(item.PROFISSIONAL))

        msg = msg.replaceAll('@convenio',           this.negrito(item.CONVENIO))
        msg = msg.replaceAll('@procedimento',       this.negrito(item.PROCEDIMENTO))
        msg = msg.replaceAll('@data_agendamento',   this.negrito(this.f_dia_da_semana(item.DATA)))
        msg = msg.replaceAll('@hora_agendamento',   this.negrito(hora))
        msg = msg.replaceAll('@empresa',            this.negrito(empresa))

        msg = msg.replaceAll('@::)',                `☺️`)
        msg = msg.replaceAll('@;)',                `😉`)
        msg = msg.replaceAll('@:)',                `🙂`)
        msg = msg.replaceAll('@[]',                `👩🏼‍💻`)

        msg = msg.replaceAll('\n',                 quebra)
        msg = msg.replaceAll('<br>',               quebra)

      


        // ANTIGO
        msg = msg.replaceAll('@IDE',                this.negrito(item.CODIGOCLIENTE))
        msg = msg.replaceAll('@PACIENTE',           this.negrito(item.NOME.trim()))
        msg = msg.replaceAll('@PROFISSIONAL',       this.negrito(item.PROFISSIONAL))

        msg = msg.replaceAll('@CONVENIO',           this.negrito(item.CONVENIO))
        msg = msg.replaceAll('@PROCEDIMENTO',       this.negrito(item.PROCEDIMENTO))
        msg = msg.replaceAll('@DATA_AGENDAMENTO',   this.negrito(item.DATA))
        msg = msg.replaceAll('@HORA_AGENDAMENTO',   this.negrito(hora))
        msg = msg.replaceAll('@CLINICA',            this.negrito(empresa))
        msg = msg.replaceAll('@ENDERECO_CLINICA',   this.negrito(sessionStorage['LSendereco']))

        return msg
      },

      retorna_altura(menos){
        // let r = '100vh'
        // let r = '720'
        let r = (window.innerHeight - 80 - 60)
        let height = window.innerHeight;

        if (menos){
          r = (r - menos)
        }

        return r
      },

      retorna_minutos(entra){
        
        let aux = (entra)
        
        let r = ''
        if (aux==60){
           r = '1 hora'
        }
        else if (aux==90){
          r = '1 hora e meia'
        }
        else if (aux>60){
          aux =  (aux / 60 )

          let xRounded = aux
          r = xRounded+' horas'
          if (aux.toString().includes('.')){
            xRounded = +(aux.toFixed(0))
            
            r = 'quase '+xRounded+' horas'
          }
           
        }
        else{
          if (aux){
            r = aux+' minutos'
          }else{
            r = aux
          }
        }

        // return entra + ' = '+ r
        if (r){ return '('+ r + ')' }else{return ''}
        
      },

      async crud_empresa () {

        // atualiza dados da empresa e msg do whats
          const res = await axios.post(url+'conexao.php', {
            acao:       1,
            meuSQL:   `SELECT
                      CONSULTORIOS.NOME,
                      CONSULTORIOS.CEP,
                      CONSULTORIOS.ENDERECO,
                      CONSULTORIOS.CIDADE,
                      CONSULTORIOS.UF,
                      CONSULTORIOS.FONE1,
                      CONSULTORIOS.FONE2,
                      CONSULTORIOS.CELULAR,
                      CONSULTORIOS.DIRETOR_TECNICO,
                      CONFIGURACOES.IDE_PROGRAMA
                    FROM
                    CONSULTORIOS,
                    CONFIGURACOES`,
          }) 
          
          // console.log('crud_empresa:', res.data[0]);


          // this.nome_empresa =  res.data[0].NOME; //res

        
          //salva dados para usar em outras janelas
          sessionStorage['LSEmpresa']          = res.data[0].NOME
          sessionStorage['LSCidade']           = res.data[0].CIDADE
          sessionStorage['LSendereco']         = res.data[0].ENDERECO +'  CEP: '+ res.data[0].CEP +'   '+res.data[0].CIDADE + ' - '+res.data[0].UF
          sessionStorage['LSid']               = res.data[0].IDE_PROGRAMA
          sessionStorage['LSDiretorTecnico']   = res.data[0].DIRETOR_TECNICO
          // sessionStorage['LSfones']            = res.data[0].FONE1+'  '+res.data[0].FONE2
          sessionStorage['LSfones']            = res.data[0].FONE1+'  '+res.data[0].CELULAR


          this.Maviso =  this.esta_empresa('odontorriso')

          await this.crud_msg_whats()

          this.sou_acencon = this.esta_empresa('acencon')

      },

      async crud_msg_whats () {

        // atualiza dados da empresa e msg do whats
          const res = await axios.post(url+'conexao.php', {
            acao:       1,
            meuSQL:   `select CODIGO, MSG_CONFIRMACAO, MSG_OBS, MSG_OBS_2, MSG_AVISO from CONFIG_WHATS`,
          }) 
          
          // console.log('crud_msg_whats:', res.data[0]);

          if (res.data.length == 0){
            //nao tem entao da um INSERT
            let sql = "insert into CONFIG_WHATS (WHATS_SITUACAO, TG, WHATS_PLATAFORMA, USAR_LINK_ATENDENDE, MSG_OBS, MSG_OBS_2, MSG_AVISO ) values ('INATIVO', 'N', '', '', '','', '')"
            // console.log('sql:', sql);
            await this.crud_sql(sql); 
          }

          this.whats_situacao = (res.data[0].WHATS_SITUACAO)
          this.whats_proprio  = (res.data[0].WHATS_PLATAFORMA == 'MEU_PROPRIO_NUMERO')
 
          this.mensagens_whats = Object.assign({}, res.data[0]) 
          // this.config_whats = Object.assign({}, res.data[0]) 
             
        

          // sessionStorage['config_whats']  =  JSON.stringify(res.data[0])

          //salva dados para usar em outras janelas
          if (res.data.length > 0){
            this.set_sessionStorage('whats_confirmacao', res.data[0].MSG_CONFIRMACAO)
            this.set_sessionStorage('whats_obs',         res.data[0].MSG_OBS)
            this.set_sessionStorage('whats_obs_2',       res.data[0].MSG_OBS_2)
            this.set_sessionStorage('whats_aviso',       res.data[0].MSG_AVISO)
          }
          else{
            this.set_sessionStorage('whats_confirmacao', '')
            this.set_sessionStorage('whats_obs',         '')
            this.set_sessionStorage('whats_obs_2',       '')
            this.set_sessionStorage('whats_aviso',       '')
          }

          this.whats_confirmacao    = this.read_sessionStorage('whats_confirmacao')
          this.whats_obs            = this.read_sessionStorage('whats_obs')
          this.whats_obs_2          = this.read_sessionStorage('whats_obs_2')
          this.whats_aviso          = this.read_sessionStorage('whats_aviso')



          this.ver_zap_testar()
          this.ver_zap()

          this.monta_menu_whats()
          this.carrega_respostas()

      },


      calcula_idade(data,campos){
        let s = DSibasico.calcula_idade(data, campos)

        if (s.years <18){
          this.is_menor = true
        }else{
          this.is_menor = false
        }

        return s.texto
      },

      rowClass(item){
        // console.log(item);
        let r = this.getColorLinha(item)

        // console.log(r);
        return r
        // return 'border-bottom';
      },

      rightClick(event, item) {
          // console.log(event)
          // console.log(item)

          if (this.user_permissoes.agendar){
            this.abre_menu(item.item)
            event.preventDefault()
          }
          
      },

      seleciona_linha(row) {
        // console.log(row);
          
           this.item_clicou = Object.assign({}, row) 

           this.selected= [{"CODIGOCONSULTA" : row.CODIGOCONSULTA}] //via v-modal no data-table


           this.set_sessionStorage('getCodigoConsulta',row.CODIGOCONSULTA)

          //  this.associa(item.CODIGOCONSULTA)//seleciono a linha 

          let idade = ''
          if (row.DATANASCIMENTO){
            idade = this.calcula_idade(this.getFormatDate(row.DATANASCIMENTO), 1)
          }

          this.$set(row, 'IDADE', idade);//defino valor manualmente
          sessionStorage.setItem('obj_dados_paciente', JSON.stringify(row));
      },


      async GetPermissoes(){
        let r = await DSirotinas.GetPermissoes()
        this.user_permissoes = Object.assign({}, r) 
      },


      async transferir_agenda(doc,de, para){

        // if (para <= de){
        //   alert('Data inválida!')
        // }

        let sql = `update agenda set data = '${para}' where data = '${de}' and codigomedico = ${doc}`
          
        // console.log(sql); 
        await this.crud_sql(sql); 

        this.dialog_agenda_log = false

        this.in_today = para //atualiza calendario
        // this.abre_agenda()

        await DSi_funcoes.registra_log('AGENDA','Transferiu Agenda de '+ this.doc_codigo.NOME + ' dia ' + this.getFormatDate(de)+' para '+ this.getFormatDate(para))

      },


      async rel_atendimentos(acao, doc,conv,di,df,proced){

        if (acao=='Lançamentos'){

          let di_br  =  di.substring(8,10) + '/'+ di.substring(5,7)+ '/'+ di.substring(0,4);//formato a data 
          let df_br  =  df.substring(8,10) + '/'+ df.substring(5,7)+ '/'+ df.substring(0,4);//formato a data 
          let titulo = ``
          let size   = `100%`

          let retorno = null

          let sql   = ``
          let sql2  = ``
          let sql3  = ``
          let where = ``
          this.header_estatisticas = []
          this.items_estatisticas  = []


          if (this.rel_cod_conv>0){
             sql3 = `AND LANCAMENTOS.CODIGO_CONVENIO = ${conv}`
          }else{
             sql3 = ''
          }
          
          if (this.tipo_selecionado == 'PREVISAO'){
              sql2 = `AND LANCAMENTOS.DATA BETWEEN  '${di}' and '${df}'`
          }
          
          else if (this.tipo_selecionado == 'RECEBIDO'){
              sql2 = `AND LANCAMENTOS.DATA_EFETUADO BETWEEN  '${di}' and '${df}' and EFETUADO = 'S'`
          }

          else if (this.tipo_selecionado == 'ATRASADO'){
              sql2 = `AND DATA BETWEEN '${di}' AND CURRENT_DATE - 1 AND EFETUADO = 'N'`
          }


          sql = `
          SELECT 
            LANCAMENTOS.CODIGO,
            LANCAMENTOS.CODIGO_CLIENTE,
            LANCAMENTOS.DATA,
            LANCAMENTOS.VALOR_UM,
            LANCAMENTOS.DATA_EFETUADO,
            LANCAMENTOS.ALTEROU,
            LANCAMENTOS.FORMA_PAGAMENTO,
            LANCAMENTOS.CODIGO_PROFISSIONAL,
            LANCAMENTOS.EFETUADO,
            LANCAMENTOS.CATEGORIA,
            LANCAMENTOS.OPERACAO,
            LANCAMENTOS.DESCRICAO,
            LANCAMENTOS.LANCOU,
            LANCAMENTOS.SEQUENCIA,
            LANCAMENTOS.DATA_LANCAMENTO,
            LANCAMENTOS.R_RECEBEU,
            LANCAMENTOS.CODIGO_CONVENIO,
            CLIENTES.NOME,
            CLIENTES.CPF,
            CLIENTES.CELULAR,
            CONVENIO.NOME AS CONVENIO,
            DOUTORES.NOME AS PROFISSIONAL
          FROM
          LANCAMENTOS
            LEFT OUTER JOIN CLIENTES ON (LANCAMENTOS.CODIGO_CLIENTE=CLIENTES.CODIGOCLIENTE)
            LEFT OUTER JOIN CONVENIO ON (LANCAMENTOS.CODIGO_CONVENIO=CONVENIO.CODIGO)
            LEFT OUTER JOIN DOUTORES ON (LANCAMENTOS.CODIGO_PROFISSIONAL=DOUTORES.CODIGO)
          WHERE
           LANCAMENTOS.CODIGO_PROFISSIONAL = ${doc} ${sql2} ${sql3} ORDER BY DATA
          `


          this.header_estatisticas.push
              (
                // { text: 'Seq', value: 'SEQUENCIA', tag:'v-text', type:'text', cols:'1'},
                // { text: 'EF', value: 'EFETUADO', tag:'v-text', type:'text', cols:'1'},
                { text: 'Cadastro', value: 'NOME', tag:'v-text', type:'date', cols:'2' },
                { text: 'Convênio', value: 'CONVENIO', tag:'v-text', type:'date', cols:'2' },
                { text: 'Categoria', value: 'CATEGORIA', tag:'v-text', type:'date', cols:'2' },
                { text: 'Cod', value: 'CODIGO', type:'number', cols:'0',width: '60px' },
                { text: 'Vencimento', value: 'x_DATA', tag:'v-text', type:'date', cols:'2', width: this.w_data },
                { text: 'Pago', value: 'x_DATA_EFETUADO', tag:'v-text', type:'date', cols:'2', width: this.w_data,},
                { text: 'Valor', value: 'VALOR_UM', tag:'v-text', type:'text', cols:'2',rule:'S', upper:'N',width: '99px' },
                  // { text: 'DATA', value: 'DATA', tag:'v-text', type:'date', cols:'2', width: this.w_data },
              )

          titulo = `${di_br} até ${df_br}\n LANÇAMENTOS - ${this.tipo_selecionado} `
          size   = `100%`


          // console.log('sql:', sql);
          retorno = await this.crud_abrir_tabela(sql, 'items_estatisticas')
          if (retorno && retorno.length) { // Verifica se retorno não está vazio
              // console.log('retorno:', retorno);

              // let camposSomados = await this.somarCampo(retorno, 'double_TOTAL', true);
              let camposSomados = await somarCampo(retorno, 'VALOR_UM', true);
              console.log('TOTAL:', camposSomados);

              this.items_estatisticas.push( 
                { VALOR_UM: 'TOTAL'},//insiro uma linha
                { VALOR_UM: 'R$ '+ camposSomados.VALOR_UM, NOME : camposSomados.count }
              );
              
          }
          
          await DSibasico.imprimir_conteudo(acao, false, this.header_estatisticas, this.items_estatisticas, titulo, false, false, size)
        }
        else{

          sessionStorage['rel.qual']   = acao
          //  sessionStorage['rel.qual']   = 'atendimento'
          sessionStorage['rel.doc']        = doc
          sessionStorage['rel.conv']       = conv
          sessionStorage['rel.di']         = di
          sessionStorage['rel.df']         = df
          sessionStorage['rel.proced']     = proced
          sessionStorage['rel.celular']    = this.checkbox_celular
          sessionStorage['rel.concluidos'] = this.checkbox_concluidos
          sessionStorage['rel.anotacao']   = this.checkbox_anotacao
          sessionStorage['rel.prof']       = this.doc_codigo.NOME
          //  this.$router.push('/relatorios')//redireciono para a pagina do router


          let route = this.$router.resolve({path: '/relatorios'});
          window.open(route.href, '_blank');
        }

      },



      async abrir_estatisticas(doc, di, df){

      
          if ( ((this.expansion_selecionao == 'ESTATISTICAS')&&(doc>0) ) ||
               ((this.expansion_selecionao == 'ESTATISTICAS_WHATS') && (doc == 0) && (this.value_expansion >= 0))
             ){

          // if (((this.value_expansion > 0) &&(doc>0)) || ((this.value_expansion == 0) &&(doc == 0))) {
            //pode
          }
          else{
            return false
          }

          // alert('passou')

           let mes = di.substring(5, 7)
           let ano = di.substring(0, 4)
           let diasMes = 0
    

           if (this.qual_estatisticas=='Mês'){

              diasMes =  new Date(ano, mes, 0).getDate();//pega o numero de dias do mes

              di = ano+'-'+mes+'-01'
              df = ano+'-'+mes+'-'+diasMes

              this.r_estatisticas = mes
              this.col_r = 2
           }
           else if (this.qual_estatisticas=='Ano'){
              di = ano+'-01-01'
              df = ano+'-12-31'
              this.r_estatisticas = ano
              this.col_r = 2
           }
           else if (this.qual_estatisticas=='Dia'){

              this.r_estatisticas = this.getFormatDate(di)
              this.col_r = 1
           }

          //  console.log(di+'-'+df)

        //limpa array
          this.lista_estatiticas = [] 
          this.count_estatiticas = null

          let satendido = "('A','O','N','M','Q','G','T')"
          if (this.geral_atendimentos == 'Atendidos'){
              satendido = "('A')"
          } 

          let sql = ''

          if (doc>0){
          
              // console.log('estatistica dr');
              sql = 
              `
              SELECT * FROM
                (

                SELECT
                DISTINCT
                COUNT(AGENDA.CODIGO) AS QT,
                AGENDA.PROCEDIMENTO,
                0 AS SEQ,
                '' AS COR
                FROM
                AGENDA
                WHERE
                (AGENDA.CODIGOMEDICO =${doc}) AND
                (AGENDA.DATA BETWEEN '${di}' AND '${df}')AND
                (AGENDA.PROCEDIMENTO IS NOT NULL)AND
                (AGENDA.ATENDIDO IN ${satendido})
                GROUP BY
                AGENDA.PROCEDIMENTO


                UNION

                SELECT
                DISTINCT
                COUNT(AGENDA.CODIGO) AS QT,
                'FALTA´S' AS PROCEDIMENTO,
                0 AS SEQ,
                '' AS COR
                FROM
                AGENDA
                WHERE
                (AGENDA.CODIGOMEDICO =${doc}) AND
                (AGENDA.DATA BETWEEN '${di}' AND '${df}')AND
                (AGENDA.PROCEDIMENTO IS NOT NULL)AND
                (AGENDA.ATENDIDO = 'F')


                UNION

                SELECT
                DISTINCT
                COUNT(AGENDA.CODIGO) AS QT,
                'CONFIRMADOS VIA WHATS' AS PROCEDIMENTO,
                1 AS SEQ,
                'purple lighten-2' AS COR
                /*'grey lighten-1' AS COR */

                FROM
                AGENDA
                WHERE
                (AGENDA.CODIGOMEDICO =${doc}) AND
                (AGENDA.DATA BETWEEN '${di}' AND '${df}')AND
                (AGENDA.PROCEDIMENTO IS NOT NULL)AND
                (AGENDA.OK_VIA_WHATS = 'S') 
                
                AND ((AGENDA.ATENDIDO <> 'C') AND (AGENDA.ATENDIDO <> 'F')) 

                )


                ORDER BY
                SEQ ASC,
                QT DESC
              `
          }
          else{
            // console.log('estatistica todos');

            sql = `
            SELECT * FROM (
                    SELECT
                    COUNT(CASE
                    WHEN (AGENDA.AVISOU_WHATS IS NOT NULL)AND
                    (AGENDA.AVISOU_WHATS NOT IN ('X','!')) THEN
                    AGENDA.CODIGO
                    END)
                    AS TOTAL,

                    COUNT(CASE
                    WHEN (AGENDA.AVISOU_WHATS IS NOT NULL)AND
                    (AGENDA.OK_VIA_WHATS = 'S') THEN
                    AGENDA.CODIGO
                    END)
                    AS QT,
                    'CONFIRMADOS' AS PROCEDIMENTO,
                    '' AS COR,
                    1 AS SEQ
                  FROM
                  AGENDA
                  WHERE (AGENDA.DATA BETWEEN '${di}' AND '${df}')

                  UNION

                  SELECT
                    COUNT(CASE
                    WHEN (AGENDA.AVISOU_WHATS IS NOT NULL)AND
                    (AGENDA.AVISOU_WHATS NOT IN ('X','!')) THEN
                    AGENDA.CODIGO
                    END)
                    AS TOTAL,

                    COUNT(CASE
                    WHEN (AGENDA.AVISOU_WHATS IS NOT NULL)AND
                    (AGENDA.AVISOU_WHATS NOT IN ('X','!')) THEN
                    AGENDA.CODIGO
                    END)
                    AS QT,
                    'ENVIADOS' AS PROCEDIMENTO,
                    '' AS COR,
                    0 AS SEQ
                  FROM
                    AGENDA
                    WHERE (AGENDA.DATA BETWEEN '${di}' AND '${df}')
                  )
                  ORDER BY
                  SEQ ASC
                    `
          }

          // console.log('abrir_estatisticas:', sql)

          await this.crud_abrir_tabela(sql,'lista_estatiticas')

          await soma(this) //chamo uma funcao dentro daqui 

          async function soma(that){

              let h = that.lista_estatiticas
              let soma = 0
              let porc = 0
              for(let i = 0; i< h.length; i++){

                if (parseInt(h[i].SEQ) == 0  ){
                  soma = parseInt(soma) + parseInt(h[i].QT)
                }

                // pega porcentagem
                if (doc==0){
                  porc = ((parseInt(h[i].QT) * 100) / parseInt(h[i].TOTAL))
                  porc = Math.round(porc);
                  if (porc>0){
                    that.$set(h[i], 'PORC', `(${porc}%)`);//defino valor manualmente
                  }
                }
              }

              that.count_estatiticas = soma
          }

          this.grafico_whats(this.in_today, this.in_today)
          
      },

      async abrir_lancamentos(doc, di, df){

           let mes = di.substring(5, 7)
           let ano = di.substring(0, 4)
           let diasMes = 0
    
          //  alert('abrir_lancamentos')

           if (this.qual_estatisticas=='Mês'){

              diasMes =  new Date(ano, mes, 0).getDate();//pega o numero de dias do mes

              di = ano+'-'+mes+'-01'
              df = ano+'-'+mes+'-'+diasMes

              this.r_estatisticas = mes
              this.col_r = 2
           }
           else if (this.qual_estatisticas=='Dia'){

              this.r_estatisticas = this.getFormatDate(di)
              this.col_r = 1
           }

          //  console.log(di+'-'+df)

        //limpa array
          this.lista_lancamentos = [] 
          // this.count_estatiticas = null

          let sql = ''

          if (doc>0){
          
              // console.log('estatistica dr');
              sql = 
              `
              SELECT
                CODIGO,
                CODIGO_CLIENTE,
                CODIGO_CONSULTA,
                VALOR_UM,
                FORMA_PAGAMENTO,
                PROFISSIONAL,
                LANCOU,
                VISTO,
                DATA,
                DATA_LANCAMENTO,
                DATA_EFETUADO,
                DESCRICAO,
                DESC_PROCEDIMENTO
              FROM
                LANCAMENTOS
               WHERE
                (LANCAMENTOS.DATA_EFETUADO BETWEEN '${di}' AND '${df}')AND
                (LANCAMENTOS.CODIGO_PROFISSIONAL = ${doc})
              ORDER BY
                 DATA DESC, CODIGO DESC
                    `
          }


          // console.log('abrir_lancamento:', sql)
          

          await this.crud_abrir_tabela(sql,'lista_lancamentos')

          await somatoria_lista(this, 'lista_lancamentos', 'items_somatorias', 'VALOR_UM', 'TOTAL', false)
          
      },

      //ABRE OS DADOS DA TABELA
      async crud_abrir_tabela(sql, items_name){
          //GLOBAL    
          var meuSQL = sql
          
          // console.log(meuSQL);
                  
          const res2 = await axios.post(url+'conexao.php', {
              acao:   5,
              meuSQL: meuSQL
          })

          try{
            this[items_name] = res2.data 
          }
          catch(e){}
          // console.log('crud_abrir_tabela:')
          // console.log(res2.data);

          return res2.data
      }, 

      emit_executa(acao,a,b,c){
        // chamo esse emit quaso queira mudar algo no componente pai = agenda
        if (acao == 'abrirChat'){
            this.verchat = a
        }
      },

      bloqueio(digitou){

        if (digitou == ''){
          digitou = 'BLOQUEADO'
        }

        digitou = digitou.toUpperCase()

        this.dialog_paciente  = false
        this.pre_cadastro     = true
        this.bloqueando       = true

        this.items_selecionados.cod_paciente  = 0
        this.items_selecionados.nome_paciente = digitou
        this.items_selecionados.convenio      = 0

        this.$set(this.editedItem, 'CODIGOCLIENTE', 0);//defino valor manualmente
        this.$set(this.editedItem, 'NOME', digitou);//defino valor manualmente

        this.$set(this.editedItem, 'CODIGOCONVENIO', 0)
        this.$set(this.editedItem, 'CONVENIO', '')
        this.$set(this.editedItem, 'ATENDIDO', 'B')



        this.dialog_agendar = true

        this.alerta("Digite o Evento!","orange")

      },

     async salvar_pre_cadastro(nome){
        alert(nome)
         let dados = {}

        //default  
        this.$set(dados, 'CODIGOCONVENIO', 0)
        this.$set(dados, 'NOME', nome)

        this.$set(dados, 'CODIGOCONSULTORIO', 0);//defino valor manualmente
        this.$set(dados, 'ATIVO', 'S');//defino valor manualmente
        //this.$set(dados, 'CODIGOCLIENTE', '(SELECT max(CLIENTES.CODIGOCLIENTE+1)FROM CLIENTES)');//defino valor manualmente
        this.$set(dados, 'CODIGOCLIENTE', '(SELECT COALESCE(MAX(CLIENTES.CODIGOCLIENTE) + 1, 1)FROM CLIENTES)');//defino valor manualmente


         const data = {
                acao: 2,
                table:             'clientes',
                fields:             dados,
         }  

        const res = await axios.post(url+'conexao.php', data);
        this.alerta(res.data)

        

      },

      cadastro_provisorio(nome){

        nome = nome.toUpperCase()

        this.dialog_paciente  = false
        this.pre_cadastro     = true

        this.items_selecionados.cod_paciente  = -5
        this.items_selecionados.nome_paciente = nome
        this.items_selecionados.convenio      = 0

        this.$set(this.editedItem, 'CODIGOCLIENTE', -5);//defino valor manualmente
        this.$set(this.editedItem, 'NOME', nome);//defino valor manualmente

        if (this.obj_user.CODIGO_CONVENIO>0){
          
          const convenio = this.convenios.find(convenio => convenio.CODIGO === this.obj_user.CODIGO_CONVENIO);

          this.items_selecionados.convenio      = convenio.CODIGO

          this.$set(this.editedItem, 'CODIGOCONVENIO' , convenio.CODIGO)
          this.$set(this.editedItem, 'CONVENIO'       , convenio.NOME)
          
        }
        else{
          this.$set(this.editedItem, 'CODIGOCONVENIO', 0)
          this.$set(this.editedItem, 'CONVENIO', '')
        }



        this.dialog_agendar = true

        this.alerta("Digite o nome!","orange")
      },

      abrir_cadastro(nova_aba, vem, acao, cs){
          // console.log('abrir_cadastro:');
          // console.log(vem);
          // console.log(cs);

          if (acao == 'novo'){
            if (!this.user_permissoes.cadastrar){
              return false
            }
          }
          
          // this.set_sessionStorage('qual_acao', 'novo')
          this.set_sessionStorage('qual_acao', acao)
          this.set_sessionStorage('nome_digitou', vem)
          this.set_sessionStorage('up_agenda', cs)
          this.set_sessionStorage('nome_digitou_abrir', 'S')


          if (nova_aba){
            let route = this.$router.resolve({path: '/paciente'});
            window.open(route.href, '_blank');
          }
          else{
            this.$router.push('/paciente')//redireciono para a pagina do router
          }
          
      },

      getFormatTime(hora){
        if (hora !== undefined){
           let shora =   hora.substring(0,2) + ':'+ hora.substring(3,5)//formato a hh:mm
           return shora 
        }else{
          return ''
        }  
      },


      abre_menu(item){

        if (this.doc_codigo.CODIGO==0){
          return 'exit'
        }
        
        this.dialog_editar = true
        this.item_principal = item

        this.seleciona_linha(item)

        //para dar quebra de linha lá no campo
        let log = this.item_principal.LOG_RECEPCAO
        log = log.replaceAll('\n', '<br>') //quebra de linha
        this.item_principal.LOG_RECEPCAO = log


        let obsproced = this.item_principal.WHATS_OBS_PROCED
        this.item_principal.WHATS_OBS_PROCED = obsproced.replaceAll('\n', '<br>') 
      },

      async definir(item,item2, motivo){
        // console.log('definir');
        // console.log('item:', item);
        // console.log('item2:',item2);
        // alert(item2.sigla)

        this.update_values()//atualiza horas

        
        // VERIFICA SE TEM ATENDIMENTO, ALERTA CASO NAO TENHA
        let msg_2 = ``
        // if ( (item2.sigla=='A') && (!this.sou_acencon) )  {
        //     let sql_busca = `select codigo from procedimento where codigoconsulta = ${item.CODIGOCONSULTA}` 
        //     let retorno = await this.crud_abrir_tabela(sql_busca,'')
        //     // console.log('sql_busca:', sql_busca);
        //     // console.log('retorno:', retorno);
        //     // console.log('retorno:', retorno.length);
        //     if (retorno.length==0){
        //       if (!confirm("Não existe Evolução salva neste Atendimento! deseja continuar?")){
        //         return false
        //       }
        //       msg_2 = ` sem Evolucao`
        //     }
            
        // }

        this.dialog_editar = false

        let sql2 = ''
        if (['F','C','A','D'].includes(item2.sigla)){
          sql2 = `HORAF = '`+ this.hora+`',`

          if (motivo){
            // alert(motivo)
            sql2 = sql2+ ` REGISTRO = '${motivo}', `
          }

        }
        else{
          if (item2.sigla == 'G'){
            sql2 = `HORA_CHEGADA = '${this.hora}', `
          }
        }

        let text = item2.text.toUpperCase();
        let sql = 
            `update agenda set atendido = '`+item2.sigla+`',`+
            sql2+
            `LOG_RECEPCAO = '`+ item.LOG_RECEPCAO+'\r\n'+ this.data_hora +'_'+ this.user_def.user_name +': '+ text + msg_2+`'
            where codigo = `+item.CODIGO
        
        // console.log(sql); 
        this.crud_sql(sql);    
        
        //atualiza
        setTimeout(function(){
         this.abre_agenda()
        }.bind(this), 0);//usar esse .bind(this) para chamar funcoes com o setTimeout

      } , 

      enviou_whats(item){
        
        // console.log(item);
        // alert('enviou: '+item.CODIGOCONSULTA)
        this.set_sessionStorage('getCodigoConsulta',item.CODIGOCONSULTA)
        this.get.CodigoConsulta = item.CODIGOCONSULTA

        let addLog = 'Avisado pelo Whats'
        if (item.LOG_RECEPCAO.includes(addLog) == false){
          // alert('nao tem entao salva log')

            let sql = 
          `update agenda set AVISOU_WHATS = 'S',
          LOG_RECEPCAO = '`+ item.LOG_RECEPCAO+'\r\n'+ this.data_hora +'_w_'+ this.user_def.user_name +': '+ addLog +`'
          where codigo = `+item.CODIGO

          console.log(sql);

          this.crud_sql(sql);  
        }
   
        
        //atualiza
        setTimeout(function(){
         this.abre_agenda()
        }.bind(this), 0);//usar esse .bind(this) para chamar funcoes com o setTimeout

      } , 


      onResize () {
        this.isMobile = window.innerWidth < 800
        // this.isMobile = this.$vuetify.breakpoint.smAndDown

        if(this.isMobile){
          this.switch_agendar = false
          this.value_expansion = null
        }

        //muda o titulo
        document.title = 'DSi - '+ sessionStorage['LSEmpresa'] 
        // document.title = localStorage['LSEmpresa'] +' - '+ this.user_def.user_name

        // this.simples = (sessionStorage.getItem('simples') === "true") 
      },

      showDrawer(){
        this.$emit('executar', true)
      },

      async logado(){

          let key_session  = this.read_sessionStorage('refresh_size') 
          let logado       =  this.read_sessionStorage('buffer')
          let key          =  this.return_key().toString() //passo pra string para comparar

          if (key_session == undefined){
              key_session = ''
          }
          key_session  =  key_session.toString()//passo pra string para comparar

          if ( (logado !=='S') || (key !== key_session)) {
            this.$router.push('/')//redireciona para a pagina login
          }

          //chamo o ler_session do app.vue para atualizar o nome do usuario
          this.$emit('executar2', 'ler_session')     
      },

      return_key(){

         //pego o mes e o dia multiplico e chego num valor.. sera analisado   
          let dia = new Date().toLocaleDateString('pt-Br',{ datestyle: 'short', hour12: false, timeZone: 'America/Sao_Paulo' }); 
          dia =  dia.slice(3, 5) + dia.slice(0, 2) ; //formato us yyyy-mm-dd
          let a = dia.substring(0,2)
          let b = dia.substring(4,2)
          let c = (a * 3 * b)
          return c
      },

      set_sessionStorage($name, $value){
        sessionStorage[$name] = $value;
      },

     read_sessionStorage($name,$to){
        if (sessionStorage[$name]) {
           return sessionStorage[$name]
          //  this[$to] =  sessionStorage[$name];
        } 
     },

     update_windows(){
       location.reload();
     },

    async ler_sessions(){

       this.get.CodigoConsulta       = this.read_sessionStorage('getCodigoConsulta') 
          
       this.doc_codigo.CODIGO        = this.read_sessionStorage('getCodigoDoc')
        
       this.doc_codigo.NOME          = this.read_sessionStorage('getNomeDoc')
       this.doc_codigo.CRM           = this.read_sessionStorage('getCRMdoc')
       this.doc_codigo.UF            = this.read_sessionStorage('getCRMuf')
       this.doc_codigo.CNES_PF       = this.read_sessionStorage('getConselho')
      //  this.doc_codigo.PATH_LOGO = this.read_sessionStorage('getPATH_LOGO')
       this.doc_codigo.PATH_LOGO_PRO = this.read_sessionStorage('getPATH_LOGO_PRO')


       this.associa(this.get.CodigoConsulta)//clica na linha selecionada 

       //definicoes de usuario 
       this.user_def.r0s9i3khepus_is   = this.read_sessionStorage('r0s9i3khepus_is')
       this.user_def.is_doc            = this.read_sessionStorage('is_doc')
       this.user_def.podeAbrir         = this.read_sessionStorage('set.podeAbrir')
       this.user_def.user_name         = DSirotinas.cripto(this.read_sessionStorage('84hfjdyruxy1_is'))
       
       
       
       this.whats_confirmacao    = this.read_sessionStorage('whats_confirmacao')
       this.whats_obs            = this.read_sessionStorage('whats_obs')
       this.whats_obs_2          = this.read_sessionStorage('whats_obs_2')
       this.whats_aviso          = this.read_sessionStorage('whats_aviso')

      //  painel dos horarios
       this.value_expansion = parseInt(sessionStorage.getItem('value_expansion'))

       this.agenda_colorida = (localStorage['colorido'] == 'N') ? false : true
       
       this.superA = (sessionStorage['superA']=='S')


       this.obj_user = await DSibasico.retorna_obj_sessions('obj_user')
       this.obj_dados_empresa    = JSON.parse(sessionStorage['obj_dados_empresa'])


    },

    timerAtualiza() {

      if (this.$route.name == 'Agenda'){

            if (this.pode_timer){
            // if ((this.pode_timer)&&(this.dialog_editar==false)){

                  this.pode_timer = false //defino que nao.. para nao ficar criando varios timers

                  setTimeout(function(){
                    // console.log('timerAtualiza')
                    this.pode_timer = true
                    this.abre_agenda() 
                  }.bind(this), 35000);//usar esse .bind(this) para chamar funcoes com o setTimeout   
            }     
      }else{
        console.log('nao esta na agenda.. parando timer');
      }

    },


    async carregar_preferencias(doc){

      if (doc>0){

          // console.log('carregar_preferencias:'+ doc);
          await this.horario_doc(doc)
          await this.lista_procedimentos(doc)

          //limpa alguns campos
          this.hora_agendar_selecionada = ''
      }

      if (this.whats_multiplos){
          this.status_zap()
      }
    },


    async abre_agenda(){


      // console.log('abre_agenda');
      this.abriu_agenda = false

      let codigo = -1
      if (this.doc_codigo.CODIGO >=0){
         codigo = this.doc_codigo.CODIGO 
      }

      // console.log('abre_agenda');
      if (!this.stop_agenda){
        await this.crud_agenda(codigo,this.in_today) //abre a agenda com o doc especificado
      }

      this.timerAtualiza()//chama o timer pra atualizar


      // // verifico aqui pois quando da um F5 ele nao busca os horarios
      if (this.ja_horario_doc == false ){

            if (this.pode_timer_horario){

                  this.pode_timer_horario = false //defino que nao.. para nao ficar criando varios timers

                  setTimeout(function(){
                    // console.log('pode_timer_horario')
                    this.pode_timer_horario = true

                    this.filtra_horarios_doc(codigo)//filtra horarios disponiveis para agendamento 

                  }.bind(this), 222); //2000//usar esse .bind(this) para chamar funcoes com o setTimeout   
            }   

      }else{
        await this.filtra_horarios_doc(codigo)//filtra horarios disponiveis para agendamento
      }
      
      
      //deixo pro final para nao pesar nos horarios
       await this.abrir_estatisticas(codigo, this.in_today, this.in_today)//abre estatisticas
      

      if (this.zapzap){
         if (sessionStorage['zazap_time']){
             let agora = new Date();
             let time  = new Date(sessionStorage['zazap_time'])
             if (time <= agora){
                 await this.trata_retorno_webhook()
             }
         }
         else { //ainda nao rodou..entao roda pela 1 vez...
          await this.trata_retorno_webhook()
         }
        
      }
      
    },

     abre_conduta(item){


       let idade = ''
       if (item.DATANASCIMENTO){
         idade = this.calcula_idade(this.getFormatDate(item.DATANASCIMENTO), 1)
       }

       this.$set(item, 'IDADE', idade);//defino valor manualmente
        

      //  salvo na session os dados da agenda
       sessionStorage['item_clicou_agenda']  =  JSON.stringify(item)

       this.associa(item.CODIGOCONSULTA)//seleciono a linha 
       
       //nome e codigo do medico pegar do input.. pois da lista ele pode misturar quando a agenda é compartilhada com sub_doc
       this.set_sessionStorage('getCodigoDoc',this.doc_codigo.CODIGO)
       this.set_sessionStorage('getNomeDoc',this.doc_codigo.NOME)
       this.set_sessionStorage('getCRMdoc',this.doc_codigo.CRM)
       this.set_sessionStorage('getCRMuf',this.doc_codigo.UF)
       this.set_sessionStorage('getConselho',this.doc_codigo.CNES_PF)


       sessionStorage['obj_dados_profissional'] = JSON.stringify(this.doc_codigo)


       //salvo direto no sessionStorage para ser lido no print.vue
       sessionStorage['printCRMUF']     = this.doc_codigo.UF
       sessionStorage['getEndPaciente'] = item.ENDERECOCOMPLETO+' '+item.CIDADE +' - '+ item.UF  
       

       this.set_sessionStorage('getCodigoConsulta',item.CODIGOCONSULTA)
       this.set_sessionStorage('getCodigoPaciente',item.CODIGOCLIENTE)
       this.set_sessionStorage('getCodigoConvenio',item.CODIGOCONVENIO)
       this.set_sessionStorage('getNomePaciente',item.NOME)
       this.set_sessionStorage('getConvenio',item.CONVENIO)
       this.set_sessionStorage('getProcedimento',item.PROCEDIMENTO)

       this.set_sessionStorage('getIdade', idade)

       this.set_sessionStorage('getProfissao',item.PROFISSAO)
       this.set_sessionStorage('getData',item.DATA)
       this.set_sessionStorage('getHora',item.HORA)
       this.set_sessionStorage('getCPF',item.CPF)
       this.set_sessionStorage('getRG',item.RG)
       this.set_sessionStorage('getEstadoCivil',item.ESTADOCIVIL)
       this.set_sessionStorage('getCidade',item.CIDADE)
       this.set_sessionStorage('getEndereco',item.ENDERECOCOMPLETO+' '+item.CIDADE+' '+item.UF)
       this.set_sessionStorage('getAtendido',item.ATENDIDO)
       this.set_sessionStorage('getSexo',item.SEXO)
       this.set_sessionStorage('getNascimento',this.getFormatDate(item.DATANASCIMENTO))

       sessionStorage.setItem('value_expansion', this.value_expansion)


       //salvo obj com os dados do paciente
       // Transformar o objeto em string e salvar em sessionStorage
       sessionStorage.setItem('obj_dados_paciente', JSON.stringify(item));
      
      
       //muda a situação na agenda      
      //  alert(item.CODIGOCONSULTA +' - '+item.CODIGO)
      
      if (item.ATENDIDO == 'G'){//somente se estiver aguardando
         this.crud_update('CODIGOCONSULTA',item.CODIGO,'agenda', { ATENDIDO:'T', LOG_RECEPCAO: item.LOG_RECEPCAO+'\r\n'+ this.data_hora +'_w_'+ this.user_def.user_name +': ATENDENDO'});
        //  this.crud_update('CODIGO',item.CODIGO,'agenda', this.editaCampos);

        DSi_funcoes.registra_log('ATENDIMENTO','Atendendo '+item.NOME + ' das ' + item.HORA)
      }
      
      //  exit;

       this.$router.push('/Atender')//redireciono para a pagina do router
     },

     associa(cs){

        this.selected= [{"CODIGOCONSULTA" : cs}] //via v-modal no data-table
      //  alert('associa: '+cs)
        //filtra array .. tb funciona
        this.desserts_filtrado  = this.desserts.filter(function(obj) { return (obj.CODIGOCONSULTA == cs) });
        // this.desserts = this.desserts_filtrado
        // this.selected = this.filtrar

        if(this.TESTE == true){
          console.log('desserts_filtrado:');
          console.log(this.desserts_filtrado);          
        }
     },

      async abre_profissionais(){ 
        
       let meuSQL = '' 
       let is_doc = this.read_sessionStorage('is_doc')
       if (is_doc == 'S'){
         let is_doc_codigo = this.read_sessionStorage('is_doc_codigo')
         meuSQL = "select * from doutores where ativo = 'S' and codigo = "+is_doc_codigo
       }
       else{
         meuSQL = "select * from doutores where ativo = 'S' order by doutores.nome"
       }


       const res = await axios.post(url+'conexao.php', {
          acao:   5,
          table:  this.crud_table,
          meuSQL: meuSQL
        })

        // console.log(res.data);          
        this.profissionais = await res.data //pego o nome dos profissionais

        this.profissionais_sub = this.profissionais.map((x) => x); //clonando array copiar array

        sessionStorage['obj_profissionais_sub']  =  JSON.stringify(this.profissionais_sub)//salva para ser utilizado no print.vue

        // console.log('this.profissionais:', this.profissionais);
        if (is_doc!='S'){
          this.profissionais.unshift({CODIGO:'0', NOME:'TODOS', CATEGORIA:'TODOS', CRM:' ' }) //no começo
        }

        this.profissionais_sub.unshift({CODIGO:'0', NOME:'NENHUM', CATEGORIA:'NENHUM', CRM:' ' }) //no começo


        this.simples =  (this.profissionais?.length < 15)

      }, 

      
      getColorSituacao (v,sub) {
        if ((sub =='')||(sub == null)){
          if (v == 'G') return '#0080ff' //aguardando
          if (v == 'N') return '#804040' //retorno
          if (v == 'F') return '#ff8080' //faltou
          if (v == 'L') return '#ff8080' //Cancelado
          if (v == 'T') return '#dede03' //Atendendo
          if (v == 'O') return '#800080' //Confirmado
          if (v == 'H') return '#800080' //Confirmado
          if (v == 'Q') return '#E70EA8' //Remarcado
          if (v == 'C') return 'black'   //Cancelado
          if (v == 'P') return 'orange'   //Antecipar
          else if (['D','B'].includes(v)) return 'red' //desmarcou / bloqueado
          else if (v == 'A') return 'green' //atendido
          else return 'grey'

        }
        else {
          return '#03e3e3'
        }
      },

      getColorLinha (item) {
        let r = ''
        let v = item.ATENDIDO
        let sub =  item.SUB_DOC_NOME
        let altura = ''

        if ((sub =='')||(sub == null)){
          if (v == 'G') r =  'blue lighten-5'
          else if (v == 'N') r =  'brown lighten-4'
          else if (v == 'F') r =  'red lighten-4'
          else if (v == 'L') r =  '#ff8080'
          else if (v == 'T') r =  'yellow lighten-4'
          else if (v == 'O') r =  'purple lighten-5'
          else if (v == 'H') r =  '#800080'
          else if (v == 'Q') r =  'pink lighten-4'
          else if (v == 'P') r =  'orange lighten-4'
          else if (v == 'C') r =  'black'
          else if (['D','B'].includes(v)) r =  'red accent-1'
          else if (v == 'A') r =  'green lighten-5'
          else r =  'grey lighten-4'

        }
        else {
            if (v == 'A')      r =  'green lighten-5'
            else if (v == 'F') r =  'red lighten-4'

            else               r =  'cyan lighten-5'//cor padrao
        }


        if (item.DURACAO >= 300){
            altura = ' myclass300'
        }
        else if (item.DURACAO >= 240){
            altura = ' myclass240'
        }
        else if (item.DURACAO >= 180){
            altura = ' myclass180'
        }
        else if (item.DURACAO >= 120){
            altura = ' myclass120'
        }
        else if (item.DURACAO >= 60){
            altura = ' myclass60'
        }


        // 1hora = 60
        // 2hora = 120
        // 3hora = 180
        // 4hroa = 240
        // 5hroa = 300

        return r + altura
        // return r
      },

      getColorTexto(texto){

        let bold =' font-weight-bold'

       if (texto.includes('CONSULTA')) return 'green--text'+bold
       else if (texto.includes('RETORNO')) return 'orange--text'+bold

       else if (texto.includes('PARTICULAR')) return 'green--text'+bold

       else if (texto.includes('DINHEIRO')) return 'green--text'+bold
       else if (texto.includes('CONVENIO')) return 'orange--text'+bold


      },
      
      getSituacao (v,sub) {
        if ((sub =='')||(sub == null)){
          if (v == 'G') return 'Aguardando'
          if (v == 'N') return 'Retorno'
          if (v == 'T') return 'Atendendo'
          if (v == 'F') return 'Faltou'
          if (v == 'B') return 'Bloqueado'
          if (v == 'D') return 'Desmarcou'
          if (v == 'M') return 'Agendado'
          if (v == 'W') return 'Agendado'
          if (v == 'O') return 'Confirmado'
          if (v == 'H') return 'Confirmado'
          if (v == 'L') return 'Cancelado'
          if (v == 'C') return 'Cancelado'
          if (v == 'Q') return 'Remarcado'
          if (v == 'P') return 'Antecipar'
          else if (v == 'A') return 'Atendido'
        }
        else{
          return 'Compartilhado com: '
        }
      },

      getFormatDate(data){
        let dia =   data.substring(8,10) + '/'+ data.substring(5,7)+ '/'+ data.substring(0,4);//formato a dd/mm/yyyy
        return dia 
      },


      getHoraTexto(hora){
        //retorna hora sem os :
        let a =   hora.substring(0,2) + hora.substring(3,5)+ hora.substring(6,8);//formato a dd/mm/yyyy
        return a 
      },

       set_defaults(){

       //associa a data de hoje com o input do filter
        this.in_today = this.today //pega por padrao a data de hoje
        // console.log('a = '+this.today);

        if (this.read_sessionStorage('dia',this.in_today) !== undefined){
          // console.log('b = '+ this.read_sessionStorage('dia',this.in_today));
          this.in_today = this.read_sessionStorage('dia',this.in_today)
        }
       },

      f_dia_da_semana(entra){
         let dia = entra.slice(8, 10)
         let mes = entra.slice(5, 7)
        //  alert(entra)
        //  const dayOfWeek = new Date(entra).getDay(); 

        var weekday = new Array("segunda-feira", "terça-feira", "quarta-feira", "quinta-feira","sexta-feira", "sábado", "domingo");
        //segunda = 0, assim por diante

        let s_dia =  weekday[new Date(entra).getDay()] //segunda = 0, assim por diante
      //  alert(s_dia)
        return dia+'/'+mes+' '+s_dia
      },

      update_values(){
        // console.log('update_values');
        let a = new Date().toLocaleDateString('pt-Br',{ datestyle: 'short', hour12: false, timeZone: 'America/Sao_Paulo' });  
        // console.log('hoje = '+ a);
        this.hoje = a;

        // a = new Date().toLocaleTimeString('pt-Br',{ timeStyle: 'short', hour12: false, timeZone: 'America/Sao_Paulo' });
        a = new Date().toLocaleTimeString('pt-Br',{ hour12: false, timeZone: 'America/Sao_Paulo' });
        // console.log('hora = '+ a);
        this.hora = a;

        a = this.hoje + '_'+ this.hora;
        // console.log('data_hora = '+ a);
        this.data_hora = a;


        a = this.hoje.slice(6, 10) +'-'+ this.hoje.slice(3, 5) +'-'+ this.hoje.slice(0, 2) ; //formato us yyyy-mm-dd
        // console.log('today = '+ a);
        this.today = a;

        a = this.data_hora+' ' /* +this.user */;
        // console.log('alterou = '+ a);
        this.alterou = a;
      },

    //  allUpper(event, field){
    //   let a = event.target.value;//captura o valor do event
    //   if (a!==''){  
    //     a = a.toUpperCase();
    //     this.editedItem[field] = a;
    //   } 
    //  },

      allUpper(event, obj_name, field){
          this[obj_name][field] = DSibasico.allUpper(event)
      },
     
     firstUpper(event, field){
      // console.log(event);
      let a = event.target.value;//captura o valor do event
            if (a!=='' && a.length==1){  
        a = a.toUpperCase();
        this.editedItem[field] = a;
      } 
     },


     prepara_whats(tipo,cel,nome, item, show_link){



        // console.log('item:', item);
        
        // ENVIO AUTOMATICO

        if (!cel){
          return 'SEM CELULAR'
        }

        // console.log(item);
        let s_confirmar = ''
        let s_cancelar  = ''

        if (show_link){
          s_confirmar = show_link[0]
          s_cancelar  = show_link[1]
        }


        

        cel = cel.replace(/[^\d]+/g,'');
        // cel = cel.substring(0,2) + cel.substring(3,20);//deixo de fora o 1º 9 //nao precisa mais disso
        
        nome = nome.toLowerCase().trim();  //passo tudo pra minuscula
        nome = nome.split(" ");//separo as palavras
        nome = nome[0];//pego o primeiro nome        
        nome = nome.charAt(0).toUpperCase() + nome.slice(1);//passo 1 letra maiuscula

        let quebra = '\n'

        let hora   =  item.HORA.slice(0, 5)

        let D1     = ''
        let hora_2 = ''

        if (this.adiantar_hora.includes('-10')){ hora_2 = 10}
        else if (this.adiantar_hora.includes('-15')){ hora_2 = 15}
        else if (this.adiantar_hora.includes('-20')){ hora_2 = 20}
        else if (this.adiantar_hora.includes('-30')){ hora_2 = 30}

        if (hora_2){
            D1 = new Date(this.today+' '+hora);
            D1.setMinutes(D1.getMinutes() - hora_2 );

            hora_2 = new Date(D1).toLocaleTimeString('pt-Br',{ hour12: false, timeZone: 'America/Sao_Paulo' });
            hora = hora_2
        }
        
        let url   = ''

        //mudo esse teste para true ou false, isso sera lido em tudo relacionado ao envio de link whats confirmacao, /r/index e /i/index   
        let teste = false

        if (teste){
           url   = 'http://localhost/vue_agenda_firebird/public/r/'
           cel = '4499685172'
           alert('testando')
        }
        else{
           url   = 'https://dsi.app.br/r/'
        }
        

        let empresa = sessionStorage['LSEmpresa']
        let ide_app = sessionStorage['LSid']

        let obs   =  (this.checkbox_obs) ? this.whats_obs  : ''
        if (obs == undefined){obs=''}
        
        let obs_2 =  (this.checkbox_obs_2) ? this.whats_obs_2  : ''
        if (obs_2 == undefined){obs_2=''}
        
        let aviso = this.whats_aviso


        let msg_confirmacao = this.whats_confirmacao

        let obs_proced = (item.WHATS_OBS_PROCED)



        let msg = ''
        if (tipo == 'ola'){
           msg = "Olá\n" + nome 
        }
        else if (tipo == 'confirmacao'){
     
        
          //i = explode
          let link_confirmar = '✅ Para *CONFIRMAR* clique no Link ou *(Digite 1)*'+ quebra+' '+ 
                         url+'?token=ci'+ ide_app+'i'+item.CODIGO+ 'i'+ this.getHoraTexto(item.HORA)

          //copio o token do confirmar para ver o mapa gps  =m = acao
          // let link_mapa = '📍 *GPS:*  ' + url+'?token=1i'+ ide_app+'i'+'17'
          let link_mapa = ''
          
          let link_cancelar = '❌ Para *CANCELAR* clique no Link ou *(Digite 9)*'+ quebra+' '+
                         url+'?token=fi'+ ide_app+'i'+item.CODIGO+ 'i'+ this.getHoraTexto(item.HORA)

          if (teste){
            // & = %26 tem que mandar via codigo o & comercial.. para nao dar erro
            link_confirmar += 'i%26teste'; //isso sera lido nos arquivos /i/index e /r/index
            link_cancelar  += 'i%26teste';
            link_mapa      += 'i%26teste';
            
            //tabela de codigos url whats https://www.eso.org/~ndelmott/url_encode.html
          }              
          // alert(link_confirmar); exit;


          //mandar link somente para clienes que estao ok
          let clientes_link = [
            '5',   //comunidade
            '356', //amicis
            '221',  //alexandre dallagnol
            '350',  //md vida
            '374',  //delfim
            '150',  //cliniman
            '326',  //carlos
            '388',  //soni
            '392',  //lucio
            '393',  //odontorriso
            '45',  //teste
            '50'  //artmed
          ]
          // automatico

          //achou os clientes 
          if (clientes_link.includes(ide_app)){
             link_confirmar +=  quebra + quebra
             link_cancelar  +=  quebra + quebra
          }else{
            link_confirmar = '✅ Para *CONFIRMAR (Digite 1)*'+ quebra + quebra 
            link_cancelar  = '❌ Para *CANCELAR (Digite 9)*'+ quebra +quebra
            link_mapa      = ''
          }



          // if (ide_app == '5'){
          //remove os links
          if (s_confirmar == 'N'){
            link_confirmar = ''
          }
          if (s_cancelar == 'N'){
            link_cancelar = ''
          }

        
          if (this.doc_codigo.CRM == '08681'){
            msg = item.CODIGOCLIENTE +quebra+
                  `Olá, sou a Atendente Virtual 👩🏼‍💻 de *`+ empresa +`*, gostaria de *confirmar* a presença de *`+ nome +`* para *entrega de exames* no dia *`+ this.f_dia_da_semana(item.DATA)  +`* às *15hs* por *ordem de chegada*, com a *Dra. Maria Filomena*.`+quebra+ quebra
          }
          else if (msg_confirmacao){
            msg = this.replace_parametros(msg_confirmacao, item, hora) +quebra+ quebra+ quebra
          }
          else if (this.esta_empresa('acencon')){

            msg = `Olá, sou a Atendente Virtual @av de *`+ empresa +`*. ${quebra + quebra}Gostaria de *confirmar* a presença de *`+ nome +`* para *`+ item.PROFISSIONAL +`* no dia *`+ this.f_dia_da_semana(item.DATA)  +`* às *`+ hora +`*.`+quebra+ quebra  
          }
          else{
            msg = 
            item.CODIGOCLIENTE +quebra+
            `Olá, sou a Atendente Virtual @av de *`+ empresa +`*, gostaria de *confirmar* a presença de *`+ nome +`* com *`+ item.PROFISSIONAL +`* no dia *`+ this.f_dia_da_semana(item.DATA)  +`* às *`+ hora +`*.`+quebra+ quebra
          }

          

          msg = msg +
            link_confirmar + 
            link_cancelar  + 
            '*Telefone:*  '+sessionStorage['LSfone1'] + quebra +
            '*Local:*  '   +sessionStorage['LSendereco'] + quebra 
            + link_mapa
        

          //pega obs para mandar junto com a confirmação
          if(tipo == 'confirmacao'){

            if (obs_proced !== ''){
              obs_proced = obs_proced.replaceAll('\n', quebra) //quebra de linha
              msg = msg +  '*Nota:*'+quebra+ obs_proced
            }

            if (obs.length>5){
              obs = obs.replaceAll('\n', quebra) //quebra de linha
              msg = msg + quebra+ '*Observação:*'+quebra+ obs
            }

            if (obs_2.length>5){
              obs_2 = obs_2.replaceAll('\n', quebra) //quebra de linha
              msg = msg + quebra+ '*Observação 2:*'+quebra+ obs_2
            }


            let parar = this.enviar_parar
            if (parar){
               msg = msg + quebra + `* Caso não queira mais receber esse tipo de mensagem, responda *PARAR*` 
            }
          }


        }
        else if (tipo == 'aviso'){

           if (aviso == undefined){
             alert('Aviso não foi definido')
           }else{

            //  console.log(item);
             msg = aviso //pega a mensagem modelo

            //  maiusculas
             msg = msg.replaceAll('@IDE',item.CODIGOCLIENTE)
             msg = msg.replaceAll('@PACIENTE',item.NOME.trim() || item.NOME_AGENDA.trim())
             msg = msg.replaceAll('@PROFISSIONAL',item.PROFISSIONAL)

             msg = msg.replaceAll('@CONVENIO',item.CONVENIO)
             msg = msg.replaceAll('@PROCEDIMENTO',item.PROCEDIMENTO)
             msg = msg.replaceAll('@DATA_AGENDAMENTO',item.DATA)
             msg = msg.replaceAll('@HORA_AGENDAMENTO',hora)
             msg = msg.replaceAll('@CLINICA',empresa)
             msg = msg.replaceAll('@ENDERECO_CLINICA',sessionStorage['LSendereco'])


            //  minusculas
             msg = msg.replaceAll('@ide',item.CODIGOCLIENTE)
             msg = msg.replaceAll('@paciente',item.NOME.trim() || item.NOME_AGENDA.trim())
             msg = msg.replaceAll('@profissional',item.PROFISSIONAL)

             msg = msg.replaceAll('@convenio',item.CONVENIO)
             msg = msg.replaceAll('@procedimento',item.PROCEDIMENTO)
             msg = msg.replaceAll('@data_agendamento',item.DATA)
             msg = msg.replaceAll('@hora_agendamento',hora)
             msg = msg.replaceAll('@clinica',empresa)
             msg = msg.replaceAll('@endereco_clinica',sessionStorage['LSendereco'])

            
             msg = msg.replaceAll('\n', quebra) //quebra de linha

            //  exit;

           } 
        }

        return msg
        
     },


     async send_whats(tipo,cel,nome, item,show_link, abrir_url){

        if (item.NOME_AGENDA != item.NOME){
            nome = item.NOME_AGENDA
        }

        // console.log('item:', item);
        
        // ENVIO MANUAL
        console.log('send_whats - item:', item);


        if (!cel){
          return 'SEM CELULAR'
        }

        // console.log(item);
        let s_confirmar = ''
        let s_cancelar  = ''

        if (show_link){
          s_confirmar = show_link[0]
          s_cancelar  = show_link[1]
        }


        

        cel = cel.replace(/[^\d]+/g,'');
        // cel = cel.substring(0,2) + cel.substring(3,20);//deixo de fora o 1º 9 //nao precisa mais disso
        
        nome = nome.toLowerCase().trim();  //passo tudo pra minuscula
        nome = nome.split(" ");//separo as palavras
        nome = nome[0];//pego o primeiro nome        
        nome = nome.charAt(0).toUpperCase() + nome.slice(1);//passo 1 letra maiuscula

        
        
        let quebra = '%0A'

        let hora   =  item.HORA.slice(0, 5)

        let D1     = ''
        let hora_2 = ''

        if (this.adiantar_hora.includes('-10')){ hora_2 = 10}
        else if (this.adiantar_hora.includes('-15')){ hora_2 = 15}
        else if (this.adiantar_hora.includes('-20')){ hora_2 = 20}
        else if (this.adiantar_hora.includes('-30')){ hora_2 = 30}

        if (hora_2){
            D1 = new Date(this.today+' '+hora);
            D1.setMinutes(D1.getMinutes() - hora_2 );

            hora_2 = new Date(D1).toLocaleTimeString('pt-Br',{ hour12: false, timeZone: 'America/Sao_Paulo' });
            hora = hora_2
        }

        
        let url   = ''

        //mudo esse teste para true ou false, isso sera lido em tudo relacionado ao envio de link whats confirmacao, /r/index e /i/index   
        let teste = false

        if (teste){
           url   = 'http://localhost/vue_agenda_firebird/public/r/'
           cel = '4499685172'
           alert('testando')
        }
        else{
           url   = 'https://dsi.app.br/r/'
        }
        

        let empresa = sessionStorage['LSEmpresa']
        let ide_app = sessionStorage['LSid']

        let obs   =  (this.checkbox_obs) ? this.whats_obs  : ''
        if (obs == undefined){obs=''}
        
        let obs_2   =  (this.checkbox_obs_2) ? this.whats_obs_2  : ''
        if (obs_2 == undefined){obs_2=''}
        
        let aviso = this.whats_aviso

        let msg_confirmacao = this.whats_confirmacao

        let obs_proced = (item.WHATS_OBS_PROCED)
        // return false



        let msg = ''
        if (tipo == 'ola'){
           msg = "Olá%20" + nome 
        }
        else if ((tipo == 'confirmacao') || (tipo == 'confirmado')){
     
        
          //i = explode
          // ou *Digite 1*
          let link_confirmar = '✅ Para *CONFIRMAR* clique no link:'+ quebra+' '+
                         url+'?token=ci'+ ide_app+'i'+item.CODIGO+ 'i'+ this.getHoraTexto(item.HORA)

          //copio o token do confirmar para ver o mapa gps  =m = acao
          let link_mapa = '📍 *GPS:*  ' + url+'?token=1i'+ ide_app+'i'+'17'
          // let link_mapa = ''
          
          // ou *Digite 9*
          let link_cancelar = '❌ Para *CANCELAR* clique no Link:'+ quebra+' '+
                         url+'?token=fi'+ ide_app+'i'+item.CODIGO+ 'i'+ this.getHoraTexto(item.HORA)

          if (teste){
            // & = %26 tem que mandar via codigo o & comercial.. para nao dar erro
            link_confirmar += 'i%26teste'; //isso sera lido nos arquivos /i/index e /r/index
            link_cancelar  += 'i%26teste';
            link_mapa      += 'i%26teste';
            
            //tabela de codigos url whats https://www.eso.org/~ndelmott/url_encode.html
          }              
          // alert(link_confirmar); exit;


          //mandar link somente para clienes que estao ok
          let clientes_link = [
            '5',   //comunidade
            '356', //amicis
            '221',  //alexandre dallagnol
            '350',  //md vida
            '374',  //delfim
            // '150',  //cliniman
            '326',  //carlos
            '388',  //soni
            '392',  //lucio
            '393',  //odontorriso
            '45',  //teste
            '414',  //acencon
            '1008',  //dayclinic
          ]
          // manual

          //achou os clientes 
          if (clientes_link.includes(ide_app)){
             link_confirmar +=  quebra + quebra+ quebra
             link_cancelar  +=  quebra + quebra+ quebra
          }else{
            link_confirmar = ''
            link_cancelar  = ''
            link_mapa      = ''
          }



          // if (ide_app == '5'){
          //remove os links
          if (s_confirmar == 'N'){
            link_confirmar = ''
          }
          if (s_cancelar == 'N'){
            link_cancelar = ''
          }

        
          if (this.doc_codigo.CRM == '08681'){
            msg = item.CODIGOCLIENTE +quebra+
                  `Olá, sou a Atendente Virtual 👩🏼‍💻 de *`+ empresa +`*, gostaria de *confirmar* a presença de *`+ nome +`* para *entrega de exames* no dia *`+ this.f_dia_da_semana(item.DATA)  +`* às *15hs* por *ordem de chegada*, com a *Dra. Maria Filomena*.`+quebra+ quebra+ quebra
          }
          else if (tipo == 'confirmado'){
          
            msg = 
              item.CODIGOCLIENTE +quebra+
              `Olá *${nome}*, sou a Atendente Virtual 👩🏼‍💻 de *`+ empresa +`*, informamos que sua consulta esta *agendada* para o dia *${this.f_dia_da_semana(item.DATA)}* às *${hora}* com *${item.PROFISSIONAL}*.${quebra}${quebra}`+
              `Estaremos aguardando por você. 😉${quebra}${quebra}`


          }
          else if (msg_confirmacao){
            msg = this.replace_parametros(msg_confirmacao, item, hora) +quebra+ quebra+ quebra
          }
          else{
            msg = 
            item.CODIGOCLIENTE +quebra+
            `Olá, sou a Atendente Virtual 👩🏼‍💻 de *`+ empresa +`*, gostaria de *confirmar* a presença de *`+ nome +`* com *`+ item.PROFISSIONAL +`* no dia *`+ this.f_dia_da_semana(item.DATA)  +`* às *`+ hora +`*.`+quebra+ quebra+ quebra
          }

          

          msg = msg +
            link_confirmar + 
            link_cancelar  + 
            '*Telefone:*  '+sessionStorage['LSfone1'] + quebra +
            '*Local:*  '   +sessionStorage['LSendereco'] + quebra + quebra 
            + link_mapa
        

            //pega obs para mandar junto com a confirmação
            if(tipo == 'confirmacao'){

                if (obs_proced !== ''){
                  obs_proced = obs_proced.replaceAll('\n', quebra) //quebra de linha
                  msg = msg +  '*Nota:*'+quebra+ obs_proced
                }

                if (obs.length>5){
                  obs = obs.replaceAll('\n', quebra) //quebra de linha
                  msg = msg + quebra+ quebra+ '*Observação:*'+quebra+ obs
                }
            }

        }
        else if (tipo == 'aviso'){

           if (aviso == undefined){
             alert('Aviso não foi definido')
           }else{

            
            let sql = ''
            if (this.esta_empresa('odontorriso')){
               sql = `update clientes set ENVIOU_AVISO = '${this.today}' where CODIGOCLIENTE = ${item.CODIGOCLIENTE}`
               this.crud_sql(sql)
               this.$set(item, 'ENVIOU_AVISO', this.today);//defino valor manualmente
            }

            //  console.log(item);
             msg = aviso //pega a mensagem modelo

             msg = msg.replaceAll('@IDE',item.CODIGOCLIENTE)
             msg = msg.replaceAll('@PACIENTE',item.NOME.trim())
             msg = msg.replaceAll('@PROFISSIONAL',item.PROFISSIONAL)

             msg = msg.replaceAll('@CONVENIO',item.CONVENIO)
             msg = msg.replaceAll('@PROCEDIMENTO',item.PROCEDIMENTO)
             msg = msg.replaceAll('@DATA_AGENDAMENTO',item.DATA)
             msg = msg.replaceAll('@HORA_AGENDAMENTO',item.HORA)
             msg = msg.replaceAll('@CLINICA',empresa)
             msg = msg.replaceAll('@ENDERECO_CLINICA',sessionStorage['LSendereco'])

            
             msg = msg.replaceAll('\n', quebra) //quebra de linha

            //  exit;

           } 
        }

        if ((msg !=='')&&(abrir_url)){
          if (this.isMobile){
            window.open("https://api.whatsapp.com/send?phone=55"+ cel +'&text='+ msg);
          }else{
            window.open("https://web.whatsapp.com/send?phone=55"+ cel +'&text='+ msg);
          }
        }

        // return msg
        
     },

     call_phone(cel){
         window.open("tel:"+ cel);
     },


     keyUpp(evt) {
       
        //limpa o input search quando pressiona Esc
        if (evt == 'esc') {            
            // alert('clicou em Esc');
            this.search = '';
        }
      },

      alerta(text,color, time){
        // alert(text);

        if (time){
           this.snack_timeout = time
          //  console.log(time);
         }
         else {
           this.snack_timeout = 2000
          //  console.log('ops');
         }

         this.snackbar = true;
         this.snack_text= text;



         if (color){
            this.snack_color = color;
         }
         else{
            if ( (text.includes('ERROR:')) || (text.includes('error'))){  
              this.snack_color = "red";
            }else{
              this.snack_color = "success";
            }
         }
      },


      async crud_agenda (doc,data) {

        this.mostrar_tabela =  false

        if (doc == 0){
          doc = ' 0 or 1>0'
        }

        let dia =   data.substring(5,7) + '/'+ data.substring(8,10)+ '/'+ data.substring(0,4);//formato a mm/dd/yyyy
        // let dia =   data.substring(8,10) + '/'+ data.substring(5,7)+ '/'+ data.substring(0,4);//formato a dd/mm/yyyy


        let sql_convenio = ``
        let cod_convenio = 0
        try{
           cod_convenio = this.obj_user.CODIGO_CONVENIO
        }catch (e) {  }
      
        if (cod_convenio>0){
          // alert('maior')
           sql_convenio = `AND (AGENDA.CODIGOCONVENIO = ${cod_convenio})`
        }
      

        //separei os campos da agenda para ser usado quando editar a agenda
        this.campos_agenda = 
`
    AGENDA.CODIGO,
    AGENDA.DATA,
    AGENDA.HORA,
    AGENDA.HORA_CHEGADA,
    AGENDA.HORAF,
    AGENDA.ATENDIDO,
    AGENDA.CODIGOCONSULTA,
    AGENDA.CODIGOCLIENTE,
    AGENDA.CODIGOMEDICO,
    AGENDA.CODIGOPROCEDIMENTO,
    AGENDA.CODIGOCONVENIO,
    AGENDA.PROCEDIMENTO,
    AGENDA.OBS,
    AGENDA.NOME AS NOME_AGENDA,
    AGENDA.SUB_DOC,
    AGENDA.SUB_DOC_NOME,
    AGENDA.NOME_DOC,
    AGENDA.IDADE_STRING,
    AGENDA.LOG_RECEPCAO,
    AGENDA.DURACAO,
    AGENDA.HORA_ATE,
    AGENDA.EFETUADO,
    AGENDA.VALOR,
    AGENDA.FORMA_PAGAMENTO,
    AGENDA.AVISOU_WHATS,
    AGENDA.REGISTRO,
    AGENDA.OK_VIA_WHATS,
    AGENDA.FONE,
    AGENDA.WHATS_ID,
    AGENDA.WHATS_RETORNO,


    (
     SELECT COUNT(AG.CODIGO)
     FROM AGENDA AS AG
     WHERE (AG.CODIGOCLIENTE = AGENDA.CODIGOCLIENTE)AND(AG.ATENDIDO<>'C')
    ) AS NUM_CONSULTAS,
    
    (
     SELECT COUNT(AG.CODIGO)
     FROM AGENDA AS AG
     WHERE (AG.CODIGOCLIENTE = AGENDA.CODIGOCLIENTE)AND(AG.ATENDIDO='C')
    ) AS NUM_CANCELAMENTOS,
    
    (
     SELECT COUNT(AG.CODIGO)
     FROM AGENDA AS AG
     WHERE (AG.CODIGOCLIENTE = AGENDA.CODIGOCLIENTE)AND(AG.ATENDIDO='F')
    ) AS NUM_FALTAS

`


        var meuSQL = 
`
    SELECT
    CLIENTES.NOME,
    CLIENTES.FONE1,
    CLIENTES.CELULAR,
    CLIENTES.PROFISSAO,
    CLIENTES.CIDADE,
    CLIENTES.ENDERECOCOMPLETO,
    CLIENTES.UF,
    CLIENTES.CPF,
    CLIENTES.RG,
    CLIENTES.ESTADOCIVIL,
    CLIENTES.SEXO,
    CLIENTES.DATANASCIMENTO,
    CLIENTES.NOMEMAE,
    CLIENTES.NOMEPAI,
    CLIENTES.FOTO_URL,
    CLIENTES.ENVIOU_AVISO,
    CLIENTES.NUMCARTEIRA,
    CONVENIO.CODIGO AS CODIGOCONVENIO,
    CONVENIO.NOME AS CONVENIO,
    DOUTORES.NOME AS PROFISSIONAL,
    VALORES_AMB.WHATS_OBS AS WHATS_OBS_PROCED,
`+
 this.campos_agenda   
+`
  FROM
   AGENDA
   LEFT OUTER JOIN CLIENTES ON (AGENDA.CODIGOCLIENTE=CLIENTES.CODIGOCLIENTE)
   LEFT OUTER JOIN DOUTORES ON (AGENDA.CODIGOMEDICO=DOUTORES.CODIGO)
   LEFT OUTER JOIN CONVENIO ON (AGENDA.CODIGOCONVENIO=CONVENIO.CODIGO)
   LEFT OUTER JOIN VALORES_AMB ON (AGENDA.CODIGOPROCEDIMENTO=VALORES_AMB.CODIGO)
 WHERE
   ((AGENDA.CODIGOMEDICO = `+doc+`) OR
  (AGENDA.SUB_DOC = `+doc+`))AND
  (AGENDA.DATA = '`+dia+`') AND
  (AGENDA.TIPO = 'A') AND
  (AGENDA.ATENDIDO <> 'C') AND
  (AGENDA.ATENDIDO <> 'D') 
  ${sql_convenio}

    ORDER BY
    AGENDA.HORA
`

//  console.log(meuSQL);


        
          const res2 = await axios.post(url+'conexao.php', {
            acao:   5,
            table:  this.crud_table,
            meuSQL: meuSQL
          })
           
          this.desserts = await res2.data; 
          //  console.log(res2.data);      
   

          this.filtra_zap_enviar(this.desserts)//pega o count de zaps disponiveis para serem enviados automaticamente

          // SOMENTE SE SELECIONOU ALGUM PROFISSIONAL
          if (doc>0){
            this.horarios_cancelados_open(doc, dia)
          }
          
          // this.associa(this.get.CodigoConsulta)

          // this.remove_horarios_ja()//apos abrir a agenda.. remove horarios ja agendados

          // ATUALIZA TABELAS SE ESTIVER EXPANDIDO
          if (this.value_expansion){
             if (this.expansion_selecionao == 'LANCAMENTOS') {
                this.abrir_lancamentos(this.doc_codigo.CODIGO, this.in_today, this.in_today)
             }
          }




          // setTimeout(function(){
            this.mostrar_tabela = true
          // }.bind(this), 300);
          
          
      },

    async crud_update(id_field,id,table,fields){

        // console.log([fields]);

        if (id != null && id !== undefined) {
            
        // console.log(this.editedItem);
            const res = await axios.post(url+'conexao.php', {
            acao: 3,
            id:                id,
            id_field:          id_field, 
            table:             table,
            fields:            fields,
            // ativo:             this.editedItem.ativo,              
            })
            // console.log('crud_update');
            // console.log(res.data);
            this.alerta(res.data, "success");
        }else{
            this.alerta("id inválido","red");
        }
    },

    async horario_doc(doc){

        this.ja_horario_doc = false
        // console.log('horario_doc:'+doc)
        if (doc >0 ){

            var meuSQL = 
              `
                SELECT *
                FROM
                ANTIGO_HORARIO
                WHERE
                  (ANTIGO_HORARIO.CODIGO_PROFISSIONAL = `+ doc+ `) order by HI
              `
              //  console.log(meuSQL);
                  
              const res2 = await axios.post(url+'conexao.php', {
                acao:   5,
                meuSQL: meuSQL
              })

            this.horario_doc_array = await res2.data  
            // console.log('horario_doc_array:', res2.data);

            await this.abre_bloqueios(doc);
            this.ja_horario_doc = true
        }  

    },


    async abre_bloqueios(doc){

      this.aviso_horarios = ''

      var meuSQL = `select * from BLOQUEAR where ((CODIGO_DOC = 0)or(CODIGO_DOC = ${doc}))and(DATA_ATE >='TODAY')`
        // console.log(meuSQL);
        
        const res2 = await axios.post(url+'conexao.php', {
            acao:   5,
            meuSQL: meuSQL
        })

        this.dias_bloqueados = res2.data
    },

    estaNoIntervalo(horario, inicio, fim) {
         return (horario >= inicio && horario < fim) || (horario == inicio);
    },


    encontrarItemPelaData(BB, dataAlvo) {
        const dataAlvoFormatada = new Date(dataAlvo);

        const itemEncontrado = BB.find((item) => {
            const dataDe = new Date(item.DATA_DE);
            const dataAte = new Date(item.DATA_ATE);

            return dataAlvoFormatada >= dataDe && dataAlvoFormatada <= dataAte;
        });

        return itemEncontrado;
     },


    async filtra_horarios_doc(doc){
      
      // console.log('filtra_horarios_doc');

       //verifico se tem blqoueio no dia selecionado 
        const itemEncontrado = await this.encontrarItemPelaData(this.dias_bloqueados, this.in_today)
        if (itemEncontrado) {
              // console.log("Item encontrado:", itemEncontrado);
              this.aviso_horarios = itemEncontrado.MOTIVO //caso queira msotra o motivo
              this.hora_agendar = []

              return false //achou bloqueio entao para por aqui
        }
        else{
          this.aviso_horarios = ''
        }
              


        // console.log('filtra_horarios_doc:'+this.in_today);
        let dia = this.in_today //pega a data selecionada 
        
        let ds =[]
        for (let i = 0; i <=5; i++){
           ds[i] = 'N'
           //0 = seg, 1=ter, 2=qua, 3=qui, 4=sex, 5=sab
        }

        //pego o dia da semana da data selecionada
        let s_dia =  new Date(dia).getDay() //segunda = 0, assim por diante
        ds[s_dia] = 'S'//pego o dia da semana e mudo o array para S para ser filtrado 

        // alert(s_dia)

        let meu_array = this.horario_doc_array
        // alert(ds)
        //filtra array receitas

        let campo = 'D_'+(s_dia+2) //formato o nome do campo com base no dia EX: D_2, sendo que s_dia = 0 + 2
        
        // console.log('horario_doc_array.length:');
        // console.log(this.horario_doc_array.length);
        // console.log(this.horario_doc_array);

        let filtrado = meu_array.filter(function(obj) { 
          // alert(obj[campo])
                            return(dia >= obj.VALIDO_DE) && (dia <= obj.ATE) && (obj[campo] == 'S')
                          });
                          
        this.horario_do_dia = filtrado 

        // console.log('filtrado');      
        // console.log(filtrado);  



// AGORA PEGA OS HORARIOS DISPONIVEIS
        let linha = filtrado
        let horarios_dia = []

        this.ja_adicionado = 'x'
        for (let i = 0; i < linha.length; i++) {

            var intervalo  = linha[i].INTERVALO
            var inicio     = new Date('2021-01-01 '+linha[i].HI);
            var fim        = new Date('2021-01-01 '+linha[i].HF);
            var corre      = inicio
            var horario    = ''

            // console.log('linha: '+ i);

            while( corre <= fim ){

              horario = new Date(corre).toLocaleTimeString('pt-Br',{ hour12: false, timeZone: 'America/Sao_Paulo' });//pego horario da data-hora
              corre = new Date(corre.getTime() + intervalo*60000) //acrescentando intervalo no horario inicial

              if (this.ja_adicionado.includes(horario)==false){
                horarios_dia.push(horario) 
                // console.log(horario);  
                this.ja_adicionado = this.ja_adicionado +'-'+horario
              }else{
                // console.log('ja adicionou o '+horario);
              }

            }
        }

        this.hora_agendar =  horarios_dia
        // console.log('horarios_dia:');
        // console.log(this.hora_agendar);


// AGORA PEGA OS HORARIOS DISPONIVEIS   

        await this.horarios_agendados(doc, dia)

        return 'exit'

        //CHAMA O TIMER PARA REMOVER HORARIOS JA AGENDADOS.. TEM TIMER PQ DEMORAR UM POUCO PRA PUXAR OS AGENDAMENTOS
        if (this.pode_timer_h1){

              this.pode_timer_h1 = false //defino que nao.. para nao ficar criando varios timers

              setTimeout(function(){
                // console.log('remove_horarios_ja')
                this.pode_timer_h1 = true
                this.remove_horarios_ja() 
              }.bind(this), 800);//usar esse .bind(this) para chamar funcoes com o setTimeout   
        }        


    },


      async horarios_agendados(doc, dia){

        this.abriu_agenda = false

        let sql = 
        `
        SELECT
            AGENDA.DATA,
            AGENDA.HORA,
            COALESCE(DATEADD(MINUTE, AGENDA.DURACAO, AGENDA.HORA), DATEADD(MINUTE, 10, AGENDA.HORA)) AS HORA_ATE,
            AGENDA.DURACAO
        FROM
            AGENDA
         WHERE
            (AGENDA.DATA = '${dia}')AND
            (AGENDA.CODIGOMEDICO = ${doc})AND
            (AGENDA.ATENDIDO NOT IN ('C'))
            
         ORDER BY AGENDA.HORA

        `
        // console.log('sql:', sql);
        const res2 = await axios.post(url+'conexao.php', {
            acao:   5,
            meuSQL: sql
        })

        let agendados = res2.data  
        // console.log('agendados:', agendados);
        // console.log('horarios:', this.hora_agendar);



        // Filtrar os horários que não estão agendados
        const horariosDisponiveis = this.hora_agendar.filter(horario => {
            // Verificar se o horário não está dentro de nenhum intervalo [HORA, HORA_ATE]
            return !agendados.some(agendado =>
                this.estaNoIntervalo(horario, agendado.HORA, agendado.HORA_ATE)
            );
        });

        // console.log('horariosDisponiveis:', horariosDisponiveis);
        this.hora_agendar = horariosDisponiveis

        this.abriu_agenda = true


      },



   async remove_horarios_ja(){
// JA AGENDADO
              // console.log('ja agendado');
              // console.log(this.desserts);

                let com_intervalo = []
                

                let ja_tem = this.desserts

// console.log('this.hora_agendar:');
// console.log(this.hora_agendar);

// console.log('ja tem:');
// console.log(ja_tem);
                for(let i = 0; i< ja_tem.length; i++){

                    if(ja_tem[i].DURACAO > 0){ //se tiver duracao.. fazer tratamento diferente.. usado em bloqueios geralmente
                      // alert(ja_tem[i].DURACAO +' = '+ ja_tem[i].HORA)
                      let inicio     = new Date('2021-01-01 '+ja_tem[i].HORA);
                      let corre = new Date(inicio.getTime() + (ja_tem[i].DURACAO) * 59000) //acrescentando intervalo no horario inicial = deixei 59000 para tirar 1 segundo para encaixar certinhos nos horarios
                      let horario = new Date(corre).toLocaleTimeString('pt-Br',{ hour12: false, timeZone: 'America/Sao_Paulo' });//pego horario da data-hora

                      if (horario < ja_tem[i].HORA ){
                        // console.log('horario final explodiu..entao defino para 23hs');
                        horario = '23:00:00'
                      }
                      // console.log(ja_tem[i].HORA + ' - '+horario)


                      //objeto para receber os horarios com intervalos
                      let obj_com_intervalo = {}
                      this.$set(obj_com_intervalo, 'inicio', ja_tem[i].HORA);//insiro no objeto os valores
                      this.$set(obj_com_intervalo, 'fim', horario);//insiro no objeto os valores


                      com_intervalo.push(obj_com_intervalo)//insiro no array onde irei tratar
                    }

                   if (this.hora_agendar.indexOf(ja_tem[i].HORA) >= 0){
                      // console.log('removido: '+ja_tem[i].HORA);
                      this.hora_agendar.splice(this.hora_agendar.indexOf(ja_tem[i].HORA), 1) //remove item do array
                    }

                }


                //tem algum horario com duracao..
                // console.log(com_intervalo);
                // alert(com_intervalo.length)
                if (com_intervalo.length > 0){

                  // console.log(teste);
                  // alert(teste[1].inicio)
                  let remover_esses = []

                  //percorre os horarios disponiveis para agendar
                  for (let i = 0; i < this.hora_agendar.length; i++) {
                      // alert(this.hora_agendar[i])
                      //percorre os horarios com duraco

                      let h = new Date('2021-01-01 '+this.hora_agendar[i]).toLocaleTimeString('pt-Br',{ hour12: false, timeZone: 'America/Sao_Paulo' });//pego horario da data-hora
                      
                      for (let j = 0; j < com_intervalo.length; j++) {
                          //ja formato no campo hora time

                          let a = new Date('2021-01-01 '+com_intervalo[j].inicio).toLocaleTimeString('pt-Br',{ hour12: false, timeZone: 'America/Sao_Paulo' });//pego horario da data-hora
                          let b = new Date('2021-01-01 '+com_intervalo[j].fim).toLocaleTimeString('pt-Br',{ hour12: false, timeZone: 'America/Sao_Paulo' });//pego horario da data-hora
                          

                          // alert(a+'-'+b+'-'+h)
                          if ((h >=a ) && (h<=b)){
                            // console.log('achou o '+h + 'entre '+a+' - '+b)
                            remover_esses.push(h)//salvo o que tem que ser removido num array.. se remover agora. da erro no index 
                            break
                          }
                          // alert(a+' - '+b)
                      }
                  }

                  // console.log('remover esse: '+remover_esses);
                  if (remover_esses.length >0){
                    for (let i = 0; i < remover_esses.length; i++) {
                      this.hora_agendar.splice(this.hora_agendar.indexOf(remover_esses[i]), 1) //remove item do array
                    }
                    
                  }

                }


                this.abriu_agenda = true                

        // JA AGENDADO
    },

    selecionar_paciente(item){
        // console.log('selecionar_paciente')
        // console.log(item);

        if (item.AVISO){
          if (item.AVISO.length>5){

            setTimeout(function(){
              alert(item.AVISO)
            }.bind(this), 333);//usar esse .bind(this) para chamar funcoes com o setTimeout   
          }
        }


        this.dialog_paciente  = false
        this.pre_cadastro     = false
        this.bloqueando       = false

        this.items_selecionados.cod_paciente  = item.CODIGOCLIENTE
        this.items_selecionados.nome_paciente = item.NOME
        this.items_selecionados.convenio      = item.CODIGOCONVENIO

        this.$set(this.editedItem, 'CODIGOCLIENTE', item.CODIGOCLIENTE);//defino valor manualmente
        this.$set(this.editedItem, 'NOME', item.NOME);//defino valor manualmente

        this.$set(this.editedItem, 'CODIGOCONVENIO', item.CODIGOCONVENIO)
        this.$set(this.editedItem, 'CONVENIO', item.CONVENIO)



        this.dialog_agendar = true

    },

    qual_agendar(){

        if (!this.editedItem.HORA_ATE){
          
          let mais = DSibasico.incMinutes(this.editedItem.HORA, this.editedItem.DURACAO )
          this.$set(this.editedItem, 'HORA_ATE', mais);//defino valor manualmente
        }

      
        if (this.editedItem.SUB_DOC==''){
          this.$set(this.editedItem, 'SUB_DOC', 0);//defino valor manualmente
        }
        

        this.copia_editedItem = Object.assign({}, this.editedItem) //copio antes de salvar
        

        if (this.editedItem.CODIGO >0 ){
           this.agendar_update()
        }else{
           this.agendar_create()
        }
    },

    agendar(event){

      // alert(event)
      // this.dialog_agendar = true

      this.pre_cadastro     = false
      this.bloqueando       = false

      this.hora_agendar_selecionada = event

      this.editedItem = {VALOR: ''}//limpa array

      //definindo os campos
      this.$set(this.editedItem, 'DATA', this.in_today);//defino valor manualmente
      this.$set(this.editedItem, 'HORA', this.hora_agendar_selecionada);//defino valor manualmente
      this.$set(this.editedItem, 'HORAF', this.hora_agendar_selecionada);//defino valor manualmente
      this.$set(this.editedItem, 'DURACAO', '');//defino valor manualmente
      this.$set(this.editedItem, 'CODIGOMEDICO', this.doc_codigo.CODIGO);//defino valor manualmente
      this.$set(this.editedItem, 'NOME_DOC', this.doc_codigo.NOME);//defino valor manualmente
      this.$set(this.editedItem, 'SUB_DOC', 0);
      // this.$set(this.editedItem, 'VALOR', 0);


      //vazio
      this.$set(this.editedItem, 'CODIGOPROCEDIMENTO', 0);//defino valor manualmente
      this.$set(this.editedItem, 'PROCEDIMENTO', '');//defino valor manualmente


      this.$set(this.editedItem, 'ATENDIDO', 'M');//defino valor manualmente
      this.$set(this.editedItem, 'FORMA_PAGAMENTO', 'DINHEIRO');//defino valor manualmente
  
      this.$set(this.editedItem, 'CODIGOCONSULTORIO', '1');//defino valor manualmente
      this.$set(this.editedItem, 'TIPO', 'A');//defino valor manualmente
      this.$set(this.editedItem, 'EFETUADO', 'N');//defino valor manualmente
      this.$set(this.editedItem, 'CODIGOCONSULTA', 'GEN_ID(AGENDA_CODIGO_GEN, 1)+1');//defino valor manualmente
      
      this.$set(this.editedItem, 'OBS', '');//defino valor manualmente

      this.$set(this.editedItem, 'LOG_RECEPCAO', this.data_hora +'_w_'+ this.user_def.user_name +': '+ 'Agendou');//defino valor manualmente

      //default nulo
      // this.$set(this.editedItem, 'codigoconvenio', 0)
      // this.$set(this.editedItem, 'convenio', '')

      // this.items_selecionados.convenio = '7'
      
      this.items_selecionados.cod_proced = '' //nulo para ser selecinado quando agendar

      //alguns dados pego via function:
      // this.proced_escolhido
      // this.muda_convenio
      // this.selecionar_paciente

      this.dialog_paciente = true
      this.nome_digitou = ''
      this.items_pacientes = []
      
    },

    agendar_editar(item,readonly){
        
        // console.log(item);

        // CAMPOS QUE SERAO EDITAVEIS NA JANELA DE AGENDAMENTO

        this.editedIndex = this.desserts.indexOf(item)
        // this.editedItem = Object.assign({}, item) //nao da pra usar isso pois pega campos da tabela CLIENTES tb


        this.$set(this.editedItem, 'DATA',       item.DATA);
        this.$set(this.editedItem, 'HORA',       item.HORA);
        this.$set(this.editedItem, 'HORAF',      item.HORAF);
        this.$set(this.editedItem, 'HORA_ATE',   item.HORA_ATE);
        this.$set(this.editedItem, 'DURACAO',    (item.DURACAO)? item.DURACAO : '0');
        this.$set(this.editedItem, 'CODIGO',     item.CODIGO);
        this.$set(this.editedItem, 'ATENDIDO',     item.ATENDIDO);
        this.$set(this.editedItem, 'CODIGOCONSULTA', item.CODIGOCONSULTA);
        this.$set(this.editedItem, 'CODIGOMEDICO', item.CODIGOMEDICO);
        this.$set(this.editedItem, 'OBS',          item.OBS);
        this.$set(this.editedItem, 'NOME_DOC',     item.NOME_DOC);
        this.$set(this.editedItem, 'VALOR',        item.VALOR);
        this.$set(this.editedItem, 'FORMA_PAGAMENTO', item.FORMA_PAGAMENTO);
        this.$set(this.editedItem, 'FONE',            item.FONE);
        this.$set(this.editedItem, 'EFETUADO',        item.EFETUADO);
        this.$set(this.editedItem, 'CODIGOCLIENTE',   item.CODIGOCLIENTE);

        this.$set(this.editedItem, 'SUB_DOC',        item.SUB_DOC);
        this.$set(this.editedItem, 'SUB_DOC_NOME',   item.SUB_DOC_NOME);


        if (this.editedItem.CODIGOCLIENTE > 0){
          this.$set(this.editedItem, 'NOME',            item.NOME);
        }else{
          this.$set(this.editedItem, 'NOME',            item.NOME_AGENDA);
        }



        this.items_selecionados.cod_paciente  = item.CODIGOCLIENTE
        this.items_selecionados.nome_paciente = item.NOME
        this.items_selecionados.convenio      = item.CODIGOCONVENIO

        this.items_selecionados.cod_proced    = item.CODIGOPROCEDIMENTO
        // this.editedItem.CODIGOPROCEDIMENTO      =     item.CODIGOPROCEDIMENTO,     
        this.editedItem.PROCEDIMENTO      =     item.PROCEDIMENTO,  


        this.dialog_agendar = true
        // this.readonly = readonly

        // console.log('editeditem');
        // console.log(this.editedItem);
    },

    async agendar_create(){

      // if (this.$refs.form.validate()){

          // console.log('this.editedItem:', this.editedItem);

        if (await this.tem_duplicidade(this.editedItem.DATA, this.editedItem.HORA, this.editedItem.CODIGOMEDICO)){
             alert('Horário não disponível!')
             return false
        }{
            // alert('Horário Ok')
            // return false
        }  

        



          this.editedItem.HORAF = this.editedItem.HORA//deixo horaf igual hora.. tipo picado 18:33.. etc

         const data = {
                acao: 2,
                table:             'agenda',
                fields:            this.editedItem,
         }  

        const res = await axios.post(url+'conexao.php', data);
        
        if ( (res.data.includes('ERROR:')) || (res.data.includes('error'))){  
          
          console.log('agendar_create1:', res.data);

          this.alerta(res.data, "red");
        }else{
          this.alerta(res.data, "success");
        }

        // console.log('agendar_create2:', res.data);

        this.dialog_agendar = false

        await this.abre_agenda()

        await this.filtra_agora(this.desserts, this.editedItem.HORA)//faz selecionar a linha

        
        if (this.isMobile){
          this.switch_agendar = false
        }


        this.editedItem = {VALOR: ''}//limpa array

        if (this.esta_empresa('acencon')){
           if (confirm("Imprimir Recibo de Agendamento?")){
              this.preparar_comprovante('agendar')
           }
        }

      // }
      // else{
      //   this.alerta("Preencha os campos!","red");
      // }
    },

    async agendar_update(){

      if (this.$refs.form.validate()){

          // console.log('this.editedItem:');
          // console.log(this.editedItem);

         let id = this.editedItem.CODIGO
         if (id<1){
           this.alerta('Código inválido!','red')
         }
         else{


            const data = {
                    acao: 3,
                    id:          this.editedItem.CODIGO ,
                    id_field:    'CODIGO', 
                    table:       'agenda',
                    fields:       this.editedItem,
            }  

            const res = await axios.post(url+'conexao.php', data);

            if (res.data.includes('ERROR:')){
              console.log('agendar_update:');
              console.log(res.data);
              this.alerta(res.data, "red");
            }else{
              this.alerta(res.data, "success");
            }

            // console.log('agendar_update:');
            // console.log(res.data);

            this.abre_agenda()
            this.dialog_agendar = false

            this.editedItem = {VALOR: ''}//limpa array


            // if (this.esta_empresa('acencon')){
            //     if (confirm("Imprimir Recibo de Agendamento?")){
            //         this.preparar_comprovante('agendar')
            //     }
            // }
         }

      }
      else{
        this.alerta("Preencha os campos!","red");
      }
    },

    

    async lista_procedimentos(doc){


        var meuSQL = 
          `
            SELECT
            VALORES_AMB.CATEGORIA,
            VALORES_AMB.CODIGO,
            VALORES_AMB.WHATS_OBS
          FROM
            VALORES_AMB
          WHERE
            (VALORES_AMB.CODIGODOUTOR = `+doc+`)AND
            ((VALORES_AMB.ATIVO = 'S')OR(VALORES_AMB.ATIVO IS NULL))
            ORDER BY CATEGORIA   
          `        
        
          const res2 = await axios.post(url+'conexao.php', {
            acao:   5,
            meuSQL: meuSQL
          })

        this.procedimentos = res2.data;  
        // console.log('procedimentos:', res2.data);

    },

    async lista_valores_procedimentos(){

        var meuSQL = 
            `
            SELECT 
              VALORES_AGENDA.DOUBLE_UM,
              VALORES_AGENDA.CODIGO,
              VALORES_AGENDA.CODIGO_MEDICO,
              VALORES_AGENDA.CODIGO_PRO,
              VALORES_AGENDA.CODIGO_CONVENIO
            FROM
            VALORES_AGENDA
            INNER JOIN DOUTORES ON (VALORES_AGENDA.CODIGO_MEDICO=DOUTORES.CODIGO)
            WHERE
              (DOUTORES.ATIVO = 'S')
            `        
        
          const res2 = await axios.post(url+'conexao.php', {
            acao:   5,
            meuSQL: meuSQL
          })

        this.items_valores_procedimentos = res2.data;
        
        // sessionStorage['items_valores_procedimentos']  =  JSON.stringify(res2.data)//salva para ser utilizado no print.vue

    },

    async lista_convenios(){

        var meuSQL = 
            `
            SELECT
            CONVENIO.NOME,
            CONVENIO.CODIGO
            FROM
            CONVENIO
            WHERE
            CONVENIO.ATIVO = 'S'
            ORDER BY
            CONVENIO.NOME
            `        
        
          const res2 = await axios.post(url+'conexao.php', {
            acao:   5,
            meuSQL: meuSQL
          })

        this.convenios = res2.data;
        
        sessionStorage['obj_todos_convenios']  =  JSON.stringify(res2.data)//salva para ser utilizado no print.vue
        // console.log('convenios:');
        // console.log(this.convenios);

    },

    async localizar_paciente(event){

    // console.log('event:', event);
    

    if (event == ''){
      event = '....'
    }


    // caso nao podssa cadastrar, faz o pre-cadastro
    if (!this.user_permissoes.cadastrar){
      this.cadastro_provisorio(this.nome_digitou.trim())
      return false
    }

    this.progress_user = true


    let arrayNomes = []

    if (isNaN(event)) {
      // alert('texto')
      arrayNomes = event.trim().split(' ');//retiro espaços vazios e separo a string
      var nome = event
      var ide  = null
    }else{
      // alert('eh numero')
      // var nome = null
      var ide  = event
      var nome = event
    }

    
    // permite buscar por qualquer parte do nome
    let sql_nome = ''
    for(let i = 0; i< arrayNomes.length; i++){
      if (i>0){
        sql_nome += ` AND `
      }
      sql_nome += `(CLIENTES.NOME CONTAINING '${arrayNomes[i]}')`     
    }

    if (sql_nome){
      sql_nome = `((${sql_nome})OR`
    }
    else{
      sql_nome = `(`
    }


    let ddata    = await this.formata_datas(nome,1)
    let ccelular = await this.formata_datas(nome,2)


    var meuSQL = 
      `
      SELECT
        CLIENTES.CODIGOCLIENTE,
        CLIENTES.NOME,
        CLIENTES.DATANASCIMENTO,
        CLIENTES.CPF,
        CLIENTES.CELULAR,
        CLIENTES.FONE1,
        CLIENTES.CODIGOCONVENIO,
        CLIENTES.AVISO,
        CONVENIO.NOME AS CONVENIO
      FROM
       CLIENTES
       LEFT OUTER JOIN CONVENIO ON (CLIENTES.CODIGOCONVENIO=CONVENIO.CODIGO)
      WHERE
       (CLIENTES.ATIVO = 'S') AND
       ((CLIENTES.CODIGOCLIENTE = `+ ide +`)OR
       ${sql_nome}
       (CLIENTES.CELULAR CONTAINING '${ccelular}')OR
       (CLIENTES.DATANASCIMENTO CONTAINING  '${ddata}')OR
       (CLIENTES.CPF CONTAINING '${nome}')))
      
      ORDER BY
        CLIENTES.NOME
      `


      // console.log(meuSQL);
      // return false

        
          const res2 = await axios.post(url+'conexao.php', {
            acao:   5,
            meuSQL: meuSQL
          })

        this.items_pacientes = res2.data;  
        if(this.items_pacientes.length == 0){
          this.alerta('Nada encontrado!','orange')
        }

        this.progress_user = false
        // console.log('items_pacientes:');
        // console.log(this.items_pacientes);
    },
    

    async proced_escolhido(item){
      // alert('proced_escolhido')
      // console.log('proced_escolhido:');
      // console.log('proced_escolhido:', item);
      // console.log('this.editedItem:', this.editedItem);

      let valor = 0
      // somente se nao tiver recebido e ainda nao atendido
      if ((item) && (this.editedItem.EFETUADO == 'N') && (this.editedItem.ATENDIDO != 'A') ){
          valor = await this.filtra_valores(this.editedItem.CODIGOMEDICO, item.CODIGO, this.editedItem.CODIGOCONVENIO)

          this.$set(this.editedItem, 'VALOR'   , valor);//defino valor manualmente
      }

      this.$set(this.editedItem, 'CODIGOPROCEDIMENTO' , item.CODIGO);
      this.$set(this.editedItem, 'PROCEDIMENTO'       , item.CATEGORIA);

      


      // this.items_selecionados.cod_proced   = value.CODIGO
      // this.items_selecionados.nome_proced  = value.CATEGORIA
    },

    muda_convenio(item){
      // console.log('item:');
      //   console.log(item);

      this.$set(this.editedItem, 'CODIGOCONVENIO', item.CODIGO)
      this.$set(this.editedItem, 'CONVENIO', item.NOME)
    },

    async crud_sql (sql) {

        const res = await axios.post(url+'conexao.php', {
            acao:   6,
            meuSQL: sql
        })
      
        // console.log(res.data);
        this.alerta(res.data, "success");//so retorna ok ou error
        return res.data
    },




      //---------------importando funcoes basicas ----------------------- INICIO
      
      moeda(event){
          let r = DSibasico.moeda(event)
          return r
      },
      


    },
  }
</script>

<style lang="css">

  .container{
    max-width: 100%;
  }
  
 /*titulo*/ 
  .v-date-picker-title__date{
    font-size: 16px !important;
    height: 16px !important;
  }

/*dias*/
  .v-date-picker-table .v-btn{
    /* font-size: 8px !important; */
    height: 18px !important;
  }

/*anos*/
  .v-date-picker-title__year{
    /* height:1px !important; */
  }

/*meses*/
 .v-date-picker-table--month td{
  height: 36px !important;  
 }

/*altura calendario*/
 .v-date-picker-table {
   height: 190px !important;
   }




/* GRIDS */
.item-2 {
  background: #F5F5F5;
  /* grid-area: main; */
  
  display: grid;
  
  grid-template-columns: 100%;
  /* word-wrap: break-word; */
  grid-template-rows: auto;
  grid-gap: 5px;
  margin-top: 5px;
  /* margin-left: 20px; */
  
  grid-template-areas: "header"
                       "article"
                       "sidebar";
  
}

/* rwd */

/* quando no celular tamanho maximo de tela 600px */
@media only screen and (min-width: 900px) {

	.item-2 {
	  
	  grid-template-columns: 290px auto;
	  grid-template-rows: auto auto;
    

	  
	  grid-template-areas: "header header"
	                       "article sidebar";
	  
	}
}

/* quando no celular tamanho maximo de tela 600px */
@media only screen and (max-width: 600px) {
	.item-6 {
    /* margin-left: 20px; */
    padding-left: 5px;
    padding-right: 20px;
	}
}

.item-5 {
  background: yellow;
  grid-area: header;
}
/* GRIDS */


/* tamanho da fonte do data-table */
.v-data-table th {
  font-size: 12px !important;
}
.v-data-table td {
  font-size: 12px !important;
}
/* tamanho da fonte do data-table */


.count_item {
  position: relative;
  top: -2px;
  left: 2px;
  font-size: 75%;
  vertical-align: super;
  color: red;
}

.count_item2 {
  position: relative;
  top: 1px;
  left: 2px;
  font-size: 80%;
  vertical-align: super;
  color: red;
}

.flutuante{
  /* float: right; */


  position: fixed; /* posição absoluta ao elemento pai, neste caso o BODY */
        /* Posiciona no meio, tanto em relação a esquerda como ao topo */
        left: 88vw; 
        top:22%;
        /* top: 125px; */
        width: 300px; /* Largura da DIV */
        /* height: 670px; */
        height: 90vh; /* Altura da DIV */
        /* A margem a esquerda deve ser menos a metade da largura */
        /* A margem ao topo deve ser menos a metade da altura */
        /* Fazendo isso, centralizará a DIV */
        margin-left: -150px;
        margin-top: -125px;
        z-index: 1000; /* Faz com que fique sobre todos os elementos da página */
        /* background-color: red; */
        background-color: rgba(255, 0, 0, 0.5)
  
}

.v-chip-group .v-slide-group__content {
    padding: 0px !important;
    /* width: 111px; */
}

td.text-start{ /* padding das colunas data-table  */
  padding-left: 5px !important;
  padding-right: 2px !important;
  /* padding: 0px 2px !important; */
  /* background-color: red; */
}

th.text-start{ /* padding dos titulos das colunas data-table */
  padding: 0px 4px !important;
  /* background-color: red; */
}



/* CALENDARIO */

/* titulo do calendario onde ficam os anos */
.v-picker__title {
    padding-top: 6px !important;
    padding-bottom: 12px !important;
    /* padding: 12px; */
    /* height: 30px !important; */
}


/* o ano no calendario */
.v-date-picker-title__year {
   display: inline !important;
   /* color: red !important; */
}

/* dia da semana do calendario */
.v-application--is-ltr .v-date-picker-title .v-picker__title__btn {
    /* text-align: left; */
    height: 0;
    /* background-color: red; */
}

.v-date-picker-header {
    padding: 0 !important;
}

/* cor do header do data-table */
.v-data-table-header {
    /* background-color: rgba(171, 167, 167, 0.943); */
}

/* cor da font do data-table */
.theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr > th {
    /* color: rgb(248, 242, 242) !important; */
}


.v-date-picker-title {
    flex-direction: row !important;
}

.v-date-picker-table--date th {
    padding: 0 !important;
}

.v-date-picker-table--date td {
    padding: 0 !important;
}

.v-date-picker .v-btn__content{
 background-color: red;
}

/* CALENDARIO  FIM*/


/* quando clica em uma coluna ele fica com bordas */
.theme--light.v-data-table tbody tr.v-data-table__selected td {
    /* seleciona_linha cor */
    /* background: red !important; */
    /* background: #eee9e9 !important; */
    border-bottom: 1px solid grey !important; 
    border-top: 1px solid grey !important; 
    /* line-height: 5 !important; altura da linha */
}

/* tr{
  height: 70px !important;
} */

/* ALTURA DAS LINHAS DO DATA-TABLE */
.myclass60 {
  /* color: red !important; */
  /* background-color: green !important; */
  height: 70px !important;
}
.myclass120 {
  height: 140px !important;
}
.myclass180 {
  height: 210px !important;
}
.myclass240 {
  height: 280px !important;
}

.myclass300 {
  height: 500px !important;
}

/* ALTURA DO LISTA DO V-SELECT */
.v-menu__content{
  /* max-height: 600px !important; */
}


/* CONFIGURACOES DAS LINHAS DO V-SELECT */
.v-select-list .v-list-item {
  /* min-height: 0 !important; */
  /* max-height: 600px !important; */
}

.chat-container2 .content{
    padding: 2px 8px;
    background-color: white;
    /* background-color: lightgreen; */
    border-radius: 10px; /* bordas arrendondadas */
    display:inline-block;
    box-shadow: 0 1px 3px 0 rgba(0,0,0,0.2), 0 1px 1px 0 rgba(0,0,0,0.14), 0 2px 1px -1px rgba(0,0,0,0.12);
    max-width: 90%;
    word-wrap: break-word;
    font-size: 12px;
    color: black;
  }

  .scroll {
   overflow-y: scroll;
}


.stackSheet {
    position: relative;
}
.stackSpark {
    position: absolute;
    top: 0;
    left: 0;
}


.quebra{
    display: block !important;
 }

 .hand:hover {
  cursor: pointer; /* Muda o cursor para a mão ao passar o mouse sobre o container */
 }


  
  /* .v-dialog__content {
     align-items: flex-start !important; 
     margin-top: 16px; 
  } */


</style>

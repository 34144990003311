<template>
  <div class="main" style="background:#6699ff; height:100%">

    <v-container> 

      <v-snackbar
        v-model="snackbar"
        :timeout="3000"
        top
        :color="snack_color"  
        class="ml-5"
      >
        <center class="black--text">
          {{ snack_text }}
        </center>

      </v-snackbar>


  <div class="text-center">
    <v-dialog
      v-model="dialog_recuperar"
      width="500"
    >

      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Confirme seu email
        </v-card-title>

        <v-card-text class="mt-4">
          A Senha de recuperação será enviada para seu e-mail

          <v-form
            ref="form"
            v-model="valido"
            >
            <v-text-field
                  v-model="meu_email"
                  flat
                  label="E-mail"
                  clearable
                  hide-details
                  class="mt-4"
                  :rules="emailRules"
                  autofocus
                  @keypress.enter="dialog_recuperar = false, enviar_email_recuperacao(meu_email)"
                ></v-text-field>
          </v-form>
        </v-card-text>

        <!-- <v-divider></v-divider> -->

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            small
            text
            @click="dialog_recuperar = false"
          >
            cancelar
          </v-btn>

          <v-btn
            color="primary"
            small
            @click="dialog_recuperar = false, enviar_email_recuperacao(meu_email)"
            :disabled="!valido"
          >
            Enviar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>



      <br/>  
      <v-row
      justify="center"
      class=""
      >
         <br/>
            <h1
            class="white--text ml-6 "
            >
            <!-- {{ nome_empresa }} -->
            </h1>
          <br/>
      </v-row>  

      <v-row
      justify="center"
      >
          


          <v-col
          md="8"
          offset-md="4"
          >

          
          <v-card
          max-width="400px"
          >

          <br>
          <h2 class="text-center"> {{ nome_empresa }} </h2>


            <v-card-text>
              <v-form
              >
              <v-container>
                <v-row>


                  <v-col
                    cols="12"
                    md="12"
                    
                  >

                  <v-select
                  v-if="(logar_como=='usuario')&&(simples)"
                  v-model="user"
                  label="Usuário"
                  :items="all_users"
                  item-value='CODIGO'
                  item-text='USUARIO'
                  return-object
                  >
                  </v-select>

                  <v-combobox
                  v-if="(logar_como=='usuario')&&(!simples)"
                  v-model="user"
                  label="Usuário"
                  :items="all_users"
                  item-value='CODIGO'
                  item-text='USUARIO'
                  return-object
                  :clearable="!simples"
                  >
                  </v-combobox>


                  <v-text-field
                    v-if="(logar_como=='cpf')"
                    v-model="cpf"
                    label="CPF"
                    type="number"
                    placeholder="somente números"
                    persistent-hint
                  >
                  </v-text-field>
                  

                    <v-text-field
                      v-if="user.CPF_WEB==''"
                      hint="Cadastre seu CPF pois em breve o login será somente pelo CPF"
                      v-model="cpf"
                      label="CPF"
                      type="number"
                      placeholder="somente números"
                      persistent-hint
                    >
                    </v-text-field>

                  </v-col>



                  <v-col
                    cols="12"
                    md="12"
                    class=""
                    
                  >
                    <v-text-field
                      v-model="password_dsi"
                      label="Senha"
                      type="password"
                      @keypress.enter="logar"
                    ></v-text-field>
                    <!-- @click="oi()" -->
                    <!-- @click="cripto('Ü¼°ä¡ÓÁ°«Á«ß¼·²÷í+¼+÷íå·¼+¶¼Ç³·é²á²í¾¼ï','E')" -->
                    <!-- @click="cripto('# cleberson da silva alves 20/04/1984 @','E')" -->
                  </v-col>


                </v-row>
              </v-container>
              </v-form>
            </v-card-text>

            <v-card-actions class="">

              <v-row>
                <v-col cols="12">
                  <v-btn
                      color="primary"
                      block
                      @click="logar"
                  >
                      Entrar
                  </v-btn>
                </v-col>

                <v-col cols="12" v-if="0>1">
                  <v-btn
                      color="primary"
                      block
                      @click="meu_email='', dialog_recuperar=true"
                      text
                  >
                      Esqueci minha senha
                  </v-btn>
                </v-col>
              </v-row>




            <!-- <v-btn
                color="primary"
                block
                @click="enviar_email('contato.dsi@gmail.com','teste','ah sei la')"
            >
                Email
            </v-btn> -->
            
            </v-card-actions>

            <!-- <p>&nbsp {{ versao }}</p> -->

                  <v-col>
                    <v-alert
                    elevation="5"
                    class="orange"
                    type="text"
                    v-if="show_alert"
                    >
                    <center>
                        Senha incorreta!
                    </center>
                    
                    </v-alert>                      
                  </v-col>

                  <p class="mb-0" style="text-align:right"> {{ versao }} &nbsp </p>
            <br/>


          </v-card>
          </v-col>
      </v-row>
          

    </v-container>  
      
  </div>
</template>

<script>
import axios from "axios";
import DSirotinas from '../assets/js/DSi_rotinas'//chamo funcao do arquivo
import { define_data_hora, diaParOuImpar, apagar_pasta } from '../assets/js/DSi_basico'
import { registra_log } from '../assets/js/DSi_funcoes'
// import DSi_funcoes from '../assets/js/DSi_funcoes'

var url =  ''


export default {

    data: () => ({

        RASTREAR: false, //ativo pra ver as etapas da criação do app

        dialog_recuperar: false,
        valido:false,
        meu_email:'',

        snackbar: false,
        snack_text: 'My timeout is set to 2000.',
        snack_color: "success",

        versao:'',
        password_dsi:'',
        user:'',
        cpf:'',
        all_users:[],
        show_alert:false,
        nome_empresa:[],
        logar_como:'usuario', //usuario ou cpf
        simples: true,
        eu_sou:'',

        notnullRules: [
          v => !!v || 'necessário'
        ],

        emailRules: [
        // v => !!v || 'necessário',
        v => /.+@.+\..+/.test(v) || 'E-mail inválido!',
       ],
    }),


    created(){
        this.rastreia('Login.vue - created start')
        this.onCreated()
        this.rastreia('Login.vue - created end')
    },

    mounted(){
      
      // console.log('mounted ');
      let r = localStorage['reload_page']
      localStorage['reload_page'] = ''

      // console.log('mounted 2');

      if (r == 'S'){
        // console.log('mounted reload')
        location.reload();//F5 na pagina
      }
    },


    watch:{
        password_dsi(a){
            if ((this.show_alert == true) && (a.length >0)){
                this.show_alert = false
            }
        },
    },

    methods:{


      async limpar_pasta(pasta){
            
            pasta = sessionStorage['LSfolder']
            let parImpar =  diaParOuImpar(null, true)
            pasta = pasta+'/print_lab_'+parImpar  //cria duas pasta assim eu apago a que nao for do dia

            apagar_pasta(pasta)
      },

      alerta(text,color){
        // alert(text);
         this.snackbar = true;
         this.snack_text= text;
         this.snack_color = color;
      },

      async enviar_email_recuperacao(para){

        // TRATA A STRING REMOVE CAMPOS E CARACTERES
        para = para.trim()
        para = para.replaceAll("'",'´');//sql injection
          
        let s =  `select * from usuarios where email = '${para}'`

        const res = await axios.post(url+'conexao.php', {
          acao:       1,
          meuSQL: s,
        })


        await executa(this, para, res.data)

        async function executa(that, para, res){
          
          if (res.length>0){

            res = res[0]

            // ALTERO A SENHA DE RECUPERAÇÃO E MANDO A NOVA SENHA PARA O EMAIL
            let nova_senha = await that.randomString(5)
            nova_senha = await nova_senha.trim()
            let sql = `UPDATE USUARIOS SET USUARIOS.SENHA_RECUPERACAO = '${nova_senha}' WHERE USUARIOS.CODIGO =${res.CODIGO}`
            // console.log(sql);

            await that.crud_sql(sql)


            let r = await that.enviar_email(para,'Recuperação de Senha','Senha de Recuperação: '+nova_senha)
            that.alerta('Senha de Recuperação enviada para seu e-mail','grey lighten-5')

          }
          else{
            that.alerta('E-mail não cadastrado!','orange lighten-2')
          }
        }




      },

      async enviar_email(para, assunto, texto){
          console.log('enviar_email:');

          var url =  sessionStorage['url']
          let link = url+"email.php"

          const data = {
                    
                    para:      para,
                    assunto:   assunto, 
                    texto:     texto,
          }  

          // console.log(data);
          // const header =   { headers: {'Content-Type': 'multipart/form-data'}}
          const header =   {}

          const res = await axios.post(link, data, header);
          console.log(res.data);
          return res.data

      }, 

      somenteNumeros(string) 
      {
          var numsStr = string.replace(/[^0-9]/g,'');
          return parseInt(numsStr);
      },

      async manda_carregar_componentes(valor){
        this.$emit('carregar_componentes', valor)
      },

      async onCreated(){

        await this.get_url() //tem qeu executar antes de tudo

        this.showDrawer()
        this.verificar_sair()
        await this.crud_read()
        await this.crud_empresa()
      },

      async get_url(){
         url = await  DSirotinas.meu_url()
      },

      rastreia(tag){
        if (this.RASTREAR){
           console.log(tag);
        }
      },


      async  tabela_create(tabela, obj_edita){

        const data = {
                acao: 2,
                table:             tabela,
                fields:            obj_edita,
        }  

        const res = await axios.post(url+'conexao.php', data);
        // console.log('tabela create: '+res.data);

      },

      async defineOnline(codigo,status){
      
        let sql = `UPDATE USUARIOS SET USUARIOS.ONLINE = '${status}' WHERE USUARIOS.CODIGO =${codigo}`
        // console.log(sql);

        await this.crud_sql(sql)

      },


      return_key(){

         //pego o mes e o dia multiplico e chego num valor.. sera analisado   
          let dia = new Date().toLocaleDateString('pt-Br',{ datestyle: 'short', hour12: false, timeZone: 'America/Sao_Paulo' }); 
          dia =  dia.slice(3, 5) + dia.slice(0, 2) ; //formato us yyyy-mm-dd
          let a = dia.substring(0,2)
          let b = dia.substring(4,2)
          let c = (a * 3 * b)

          this.set_sessionStorage('version', dia)//logado

          return c
      },

      showDrawer(){
        // console.log('showDrawer');
        this.$emit('executar', false)//mostrar ou nao paineis
      },

      async verificar_sair(){
          
          let logout = sessionStorage['logout']
          let codigo = sessionStorage['8ukjkljdfai'] //pega codigo do usuario para logout

          if (logout == 'S'){
            await  this.defineOnline(codigo, 'N')
            await  registra_log('LOGIN','Saiu')

            // console.clear()
            sessionStorage.clear();//limpo a sessao SAIR limpa session
            // localStorage.clear();//limpo a sessao 
            location.reload();//F5 na pagina para puxar atualizacao
          }

      },


        logar(){
            if (!this.password_dsi){
              alert('Digite a Senha!')
              return 'exit'
            }

            this.showDrawer()  
            this.busca_user(this.user.CODIGO, this.password_dsi );
        },

      set_sessionStorage($name, $value){
        sessionStorage[$name] = $value;
      },



      async crud_read () {

          const res = await axios.post(url+'conexao.php', {
            acao:       1,
            meuSQL:   "select * from usuarios order by usuario ",
          })

        //    console.log(res.data);          
          //  alert(res);
           this.all_users = await res.data; //res       

           this.simples = (res.data.length < 12)
           sessionStorage.setItem('simples',this.simples)

      },

      async crud_empresa () {
               
        // console.log('crud_empresa');
          this.versao = localStorage['v']

          const res = await axios.post(url+'conexao.php', {
            acao:       1,
            meuSQL:   
                `SELECT
                  CONSULTORIOS.NOME,
                  CONSULTORIOS.CEP,
                  CONSULTORIOS.ENDERECO,
                  CONSULTORIOS.CIDADE,
                  CONSULTORIOS.UF,
                  CONSULTORIOS.FONE1,
                  CONSULTORIOS.FONE2,
                  CONSULTORIOS.CELULAR,
                  CONSULTORIOS.CNPJ,
                  CONSULTORIOS.EMAIL1,
                  CONSULTORIOS.MSG_WHATS,
                  CONSULTORIOS.MSG_WHATS_2,
                  CONSULTORIOS.PATH_LOGO,
                  CONSULTORIOS.AGENDAR_ONLINE,
                  CONFIGURACOES.IDE_PROGRAMA
                FROM
                CONSULTORIOS,
                CONFIGURACOES`,
          })

          // PEGAR O CAMPO IDE_PROGRAMA DIRETO DA TABELA CONSULTORIOS =====================================

          //  console.log(res.data);          
          //  alert(res);
           this.nome_empresa = await res.data[0].NOME; //res  

           sessionStorage.setItem('obj_dados_empresa', JSON.stringify(res.data[0]));

           
           //salva dados para usar em outras janelas
           sessionStorage['emp_ide']         = res.data[0].IDE_PROGRAMA
           sessionStorage['LSEmpresa']       = res.data[0].NOME
           sessionStorage['LSCidade']        = res.data[0].CIDADE
           sessionStorage['LSendereco']      = res.data[0].ENDERECO +', CEP: '+ res.data[0].CEP +',  '+res.data[0].CIDADE + ' - '+res.data[0].UF
           sessionStorage['LSid']            = res.data[0].IDE_PROGRAMA
           sessionStorage['LSfones']         = res.data[0].FONE1+'  '+res.data[0].FONE2
           sessionStorage['LSfone1']         = res.data[0].FONE1
           sessionStorage['emp_celular']     = res.data[0].CELULAR
           sessionStorage['LSrua']           = res.data[0].ENDERECO
           sessionStorage['emp_email']       = res.data[0].EMAIL1
           sessionStorage['LScnpj']          = res.data[0].CNPJ

           sessionStorage['LSfolder']        = 'mc_'+res.data[0].IDE_PROGRAMA

           this.set_sessionStorage('whats_obs', res.data[0].MSG_WHATS)
           this.set_sessionStorage('whats_aviso', res.data[0].MSG_WHATS_2)

           sessionStorage['emp_url_logo']   = sessionStorage['url']+'images/'+ res.data[0].PATH_LOGO
           sessionStorage['emp_url_images'] = sessionStorage['url']+'images/'

          //  console.log(res.data[0]);
           

      },


    async crud_sql (sql) {

        const res = await axios.post(url+'conexao.php', {
            acao:   6,
            meuSQL: sql
        })
      
        // console.log(res.data);
        return res.data
    },


      async busca_user (codigo,senha) {
               
        // console.log('busca_user');
        // console.log(codigo, senha);
        senha = senha.replaceAll("'",'´');//sql injection
        

         let s = ''
         if (this.logar_como=='usuario'){
           s =  `select * from usuarios where (codigo = ${codigo}) and (SENHA_WEB = '${senha}')`
          //  s =  `select * from usuarios where (codigo = ${codigo}) and ((SENHA_WEB = '${senha}') or (SENHA_RECUPERACAO = '${senha}'))`
         }else if (this.logar_como=='cpf'){
           s =  `select * from usuarios where (CPF_WEB = '${this.cpf}') and ((SENHA_WEB = '${senha}') or (SENHA_RECUPERACAO = '${senha}'))`
         } 

          const res = await axios.post(url+'conexao.php', {
            acao:       1,
            // meuSQL:   "select * from usuarios where codigo = " + codigo + " and SENHA_WEB = '"+ senha+"'",
            // meuSQL:   "select * from usuarios where CPF_WEB = " + this.cpf + " and SENHA_WEB = '"+ senha+"'",
            meuSQL: s,
          })

        //    console.log(res.data);   

           // ACHOU O USUARIO 
           if ((res.data.length > 0) && (res.data[0].CODIGO > 0)){


              //  depois de logado, carrego os componentes no App.vue = Chat e Lembretes
               await this.manda_carregar_componentes(true)


                //salvo o cpf 
                if (this.user.CPF_WEB == ''){
                  if (this.cpf !=''){
                        let sql = "update usuarios set CPF_WEB ='" + this.cpf + "' where codigo = "+this.user.CODIGO
                        // console.log(sql);   
                        this.crud_sql(sql); 
                        // exit;
                  }
                }


                //logou com sucesso entao salvo o url no sessionstorage 
                // DSirotinas.url_app() //passei pro App.vue

                // APAGA ALGUNS CAMPOS
                this.$set(res.data[0], 'SENHA', '????')
                this.$set(res.data[0], 'SENHA_WEB', '????')
                this.$set(res.data[0], 'SENHA_RECUPERACAO', '????')

                let obj_user = JSON.stringify(res.data[0])
                

                // console.log('obj_user:', obj_user);

                sessionStorage['obj_user']  =  obj_user

                //PERMISSOES:
                sessionStorage['buffer']         = 'S'//logado
                sessionStorage['refresh_size']   = this.return_key()//logado
                //pego o mes e o dia multiplico e chego num valor.. sera analisado   
              
              
                sessionStorage['r0s9i3khepus_is'] =  res.data[0].SUPERUSER 
                sessionStorage['84hfjdyruxy1_is'] =  DSirotinas.cripto(res.data[0].USUARIO)
                sessionStorage['lg:USUARIO']      =  (res.data[0].USUARIO)
                sessionStorage['8ukjkljdfai']     =  res.data[0].CODIGO


                let superUser = res.data[0].SUPERUSER 

                sessionStorage.setItem('uTdoc', (superUser == 'S') || (res.data[0].ALTERADOUTORES == 'S') ? 'S': 'N')
                sessionStorage.setItem('set.alteraconvenio', res.data[0].ALTERASINDICATO)
                sessionStorage.setItem('set.doutor', res.data[0].DOUTOR)
                sessionStorage.setItem('set.agendar', res.data[0].AGENDAR)
                sessionStorage.setItem('set.cadastrar', res.data[0].ALTERACADASTRO)
              
                

                // document.title = this.nome_empresa +' - '+ res.data[0].USUARIO 
                // document.title = 'DSi Clinica - '+ res.data[0].USUARIO +' - '+this.nome_empresa  


                // console.log('user:');
                // console.log(this.user);


                let esse_usuario = res.data[0].USUARIO
                let podeAbrir = res.data[0].DOUTOR //pode abrir atendimento...porem nao vê historico
                // let podeAbrir = 'N' //pode atender
                //pxy9amy
                // i2s8_2d3o2c


                // console.log('esse_usuario:');
                // console.log(esse_usuario);
                if (esse_usuario=='A'){
                    podeAbrir = 'S'
                    sessionStorage.setItem('superA', 'S')
                }else{
                  sessionStorage.setItem('superA', 'N')
                }

                
                if (esse_usuario=='STHAIS'){
                    podeAbrir = 'S'
                }
                if (esse_usuario=='SGLAUCIA'){
                    podeAbrir = 'S'
                }
                if (esse_usuario=='SEC. RAFAELA'){
                    podeAbrir = 'S'
                }


                podeAbrir = superUser

                

                if (res.data[0].CODIGODOUTOR > 0){// DOUTOR
                    this.set_sessionStorage('is_doc','S')
                    this.set_sessionStorage('is_doc_codigo',res.data[0].CODIGODOUTOR)//salvo o codigo do doc para ler na agenda, se for doc abrir somente o seu doc
                    podeAbrir = 'S'
                }
                else{//OPERADOR 
                    this.set_sessionStorage('is_doc','N')
                }

                this.set_sessionStorage('set.podeAbrir',podeAbrir)





                //------------- EXECUTA ALGUMAS FUNCOES -----------------

                //define logado ou nao
                this.defineOnline(this.user.CODIGO, 'S')

                //registra log que entrou
                this.eu_sou = res.data[0].USUARIO

                registra_log('LOGIN','Entrou')

                this.updateVersoes(this.versao)

                this.limpar_pasta()

                //------------- EXECUTA ALGUMAS FUNCOES -----------------


                this.$router.push('/agenda')//redireciono para a pagina do router
                
           }
           else{
               this.set_sessionStorage('buffer','N')
               this.password_dsi = ''
            //    alert('Senha incorreta!')
               this.show_alert = true
           }       
          //  alert(res);
        //    this.all_users =  res.data; //res       
      },


      // executa atualizacao no banco de dados atubd atu bd
      async updateVersoes(versaoAtual){

        define_data_hora(this)//passo o this para que la ele defina as variaveis daqui


        let sql = []
        let r = ''
        let v = versaoAtual


        v = this.somenteNumeros(v)
        

        // sempre executa esses sql´s

        sql.push( `UPDATE CONSULTORIOS SET IDE_PROGRAMA = (SELECT CONFIGURACOES.IDE_PROGRAMA FROM CONFIGURACOES)WHERE CONSULTORIOS.IDE_PROGRAMA IS NULL` )//2.3.87

        sql.push( `UPDATE EXAMES SET QTDESOLICITADA = 1 WHERE QTDESOLICITADA IS NULL` )//2.3.86

        sql.push( `UPDATE LANCAMENTOS SET CONVENIO = '' WHERE CONVENIO IS NULL` )//2.3.64

        sql.push( `UPDATE EXAMES SET COD96 = '' WHERE COD96 IS NULL` )//2.3.60
        

        sql.push( `UPDATE CONFIG_IMPRESSAO SET COD_PROF = '0' WHERE COD_PROF IS NULL` )//2.3.46
        sql.push( `UPDATE IMPRESSOS_WEB SET COD_PROF = '0' WHERE COD_PROF IS NULL` )//2.3.46

        sql.push( `UPDATE CLIENTES SET ATIVO = 'S' WHERE ATIVO IS NULL` )//2.3.46
        sql.push( `DELETE FROM ALERTA WHERE DATA_ALERTA <= DATEADD(-2 YEAR TO CURRENT_DATE)` )//2.3.46

        sql.push( `DELETE FROM mensagem WHERE data <= DATEADD(-45 DAY TO CURRENT_DATE)` )
        sql.push( `DELETE FROM REGISTRO_LOG WHERE data <= DATEADD(-180 DAY TO CURRENT_DATE)` ) //6 meses
        sql.push( `UPDATE EXAMES SET CID = ' ' WHERE CID IS NULL` )
        sql.push( `DELETE FROM BLOQUEAR WHERE DATA_ATE < 'TODAY'` )//2.3.23
        // sql.push( `DELETE FROM ANTIGO_HORARIO WHERE ATE < 'TODAY'` )//2.3.23
        // console.log(sql);


        // atualizar versao versoes
        if (v <= 2399){


            // sql.push( `ALTER TABLE PT ADD ANOTACAO  VARCHAR(9999)` )//2.3.88
            sql.push( `ALTER TABLE PC ADD SITUACAO  VARCHAR(20)` )//2.3.88
            sql.push( `ALTER TABLE PC ADD ANOTACAO  VARCHAR(9999)` )//2.3.88
            sql.push( `UPDATE PC SET SITUACAO = 'ATIVO' WHERE SITUACAO IS NULL` )//2.3.88
            sql.push( `UPDATE LANCAMENTOS SET DATA_EFETUADO = DATA WHERE DATA_EFETUADO IS NULL AND EFETUADO = 'S'` )//2.3.88

            this.sql_nova_tabela('OCORRENCIA') //2.3.87

            sql.push( `ALTER TABLE OCORRENCIA ADD ATIVO  VARCHAR(10)` )//2.3.87

            sql.push( `ALTER TABLE PC ADD NUM_DENTE VARCHAR(10)` )//2.3.87
            sql.push( `ALTER TABLE PC ADD VALOR DOUBLE PRECISION` )//2.3.87
            sql.push( `ALTER TABLE PC ADD QT INTEGER` )//2.3.87
            sql.push( `ALTER TABLE PC ADD TOTAL DOUBLE PRECISION` )//2.3.87
            sql.push( `ALTER TABLE PC ADD APROVADO VARCHAR(10)` )//2.3.87

            sql.push( `update PC set APROVADO  = 'S' where APROVADO is null` )//2.3.87

            sql.push( `ALTER TABLE CONSULTORIOS ADD IDE_PROGRAMA VARCHAR(10)` )//2.3.87

            sql.push( `ALTER TABLE USUARIOS ADD ABRE_ATENDIMENTO  VARCHAR(10)` )//2.3.79
            sql.push( `ALTER TABLE USUARIOS ADD VER_EVOLUCAO      VARCHAR(10)` )//2.3.79
            sql.push( `update usuarios set ABRE_ATENDIMENTO = DOUTOR where ABRE_ATENDIMENTO is null` )//2.3.79
            sql.push( `update usuarios set VER_EVOLUCAO     = 'N'    where VER_EVOLUCAO is null` )//2.3.79

            sql.push( `ALTER TABLE VALORES_AMB ADD AGENDAR_ONLINE VARCHAR(10)` )//2.3.78
            sql.push( `ALTER TABLE ANTIGO_HORARIO ADD AGENDAR_ONLINE VARCHAR(10)` )//2.3.78
            sql.push( `ALTER TABLE CONSULTORIOS ADD AGENDAR_ONLINE VARCHAR(10)` )//2.3.78
            
            sql.push( `ALTER TABLE USUARIOS ADD CODIGO_CONVENIO INTEGER` )//2.3.71
            
            sql.push( `ALTER TABLE VALORES_AGENDA ADD DOUBLE_UM DOUBLE PRECISION` )//2.3.69
            sql.push( `ALTER TABLE VALORES_AGENDA ADD DOUBLE_DOIS DOUBLE PRECISION` )//2.3.69
            sql.push( `UPDATE VALORES_AGENDA SET DOUBLE_UM = UM WHERE double_UM IS NULL` )//2.3.64
            sql.push( `UPDATE VALORES_AGENDA SET DOUBLE_DOIS = DOIS WHERE double_DOIS IS NULL` )//2.3.64
            
            sql.push( `ALTER TABLE CONFIG_WHATS ADD RESPOSTA_CONFIRMADO VARCHAR(900)` )//2.3.66

            sql.push( `ALTER TABLE CONFIG_WHATS ADD MSG_OBS_2 VARCHAR(900)` )//2.3.64
            sql.push( `ALTER TABLE LANCAMENTOS ADD CONVENIO VARCHAR(90)` )//2.3.64
            sql.push( `ALTER TABLE LANCAMENTOS ADD SEQUENCIA VARCHAR(10)` )//2.3.64

            sql.push( `ALTER TABLE LANCAMENTOS ALTER COLUMN OPERACAO TYPE VARCHAR(200)` )//2.3.64
            sql.push( `ALTER TABLE LANCAMENTOS ALTER COLUMN CATEGORIA TYPE VARCHAR(200)` )//2.3.64
            sql.push( `ALTER TABLE LANCAMENTOS ALTER COLUMN DESC_PROCEDIMENTO TYPE VARCHAR(200)` )//2.3.64


            

            // sql.push( `ALTER TABLE WEB_REGISTROS ALTER COLUMN ENVIAR_PARAR TYPE VARCHAR(500)` )//2.3.60
            sql.push( `ALTER TABLE WEB_REGISTROS ADD PATH_ASSINATURA BLOB sub_type 1 segment size 1` )//2.3.60

            this.sql_nova_tabela('FARMACIAS') //2.3.59

            this.sql_nova_tabela('WEB_REGISTROS') //2.3.58 

            sql.push( `ALTER TABLE WP_CAMPANHA ADD ENVIAR_PARAR VARCHAR(20)` )//2.3.55

            sql.push( `ALTER TABLE CADASTRO_ORC ADD TEXTO_2 VARCHAR(9999)` )//2.3.54
            
            sql.push( `ALTER TABLE CLIENTES ADD AVISO VARCHAR(600)` )//2.3.54

            sql.push( `ALTER TABLE LANCAMENTOS ADD OPERACAO VARCHAR(60)` )//2.3.51
            sql.push( `ALTER TABLE LANCAMENTOS ADD DESCRICAO VARCHAR(100)` )//2.3.51
            sql.push( `ALTER TABLE LANCAMENTOS ADD LANCOU VARCHAR(60)` )//2.3.51
            sql.push( `ALTER TABLE LANCAMENTOS ADD VISTO VARCHAR(1)` )//2.3.51
            sql.push( `ALTER TABLE LANCAMENTOS ADD CODIGO_CONSULTA INTEGER` )//2.3.51
            sql.push( `ALTER TABLE LANCAMENTOS ADD CODIGO_CONVENIO INTEGER` )//2.3.51
            sql.push( `ALTER TABLE LANCAMENTOS ADD DESC_PROCEDIMENTO VARCHAR(200)` )//2.3.51
            sql.push( `ALTER TABLE LANCAMENTOS ADD DATA_LANCAMENTO DATE` )//2.3.51

            sql.push( `ALTER TABLE LANCAMENTOS ADD R_RECEBEU VARCHAR(60)` )//2.3.51
            sql.push( `ALTER TABLE LANCAMENTOS ADD COD_USER_RECEBEU INTEGER` )//2.3.51
            sql.push( `ALTER TABLE LANCAMENTOS ADD COD_USER_LANCOU INTEGER` )//2.3.51

            

            
            

            sql.push( `ALTER TABLE IMPRESSOS_WEB ADD NOME_FORMATO VARCHAR(60)` )//2.3.47
            sql.push( `UPDATE IMPRESSOS_WEB SET NOME_FORMATO = 'NENHUM' WHERE NOME_FORMATO IS NULL` )//2.3.47


            sql.push( `ALTER TABLE CONFIG_IMPRESSAO ADD COD_PROF integer` )//2.3.47
            sql.push( `UPDATE CONFIG_IMPRESSAO SET COD_PROF = '0' WHERE COD_PROF IS NULL` )//2.3.47
            
            sql.push( `ALTER TABLE IMPRESSOS_WEB ADD COD_PROF integer` )//2.3.47
            sql.push( `UPDATE IMPRESSOS_WEB SET COD_PROF = '0' WHERE COD_PROF IS NULL` )//2.3.47

            sql.push( `ALTER TABLE CONFIG_IMPRESSAO ADD MOSTRAR_LOGO_PRO VARCHAR(1)` )//2.3.41

            sql.push( `ALTER TABLE WP_CAMPANHA ADD URL_FILE VARCHAR(999)` )//2.3.36

            sql.push( `ALTER TABLE VALORES_AMB ADD WHATS_OBS VARCHAR(999)` )//2.3.35


            this.sql_nova_tabela('WP_CONTATOS') //2.3.29 
            this.sql_nova_tabela('WP_CAMPANHA') //2.3.29 

            this.sql_nova_tabela('BLOQUEAR') //2.3.23 

            sql.push( `ALTER TABLE CONVENIO ADD AGENDAR_ONLINE VARCHAR(1)` )//2.3.23
            sql.push( `ALTER TABLE DOUTORES ADD AGENDAR_ONLINE VARCHAR(1)` )//2.3.23

            sql.push( `ALTER TABLE CLIENTES ADD ENVIOU_AVISO DATE` )//2.3.21

            sql.push( `ALTER TABLE AGENDA ADD HORA_ATE TIME` )//2.3.20
            sql.push( `ALTER TABLE LANCAMENTOS ADD CATEGORIA VARCHAR(200)` )
            sql.push( `UPDATE LANCAMENTOS SET LANCAMENTOS.CATEGORIA = (SELECT VALORES_AMB.CATEGORIA FROM VALORES_AMB WHERE VALORES_AMB.CODIGO = LANCAMENTOS.CODIGO_PROCED) WHERE CATEGORIA IS NULL` )

            sql.push( `ALTER TABLE MANIPULADOS ADD TEXTO VARCHAR(9999)` )
            sql.push( `ALTER TABLE MENSAGEM ADD NOTIFICOU VARCHAR(1)` )

            sql.push( `ALTER TABLE CONFIG_IMPRESSAO ADD MOSTRAR_CIDADE VARCHAR(1)` )
            sql.push( `ALTER TABLE CONFIG_IMPRESSAO ADD MOSTRAR_ASSINATURA VARCHAR(1)` )

            sql.push( `update CONFIG_IMPRESSAO set MOSTRAR_CIDADE = MOSTRAR_RODAPE where MOSTRAR_CIDADE is null` )
            sql.push( `update CONFIG_IMPRESSAO set MOSTRAR_ASSINATURA = MOSTRAR_RODAPE where MOSTRAR_ASSINATURA is null` )

            sql.push( `ALTER TABLE IMPRESSOS_WEB ADD PATH_IMG VARCHAR(300)` )
            sql.push( `ALTER TABLE IMPRESSOS_WEB ADD FORMATO_PAPEL VARCHAR(40)` )

            sql.push( `ALTER TABLE CLIENTES ADD FOTO_URL VARCHAR(300)` )
            sql.push( `ALTER TABLE AGENDA ADD WHATS_ID VARCHAR(90)` )
            sql.push( `ALTER TABLE AGENDA ADD WHATS_RETORNO VARCHAR(200)` )
            

            this.sql_nova_tabela('CONFIG_IMPRESSAO')
            this.sql_nova_tabela('CONFIG_WHATS')

            sql.push( `ALTER TABLE USUARIOS ADD EMAIL VARCHAR(200)`)

            sql.push( ``)

        }
        // console.log(sql);
        // alert(sql.length)

        if (sql?.length>0){
          for(let i = 0; i <= sql.length; i++){

            r = await this.crud_sql(sql[i]); 
            // console.log(r);
          }
        }


        // se estiver no BD teste.. joga a agenda pro dia de hoje
        if (sessionStorage['LSid'] == '45'){

          let sql2 = `update agenda set data = '${this.today}' where codigoconsultorio = 45 `
          
          console.log(sql2); 
          r = await this.crud_sql(sql2);
          console.log(r);


          sql2 = `update agenda set data = '${this.today}', atendido = 'M', AVISOU_WHATS = '', OK_VIA_WHATS = '' where CODIGOMEDICO = 46 `
          
          console.log(sql2); 
          r = await this.crud_sql(sql2);
          console.log(r);

        }

      },



      async sql_nova_tabela(tabela){
        let sql = ''
        let r =

        sql = this.returna_estrutra_tabela(tabela)
        r = await this.crud_sql(sql);

        sql = `ALTER TABLE ${tabela} ADD CONSTRAINT PK_${tabela} PRIMARY KEY (CODIGO)`
        r = await this.crud_sql(sql);
        
        sql = `CREATE GENERATOR ${tabela}_CODIGO_GEN`
        r = await this.crud_sql(sql);
        
        sql = `
        CREATE TRIGGER AI_${tabela}_CODIGO FOR ${tabela} ACTIVE 
                  BEFORE INSERT POSITION 0 AS  BEGIN 
                  IF (NEW.CODIGO IS NULL) THEN 
                  NEW.CODIGO = GEN_ID(${tabela}_CODIGO_GEN, 1); END
        `
        r = await this.crud_sql(sql);
        
        
      },

      returna_estrutra_tabela(tabela){

        let r = ''

        if (tabela == 'OCORRENCIA'){

          r = `
            CREATE TABLE OCORRENCIA (
              CODIGO INTEGER NOT NULL,
              DESCRICAO VARCHAR (60) CHARACTER SET NONE COLLATE NONE,
              VALOR NUMERIC (18, 2),
              DURACAO VARCHAR (2) CHARACTER SET NONE COLLATE NONE,
              ALERTA VARCHAR (100) CHARACTER SET WIN1251 COLLATE WIN1251,
              ATIVO VARCHAR(10));
          `
        }
        else if (tabela == 'CONFIG_IMPRESSAO'){
          r = `
          CREATE TABLE CONFIG_IMPRESSAO (
            CODIGO INTEGER NOT NULL,
            COD_DOC INTEGER,
            MODELO_IMPRESSAO VARCHAR (90) CHARACTER SET WIN1251 COLLATE WIN1251,
            ALTURA_CABECALHO VARCHAR(5),
            ALTURA_MEIO VARCHAR(5),
            ALTURA_RODAPE VARCHAR(5),
            LARGURA_PAGINA VARCHAR(5),
            MOSTRAR_LOGO VARCHAR (1) CHARACTER SET WIN1251 COLLATE WIN1251,
            POSICAO_LOGO VARCHAR (20) CHARACTER SET WIN1251 COLLATE WIN1251,
            ALTURA_LOGO VARCHAR (5) CHARACTER SET WIN1251 COLLATE WIN1251,
            MOSTRAR_TITULO VARCHAR (1) CHARACTER SET WIN1251 COLLATE WIN1251,
            MOSTRAR_RODAPE VARCHAR (1) CHARACTER SET WIN1251 COLLATE WIN1251,
            MOSTRAR_ENDERECO VARCHAR (1) CHARACTER SET WIN1251 COLLATE WIN1251,
            MOSTRAR_DIR_TECNICO VARCHAR (1) CHARACTER SET WIN1251 COLLATE WIN1251);
            MOSTRAR_CIDADE VARCHAR (1) CHARACTER SET WIN1251 COLLATE WIN1251);
            MOSTRAR_ASSINATURA VARCHAR (1) CHARACTER SET WIN1251 COLLATE WIN1251);
          `
        }

        else if (tabela == 'WEB_REGISTROS'){
          r = `
          CREATE TABLE WEB_REGISTROS (
            CODIGO INTEGER NOT NULL,
            CODIGO_CLIENTE INTEGER ,
            CODIGO_DOC INTEGER ,
            NOME_DOC VARCHAR (200) CHARACTER SET WIN1251 COLLATE WIN1251,
            DATA DATE ,
            NOME_TEXTO VARCHAR (90) CHARACTER SET WIN1251 COLLATE WIN1251,
            TEXTO_REGISTRO BLOB sub_type 1 segment size 1,
            ACEITOU VARCHAR (20) CHARACTER SET WIN1251 COLLATE WIN1251,
            CHAVE VARCHAR (100) CHARACTER SET WIN1251 COLLATE WIN1251,
            DATA_HORA VARCHAR (40) CHARACTER SET WIN1251 COLLATE WIN1251,
            IP VARCHAR (100) CHARACTER SET WIN1251 COLLATE WIN1251,
            LATITUDE VARCHAR (40) CHARACTER SET WIN1251 COLLATE WIN1251,
            LONGITUDE VARCHAR (40) CHARACTER SET WIN1251 COLLATE WIN1251,
            PATH_PDF VARCHAR (500) CHARACTER SET WIN1251 COLLATE WIN1251,
            PATH_ASSINATURA BLOB sub_type 1 segment size 1,
            QUEM_GEROU VARCHAR (60) CHARACTER SET WIN1251 COLLATE WIN1251,
            QUEM_ENVIOU VARCHAR (60) CHARACTER SET WIN1251 COLLATE WIN1251,
            TIPO VARCHAR (40) CHARACTER SET WIN1251 COLLATE WIN1251
            );
          `
        }

        else if (tabela == 'FARMACIAS'){
          r = `
          CREATE TABLE FARMACIAS (
            CODIGO INTEGER NOT NULL,
            ATIVO VARCHAR (10) CHARACTER SET WIN1251 COLLATE WIN1251,
            NOME VARCHAR (100) CHARACTER SET WIN1251 COLLATE WIN1251,
            NUMERO VARCHAR (20) CHARACTER SET WIN1251 COLLATE WIN1251,
            MENSAGEM_WHATS VARCHAR (300) CHARACTER SET WIN1251 COLLATE WIN1251,
            DESCRICAO VARCHAR (100) CHARACTER SET WIN1251 COLLATE WIN1251);
          `
        }

        else if (tabela == 'CONFIG_WHATS'){
          r = `
          CREATE TABLE CONFIG_WHATS (
            CODIGO INTEGER NOT NULL,
            MSG_CONFIRMACAO VARCHAR (900) CHARACTER SET WIN1251 COLLATE WIN1251,
            MSG_OBS VARCHAR (900) CHARACTER SET WIN1251 COLLATE WIN1251,
            MSG_AVISO VARCHAR (900) CHARACTER SET WIN1251 COLLATE WIN1251,
            MSG_OBS_2 VARCHAR (900) CHARACTER SET WIN1251 COLLATE WIN1251);
          `
        }

        else if (tabela == 'BLOQUEAR'){
          r = `
          CREATE TABLE BLOQUEAR (
            CODIGO INTEGER NOT NULL,
            CODIGO_DOC INTEGER,
            DATA_DE DATE,
            DATA_ATE DATE,
            MOTIVO VARCHAR (200) CHARACTER SET WIN1251 COLLATE WIN1251,
            QUEM_ALTEROU VARCHAR (200) CHARACTER SET WIN1251 COLLATE WIN1251);
          `
        }

        else if (tabela == 'WP_CAMPANHA'){
          r = `
          CREATE TABLE WP_CAMPANHA (
            CODIGO INTEGER NOT NULL,
            DATA DATE,
            TITULO VARCHAR (200) CHARACTER SET WIN1251 COLLATE WIN1251,
            TEXTO VARCHAR (9999) CHARACTER SET WIN1251 COLLATE WIN1251,
            NOMES VARCHAR (9999) CHARACTER SET WIN1251 COLLATE WIN1251);
          `
        }

        else if (tabela == 'WP_CONTATOS'){
          r = `
          CREATE TABLE WP_CONTATOS (
            CODIGO INTEGER NOT NULL,
            COD_CAMPANHA INTEGER,
            COD_CLIENTE INTEGER,
            NOME VARCHAR (60) CHARACTER SET WIN1251 COLLATE WIN1251,
            NUMERO VARCHAR (20) CHARACTER SET WIN1251 COLLATE WIN1251,
            DATA_VISTO DATE,
            ID_WHATS VARCHAR (99) CHARACTER SET WIN1251 COLLATE WIN1251);
          `
        }

        return r
      },

      async randomString(length){
        return await  DSirotinas.randomString(length)
      },

    }
  

}
</script>

<style>
.main{
    padding: 5px;
  }

/* .v-main__wrap{
    background-color: #6699ff
}   */
</style>

